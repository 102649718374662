// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/TransactionReports/';

// Async Thunks
export const getAllTransactionReports = createAsyncThunk('backend_getalltransactionreports', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllTransactionReports`, { signal, withCredentials: true });
    return res.data.data.map(item => ({ ...item, date: item.date.slice(0, 10) }));
  } catch (error) { throw error?.response?.data || error; }
});
export const getTransactionReportById = createAsyncThunk('backend_gettransactionreportbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetTransactionReportById?id=${id}`, { withCredentials: true });
    return { ...res.data.data, date: res.data.data.date.slice(0, 10) };
  } catch (error) { throw error.response.data || error; }
});
export const addTransactionReport = createAsyncThunk('backend_addnewtransactionreport', async ({ newtransactionreport }) => {
  try {
    const res = await axios.post(`${baseurl}AddTransactionReport`, newtransactionreport, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateTransactionReport = createAsyncThunk('backend_updatetransactionreport', async ({ transactionreport }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateTransactionReport`, transactionreport, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteTransactionReport = createAsyncThunk('backend_deletetransactionreport', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteTransactionReport?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const transactionreportSlice = createSlice({
  name: 'transactionreport',
  initialState: {
    transactionreports: [],
    transactionreport: {},
    getAllStatus: null,
    getDataStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetGetDataStatus: (state) => { state.getDataStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTransactionReports.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllTransactionReports.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.transactionreports = action.payload || []; })
      .addCase(getAllTransactionReports.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getTransactionReportById.pending, (state) => { state.getDataStatus = "pending"; })
      .addCase(getTransactionReportById.fulfilled, (state, action) => { state.getDataStatus = "fulfilled"; state.transactionreport = action.payload || {}; })
      .addCase(getTransactionReportById.rejected, (state, action) => { state.getDataStatus = "rejected"; state.error = action.error.message; })
      .addCase(addTransactionReport.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addTransactionReport.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addTransactionReport.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateTransactionReport.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateTransactionReport.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateTransactionReport.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteTransactionReport.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteTransactionReport.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteTransactionReport.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = transactionreportSlice.actions;
export default transactionreportSlice.reducer;