// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllDealers, getDealerById, addNewDealer, updateDealer, deleteDealer, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/DealerSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import DealerAddModal from '../modals/dealers/DealerAddModal';
import DealerUpdateModal from '../modals/dealers/DealerUpdateModal';
import DealerDetailsModal from '../modals/dealers/DealerDetailsModal';
import DealerDeleteModal from '../modals/DeleteModal';
// Icons
import { TbHomePlus, TbHomeMinus, TbHomeCog, TbHomeStar, TbPrinter } from 'react-icons/tb';

function Dealers() {
  // Storage Import
  const dispatch = useDispatch();
  const { dealers, dealer, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.dealer);
  // Context Import
  const { myUser } = useData();
  const { dictionaryStatuses } = useFunctions();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllDealers({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      setDataToTable(dealers.map(item => ({ ...item, status: dictionaryStatuses[item.status] })));
      setDataToPDF(dealers.map(item => ({ ...item, status: dictionaryStatuses[item.status] })));
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseDealerAddModal();
      dispatch(addToast({ background: 'success', icon: 'dealerAdd', message: 'Bayi Eklendi!', delay: 6000 }));
      dispatch(getAllDealers({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseDealerUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'dealerUpdate', message: 'Bayi Güncellendi!', delay: 6000 }));
      dispatch(getAllDealers({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseDealerDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'dealerDelete', message: 'Bayi Silindi!', delay: 6000 }));
      dispatch(getAllDealers({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataDealerUpdateModal({ ...dealer, status: dictionaryStatuses[dealer.status] });
        setShowDealerUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'details') {
        setInputDataDealerDetailsModal({ ...dealer, status: dictionaryStatuses[dealer.status] });
        setShowDealerDetailsModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataDealerDeleteModal({ ...dealer, status: dictionaryStatuses[dealer.status] });
        setShowDealerDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'BAYİ' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'name', 'status'];
  const clickOn = (id) => { handleShowDealerUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <TbHomeCog className='button-icon' />,
      onclick: ((rowData) => { handleShowDealerUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Detaylar',
      icon: <TbHomeStar className='button-icon' />,
      onclick: ((rowData) => { handleShowDealerDetailsModal(rowData.id); }),
      variant: 'info'
    },
    {
      text: 'Sil',
      icon: <TbHomeMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowDealerDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Bayiler';
  const operations = [
    {
      text: 'Bayi Ekle',
      icon: <TbHomePlus className='button-icon' />,
      onclick: (() => { handleShowDealerAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showDealerAddModal, setShowDealerAddModal] = useState(false);
  const handleCloseDealerAddModal = () => {
    setShowDealerAddModal(false);
  }
  const handleShowDealerAddModal = () => {
    setShowDealerAddModal(true);
  }
  const handleAddDealerAddModal = (values) => {
    dispatch(addNewDealer({ newdealer: values }));
  }
  // Update Modal
  const [showDealerUpdateModal, setShowDealerUpdateModal] = useState(false);
  const [inputDataDealerUpdateModal, setInputDataDealerUpdateModal] = useState(null);
  const handleCloseDealerUpdateModal = () => {
    setShowDealerUpdateModal(false);
    setInputDataDealerUpdateModal(null);
  }
  const handleShowDealerUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getDealerById({ id: id }));
  }
  const handleUpdateDealerUpdateModal = (values) => {
    dispatch(updateDealer({ dealer: values }));
  }
  // Details Modal
  const [showDealerDetailsModal, setShowDealerDetailsModal] = useState(false);
  const [inputDataDealerDetailsModal, setInputDataDealerDetailsModal] = useState(null);
  const handleCloseDealerDetailsModal = () => {
    setShowDealerDetailsModal(false);
    setInputDataDealerDetailsModal(null);
  }
  const handleShowDealerDetailsModal = (id) => {
    setDataRequester('details');
    dispatch(getDealerById({ id: id }));
  }
  // Delete Modal
  const [showDealerDeleteModal, setShowDealerDeleteModal] = useState(false);
  const [inputDataDealerDeleteModal, setInputDataDealerDeleteModal] = useState(null);
  const handleCloseDealerDeleteModal = () => {
    setShowDealerDeleteModal(false);
    setInputDataDealerDeleteModal(null);
  }
  const handleShowDealerDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getDealerById({ id: id }));
  }
  const handleDeleteDealerDeleteModal = () => {
    dispatch(deleteDealer({ id: inputDataDealerDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <DealerAddModal
        show={showDealerAddModal}
        handleClose={handleCloseDealerAddModal}
        handleAdd={handleAddDealerAddModal}
      />
      <DealerUpdateModal
        show={showDealerUpdateModal}
        handleClose={handleCloseDealerUpdateModal}
        handleUpdate={handleUpdateDealerUpdateModal}
        inputData={inputDataDealerUpdateModal}
      />
      <DealerDetailsModal
        show={showDealerDetailsModal}
        handleClose={handleCloseDealerDetailsModal}
        inputData={inputDataDealerDetailsModal}
      />
      <DealerDeleteModal
        show={showDealerDeleteModal}
        handleClose={handleCloseDealerDeleteModal}
        handleDelete={handleDeleteDealerDeleteModal}
        title="Bayiyi Sil"
        message={`${inputDataDealerDeleteModal?.name} adlı bayiyi silmek istediğinize emin misiniz?`}
        deleteIcon={<TbHomeMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showDealerAddModal, showDealerUpdateModal, inputDataDealerUpdateModal, showDealerDetailsModal, inputDataDealerDetailsModal, showDealerDeleteModal, inputDataDealerDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Dealers;