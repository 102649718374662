// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Stocks/';

// Async Thunks
export const getAllStocks = createAsyncThunk('backend_getallstocks', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllStocks`, { signal, withCredentials: true });
    return res.data.data.map(item => ({ ...item, updatedDate: item.updatedDate.slice(0, 10) }));
  } catch (error) { throw error?.response?.data || error; }
});
export const getStockById = createAsyncThunk('backend_getstockbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetStockById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewStock = createAsyncThunk('backend_addnewstock', async ({ newstock }) => {
  try {
    const res = await axios.post(`${baseurl}AddStock`, newstock, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateStock = createAsyncThunk('backend_updatestock', async ({ stock }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateStock`, stock, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteStock = createAsyncThunk('backend_deletestock', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteStock?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const stockSlice = createSlice({
  name: 'stock',
  initialState: {
    stocks: [],
    stock: {},
    getAllStatus: null,
    getDataStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetGetDataStatus: (state) => { state.getDataStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStocks.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllStocks.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.stocks = action.payload || []; })
      .addCase(getAllStocks.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getStockById.pending, (state) => { state.getDataStatus = "pending"; })
      .addCase(getStockById.fulfilled, (state, action) => { state.getDataStatus = "fulfilled"; state.stock = action.payload || {}; })
      .addCase(getStockById.rejected, (state, action) => { state.getDataStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewStock.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewStock.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewStock.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateStock.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateStock.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateStock.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteStock.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteStock.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteStock.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = stockSlice.actions;
export default stockSlice.reducer;