// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllShelves, getShelfById, addNewShelf, updateShelf, deleteShelf, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/ShelfSlice';
import { getAllWarehouses, addNewWarehouse, resetAddStatus as resetAddWarehouseStatus, resetError as resetErrorWarehouse } from '../../../storage/slices/backend/WarehouseSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import ShelfAddModal from '../modals/shelves/ShelfAddModal';
import WarehouseAddModal from '../modals/warehouses/WarehouseAddModal';
import ShelfUpdateModal from '../modals/shelves/ShelfUpdateModal';
import ShelfDeleteModal from '../modals/DeleteModal';
// Icons
import { LuFolderPlus, LuFolderMinus, LuFolderCog } from "react-icons/lu";
import { TbPrinter, TbHomePlus } from 'react-icons/tb';

function Shelves() {
  // Storage Import
  const dispatch = useDispatch();
  const { shelves, shelf, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.shelf);
  const { addStatus: addWarehouseStatus, error: errorWarehouse } = useSelector((state) => state.warehouse);
  // Context Import
  const { myUser } = useData();
  const { dictionaryStatuses } = useFunctions();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllShelves({ signal }));
      await dispatch(getAllWarehouses({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      setDataToTable(shelves);
      setDataToPDF(shelves);
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseShelfAddModal();
      dispatch(addToast({ background: 'success', icon: 'shelfAdd', message: 'Raf Eklendi!', delay: 6000 }));
      dispatch(getAllShelves({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (addWarehouseStatus === "fulfilled") {
      handleCloseWarehouseAddModal();
      dispatch(addToast({ background: 'success', icon: 'warehouseAdd', message: 'Depo Eklendi!', delay: 6000 }));
      dispatch(getAllWarehouses({}));
      dispatch(resetAddWarehouseStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addWarehouseStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseShelfUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'shelfUpdate', message: 'Raf Güncellendi!', delay: 6000 }));
      dispatch(getAllShelves({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseShelfDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'shelfDelete', message: 'Raf Silindi!', delay: 6000 }));
      dispatch(getAllShelves({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataShelfUpdateModal(shelf);
        setShowShelfUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataShelfDeleteModal(shelf);
        setShowShelfDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  useEffect(() => {
    if (errorWarehouse && errorWarehouse !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: errorWarehouse, delay: 4000 }));
      dispatch(resetErrorWarehouse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorWarehouse]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'RAF' }, { value: 'warehouseName', name: 'DEPO' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'name', 'warehouseName', 'description'];
  const clickOn = (id) => { handleShowShelfUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <LuFolderCog className='button-icon' />,
      onclick: ((rowData) => { handleShowShelfUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <LuFolderMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowShelfDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Raflar';
  const operations = [
    {
      text: 'Raf Ekle',
      icon: <LuFolderPlus className='button-icon' />,
      onclick: (() => { handleShowShelfAddModal(); })
    },
    {
      text: 'Depo Ekle',
      icon: <TbHomePlus className='button-icon' />,
      onclick: (() => { handleShowWarehouseAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showShelfAddModal, setShowShelfAddModal] = useState(false);
  const handleCloseShelfAddModal = () => {
    setShowShelfAddModal(false);
  }
  const handleShowShelfAddModal = () => {
    setShowShelfAddModal(true);
  }
  const handleAddShelfAddModal = (values) => {
    dispatch(addNewShelf({ newshelf: values }));
  }
  // Add Modal (Warehouse)
  const [showWarehouseAddModal, setShowWarehouseAddModal] = useState(false);
  const handleCloseWarehouseAddModal = () => {
    setShowWarehouseAddModal(false);
  }
  const handleShowWarehouseAddModal = () => {
    setShowWarehouseAddModal(true);
  }
  const handleAddWarehouseAddModal = (values) => {
    dispatch(addNewWarehouse({ newwarehouse: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Update Modal
  const [showShelfUpdateModal, setShowShelfUpdateModal] = useState(false);
  const [inputDataShelfUpdateModal, setInputDataShelfUpdateModal] = useState(null);
  const handleCloseShelfUpdateModal = () => {
    setShowShelfUpdateModal(false);
    setInputDataShelfUpdateModal(null);
  }
  const handleShowShelfUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getShelfById({ id: id }));
  }
  const handleUpdateShelfUpdateModal = (values) => {
    dispatch(updateShelf({ shelf: values }));
  }
  // Delete Modal
  const [showShelfDeleteModal, setShowShelfDeleteModal] = useState(false);
  const [inputDataShelfDeleteModal, setInputDataShelfDeleteModal] = useState(null);
  const handleCloseShelfDeleteModal = () => {
    setShowShelfDeleteModal(false);
    setInputDataShelfDeleteModal(null);
  }
  const handleShowShelfDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getShelfById({ id: id }));
  }
  const handleDeleteShelfDeleteModal = () => {
    dispatch(deleteShelf({ id: inputDataShelfDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <ShelfAddModal
        show={showShelfAddModal}
        handleClose={handleCloseShelfAddModal}
        handleAdd={handleAddShelfAddModal}
      />
      <WarehouseAddModal
        show={showWarehouseAddModal}
        handleClose={handleCloseWarehouseAddModal}
        handleAdd={handleAddWarehouseAddModal}
      />
      <ShelfUpdateModal
        show={showShelfUpdateModal}
        handleClose={handleCloseShelfUpdateModal}
        handleUpdate={handleUpdateShelfUpdateModal}
        inputData={inputDataShelfUpdateModal}
      />
      <ShelfDeleteModal
        show={showShelfDeleteModal}
        handleClose={handleCloseShelfDeleteModal}
        handleDelete={handleDeleteShelfDeleteModal}
        title="Rafı Sil"
        message={`${inputDataShelfDeleteModal?.name} adlı dafı silmek istediğinize emin misiniz?`}
        deleteIcon={<LuFolderMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showShelfAddModal, showWarehouseAddModal, showShelfUpdateModal, inputDataShelfUpdateModal, showShelfDeleteModal, inputDataShelfDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Shelves;