// General
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
// Styles
import { Modal } from 'react-bootstrap';
import { TextField, Autocomplete, Chip } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Checkbox from '@mui/material/Checkbox';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getDistricts } from '../../../../storage/slices/general/DistrictSlice';
import { getUserAssignedServices, assignAServiceToAnEmployee } from '../../../../storage/slices/backend/ServiceplanSlice';
// Contexts
import { useData } from '../../../../context/DataContext';
import { useFunctions } from '../../../../context/FunctionContext';
// Icons
import { TiDelete } from "react-icons/ti";
import { TbUserMinus } from "react-icons/tb";
import { IoEye } from "react-icons/io5";
import { GrPlan } from "react-icons/gr";
// Modals
import UserServicesModal from './UserServicesModal';
import ServiceAssignModal from './ServiceAssignModal';

function ServicePlannerModal({ show, handleClose }) {
  // Storage Import
  const dispatch = useDispatch();
  const { districts } = useSelector((state) => state.district);
  const { planServices } = useSelector((state) => state.serviceplan);
  const { employees } = useSelector((state) => state.employee);
  const { devices } = useSelector((state) => state.device);
  const { sources } = useSelector((state) => state.source);
  // Context Import
  const { myUser, cities, serviceOperations } = useData();
  const { getStringDate, configureServiceAssignData } = useFunctions();
  // Variables
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [searchedCity, setSearchedCity] = useState('');
  // Filtering
  const [filterDate, setFilterDate] = useState(getStringDate(0));
  const [filterCity, setFilterCity] = useState('İstanbul');
  const [filterDistrict, setFilterDistrict] = useState([]);
  const [filterDevice, setFilterDevice] = useState([]);
  const [filterSource, setFilterSource] = useState([]);
  const [filterStatus, setFilterStatus] = useState('');
  // Api Operations
  const fetchAllDataSequentially = async (dispatch, signal, search) => {
    try {
      await dispatch(getDistricts({ signal, city: search }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (filterCity !== searchedCity) {
      fetchAllDataSequentially(dispatch, signal, filterCity);
      setSearchedCity(filterCity);
    }
    return () => { controller.abort(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCity]);
  // Data Operations
  const [allData, setAllData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  useEffect(() => { setAllData(planServices); }, [planServices]);
  // Table Content
  const select = (rowData) => { console.log(rowData); }
  const check = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <Checkbox checked={rowData.selected} onChange={(e) => handleChangeSelected(rowData.id, e.target.checked ? true : false)} />
      </div>
    );
  }
  const buttons = (rowData) => {
    return (
      <div className="d-flex justify-content-center">
        <button className={`btn ${isMobile ? 'btn-sm' : ''} btn-danger m-0 p-2`} onClick={() => handleChangeSelected(rowData.id, false)}><TbUserMinus style={{ fontSize: '1.2em' }} /></button>
      </div>
    );
  };
  const [rightexpanderopened, setRightexpanderopened] = useState(null);
  const [leftexpanderopened, setLeftexpanderopened] = useState(null);
  const expander = (rowData) => {
    return (
      <div className="pb-0 ps-2 text-start">
        <ul className='list-unstyled mb-1'>
          <li><span className='text-capitalize fs-6 fw-bold'>Müşteri: </span><span className='fs-6 text-break '>{rowData.name}</span></li>
          <li><span className='text-capitalize fs-6 fw-bold'>İlçe: </span><span className='fs-6 text-break '>{rowData.district}</span></li>
          <li><span className='text-capitalize fs-6 fw-bold'>Cihaz: </span><span className='fs-6 text-break '>{rowData.deviceName}</span></li>
          <li><span className='text-capitalize fs-6 fw-bold'>Arıza: </span><span className='fs-6 text-break '>{rowData.problem}</span></li>
        </ul>
      </div>
    );
  };
  // Functions
  const handleChangeSelected = (id, value) => {
    setAllData(allData.map(item => item.id === id ? ({ ...item, selected: value }) : item));
  }
  useEffect(() => {
    setSelectedData(allData.filter(item => item.selected === true));
  }, [allData]);
  // Modals
  // User Services Modal
  const [showUserServicesModal, setShowUserServicesModal] = useState(false);
  const handleCloseUserServicesModal = () => {
    setShowUserServicesModal(false);
  }
  const handleShowUserServicesModal = async (username) => {
    const id = employees.find(item => item.name === username)?.id;
    await dispatch(getUserAssignedServices({ id: id, date: filterDate }));
    setShowUserServicesModal(true);
  }
  // Service Assign Modal
  const [showServiceAssignModal, setShowServiceAssignModal] = useState(false);
  const handleCloseServiceAssignModal = () => {
    setShowServiceAssignModal(false);
  }
  const handleShowServiceAssignModal = () => {
    setShowServiceAssignModal(true);
  }
  const handleAssignServiceAssignModal = (values) => {
    const assignData = {
      userId: myUser.id || -1,
      serviceIds: selectedData.map(item => item.id),
      description: configureServiceAssignData(values)
    }
    dispatch(assignAServiceToAnEmployee({ data: assignData }));
    handleCloseServiceAssignModal();
  }
  // Asign Employee
  const [selectedEmployee, setSelectedEmployee] = useState('');
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered fullscreen backdrop="static">
      <Modal.Header className='py-2' closeButton>
        <Modal.Title>Servis Planlama</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row g-2'>
          <div className='col-12 d-flex align-items-stretch'>
            <div className='card bg-light flex-fill'>
              <div className='card-body'>
                <div className='row g-2'>
                  <div className='col-12 col-md-4'>
                    <TextField
                      id='date'
                      name='date'
                      type="date"
                      variant='outlined'
                      size='small'
                      value={filterDate}
                      onChange={(e) => setFilterDate(e.target.value)}
                      slotProps={{ input: { shrink: "true" } }}
                      fullWidth
                    />
                  </div>
                  <div className='col-12 col-md-4'>
                    <Autocomplete
                      id='filterCity'
                      name='filterCity'
                      value={filterCity}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setFilterCity(newValue);
                          setFilterDistrict([]);
                        } else {
                          setFilterCity('');
                          setFilterDistrict([]);
                        }
                      }}
                      variant='outlined'
                      size='small'
                      options={cities}
                      renderInput={(params) => <TextField {...params} label="İl" />}
                    />
                  </div>
                  <div className='col-12 col-md-4'>
                    <Autocomplete
                      id='filterDistrict'
                      name='filterDistrict'
                      multiple
                      limitTags={3}
                      options={districts}
                      size='small'
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={filterDistrict || []}
                      onChange={(event, newValue) => { if (filterCity !== '') { setFilterDistrict(newValue); } }}
                      disabled={filterCity === ''}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={option}
                              label={option}
                              size='small'
                              {...tagProps}
                              deleteIcon={
                                <TiDelete
                                  type='button'
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const newValues = filterDistrict.filter((i) => i !== option);
                                    setFilterDistrict(newValues);
                                  }}
                                />
                              }
                            />
                          );
                        })
                      }
                      renderInput={(params) => <TextField {...params} label="İlçeler" />}
                    />
                  </div>
                  <div className='col-12 col-md-4'>
                    <Autocomplete
                      id='device'
                      name='device'
                      multiple
                      limitTags={3}
                      options={devices.map(item => item.name)}
                      size='small'
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={filterDevice || []}
                      onChange={(event, newValue) => { setFilterDevice(newValue); }}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={option}
                              label={option}
                              size='small'
                              {...tagProps}
                              deleteIcon={
                                <TiDelete
                                  type='button'
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const newValues = filterDevice.filter((i) => i !== option);
                                    setFilterDevice(newValues);
                                  }}
                                />
                              }
                            />
                          );
                        })
                      }
                      renderInput={(params) => <TextField {...params} label="Cihazlar" />}
                    />
                  </div>
                  <div className='col-12 col-md-4'>
                    <Autocomplete
                      id='source'
                      name='source'
                      multiple
                      limitTags={3}
                      options={sources.map(item => item.name)}
                      size='small'
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={filterSource || []}
                      onChange={(event, newValue) => { setFilterSource(newValue); }}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={option}
                              label={option}
                              size='small'
                              {...tagProps}
                              deleteIcon={
                                <TiDelete
                                  type='button'
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const newValues = filterSource.filter((i) => i !== option);
                                    setFilterSource(newValues);
                                  }}
                                />
                              }
                            />
                          );
                        })
                      }
                      renderInput={(params) => <TextField {...params} label="Kaynaklar" />}
                    />
                  </div>
                  <div className='col-12 col-md-4'>
                    <Autocomplete
                      id='status'
                      name='status'
                      value={filterStatus}
                      onChange={(event, newValue) => newValue ? setFilterStatus(newValue) : setFilterStatus('')}
                      variant='outlined'
                      size='small'
                      options={serviceOperations}
                      renderInput={(params) => <TextField {...params} label="Durum" />}
                    />
                  </div>
                  <div className='col-12 col-md-8'></div>
                  <div className='col-12 col-md-4'>
                    <button className='btn btn-primary w-100'>Filtrele</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 d-flex align-items-stretch'>
            <div className='card bg-light flex-fill'>
              <div className='card-body'>
                {isMobile ? (
                  <DataTable
                    value={allData}
                    size='small'
                    className='activehover'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    expandedRows={rightexpanderopened}
                    onRowToggle={(e) => setRightexpanderopened(e.data)}
                    rowExpansionTemplate={expander}
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}
                    onSelectionChange={(e) => select((e.value.id))}
                    selectionMode="single">
                    <Column align='center' body={check} bodyClassName='align-middle' />
                    <Column align='center' sortable field='id' header='ID' bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' expander style={{ width: "0.5em" }} />
                  </DataTable >
                ) : (
                  <DataTable
                    value={allData}
                    size='small'
                    className='activehover'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}
                    onSelectionChange={(e) => select((e.value.id))}
                    selectionMode="single">
                    <Column align='center' body={check} bodyClassName='align-middle' />
                    <Column align='center' sortable field='id' header='ID' bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field='name' header='MÜŞTERİ' bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field='district' header='İLÇE' bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field='deviceName' header='CİHAZ' bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field='problem' header='ARIZA' bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                )}
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 d-flex align-items-stretch'>
            <div className='card bg-light flex-fill'>
              <div className='card-body d-flex flex-column'>
                <div className='row g-2 flex-grow-1'>
                  <div className='col-12'>
                    {isMobile ? (
                      <DataTable
                        value={selectedData}
                        size='small'
                        className='activehover'
                        stripedRows
                        emptyMessage="Bu tabloda henüz veri yok!"
                        expandedRows={leftexpanderopened}
                        onRowToggle={(e) => setLeftexpanderopened(e.data)}
                        rowExpansionTemplate={expander}
                        showGridlines
                        paginatorLeft
                        paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                        paginator
                        paginatorClassName='bg-light'
                        rows={5}
                        onSelectionChange={(e) => select((e.value.id))}
                        selectionMode="single">
                        <Column align='center' expander style={{ width: "0.5em" }} />
                        <Column align='center' sortable field='id' header='ID' bodyClassName='align-middle fs-6 text-center' />
                        <Column align='center' body={buttons} bodyClassName='align-middle' />
                      </DataTable >
                    ) : (
                      <DataTable
                        value={selectedData}
                        size='small'
                        className='activehover'
                        stripedRows
                        emptyMessage="Bu tabloda henüz veri yok!"
                        showGridlines
                        paginatorLeft
                        paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                        paginator
                        paginatorClassName='bg-light'
                        rows={5}
                        onSelectionChange={(e) => select((e.value.id))}
                        selectionMode="single">
                        <Column align='center' sortable field='id' header='ID' bodyClassName='align-middle fs-6 text-center' />
                        <Column align='center' sortable field='name' header='MÜŞTERİ' bodyClassName='align-middle fs-6 text-center' />
                        <Column align='center' sortable field='district' header='İLÇE' bodyClassName='align-middle fs-6 text-center' />
                        <Column align='center' sortable field='deviceName' header='CİHAZ' bodyClassName='align-middle fs-6 text-center' />
                        <Column align='center' sortable field='problem' header='ARIZA' bodyClassName='align-middle fs-6 text-center' />
                        <Column align='center' body={buttons} bodyClassName='align-middle' />
                      </DataTable >
                    )}
                  </div>
                  <div className='col-12 mt-auto'>
                    <div className='row g-2'>
                      <div className='col-12 col-sm-12 col-lg-12 col-xl-4'>
                        <Autocomplete
                          value={selectedEmployee}
                          onChange={(event, newValue) => newValue ? setSelectedEmployee(newValue) : setSelectedEmployee('')}
                          variant='outlined'
                          size='small'
                          options={employees.map(item => item.name)}
                          renderInput={(params) => <TextField {...params} label="Personel" />}
                        />
                      </div>
                      <div className='col-12 col-sm-6 col-lg-6 col-xl-4'>
                        <button className='button-with-icon btn btn-info w-100' onClick={() => handleShowUserServicesModal(selectedEmployee)} disabled={selectedEmployee === ''}>
                          <IoEye className='button-icon' />
                          Atanmış Servisleri Gör
                        </button>
                      </div>
                      <div className='col-12 col-sm-6 col-lg-6 col-xl-4 d-flex align-items-stretch' onClick={() => handleShowServiceAssignModal()}>
                        <button className='button-with-icon btn btn-primary w-100'>
                          <GrPlan className='button-icon' />
                          Atama Yap
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <UserServicesModal
        show={showUserServicesModal}
        handleClose={handleCloseUserServicesModal}
        username={selectedEmployee}
      />
      <ServiceAssignModal
        show={showServiceAssignModal}
        handleClose={handleCloseServiceAssignModal}
        handleAssign={handleAssignServiceAssignModal}
        inputStatus={filterStatus}
      />
    </Modal>
  );
}

export default ServicePlannerModal;