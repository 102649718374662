// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useSelector } from 'react-redux';
// Contexts
import { useData } from '../../../../context/DataContext';
import { useValidations } from '../../../../context/ValidationContext';
// Icons
import { TbArrowBackUpDouble, TbDeviceIpadHorizontalPlus } from 'react-icons/tb';

function ConsigmentdeviceAddModal({ show, handleClose, handleAdd }) {
  // Storage Import
  const { devices } = useSelector((state) => state.device);
  const { brands } = useSelector((state) => state.brand);
  // Context Import
  const { consigmentdeviceStatuses } = useData();
  const { vs_consigmentdevice } = useValidations();
  // Formik Variables
  const initialValues = {
    brandName: "",
    deviceName: "",
    model: "",
    quantity: 0,
    status: "",
    description: ""
  }
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header className='py-2' closeButton>
          <Modal.Title>Konsinye Cihaz Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={initialValues} validationSchema={vs_consigmentdevice} onSubmit={(values) => handleAdd(values)}>
            {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="ConsigmentdeviceAddModal_brandName"
                      name="brandName"
                      options={brands.map(item => item.name)}
                      value={values.brandName}
                      onChange={(event, newValue) => { setFieldValue('brandName', newValue); }}
                      onBlur={() => { setFieldTouched('brandName', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Marka" error={touched.brandName && !!errors.brandName} helperText={touched.brandName && errors.brandName} />}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="ConsigmentdeviceAddModal_deviceName"
                      name="deviceName"
                      options={devices.map(item => item.name)}
                      value={values.deviceName}
                      onChange={(event, newValue) => { setFieldValue('deviceName', newValue); }}
                      onBlur={() => { setFieldTouched('deviceName', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Cihaz" error={touched.deviceName && !!errors.deviceName} helperText={touched.deviceName && errors.deviceName} />}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='ConsigmentdeviceAddModal_model'
                      name='model'
                      label='Model'
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.model && !!errors.model}
                      helperText={touched.model && errors.model}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <Field
                      as={TextField}
                      id='ConsigmentdeviceAddModal_quantity'
                      name='quantity'
                      label='Miktar'
                      type='number'
                      value={values.quantity}
                      onChange={(e) => setFieldValue("quantity", e.target.value)}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.quantity && !!errors.quantity}
                      helperText={touched.quantity && errors.quantity}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="ConsigmentdeviceAddModal_status"
                      name="status"
                      options={consigmentdeviceStatuses}
                      value={values.status}
                      onChange={(event, newValue) => { setFieldValue('status', newValue); }}
                      onBlur={() => { setFieldTouched('status', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Durum" error={touched.status && !!errors.status} helperText={touched.status && errors.status} />}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='ConsigmentdeviceAddModal_description'
                      name='description'
                      label="Açıklama"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none' } } }}
                      multiline
                      rows={4}
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                        <TbArrowBackUpDouble className='button-icon' />
                        Geri
                      </button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-success w-100'>
                        <TbDeviceIpadHorizontalPlus className='button-icon' />
                        Ekle
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  );
}

export default ConsigmentdeviceAddModal;