// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllEmployees, getEmployeeById, addNewEmployee, updateEmployee, deleteEmployee, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/EmployeeSlice';
import { getAllRoles, addNewRole, resetAddStatus as resetAddRoleStatus, resetError as resetErrorRole } from '../../../storage/slices/backend/RoleSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import EmployeeAddModal from '../modals/employees/EmployeeAddModal';
import RoleAddModal from '../modals/roles/RoleAddModal';
import EmployeeUpdateModal from '../modals/employees/EmployeeUpdateModal';
import EmployeeDeleteModal from '../modals/DeleteModal';
// Icons
import { LuUserRoundPlus, LuUserRoundMinus, LuUserRoundCog } from "react-icons/lu";
import { TbPrinter, TbLayoutGridAdd } from 'react-icons/tb';

function Employees() {
  // Storage Import
  const dispatch = useDispatch();
  const { employees, employee, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.employee);
  const { addStatus: addRoleStatus, error: errorRole } = useSelector((state) => state.role);
  // Context Import
  const { myUser } = useData();
  const { dictionaryStatuses } = useFunctions();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllEmployees({ signal }));
      await dispatch(getAllRoles({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      const data = employees.map((data) => {
        const id = data.id;
        const name = data.name;
        const role = data.role;
        const phone = data.phone1;
        const address = data.address;
        const district = data.district;
        const city = data.city;
        const addressString = `${address}\n${district}/${city}`;
        const addressDiv = (
          <div className='d-flex flex-column justify-content-start align-items-start'>
            <p className='m-0 p-0'>{address}</p>
            <p className='m-0 p-0'>{district}/{city}</p>
          </div>
        );
        const status = dictionaryStatuses[data.status];
        return { id, name, role, phone, address, district, city, addressString, addressDiv, status };
      });
      setDataToTable(data.map(item => ({ id: item.id, name: item.name, role: item.role, phone: item.phone, address: item.addressDiv, searchAddress: item.addressString, status: item.status })));
      setDataToPDF(data.map(item => ({ id: item.id, name: item.name, role: item.role, phone: item.phone, address: item.addressString, status: item.status })));
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseEmployeeAddModal();
      dispatch(addToast({ background: 'success', icon: 'employeeAdd', message: 'Personel Eklendi!', delay: 6000 }));
      dispatch(getAllEmployees({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (addRoleStatus === "fulfilled") {
      handleCloseRoleAddModal();
      dispatch(addToast({ background: 'success', icon: 'roleAdd', message: 'Rol Eklendi!', delay: 6000 }));
      dispatch(getAllRoles({}));
      dispatch(resetAddRoleStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addRoleStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseEmployeeUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'employeeUpdate', message: 'Personel Güncellendi!', delay: 6000 }));
      dispatch(getAllEmployees({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseEmployeeDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'employeeDelete', message: 'Personel Silindi!', delay: 6000 }));
      dispatch(getAllEmployees({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataEmployeeUpdateModal({ ...employee, status: dictionaryStatuses[employee.status] });
        setShowEmployeeUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataEmployeeDeleteModal({ ...employee, status: dictionaryStatuses[employee.status] });
        setShowEmployeeDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  useEffect(() => {
    if (errorRole && errorRole !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: errorRole, delay: 4000 }));
      dispatch(resetErrorRole());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorRole]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'PERSONEL' }, { value: 'role', name: 'ROL' }, { value: 'phone', name: 'TELEFON' }, { value: 'address', name: 'ADRES' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'name', 'role', 'phone', 'searchAddress', 'status'];
  const clickOn = (id) => { handleShowEmployeeUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <LuUserRoundCog className='button-icon' />,
      onclick: ((rowData) => { handleShowEmployeeUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <LuUserRoundMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowEmployeeDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Personeller';
  const operations = [
    {
      text: 'Personel Ekle',
      icon: <LuUserRoundPlus className='button-icon' />,
      onclick: (() => { handleShowEmployeeAddModal(); })
    },
    {
      text: 'Rol Ekle',
      icon: <TbLayoutGridAdd className='button-icon' />,
      onclick: (() => { handleShowRoleAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showEmployeeAddModal, setShowEmployeeAddModal] = useState(false);
  const handleCloseEmployeeAddModal = () => {
    setShowEmployeeAddModal(false);
  }
  const handleShowEmployeeAddModal = () => {
    setShowEmployeeAddModal(true);
  }
  const handleAddEmployeeAddModal = (values) => {
    dispatch(addNewEmployee({ newemployee: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Add Modal (Role)
  const [showRoleAddModal, setShowRoleAddModal] = useState(false);
  const handleCloseRoleAddModal = () => {
    setShowRoleAddModal(false);
  }
  const handleShowRoleAddModal = () => {
    setShowRoleAddModal(true);
  }
  const handleAddRoleAddModal = (values) => {
    dispatch(addNewRole({ newrole: values }));
  }
  // Update Modal
  const [showEmployeeUpdateModal, setShowEmployeeUpdateModal] = useState(false);
  const [inputDataEmployeeUpdateModal, setInputDataEmployeeUpdateModal] = useState(null);
  const handleCloseEmployeeUpdateModal = () => {
    setShowEmployeeUpdateModal(false);
    setInputDataEmployeeUpdateModal(null);
  }
  const handleShowEmployeeUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getEmployeeById({ id: id }));
  }
  const handleUpdateEmployeeUpdateModal = (values) => {
    dispatch(updateEmployee({ employee: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Delete Modal
  const [showEmployeeDeleteModal, setShowEmployeeDeleteModal] = useState(false);
  const [inputDataEmployeeDeleteModal, setInputDataEmployeeDeleteModal] = useState(null);
  const handleCloseEmployeeDeleteModal = () => {
    setShowEmployeeDeleteModal(false);
    setInputDataEmployeeDeleteModal(null);
  }
  const handleShowEmployeeDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getEmployeeById({ id: id }));
  }
  const handleDeleteEmployeeDeleteModal = () => {
    dispatch(deleteEmployee({ id: inputDataEmployeeDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <EmployeeAddModal
        show={showEmployeeAddModal}
        handleClose={handleCloseEmployeeAddModal}
        handleAdd={handleAddEmployeeAddModal}
      />
      <RoleAddModal
        show={showRoleAddModal}
        handleClose={handleCloseRoleAddModal}
        handleAdd={handleAddRoleAddModal}
      />
      <EmployeeUpdateModal
        show={showEmployeeUpdateModal}
        handleClose={handleCloseEmployeeUpdateModal}
        handleUpdate={handleUpdateEmployeeUpdateModal}
        inputData={inputDataEmployeeUpdateModal}
      />
      <EmployeeDeleteModal
        show={showEmployeeDeleteModal}
        handleClose={handleCloseEmployeeDeleteModal}
        handleDelete={handleDeleteEmployeeDeleteModal}
        title="Personeli Sil"
        message={`${inputDataEmployeeDeleteModal?.name} adlı personeli silmek istediğinize emin misiniz?`}
        deleteIcon={<LuUserRoundMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showEmployeeAddModal, showRoleAddModal, showEmployeeUpdateModal, inputDataEmployeeUpdateModal, showEmployeeDeleteModal, inputDataEmployeeDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Employees;