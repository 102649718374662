// General
import React, { useState } from 'react'
// Styles
import { Modal, ModalBody } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'
import { Autocomplete, TextField } from '@mui/material';
// Contexts
import { useData } from '../../../../context/DataContext';
import { useFunctions } from '../../../../context/FunctionContext';
import { useValidations } from '../../../../context/ValidationContext';
// Icons
import { IoAddCircle } from "react-icons/io5";
import { TbArrowBackUpDouble, TbTag } from "react-icons/tb";
import { MdDeleteOutline } from 'react-icons/md';
// Components
import CustomerType from '../../components/CustomerType';

function OfferUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Context Import
  const { offerStatuses } = useData();
  const { getStringDate, listAddItem, calculateTotalOfColumn, calculateAmountWithoutKdv, calculateTotal, listDeleteItem } = useFunctions();
  const { vs_offer } = useValidations();
  // Variables
  const [priceWithKdv, setPriceWithKdv] = useState(0);
  const [priceWithoutKdv, setPriceWithoutKdv] = useState(0);
  // Formik Variables
  const initialValues = {
    id: inputData?.id || -1,
    customerName: inputData?.customerName || '',
    customerPhone1: inputData?.customerPhone1 || '',
    customerPhone2: inputData?.customerPhone2 || '',
    customerAddress: inputData?.customerAddress || '',
    city: inputData?.city || 'İstanbul',
    district: inputData?.district || '',
    identityNumber: inputData?.identityNumber || '',
    taxNumber: inputData?.taxNumber || '',
    taxOffice: inputData?.taxOffice || '',
    customerType: inputData?.customerType || 'Bireysel',
    title: inputData?.title || '',
    date: inputData?.date || getStringDate(0),
    totalText: inputData?.totalText || '',
    usdBuying: inputData?.usdBuying || 0,
    usdSelling: inputData?.usdSelling || 0,
    total: inputData?.total || 0,
    kdv: inputData?.kdv || 0,
    generalTotal: inputData?.generalTotal || 0,
    explanation: inputData?.explanation || '',
    status: inputData?.status || '',
    offerDetails: inputData?.offerDetails || [
      {
        id: 1,
        offerId: 0,
        type: "",
        quantity: 0,
        price: 0,
        total: 0,
      }
    ],
  }
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal-xl' backdrop="static">
        <Modal.Header className='py-2' closeButton>
          <Modal.Title> Teklif Güncelle</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <Formik initialValues={initialValues} validationSchema={vs_offer} onSubmit={(values) => handleUpdate(values)} >
            {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className='row g-2'>
                  <div className='col-12 col-md-12 mt-0'>
                    <div className="card bg-light m-0">
                      <div className='card-header py-1'>
                        <div className='row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100'>
                          <div className='col-12 col-md-6 d-flex align-items-center'>
                            <div className='card-title my-1'>Teklif Bilgileri</div>
                          </div>
                          <div className="col-12 col-md-3">
                            <Field
                              as={TextField}
                              id='OffersUpdateModal_KDVCost'
                              name='generalkdvcost'
                              label="KDV'li Fiyat"
                              value={priceWithKdv}
                              onChange={(e) => {
                                setPriceWithKdv(e.target.value);
                                setPriceWithoutKdv(calculateAmountWithoutKdv(e.target.value, parseFloat(values.kdv)));
                              }}
                              type="number"
                              variant='outlined'
                              size='small'
                              fullWidth
                            />
                          </div>
                          <div className="col-12 col-md-3">
                            <Field
                              as={TextField}
                              id='OffersUpdateModal_withoutKDVCost'
                              name='offferpricewithoutvat'
                              label="KDV'siz Fiyatı"
                              value={priceWithoutKdv.toFixed(2)}
                              slotProps={{ input: { readOnly: true } }}
                              variant='outlined'
                              size='small'
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='row g-2'>
                          <div className="col-12 col-md-6">
                            <Field
                              as={TextField}
                              id='OffersUpdateModal_date'
                              name='date'
                              label='Teklif Tarihi'
                              type="date"
                              InputLabelProps={{ shrink: true, }}
                              variant='outlined'
                              size='small'
                              fullWidth
                              error={touched.date && !!errors.date}
                              helperText={touched.date && errors.date}
                            />
                          </div>
                          <div className='col-12 col-md-6'>
                            <Field
                              as={TextField}
                              id='OffersUpdateModal_title'
                              name='title'
                              label='Teklifin Adı'
                              variant='outlined'
                              size='small'
                              fullWidth
                              error={touched.title && !!errors.title}
                              helperText={touched.title && errors.title}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 ">
                    <div className='card bg-light  m-0'>
                      <div className='card-header  d-flex justify-content-between py-1'>
                        <div className='card-title my-1'>Ürün Bilgileri</div>
                        <button type='button' onClick={() => {
                          const maxId = values.offerDetails.length > 0 ? Math.max(...values.offerDetails.map(product => product.id)) : 0;
                          const newProduct = { id: maxId + 1, offerId: maxId + 1, type: '', quantity: 0, price: 0, total: 0 };
                          setFieldValue('offerDetails', listAddItem(values.offerDetails, newProduct));
                        }} className='btn btn-outline-warning mx-2 p-1 fs-6'><IoAddCircle style={{ fontSize: '1.2em' }} />Ürün Satırı Ekle</button>
                      </div>
                      <div className='card-body' style={{ maxHeight: '240px', overflowY: 'auto' }}>
                        {values.offerDetails.map((product, index) => (
                          <div key={index} className="row g-2 my-0">
                            <div className='col-12 col-md-5'>
                              <TextField
                                id="OffersAddModal_offerDetails_type"
                                name="type"
                                label='Ürün Adı'
                                value={product.type}
                                onChange={(e) => setFieldValue(`offerDetails[${index}].type`, e.target.value)}
                                variant='outlined'
                                size='small'
                                fullWidth
                                error={touched.offerDetails?.[index]?.type && !!errors.offerDetails?.[index]?.type}
                                helperText={touched.offerDetails?.[index]?.type && errors.offerDetails?.[index]?.type}
                              />
                            </div>
                            <div className='col-12 col-md-2'>
                              <TextField
                                id="OffersUpdateModal_offerDetails_quantity"
                                name="quantity"
                                label='Ürün Miktarı'
                                type='number'
                                value={parseFloat(product.quantity)}
                                onChange={(e) => {
                                  const value = parseFloat(e.target.value);
                                  if (value >= 0) {
                                    setFieldValue(`offerDetails[${index}].quantity`, value);
                                    setFieldValue(`offerDetails[${index}].total`, value * values.offerDetails[index].price);
                                    const totalPrice = calculateTotalOfColumn(values.offerDetails.map(item => item.id === values.offerDetails[index].id ? { ...item, total: value * values.offerDetails[index].price } : item), 'total');
                                    setFieldValue('total', totalPrice);
                                    setFieldValue('generalTotal', calculateTotal(totalPrice, values.kdv));
                                  }
                                }}
                                variant='outlined'
                                size='small'
                                fullWidth
                                error={touched.offerDetails?.[index]?.quantity && !!errors.offerDetails?.[index]?.quantity}
                                helperText={touched.offerDetails?.[index]?.quantity && errors.offerDetails?.[index]?.quantity}
                              />
                            </div>
                            <div className='col-12 col-md-2'>
                              <TextField
                                id="OffersUpdateModal_offerDetails_price"
                                name="price"
                                label='Ürün Fiyatı'
                                type='number'
                                value={parseFloat(product.price)}
                                onChange={(e) => {
                                  const value = parseFloat(e.target.value);
                                  if (value >= 0) {
                                    setFieldValue(`offerDetails[${index}].price`, value);
                                    setFieldValue(`offerDetails[${index}].total`, value * values.offerDetails[index].quantity);
                                    const totalPrice = calculateTotalOfColumn(values.offerDetails.map(item => item.id === values.offerDetails[index].id ? { ...item, total: value * values.offerDetails[index].quantity } : item), 'total');
                                    setFieldValue('total', totalPrice);
                                    setFieldValue('generalTotal', calculateTotal(totalPrice, values.kdv));
                                  }
                                }}
                                variant='outlined'
                                size='small'
                                fullWidth
                                error={touched.offerDetails?.[index]?.price && !!errors.offerDetails?.[index]?.price}
                                helperText={touched.offerDetails?.[index]?.price && errors.offerDetails?.[index]?.price}
                              />
                            </div>
                            <div className='col-12 col-md-2'>
                              <TextField
                                id="OffersUpdateModal_offerDetails_total"
                                name="total"
                                label='Toplam Fiyat'
                                value={values.offerDetails[index].total}
                                slotProps={{ input: { readOnly: true } }}
                                variant='outlined'
                                size='small'
                                fullWidth
                              />
                            </div>
                            <div className='col-12 col-md-1'>
                              <button type='button' className='button-with-icon btn btn-danger w-100' onClick={() => {
                                setFieldValue("offerDetails", listDeleteItem(values.offerDetails, values.offerDetails[index].id))
                              }}><MdDeleteOutline className='button-icon' />Sil</button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-12 '>
                    <div className="row g-2">
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className='card bg-light m-0'>
                          <div className='card-header py-0'>
                            <div className='card-title my-1'>Müşteri Bilgileri</div>
                          </div>
                          <div className='card-body'>
                            <div className='row g-2'>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersUpdateModal_customerPhone1'
                                  name='customerPhone1'
                                  label='Birincil Telefon'
                                  value={values.customerPhone1}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersUpdateModal_customerPhone2'
                                  name='customerPhone2'
                                  label='İkincil Telefon'
                                  value={values.customerPhone2}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersUpdateModal_customerName'
                                  name='customerName'
                                  label='Müşteri Adı'
                                  value={values.customerName}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                />
                              </div>
                              <div className="col-12 col-md-6">
                                <Field
                                  as={TextField}
                                  id='OffersUpdateModal_customerType'
                                  name='customerType'
                                  label='Müşteri Türü'
                                  value={values.customerType}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                />
                              </div>
                              <CustomerType errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} readOnly={true} />
                              <div className="col-12 col-md-6">
                                <Field
                                  as={TextField}
                                  id='OffersUpdateModal_city'
                                  name='city'
                                  label='Şehir'
                                  value={values.city}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                />
                              </div>
                              <div className="col-12 col-md-6">
                                <Field
                                  as={TextField}
                                  id='OffersUpdateModal_district'
                                  name='district'
                                  label='İlçe'
                                  value={values.district}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                />
                              </div>
                              <div className="col-12 col-md-12">
                                <Field
                                  as={TextField}
                                  id='OffersUpdateModal_customerAddress'
                                  name='customerAddress'
                                  label='Adres'
                                  value={values.customerAddress}
                                  slotProps={{ input: { readOnly: true } }}
                                  multiline
                                  rows={4}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className='card h-100 bg-light m-0'>
                          <div className='card-header py-0'>
                            <div className='card-title my-1'>Genel Bilgiler</div>
                          </div>
                          <div className='card-body'>
                            <div className='row g-2'>
                              <div className='col-12 col-md-12'>
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_totalText'
                                  name='totalText'
                                  label='Toplam (Yazıyla)'
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  error={touched.totalText && !!errors.totalText}
                                  helperText={touched.totalText && errors.totalText}
                                />
                              </div>
                              <div className="col-12 col-md-6">
                                <Field
                                  as={Autocomplete}
                                  id="OffersAddModal_status"
                                  name="status"
                                  options={offerStatuses}
                                  value={values.status}
                                  onChange={(event, newValue) => { setFieldValue('status', newValue); }}
                                  onBlur={() => { setFieldTouched('status', true); }}
                                  variant='outlined'
                                  size='small'
                                  renderInput={(params) => <TextField {...params}
                                    label="Durum" error={touched.status && !!errors.status}
                                    helperText={touched.status && errors.status} />}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={Autocomplete}
                                  id="OffersAddModal_kdv"
                                  name="kdv"
                                  options={['0', '0.01', '0.08', '0.18', '0.2']}
                                  value={values.kdv}
                                  onChange={(event, newValue) => {
                                    setFieldValue('kdv', newValue || '0');
                                    setPriceWithoutKdv(calculateAmountWithoutKdv(priceWithKdv, parseFloat(newValue)));
                                    setFieldValue('generalTotal', calculateTotal(values.total, parseFloat(newValue)));
                                  }}
                                  onBlur={() => { setFieldTouched('kdv', true); }}
                                  getOptionLabel={(option) => option.toString()}
                                  variant='outlined'
                                  size='small'
                                  renderInput={(params) => <TextField {...params}
                                    label="KDV" error={touched.kdv && !!errors.kdv}
                                    helperText={touched.kdv && errors.kdv} />}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_usdBuying'
                                  name='usdBuying'
                                  label='Dolar Alış'
                                  value={values.usdBuying}
                                  onChange={(e) => setFieldValue('usdBuying', e.target.value)}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  error={touched.usdBuying && !!errors.usdBuying}
                                  helperText={touched.usdBuying && errors.usdBuying}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_usdSelling'
                                  name='usdSelling'
                                  label='Dolar Satış'
                                  value={values.usdSelling}
                                  onChange={(e) => setFieldValue('usdSelling', e.target.value)}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  error={touched.usdSelling && !!errors.usdSelling}
                                  helperText={touched.usdSelling && errors.usdSelling}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersUpdateModal_total'
                                  name='total'
                                  label='Ürünlerin Toplam Fiyatı'
                                  slotProps={{ input: { readOnly: true } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  error={touched.total && !!errors.total}
                                  helperText={touched.total && errors.total}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersUpdateModal_generalTotal'
                                  name='generalTotal'
                                  label='Genel Toplam'
                                  value={values.generalTotal.toFixed(2)}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  error={touched.generalTotal && !!errors.generalTotal}
                                  helperText={touched.generalTotal && errors.generalTotal}
                                />
                              </div>
                              <div className="col-12 col-md-12">
                                <Field
                                  as={TextField}
                                  id='OffersUpdateModal_explanation'
                                  name='explanation'
                                  label="Açıklama"
                                  slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                                  multiline
                                  rows={4}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  error={touched.explanation && !!errors.explanation}
                                  helperText={touched.explanation && errors.explanation}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                        <TbArrowBackUpDouble className='button-icon' />
                        Geri
                      </button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-primary w-100' >
                        <TbTag className='button-icon' />
                        Güncelle
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default OfferUpdateModal;