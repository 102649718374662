// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Sources/';

// Async Thunks
export const getAllSources = createAsyncThunk('backend_getallsources', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllSources`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getSourceById = createAsyncThunk('backend_getsourcebyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetSourceById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewSource = createAsyncThunk('backend_addnewsource', async ({ newsource }) => {
  try {
    const res = await axios.post(`${baseurl}AddSource`, newsource, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateSource = createAsyncThunk('backend_updatenewsource', async ({ source }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateSource`, source, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteSource = createAsyncThunk('backend_deletesource', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteSource?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const sourceSlice = createSlice({
  name: 'source',
  initialState: {
    sources: [],
    source: {},
    getAllStatus: null,
    getDataStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetGetDataStatus: (state) => { state.getDataStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSources.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllSources.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.sources = action.payload || []; })
      .addCase(getAllSources.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getSourceById.pending, (state) => { state.getDataStatus = "pending"; })
      .addCase(getSourceById.fulfilled, (state, action) => { state.getDataStatus = "fulfilled"; state.source = action.payload || {}; })
      .addCase(getSourceById.rejected, (state, action) => { state.getDataStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewSource.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewSource.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewSource.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateSource.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateSource.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateSource.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteSource.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteSource.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteSource.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = sourceSlice.actions;
export default sourceSlice.reducer;