// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllSources, getSourceById, addNewSource, updateSource, deleteSource, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/SourceSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import SourceAddModal from '../modals/sources/SourceAddModal';
import SourceUpdateModal from '../modals/sources/SourceUpdateModal';
import SourceDeleteModal from '../modals/DeleteModal';
// Icons
import { BsTelephonePlus, BsTelephoneMinus, BsTelephoneFill } from 'react-icons/bs';
import { TbPrinter } from "react-icons/tb";

function Sources() {
  // Storage Import
  const dispatch = useDispatch();
  const { sources, source, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.source);
  // Context Import
  const { myUser } = useData();
  const { dictionaryStatuses } = useFunctions();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllSources({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      setDataToTable(sources.map(item => ({ ...item, status: dictionaryStatuses[item.status] })));
      setDataToPDF(sources.map(item => ({ ...item, status: dictionaryStatuses[item.status] })));
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseSourceAddModal();
      dispatch(addToast({ background: 'success', icon: 'sourceAdd', message: 'Kaynak Eklendi!', delay: 6000 }));
      dispatch(getAllSources({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseSourceUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'sourceUpdate', message: 'Kaynak Güncellendi!', delay: 6000 }));
      dispatch(getAllSources({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseSourceDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'sourceDelete', message: 'Kaynak Silindi!', delay: 6000 }));
      dispatch(getAllSources({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataSourceUpdateModal({ ...source, status: dictionaryStatuses[source.status] });
        setShowSourceUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataSourceDeleteModal({ ...source, status: dictionaryStatuses[source.status] });
        setShowSourceDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'KAYNAK' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'name', 'status'];
  const clickOn = (id) => { handleShowSourceUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <BsTelephoneFill className='button-icon' />,
      onclick: ((rowData) => { handleShowSourceUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <BsTelephoneMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowSourceDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Kaynaklar';
  const operations = [
    {
      text: 'Kaynak Ekle',
      icon: <BsTelephonePlus className='button-icon' />,
      onclick: (() => { handleShowSourceAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showSourceAddModal, setShowSourceAddModal] = useState(false);
  const handleCloseSourceAddModal = () => {
    setShowSourceAddModal(false);
  }
  const handleShowSourceAddModal = () => {
    setShowSourceAddModal(true);
  }
  const handleAddSourceAddModal = (values) => {
    dispatch(addNewSource({ newsource: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Update Modal
  const [showSourceUpdateModal, setShowSourceUpdateModal] = useState(false);
  const [inputDataSourceUpdateModal, setInputDataSourceUpdateModal] = useState(null);
  const handleCloseSourceUpdateModal = () => {
    setShowSourceUpdateModal(false);
    setInputDataSourceUpdateModal(null);
  }
  const handleShowSourceUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getSourceById({ id: id }));
  }
  const handleUpdateSourceUpdateModal = (values) => {
    dispatch(updateSource({ source: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Delete Modal
  const [showSourceDeleteModal, setShowSourceDeleteModal] = useState(false);
  const [inputDataSourceDeleteModal, setInputDataSourceDeleteModal] = useState(null);
  const handleCloseSourceDeleteModal = () => {
    setShowSourceDeleteModal(false);
    setInputDataSourceDeleteModal(null);
  }
  const handleShowSourceDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getSourceById({ id: id }));
  }
  const handleDeleteSourceDeleteModal = () => {
    dispatch(deleteSource({ id: inputDataSourceDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <SourceAddModal
        show={showSourceAddModal}
        handleClose={handleCloseSourceAddModal}
        handleAdd={handleAddSourceAddModal}
      />
      <SourceUpdateModal
        show={showSourceUpdateModal}
        handleClose={handleCloseSourceUpdateModal}
        handleUpdate={handleUpdateSourceUpdateModal}
        inputData={inputDataSourceUpdateModal}
      />
      <SourceDeleteModal
        show={showSourceDeleteModal}
        handleClose={handleCloseSourceDeleteModal}
        handleDelete={handleDeleteSourceDeleteModal}
        title="Kaynağı Sil"
        message={`${inputDataSourceDeleteModal?.name} adlı kaynağı silmek istediğinize emin misiniz?`}
        deleteIcon={<BsTelephoneMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showSourceAddModal, showSourceUpdateModal, inputDataSourceUpdateModal, showSourceDeleteModal, inputDataSourceDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Sources;