// General
import React, { useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useDispatch } from 'react-redux';
import { getServiceById, resetGetDataStatus } from '../../../../storage/slices/backend/ServiceSlice';
// Contexts
import { useFunctions } from '../../../../context/FunctionContext';
import { useValidations } from '../../../../context/ValidationContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { HiOutlineDocumentPlus } from "react-icons/hi2";

function TransactionreportsAddModal({ show, handleClose, handleAdd }) {
  // Storage Import
  const dispatch = useDispatch();
  // Context Import
  const { stringOnlyNumbers, getStringDate } = useFunctions();
  const { vs_transactionreports } = useValidations();
  // Variables
  const [filteredOptions, setFilteredOptions] = useState([]);
  // Functions
  const handleInputChange = async (event, newInputValue) => {
    if (!newInputValue) { setFilteredOptions([]); return; }
    const id = /^\d+$/.test(newInputValue);
    if (id) {
      try {
        const service = await dispatch(getServiceById({ id: parseInt(newInputValue) }));
        if (service.payload) {
          setFilteredOptions([service.payload]);
        } else { setFilteredOptions([]); }
      } catch (error) { throw error; } finally { dispatch(resetGetDataStatus()); }
    }
    else { setFilteredOptions([]); }
  }
  // Formik Variables
  const initialValues = {
    serviceId: null,
    date: getStringDate(0),
    fault: '',
    transactions: '',
    informations: '',
    total: 0
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header className='py-2' closeButton>
          <Modal.Title>İşlem Raporu Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={initialValues} validationSchema={vs_transactionreports} onSubmit={(values) => handleAdd(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={Autocomplete}
                      id="transactionreportsaddmodal_serviceId"
                      name="serviceId"
                      options={filteredOptions || []}
                      value={values.serviceId}
                      onChange={(event, newValue) => { setFieldValue('serviceId', newValue || ''); }}
                      onInputChange={handleInputChange}
                      onBlur={() => { setFieldTouched('serviceId', true); }}
                      variant='outlined'
                      size='small'
                      getOptionLabel={(option) => option && option.id ? `${option.id} - ${option.name} (${option.phone1}) - ${option.brandName}/${option.deviceName}` : ''}
                      renderOption={(props, option) => (<li {...props} key={option.id}>{`${option.id} - ${option.name} (${option.phone1}) - ${option.brandName}/${option.deviceName}`}</li>)}
                      isOptionEqualToValue={(option, value) => option.id === value}
                      renderInput={(params) => (<TextField {...params} label="Servis" error={touched.serviceId && !!errors.serviceId} helperText={touched.serviceId && errors.serviceId} />)}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='transactionreportsaddmodal_fault'
                      name='fault'
                      label="Arıza"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none' } } }}
                      multiline
                      rows={3}
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={touched.fault && !!errors.fault}
                      helperText={touched.fault && errors.fault}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='transactionreportsaddmodal_transactions'
                      name='transactions'
                      label="Yapılan İşlemler"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none' } } }}
                      multiline
                      rows={3}
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={touched.transactions && !!errors.transactions}
                      helperText={touched.transactions && errors.transactions}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='transactionreportsaddmodal_informations'
                      name='informations'
                      label="İşlem Bilgileri"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none' } } }}
                      multiline
                      rows={3}
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={touched.informations && !!errors.informations}
                      helperText={touched.informations && errors.informations}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='transactionreportsaddmodal_total'
                      name='total'
                      type='number'
                      label='Maliyet'
                      value={values.total}
                      onChange={(e) => { setFieldValue('total', parseFloat(stringOnlyNumbers(e.target.value))) }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.total && !!errors.total}
                      helperText={touched.total && errors.total}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                        <TbArrowBackUpDouble className='button-icon' />
                        Geri
                      </button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-success w-100'>
                        <HiOutlineDocumentPlus className='button-icon' />
                        Ekle
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  );
}

export default TransactionreportsAddModal;