// General
import { createContext, useContext } from 'react';
// Icons
import { MdErrorOutline, MdDevicesOther, MdShelves } from "react-icons/md";
import { FaDoorOpen, FaDoorClosed } from "react-icons/fa";
import { TbArrowBackUpDouble, TbHome, TbDatabase, TbDatabasePlus, TbDatabaseMinus, TbDatabaseCog, TbDatabaseEdit, TbTags, TbTagPlus, TbTagMinus, TbTag, TbTagFilled, TbCashRegister, TbCashBanknote, TbCashBanknoteOff, TbCashBanknoteFilled, TbCash, TbMessage2, TbMessages, TbMessage2Plus, TbMessage2Minus, TbMessage2Cog, TbMessage2Star, TbHomePlus, TbHomeMinus, TbHomeCog, TbHomeStar, TbLayoutGrid, TbLayoutGridAdd, TbLayoutGridRemove, TbLayoutGridFilled, TbDeviceIpadHorizontal, TbDeviceIpadHorizontalPlus, TbDeviceIpadHorizontalMinus, TbDeviceIpadHorizontalCog, TbDeviceIpadHorizontalStar } from "react-icons/tb";
import { LuUser, LuUsers, LuUserRoundPlus, LuUserRoundMinus, LuUserRoundCog, LuUserRoundPen, LuPackage, LuPackagePlus, LuPackageMinus, LuPackageCheck, LuPackageOpen, LuCalendar, LuCalendarPlus, LuCalendarMinus, LuCalendarCog, LuClipboard, LuChartPie, LuCar, LuSquareUser, LuWarehouse, LuFolderPlus, LuFolderMinus, LuFolderCog, LuFolderPen, LuBadge, LuBadgePlus, LuBadgeMinus, LuBadgeCheck, LuBadgeInfo, LuPlug2, LuMapPinHouse } from "react-icons/lu";
import { HiOutlineDocumentDuplicate, HiOutlineClipboardDocumentList, HiOutlineDocumentPlus, HiOutlineDocumentMinus, HiOutlineDocumentCheck, HiDocument } from "react-icons/hi2";
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline, IoMdInformationCircleOutline, IoMdHelpCircleOutline } from "react-icons/io";
import { FaPlugCirclePlus, FaPlugCircleMinus, FaPlugCircleCheck, FaPlug } from "react-icons/fa6";
import { BsTelephone, BsTelephonePlus, BsTelephoneMinus, BsTelephoneFill } from "react-icons/bs";
import { RiMailSendFill } from "react-icons/ri";

const IconContext = createContext();

const IconProvider = ({ children }) => {
  const Icons = {
    error: <MdErrorOutline style={{ fontSize: '1.5em' }} />,
    login: <FaDoorOpen style={{ fontSize: '1.5em' }} />,
    logout: <FaDoorClosed style={{ fontSize: '1.5em' }} />,
    goback: <TbArrowBackUpDouble style={{ fontSize: '1.5em' }} />,
    home: <TbHome style={{ fontSize: '1.5em' }} />,
    others: <MdDevicesOther style={{ fontSize: '1.5em' }} />,
    services: <TbDatabase style={{ fontSize: '1.5em' }} />,
    serviceAdd: <TbDatabasePlus style={{ fontSize: '1.5em' }} />,
    serviceDelete: <TbDatabaseMinus style={{ fontSize: '1.5em' }} />,
    serviceUpdate: <TbDatabaseCog style={{ fontSize: '1.5em' }} />,
    serviceDetails: <TbDatabaseEdit style={{ fontSize: '1.5em' }} />,
    customers: <LuUser style={{ fontSize: '1.5em' }} />,
    customerAdd: <LuUserRoundPlus style={{ fontSize: '1.5em' }} />,
    customerDelete: <LuUserRoundMinus style={{ fontSize: '1.5em' }} />,
    customerUpdate: <LuUserRoundCog style={{ fontSize: '1.5em' }} />,
    customerDetails: <LuUserRoundPen style={{ fontSize: '1.5em' }} />,
    employees: <LuUsers style={{ fontSize: '1.5em' }} />,
    employeeAdd: <LuUserRoundPlus style={{ fontSize: '1.5em' }} />,
    employeeDelete: <LuUserRoundMinus style={{ fontSize: '1.5em' }} />,
    employeeUpdate: <LuUserRoundCog style={{ fontSize: '1.5em' }} />,
    employeeDetails: <LuUserRoundPen style={{ fontSize: '1.5em' }} />,
    warehouse: <LuPackage style={{ fontSize: '1.5em' }} />,
    stockAdd: <LuPackagePlus style={{ fontSize: '1.5em' }} />,
    stockDelete: <LuPackageMinus style={{ fontSize: '1.5em' }} />,
    stockUpdate: <LuPackageCheck style={{ fontSize: '1.5em' }} />,
    stockDetails: <LuPackageOpen style={{ fontSize: '1.5em' }} />,
    offers: <TbTags style={{ fontSize: '1.5em' }} />,
    offerAdd: <TbTagPlus style={{ fontSize: '1.5em' }} />,
    offerDelete: <TbTagMinus style={{ fontSize: '1.5em' }} />,
    offerUpdate: <TbTag style={{ fontSize: '1.5em' }} />,
    offerDetails: <TbTagFilled style={{ fontSize: '1.5em' }} />,
    reminder: <LuCalendar style={{ fontSize: '1.5em' }} />,
    reminderAdd: <LuCalendarPlus style={{ fontSize: '1.5em' }} />,
    reminderDelete: <LuCalendarMinus style={{ fontSize: '1.5em' }} />,
    reminderUpdate: <LuCalendarCog style={{ fontSize: '1.5em' }} />,
    reminderDetails: <LuCalendar style={{ fontSize: '1.5em' }} />,
    surveys: <LuClipboard style={{ fontSize: '1.5em' }} />,
    statistics: <LuChartPie style={{ fontSize: '1.5em' }} />,
    case: <TbCashRegister style={{ fontSize: '1.5em' }} />,
    caseactionAdd: <TbCashBanknote style={{ fontSize: '1.5em' }} />,
    caseactionDelete: <TbCashBanknoteOff style={{ fontSize: '1.5em' }} />,
    caseactionUpdate: <TbCashBanknoteFilled style={{ fontSize: '1.5em' }} />,
    caseactionDetails: <TbCash style={{ fontSize: '1.5em' }} />,
    invoices: <HiOutlineDocumentDuplicate style={{ fontSize: '1.5em' }} />,
    invoiceAdd: <HiOutlineDocumentPlus style={{ fontSize: '1.5em' }} />,
    invoiceDelete: <HiOutlineDocumentMinus style={{ fontSize: '1.5em' }} />,
    invoiceUpdate: <HiOutlineDocumentCheck style={{ fontSize: '1.5em' }} />,
    invoiceDetails: <HiDocument style={{ fontSize: '1.5em' }} />,
    bulkmessages: <TbMessage2 style={{ fontSize: '1.5em' }} />,
    bulkmessageSent: <RiMailSendFill style={{ fontSize: '1.5em' }} />,
    transactionreports: <HiOutlineClipboardDocumentList style={{ fontSize: '1.5em' }} />,
    transactionreportAdd: <HiOutlineDocumentPlus style={{ fontSize: '1.5em' }} />,
    transactionreportDelete: <HiOutlineDocumentMinus style={{ fontSize: '1.5em' }} />,
    transactionreportUpdate: <HiOutlineDocumentCheck style={{ fontSize: '1.5em' }} />,
    transactionreportDetails: <HiDocument style={{ fontSize: '1.5em' }} />,
    vehicles: <LuCar style={{ fontSize: '1.5em' }} />,
    vehicleAdd: <IoMdAddCircleOutline style={{ fontSize: '1.5em' }} />,
    vehicleDelete: <IoMdRemoveCircleOutline style={{ fontSize: '1.5em' }} />,
    vehicleUpdate: <IoMdInformationCircleOutline style={{ fontSize: '1.5em' }} />,
    vehicleDetails: <LuCar style={{ fontSize: '1.5em' }} />,
    suppliers: <LuSquareUser style={{ fontSize: '1.5em' }} />,
    supplierAdd: <LuUserRoundPlus style={{ fontSize: '1.5em' }} />,
    supplierDelete: <LuUserRoundMinus style={{ fontSize: '1.5em' }} />,
    supplierUpdate: <LuUserRoundCog style={{ fontSize: '1.5em' }} />,
    supplierDetails: <LuUserRoundPen style={{ fontSize: '1.5em' }} />,
    warehouses: <LuWarehouse style={{ fontSize: '1.5em' }} />,
    warehouseAdd: <TbHomePlus style={{ fontSize: '1.5em' }} />,
    warehouseDelete: <TbHomeMinus style={{ fontSize: '1.5em' }} />,
    warehouseUpdate: <TbHomeCog style={{ fontSize: '1.5em' }} />,
    warehouseDetails: <TbHomeStar style={{ fontSize: '1.5em' }} />,
    shelves: <MdShelves style={{ fontSize: '1.5em' }} />,
    shelfAdd: <LuFolderPlus style={{ fontSize: '1.5em' }} />,
    shelfDelete: <LuFolderMinus style={{ fontSize: '1.5em' }} />,
    shelfUpdate: <LuFolderCog style={{ fontSize: '1.5em' }} />,
    shelfDetails: <LuFolderPen style={{ fontSize: '1.5em' }} />,
    roles: <TbLayoutGrid style={{ fontSize: '1.5em' }} />,
    roleAdd: <TbLayoutGridAdd style={{ fontSize: '1.5em' }} />,
    roleDelete: <TbLayoutGridRemove style={{ fontSize: '1.5em' }} />,
    roleUpdate: <TbLayoutGridFilled style={{ fontSize: '1.5em' }} />,
    roleDetails: <TbLayoutGrid style={{ fontSize: '1.5em' }} />,
    brands: <LuBadge style={{ fontSize: '1.5em' }} />,
    brandAdd: <LuBadgePlus style={{ fontSize: '1.5em' }} />,
    brandDelete: <LuBadgeMinus style={{ fontSize: '1.5em' }} />,
    brandUpdate: <LuBadgeCheck style={{ fontSize: '1.5em' }} />,
    brandDetails: <LuBadgeInfo style={{ fontSize: '1.5em' }} />,
    devices: <LuPlug2 style={{ fontSize: '1.5em' }} />,
    deviceAdd: <FaPlugCirclePlus style={{ fontSize: '1.5em' }} />,
    deviceDelete: <FaPlugCircleMinus style={{ fontSize: '1.5em' }} />,
    deviceUpdate: <FaPlugCircleCheck style={{ fontSize: '1.5em' }} />,
    deviceDetails: <FaPlug style={{ fontSize: '1.5em' }} />,
    dealers: <LuMapPinHouse style={{ fontSize: '1.5em' }} />,
    dealerAdd: <TbHomePlus style={{ fontSize: '1.5em' }} />,
    dealerDelete: <TbHomeMinus style={{ fontSize: '1.5em' }} />,
    dealerUpdate: <TbHomeCog style={{ fontSize: '1.5em' }} />,
    dealerDetails: <TbHomeStar style={{ fontSize: '1.5em' }} />,
    sources: <BsTelephone style={{ fontSize: '1.5em' }} />,
    sourceAdd: <BsTelephonePlus style={{ fontSize: '1.5em' }} />,
    sourceDelete: <BsTelephoneMinus style={{ fontSize: '1.5em' }} />,
    sourceUpdate: <BsTelephoneFill style={{ fontSize: '1.5em' }} />,
    sourceDetails: <BsTelephone style={{ fontSize: '1.5em' }} />,
    consigmentdevices: <TbDeviceIpadHorizontal style={{ fontSize: '1.5em' }} />,
    consigmentdeviceAdd: <TbDeviceIpadHorizontalPlus style={{ fontSize: '1.5em' }} />,
    consigmentdeviceDelete: <TbDeviceIpadHorizontalMinus style={{ fontSize: '1.5em' }} />,
    consigmentdeviceUpdate: <TbDeviceIpadHorizontalCog style={{ fontSize: '1.5em' }} />,
    consigmentdeviceDetails: <TbDeviceIpadHorizontalStar style={{ fontSize: '1.5em' }} />,
    surveyquestions: <IoMdHelpCircleOutline style={{ fontSize: '1.5em' }} />,
    surveyquestionAdd: <IoMdAddCircleOutline style={{ fontSize: '1.5em' }} />,
    surveyquestionDelete: <IoMdRemoveCircleOutline style={{ fontSize: '1.5em' }} />,
    surveyquestionUpdate: <IoMdInformationCircleOutline style={{ fontSize: '1.5em' }} />,
    surveyquestionDetails: <IoMdHelpCircleOutline style={{ fontSize: '1.5em' }} />,
    messages: <TbMessages style={{ fontSize: '1.5em' }} />,
    messageAdd: <TbMessage2Plus style={{ fontSize: '1.5em' }} />,
    messageDelete: <TbMessage2Minus style={{ fontSize: '1.5em' }} />,
    messageUpdate: <TbMessage2Cog style={{ fontSize: '1.5em' }} />,
    messageDetails: <TbMessage2Star style={{ fontSize: '1.5em' }} />
  }
  const values = { Icons }
  return <IconContext.Provider value={values}>{children}</IconContext.Provider>
}

const useIcons = () => useContext(IconContext);

export { IconProvider, useIcons };