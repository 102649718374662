// General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, IconButton, InputAdornment, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getExchange, resetExchanges, resetStatus as resetExchangeStatus } from '../../../../storage/slices/general/ExchangeSlice';
// Contexts
import { useData } from '../../../../context/DataContext';
import { useFunctions } from '../../../../context/FunctionContext';
import { useValidations } from '../../../../context/ValidationContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { LuPackagePlus } from "react-icons/lu";
import { IoCamera } from "react-icons/io5";

function StockAddModal({ show, handleClose, handleAdd }) {
  // Storage Import
  const dispatch = useDispatch();
  const { exchanges, status: exchangeStatus } = useSelector((state) => state.exchange);
  const { suppliers } = useSelector((state) => state.supplier);
  const { brands } = useSelector((state) => state.brand);
  const { devices } = useSelector((state) => state.device);
  const { warehouses } = useSelector((state) => state.warehouse);
  const { shelves } = useSelector((state) => state.shelf);
  // Context Import
  const { statuses } = useData();
  const { stringOnlyNumbers } = useFunctions();
  const { vs_stock } = useValidations();
  // Variables
  const [warehouse, setWarehouse] = useState('');
  const [shelfData, setShelfData] = useState([]);
  const [dollar, setDollar] = useState(null);
  // Functions
  useEffect(() => {
    setShelfData(shelves.filter(item => item.warehouseName === warehouse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse]);
  // Api Operations
  useEffect(() => {
    if (show) {
      if (dollar === null) {
        dispatch(getExchange({ signal: null }));
      }
    } else { setDollar(null); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  useEffect(() => {
    if (exchangeStatus === 'fulfilled') {
      setDollar(exchanges?.find(item => item.currency === 'US DOLLAR'));
      dispatch(resetExchanges());
      dispatch(resetExchangeStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeStatus]);
  // Formik Variables
  const initialValues = {
    name: '',
    supplierName: '',
    barcode: '',
    brandName: '',
    deviceName: '',
    warehouseName: '',
    warehouseShelfName: '',
    status: '',
    quantity: 0,
    purchasePrice: 0,
    salePrice: 0
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header className='py-2' closeButton>
          <Modal.Title>Yeni Ürün Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={initialValues} validationSchema={vs_stock} onSubmit={(values) => handleAdd(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => {
              return (
                <Form>
                  <div className='row g-2'>
                    <div className="col-12 col-md-6">
                      <Field
                        as={TextField}
                        id='StockAddModal_name'
                        name='name'
                        label='Ürün Adı'
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <Field
                        as={Autocomplete}
                        id="StockAddModal_supplierName"
                        name="supplierName"
                        options={suppliers.map((item) => item.name)}
                        value={values.supplierName}
                        onChange={(event, newValue) => { setFieldValue('supplierName', newValue); }}
                        onBlur={() => { setFieldTouched('supplierName', true); }}
                        variant='outlined'
                        size='small'
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Tedarikçi" error={touched.supplierName && !!errors.supplierName} helperText={touched.supplierName && errors.supplierName} />}
                      />
                    </div>
                    <div className="col-12 col-md-6 ">
                      <Field
                        as={Autocomplete}
                        id="StockAddModal_brandName"
                        name="brandName"
                        options={brands.map((item) => item.name)}
                        value={values.brandName}
                        onChange={(event, newValue) => { setFieldValue('brandName', newValue); }}
                        onBlur={() => { setFieldTouched('brandName', true); }}
                        variant='outlined'
                        size='small'
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Marka" error={touched.brandName && !!errors.brandName} helperText={touched.brandName && errors.brandName} />}
                      />
                    </div>
                    <div className="col-12 col-md-6 ">
                      <Field
                        as={Autocomplete}
                        id="StockAddModal_deviceName"
                        name="deviceName"
                        options={devices.map((item) => item.name)}
                        value={values.device}
                        onChange={(event, newValue) => { setFieldValue('deviceName', newValue); }}
                        onBlur={() => { setFieldTouched('deviceName', true); }}
                        variant='outlined'
                        size='small'
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Cihaz" error={touched.deviceName && !!errors.deviceName} helperText={touched.deviceName && errors.deviceName} />}
                      />
                    </div>
                    <div className="col-12 col-md-6 ">
                      <Field
                        as={Autocomplete}
                        id="StockAddModal_warehouseName"
                        name="warehouseName"
                        options={warehouses.map((item) => item.name)}
                        value={values.warehouseName}
                        onChange={(event, newValue) => {
                          setFieldValue('warehouseName', newValue);
                          if (newValue) { setWarehouse(newValue); }
                          else { setWarehouse(''); }
                          setFieldValue('warehouseShelfName', '');
                        }}
                        onBlur={() => { setFieldTouched('warehouseName', true); }}
                        variant='outlined'
                        size='small'
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Depo" error={touched.warehouseName && !!errors.warehouseName} helperText={touched.warehouseName && errors.warehouseName} />}
                      />
                    </div>
                    <div className="col-12 col-md-6 ">
                      <Field
                        as={Autocomplete}
                        id="StockAddModal_warehouseShelfName"
                        name="warehouseShelfName"
                        options={shelfData.map((item) => item.name)}
                        value={values.warehouseShelfName}
                        onChange={(event, newValue) => { setFieldValue('warehouseShelfName', newValue); }}
                        onBlur={() => { setFieldTouched('warehouseShelfName', true); }}
                        readOnly={(!warehouse) && (warehouse === '')}
                        variant='outlined'
                        size='small'
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Raf" error={touched.warehouseShelfName && !!errors.warehouseShelfName} helperText={touched.warehouseShelfName && errors.warehouseShelfName} />}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <Field
                        as={TextField}
                        id='StockAddModal_purchasePrice'
                        name='purchasePrice'
                        label='Alış fiyatı'
                        type='number'
                        value={values.purchasePrice}
                        onChange={(e) => setFieldValue("purchasePrice", stringOnlyNumbers(e.target.value))}
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={touched.purchasePrice && !!errors.purchasePrice}
                        helperText={touched.purchasePrice && errors.purchasePrice}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <Field
                        as={TextField}
                        id='StockAddModal_salePrice'
                        name='salePrice'
                        label='Satış fiyatı'
                        type='number'
                        value={values.salePrice}
                        onChange={(e) => setFieldValue("salePrice", stringOnlyNumbers(e.target.value))}
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={touched.salePrice && !!errors.salePrice}
                        helperText={touched.salePrice && errors.salePrice}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <Field
                        as={TextField}
                        id='StockAddModal_usd'
                        name='usd'
                        label='Güncel Dolar Alış'
                        type='number'
                        value={dollar?.buying || ''}
                        slotProps={{ input: { readOnly: true } }}
                        variant='outlined'
                        size='small'
                        fullWidth
                      />
                    </div>
                    <div className="col-12 col-md-12">
                      <Field
                        as={TextField}
                        id='StockAddModal_quantity'
                        name='quantity'
                        label='Adet'
                        type="number"
                        value={values.quantity}
                        onChange={(e) => setFieldValue("quantity", stringOnlyNumbers(e.target.value))}
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={touched.quantity && !!errors.quantity}
                        helperText={touched.quantity && errors.quantity}
                      />
                    </div>
                    <div className="col-12 col-md-12">
                      <Field
                        as={TextField}
                        id='StockAddModal_barcode'
                        name='barcode'
                        label='Barkod'
                        value={values.barcode}
                        onChange={(e) => setFieldValue("barcode", stringOnlyNumbers(e.target.value))}
                        slotProps={{
                          input: {
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton aria-label="toggle barcode" edge="end">
                                  <IoCamera></IoCamera>
                                </IconButton>
                              </InputAdornment>)
                          }
                        }}
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={touched.barcode && !!errors.barcode}
                        helperText={touched.barcode && errors.barcode}
                      />
                    </div>
                    <div className="col-12 col-md-12">
                      <Field
                        as={Autocomplete}
                        id="StockAddModal_status"
                        name="status"
                        options={statuses}
                        value={values.status}
                        onChange={(event, newValue) => { setFieldValue('status', newValue); }}
                        onBlur={() => { setFieldTouched('status', true); }}
                        variant='outlined'
                        size='small'
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Durum" error={touched.status && !!errors.status} helperText={touched.status && errors.status} />}
                      />
                    </div>
                    <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                      <div className='col-12 col-md-6'>
                        <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                          <TbArrowBackUpDouble className='button-icon' />
                          Geri
                        </button>
                      </div>
                      <div className='col-12 col-md-6'>
                        <button type='submit' className='button-with-icon btn btn-success w-100'>
                          <LuPackagePlus className='button-icon' />
                          Ekle
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  );
}

export default StockAddModal;