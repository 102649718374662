// General
import React from 'react';
import { NavLink } from 'react-router-dom';
// Icons
import { TbHeartFilled } from "react-icons/tb";

function Footer() {
  // HTML
  return (
    <footer className="my-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col fs-13 text-muted text-center">
            &copy; {new Date().getFullYear()} - Made with <TbHeartFilled className='text-danger p-0 m-0' style={{ fontSize: '1.2em' }} /> by <NavLink to="https://www.tuyak.com.tr" className="text-reset fw-semibold"><b>TÜYAK</b></NavLink>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;