// General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../../storage/slices/general/ToastSlice';
import { getAllUserPremiums, payUserPremium } from '../../../../storage/slices/backend/PremiumSlice';
import { getAllRolesForPremium } from '../../../../storage/slices/backend/RoleSlice';
// Contexts
import { useData } from '../../../../context/DataContext';
import { useFunctions } from '../../../../context/FunctionContext';
// Icons
import { TbDatabaseShare, TbPremiumRights } from "react-icons/tb";
// Components
import Table from '../../components/Table';

function PremiumCalculatorModal({ show, handleClose }) {
  // Storage Import
  const dispatch = useDispatch();
  const { premiums, payStatus } = useSelector((state) => state.premium);
  const { rolesForPremium, getAllForPremiumStatus } = useSelector((state) => state.role);
  // Context Import
  const { premiumStatuses } = useData();
  const { stringMoney, getStringDate } = useFunctions();
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  const [types, setTypes] = useState([]);
  const [dataValues, setDataValues] = useState({});
  // Functions
  useEffect(() => {
    if (!canfetchData & show) {
      setCanFetchData(true);
    } else if (!show) {
      setCanFetchData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  const handleGetData = async (values) => {
    const controller = new AbortController();
    const { signal } = controller;
    setDataValues(values);
    await fetchTableDataSequentially(dispatch, signal, values.start, values.end, values.type, values.name, values.status)
    return () => { controller.abort(); };
  }
  const handlePayPremiums = async () => {
    await dispatch(payUserPremium({ start: dataValues.start, end: dataValues.end, type: dataValues.type, name: dataValues.name, status: dataValues.status }));
    if (payStatus === 'fulfilled') {
      dispatch(addToast({ background: 'success', icon: 'add', message: 'Primler Ödendi!', delay: 6000 }));
    }
  }
  // Api Operations
  const fetchTableDataSequentially = async (dispatch, signal, start, end, type, name, status) => {
    try {
      await dispatch(getAllUserPremiums({ signal, start, end, type, name, status }));
    } catch (error) { throw error; }
  }
  const fetchStartDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllRolesForPremium({ signal }));
    } catch (error) { throw error; }
  }
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchStartDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => {
    if (getAllForPremiumStatus === 'fulfilled') {
      setTypes(rolesForPremium.map(item => item.name));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllForPremiumStatus]);
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered className='modal-xl' backdrop="static">
      <Modal.Header className='py-2' closeButton>
        <Modal.Title>Prim Hesapla</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row g-2'>
          <div className='col-12'>
            <div className='card bg-light'>
              <div className='card-body'>
                <div className='row'>
                  <Formik initialValues={{ start: getStringDate(0), end: getStringDate(0), type: '', name: '', status: '' }} onSubmit={(values) => handleGetData(values)}>
                    {({ values, setFieldValue, setFieldTouched }) => {
                      return (
                        <Form>
                          <div className='col-12'>
                            <div className='row g-2'>
                              <div className='col-12 col-sm-6 col-lg-2 col-xl-2'>
                                <Field
                                  as={TextField}
                                  id='PremiumCalculatorModal_start'
                                  name='start'
                                  label="Başlangıç tarihi"
                                  type="date"
                                  value={values.start}
                                  onChange={(e) => setFieldValue('start', e.target.value)}
                                  slotProps={{ input: { shrink: "true" } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                />
                              </div>
                              <div className='col-12 col-sm-6 col-lg-2 col-xl-2'>
                                <Field
                                  as={TextField}
                                  id='PremiumCalculatorModal_end'
                                  name='end'
                                  label="Bitiş tarihi"
                                  type="date"
                                  value={values.end}
                                  onChange={(e) => setFieldValue('end', e.target.value)}
                                  slotProps={{ input: { shrink: "true" } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                />
                              </div>
                              <div className='col-12 col-sm-6 col-lg-2 col-xl-2'>
                                <Field
                                  as={Autocomplete}
                                  id='PremiumCalculatorModal_type'
                                  name='type'
                                  value={values.type}
                                  onChange={(event, newValue) => setFieldValue('type', newValue)}
                                  options={types}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  renderInput={(params) => <TextField {...params} label="Personel Türü" />}
                                />
                              </div>
                              <div className='col-12 col-sm-6 col-lg-2 col-xl-2'>
                                <Field
                                  as={Autocomplete}
                                  id='PremiumCalculatorModal_name'
                                  name='name'
                                  value={values.name}
                                  onChange={(event, newValue) => setFieldValue('name', newValue)}
                                  options={['Deneme', 'Deneme']}
                                  disabled={values.type === ''}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  renderInput={(params) => <TextField {...params} label="Personel Adı" />}
                                />
                              </div>
                              <div className='col-12 col-sm-6 col-lg-2 col-xl-2'>
                                <Field
                                  as={Autocomplete}
                                  id='PremiumCalculatorModal_status'
                                  name='status'
                                  value={values.status}
                                  onChange={(event, newValue) => setFieldValue('status', newValue)}
                                  options={premiumStatuses}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  renderInput={(params) => <TextField {...params} label="Durum" />}
                                />
                              </div>
                              <div className='col-12 col-sm-6 col-lg-2 col-xl-2'>
                                <button type='submit' className='button-with-icon btn btn-primary w-100'>
                                  <TbDatabaseShare className='button-icon' />
                                  Veri Getir
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <Table
                data={premiums.map(item => ({ ...item, bonus: stringMoney(item.bonus, 'TL') }))}
                rows={5}
                columns={[{ value: 'id', name: 'ID' }, { value: 'name', name: 'PERSONEL ADI' }, { value: 'type', name: 'PERSONEL TÜRÜ' }, { value: 'date', name: 'TARİH' }, { value: 'bonus', name: 'PRİM' }, { value: 'status', name: 'DURUM' }]}
                headerAlign='center'
              />
            </div>
            {premiums.length > 0 && (
              <>
                <div className='col-12 col-sm-6 col-lg-8 col-xl-8'></div>
                <div className='col-12 col-sm-6 col-lg-4 col-xl-4'>
                  <button type='button' className='button-with-icon btn btn-primary w-100' onClick={handlePayPremiums}>
                    <TbPremiumRights className='button-icon' />
                    Primleri Öde
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PremiumCalculatorModal;