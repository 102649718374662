
//General
import React, { useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
// Contexts
import { useFunctions } from '../../../../context/FunctionContext';
// Components
import Table from '../../components/Table';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";

function SupplierDetailsModal({ show, handleClose, inputData }) {
  // Context Import
  const { getStringDate } = useFunctions();
  // Variables
  const [dates, setDates] = useState({ start: getStringDate(7), end: getStringDate(0) });
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const rows = 5;
  const columns = [{ value: 'serviceid', name: 'SERVİS' }, { value: 'device', name: 'CİHAZ' }, { value: 'operation', name: 'İŞLEM' }];
  const changeDates = (startDate, endDate) => { setDates({ start: startDate, end: endDate }); }
  // Components
  const TableContent = () => {
    return (
      <Table
        data={dataToTable}
        rows={rows}
        columns={columns}
        changeDates={changeDates}
        dates={dates}
        datesfullwidth={true}
        headerAlign='center'
      />
    );
  }
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header className='py-2' closeButton>
          <Modal.Title>Detaylar ({inputData?.name})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableContent />
        </Modal.Body>
        <Modal.Footer>
          <div className='row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100'>
            <div className='col-12 col-md-6'>
              <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                <TbArrowBackUpDouble className='button-icon' />
                Geri
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default SupplierDetailsModal;