// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllMessages, deleteMessage, resetGetAllStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/MessageSlice';
// Contexts
import { useData } from '../../../context/DataContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import MessageDeleteModal from '../modals/DeleteModal';
// Icons
import { TbPrinter, TbMessage2Minus } from 'react-icons/tb';

function Messages() {
  // Storage Import
  const dispatch = useDispatch();
  const { messages, getAllStatus, deleteStatus, error } = useSelector((state) => state.message);
  // Context Import
  const { myUser } = useData();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllMessages({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      setDataToTable(messages);
      setDataToPDF(messages);
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseMessageDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'messageDelete', message: 'Mesaj Silindi!', delay: 6000 }));
      dispatch(getAllMessages({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'message', name: 'MESAJ' }, { value: 'receivers', name: 'ALICILAR' }];
  const filters = ['id', 'message', 'receivers'];
  const buttons = [
    {
      text: 'Sil',
      icon: <TbMessage2Minus className='button-icon' />,
      onclick: ((rowData) => { handleShowMessageDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Mesajlar';
  const operations = [
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
      />
    );
  }
  // Modals
  // Delete Modal
  const [showMessageDeleteModal, setShowMessageDeleteModal] = useState(false);
  const [inputDataMessageDeleteModal, setInputDataMessageDeleteModal] = useState(null);
  const handleCloseMessageDeleteModal = () => {
    setShowMessageDeleteModal(false);
    setInputDataMessageDeleteModal(null);
  }
  const handleShowMessageDeleteModal = (id) => {
    setInputDataMessageDeleteModal(id);
    setShowMessageDeleteModal(true);
  }
  const handleDeleteMessageDeleteModal = () => {
    dispatch(deleteMessage({ id: inputDataMessageDeleteModal }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <MessageDeleteModal
        show={showMessageDeleteModal}
        handleClose={handleCloseMessageDeleteModal}
        handleDelete={handleDeleteMessageDeleteModal}
        title="Mesajı Sil"
        message={`${inputDataMessageDeleteModal} id numaralı mesajı silmek istediğinize emin misiniz?`}
        deleteIcon={<TbMessage2Minus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showMessageDeleteModal, inputDataMessageDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Messages;