// General
import React from 'react';
import { NavLink } from 'react-router-dom';
// Image
import ErrorImage from '../../../assets/images/error404.gif';

function Error404() {
  // HTML
  return (
    <div className="maintenance-pages bg-white">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="text-center">
              <div className="maintenance-img">
                <img src={ErrorImage} className="img-fluid" alt="coming-soon" />
              </div>
              <div className="text-center">
                <h3 className="mt-5 fw-semibold text-black text-capitalize">Hay aksi! Aradığınız sayfayı bulamadık.</h3>
              </div>
              <NavLink className="error-backbutton" to="/anasayfa"><b>Ana Sayfa'ya dön</b></NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error404