// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Customers/';

// Async Thunks
export const getAllCustomers = createAsyncThunk('backend_getallcustomers', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllCustomers`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getCustomersByDates = createAsyncThunk('backend_getcustomersbydates', async ({ startdate, enddate }) => {
  try {
    const res = await axios.get(`${baseurl}GetCustomersByDate?startDate=${startdate}&endDate=${enddate}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const getCustomerById = createAsyncThunk('backend_getcustomerbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetCustomerById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const getCustomerByPhone = createAsyncThunk('backend_getcustomerbyphone', async ({ phone }) => {
  try {
    const res = await axios.get(`${baseurl}GetCustomerByPhone?phone=${phone}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewCustomer = createAsyncThunk('backend_addnewcustomer', async ({ newcustomer }) => {
  try {
    const res = await axios.post(`${baseurl}AddCustomer`, newcustomer, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateCustomer = createAsyncThunk('backend_updatecustomer', async ({ customer }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateCustomer`, customer, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteCustomer = createAsyncThunk('backend_deletecustomer', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteCustomer?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    customers: [],
    customer: {},
    getAllStatus: null,
    getDataStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetGetDataStatus: (state) => { state.getDataStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomers.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllCustomers.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.customers = action.payload || []; })
      .addCase(getAllCustomers.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getCustomersByDates.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getCustomersByDates.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.customers = action.payload || []; })
      .addCase(getCustomersByDates.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getCustomerById.pending, (state) => { state.getDataStatus = "pending"; })
      .addCase(getCustomerById.fulfilled, (state, action) => { state.getDataStatus = "fulfilled"; state.customer = action.payload || {}; })
      .addCase(getCustomerById.rejected, (state, action) => { state.getDataStatus = "rejected"; state.error = action.error.message; })
      .addCase(getCustomerByPhone.pending, (state) => { state.getDataStatus = "pending"; })
      .addCase(getCustomerByPhone.fulfilled, (state, action) => { state.getDataStatus = "fulfilled"; state.customer = action.payload || {}; })
      .addCase(getCustomerByPhone.rejected, (state) => { state.getDataStatus = "rejected"; })
      .addCase(addNewCustomer.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewCustomer.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewCustomer.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateCustomer.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateCustomer.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateCustomer.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteCustomer.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteCustomer.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteCustomer.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = customerSlice.actions;
export default customerSlice.reducer;