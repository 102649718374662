// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllRoles, getRoleById, addNewRole, updateRole, deleteRole, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/RoleSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import RoleAddModal from '../modals/roles/RoleAddModal';
import RoleUpdateModal from '../modals/roles/RoleUpdateModal';
import RolePermissionsModal from '../modals/roles/RolePermissionsModal';
import RoleDeleteModal from '../modals/DeleteModal';
// Icons
import { TbLayoutGridAdd, TbLayoutGridRemove, TbLayoutGridFilled, TbLayoutGrid, TbPrinter } from "react-icons/tb";

function Roles() {
  // Storage Import
  const dispatch = useDispatch();
  const { roles, role, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.role);
  // Context Import
  const { myUser } = useData();
  const { dictionaryBooleans } = useFunctions();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllRoles({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      setDataToTable(roles.map(item => ({ ...item, isPremium: dictionaryBooleans[item.isPremium] })));
      setDataToPDF(roles.map(item => ({ ...item, isPremium: dictionaryBooleans[item.isPremium] })));
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseRoleAddModal();
      dispatch(addToast({ background: 'success', icon: 'roleAdd', message: 'Rol Eklendi!', delay: 6000 }));
      dispatch(getAllRoles({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseRoleUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'roleUpdate', message: 'Rol Güncellendi!', delay: 6000 }));
      dispatch(getAllRoles({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseRoleDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'roleDelete', message: 'Rol Silindi!', delay: 6000 }));
      dispatch(getAllRoles({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataRoleUpdateModal({ ...role, isPremium: dictionaryBooleans[role.isPremium] });
        setShowRoleUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'permissions') {
        setInputDataRolePermissionsModal({ ...role, isPremium: dictionaryBooleans[role.isPremium] });
        setShowRolePermissionsModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataRoleDeleteModal({ ...role, isPremium: dictionaryBooleans[role.isPremium] });
        setShowRoleDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'ROL' }, { value: 'description', name: 'AÇIKLAMA' }, { value: 'isPremium', name: 'PRİM ALABİLİR Mİ?' }];
  const filters = ['id', 'name', 'description', 'isPremium'];
  const clickOn = (id) => { handleShowRoleUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <TbLayoutGridFilled className='button-icon' />,
      onclick: ((rowData) => { handleShowRoleUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'İzinler',
      icon: <TbLayoutGrid className='button-icon' />,
      onclick: ((rowData) => { handleShowRolePermissionsModal(rowData.id); }),
      variant: 'info'
    },
    {
      text: 'Sil',
      icon: <TbLayoutGridRemove className='button-icon' />,
      onclick: ((rowData) => { handleShowRoleDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Roller';
  const operations = [
    {
      text: 'Rol Ekle',
      icon: <TbLayoutGridAdd className='button-icon' />,
      onclick: (() => { handleShowRoleAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showRoleAddModal, setShowRoleAddModal] = useState(false);
  const handleCloseRoleAddModal = () => {
    setShowRoleAddModal(false);
  }
  const handleShowRoleAddModal = () => {
    setShowRoleAddModal(true);
  }
  const handleAddRoleAddModal = (values) => {
    dispatch(addNewRole({ newrole: { ...values, isPremium: dictionaryBooleans[values.isPremium] } }));
  }
  // Update Modal
  const [showRoleUpdateModal, setShowRoleUpdateModal] = useState(false);
  const [inputDataRoleUpdateModal, setInputDataRoleUpdateModal] = useState(null);
  const handleCloseRoleUpdateModal = () => {
    setShowRoleUpdateModal(false);
    setInputDataRoleUpdateModal(null);
  }
  const handleShowRoleUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getRoleById({ id: id }));
  }
  const handleUpdateRoleUpdateModal = (values) => {
    dispatch(updateRole({ role: { ...values, isPremium: dictionaryBooleans[values.isPremium] } }));
  }
  // Permissions Modal
  const [showRolePermissionsModal, setShowRolePermissionsModal] = useState(false);
  const [inputDataRolePermissionsModal, setInputDataRolePermissionsModal] = useState(null);
  const handleCloseRolePermissionsModal = () => {
    setShowRolePermissionsModal(false);
    setInputDataRolePermissionsModal(null);
  }
  const handleShowRolePermissionsModal = (id) => {
    setDataRequester('permissions');
    dispatch(getRoleById({ id: id }));
  }
  const handleSetRolePermissionsModal = (values) => {
    console.log(values);
  }
  // Delete Modal
  const [showRoleDeleteModal, setShowRoleDeleteModal] = useState(false);
  const [inputDataRoleDeleteModal, setInputDataRoleDeleteModal] = useState(null);
  const handleCloseRoleDeleteModal = () => {
    setShowRoleDeleteModal(false);
    setInputDataRoleDeleteModal(null);
  }
  const handleShowRoleDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getRoleById({ id: id }));
  }
  const handleDeleteRoleDeleteModal = () => {
    dispatch(deleteRole({ id: inputDataRoleDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <RoleAddModal
        show={showRoleAddModal}
        handleClose={handleCloseRoleAddModal}
        handleAdd={handleAddRoleAddModal}
      />
      <RoleUpdateModal
        show={showRoleUpdateModal}
        handleClose={handleCloseRoleUpdateModal}
        handleUpdate={handleUpdateRoleUpdateModal}
        inputData={inputDataRoleUpdateModal}
      />
      <RolePermissionsModal
        show={showRolePermissionsModal}
        handleClose={handleCloseRolePermissionsModal}
        handleSet={handleSetRolePermissionsModal}
        inputData={inputDataRolePermissionsModal}
      />
      <RoleDeleteModal
        show={showRoleDeleteModal}
        handleClose={handleCloseRoleDeleteModal}
        handleDelete={handleDeleteRoleDeleteModal}
        title="Rolü Sil"
        message={`${inputDataRoleDeleteModal?.name} adlı rolü silmek istediğinize emin misiniz?`}
        deleteIcon={<TbLayoutGridRemove className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showRoleAddModal, showRoleUpdateModal, inputDataRoleUpdateModal, showRolePermissionsModal, inputDataRolePermissionsModal, showRoleDeleteModal, inputDataRoleDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Roles;