// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllVehicles, getVehicleById, addNewVehicle, updateVehicle, deleteVehicle, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/VehicleSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import VehicleAddModal from '../modals/vehicles/VehicleAddModal';
import VehicleUpdateModal from '../modals/vehicles/VehicleUpdateModal';
import VehicleDeleteModal from '../modals/DeleteModal';
// Icons
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline, IoMdInformationCircleOutline } from "react-icons/io";
import { TbPrinter } from 'react-icons/tb';

function Vehicles() {
  // Storage Import
  const dispatch = useDispatch();
  const { vehicles, vehicle, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.vehicle);
  // Context Import
  const { myUser } = useData();
  const { stringPlate } = useFunctions();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllVehicles({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      setDataToTable(vehicles);
      setDataToPDF(vehicles);
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseVehicleAddModal();
      dispatch(addToast({ background: 'success', icon: 'vehicleAdd', message: 'Araç Eklendi!', delay: 6000 }));
      dispatch(getAllVehicles({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseVehicleUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'vehicleUpdate', message: 'Araç Güncellendi!', delay: 6000 }));
      dispatch(getAllVehicles({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseVehicleDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'vehicleDelete', message: 'Araç Silindi!', delay: 6000 }));
      dispatch(getAllVehicles({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataVehicleUpdateModal(vehicle);
        setShowVehicleUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataVehicleDeleteModal(vehicle);
        setShowVehicleDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const buttons = [
    {
      text: 'Güncelle',
      icon: <IoMdInformationCircleOutline className='button-icon' />,
      onclick: ((rowData) => { handleShowVehicleUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <IoMdRemoveCircleOutline className='button-icon' />,
      onclick: ((rowData) => { handleShowVehicleDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'plate', name: 'PLAKA' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'plate', 'description'];
  const clickOn = (id) => { handleShowVehicleUpdateModal(id); }
  // Table Page Components
  const title = 'Araçlar';
  const operations = [
    {
      text: 'Araç Ekle',
      icon: <IoMdAddCircleOutline className='button-icon' />,
      onclick: (() => { handleShowVehicleAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showVehicleAddModal, setShowVehicleAddModal] = useState(false);
  const handleCloseVehicleAddModal = () => {
    setShowVehicleAddModal(false);
  }
  const handleShowVehicleAddModal = () => {
    setShowVehicleAddModal(true);
  }
  const handleAddVehicleAddModal = (values) => {
    dispatch(addNewVehicle({ newvehicle: { ...values, plate: stringPlate(values.plate) } }));
  }
  // Update Modal
  const [showVehicleUpdateModal, setShowVehicleUpdateModal] = useState(false);
  const [inputDataVehicleUpdateModal, setInputDataVehicleUpdateModal] = useState(null);
  const handleCloseVehicleUpdateModal = () => {
    setShowVehicleUpdateModal(false);
    setInputDataVehicleUpdateModal(null);
  }
  const handleShowVehicleUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getVehicleById({ id: id }));
  }
  const handleUpdateVehicleUpdateModal = (values) => {
    dispatch(updateVehicle({ vehicle: { ...values, plate: stringPlate(values.plate) } }));
  }
  // Delete Modal
  const [showVehicleDeleteModal, setShowVehicleDeleteModal] = useState(false);
  const [inputDataVehicleDeleteModal, setInputDataVehicleDeleteModal] = useState(null);
  const handleCloseVehicleDeleteModal = () => {
    setShowVehicleDeleteModal(false);
    setInputDataVehicleDeleteModal(null);
  }
  const handleShowVehicleDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getVehicleById({ id: id }));
  }
  const handleDeleteVehicleDeleteModal = () => {
    dispatch(deleteVehicle({ id: inputDataVehicleDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <VehicleAddModal
        show={showVehicleAddModal}
        handleClose={handleCloseVehicleAddModal}
        handleAdd={handleAddVehicleAddModal}
      />
      <VehicleUpdateModal
        show={showVehicleUpdateModal}
        handleClose={handleCloseVehicleUpdateModal}
        handleUpdate={handleUpdateVehicleUpdateModal}
        inputData={inputDataVehicleUpdateModal}
      />
      <VehicleDeleteModal
        show={showVehicleDeleteModal}
        handleClose={handleCloseVehicleDeleteModal}
        handleDelete={handleDeleteVehicleDeleteModal}
        title="Aracı Sil"
        message={`${inputDataVehicleDeleteModal?.plate} plakalı aracı silmek istediğinize emin misiniz?`}
        deleteIcon={<IoMdRemoveCircleOutline className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showVehicleAddModal, showVehicleUpdateModal, inputDataVehicleUpdateModal, showVehicleDeleteModal, inputDataVehicleDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Vehicles;