// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllStocks, getStockById, addNewStock, updateStock, deleteStock, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/StockSlice';
import { getAllSuppliers, addNewSupplier, resetAddStatus as resetAddSupplierStatus, resetError as resetErrorSupplier } from '../../../storage/slices/backend/SupplierSlice';
import { getAllWarehouses, addNewWarehouse, resetAddStatus as resetAddWarehouseStatus, resetError as resetErrorWarehouse } from '../../../storage/slices/backend/WarehouseSlice';
import { getAllShelves, addNewShelf, resetAddStatus as resetAddShelfStatus, resetError as resetErrorShelf } from '../../../storage/slices/backend/ShelfSlice';
import { getAllBrands, addNewBrand, resetAddStatus as resetAddBrandStatus, resetError as resetErrorBrand } from '../../../storage/slices/backend/BrandSlice';
import { getAllDevices, addNewDevice, resetAddStatus as resetAddDeviceStatus, resetError as resetErrorDevice } from '../../../storage/slices/backend/DeviceSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import StockAddModal from '../modals/warehouse/StockAddModal';
import SupplierAddModal from '../modals/suppliers/SupplierAddModal';
import WarehouseAddModal from '../modals/warehouses/WarehouseAddModal';
import ShelfAddModal from '../modals/shelves/ShelfAddModal';
import BrandAddModal from '../modals/brands/BrandAddModal';
import DeviceAddModal from '../modals/devices/DeviceAddModal';
import StockUpdateModal from '../modals/warehouse/StockUpdateModal';
import StockDetailsModal from '../modals/warehouse/StockDetailsModal';
import StockDeleteModal from '../modals/DeleteModal';
// Icons
import { LuPackagePlus, LuPackageMinus, LuPackageCheck, LuPackageOpen, LuUserRoundPlus, LuFolderPlus, LuBadgePlus } from "react-icons/lu";
import { TbPrinter, TbHomePlus } from 'react-icons/tb';
import { FaPlugCirclePlus } from 'react-icons/fa6';

function Warehouse() {
  // Storage Import
  const dispatch = useDispatch();
  const { stocks, stock, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.stock);
  const { addStatus: addSupplierStatus, error: errorSupplier } = useSelector((state) => state.supplier);
  const { addStatus: addWarehouseStatus, error: errorWarehouse } = useSelector((state) => state.warehouse);
  const { addStatus: addShelfStatus, error: errorShelf } = useSelector((state) => state.shelf);
  const { addStatus: addBrandStatus, error: errorBrand } = useSelector((state) => state.brand);
  const { addStatus: addDeviceStatus, error: errorDevice } = useSelector((state) => state.device);
  // Context Import
  const { myUser } = useData();
  const { stringDate, stringMoney, dictionaryStatuses } = useFunctions();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllStocks({ signal }));
      await dispatch(getAllSuppliers({ signal }));
      await dispatch(getAllWarehouses({ signal }));
      await dispatch(getAllShelves({ signal }));
      await dispatch(getAllBrands({ signal }));
      await dispatch(getAllDevices({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      const data = stocks.map(data => {
        const id = data.id;
        const date = stringDate(data.updatedDate);
        const name = data.name;
        const price = stringMoney(data.salePrice, 'TL');
        const quantity = `${data.quantity} Adet`;
        const shelf = data.warehouseShelfName;
        const device = `${data.brandName}/${data.deviceName}`;
        return { id, date, name, price, quantity, shelf, device }
      });
      setDataToTable(data);
      setDataToPDF(data);
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseStockAddModal();
      dispatch(addToast({ background: 'success', icon: 'stockAdd', message: 'Stok Eklendi!', delay: 6000 }));
      dispatch(getAllStocks({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (addSupplierStatus === "fulfilled") {
      handleCloseSupplierAddModal();
      dispatch(addToast({ background: 'success', icon: 'supplierAdd', message: 'Tedarikçi Eklendi!', delay: 6000 }));
      dispatch(getAllSuppliers({}));
      dispatch(resetAddSupplierStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSupplierStatus]);
  useEffect(() => {
    if (addWarehouseStatus === "fulfilled") {
      handleCloseWarehouseAddModal();
      dispatch(addToast({ background: 'success', icon: 'warehouseAdd', message: 'Depo Eklendi!', delay: 6000 }));
      dispatch(getAllWarehouses({}));
      dispatch(resetAddWarehouseStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addWarehouseStatus]);
  useEffect(() => {
    if (addShelfStatus === "fulfilled") {
      handleCloseShelfAddModal();
      dispatch(addToast({ background: 'success', icon: 'shelfAdd', message: 'Raf Eklendi!', delay: 6000 }));
      dispatch(getAllShelves({}));
      dispatch(resetAddShelfStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addShelfStatus]);
  useEffect(() => {
    if (addBrandStatus === "fulfilled") {
      handleCloseBrandAddModal();
      dispatch(addToast({ background: 'success', icon: 'brandAdd', message: 'Marka Eklendi!', delay: 6000 }));
      dispatch(getAllBrands({}));
      dispatch(resetAddBrandStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addBrandStatus]);
  useEffect(() => {
    if (addDeviceStatus === "fulfilled") {
      handleCloseDeviceAddModal();
      dispatch(addToast({ background: 'success', icon: 'deviceAdd', message: 'Cihaz Eklendi!', delay: 6000 }));
      dispatch(getAllDevices({}));
      dispatch(resetAddDeviceStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDeviceStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseStockUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'stockUpdate', message: 'Stok Güncellendi!', delay: 6000 }));
      dispatch(getAllStocks({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseStockDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'stockDelete', message: 'Stok Silindi!', delay: 6000 }));
      dispatch(getAllStocks({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataStockUpdateModal({ ...stock, status: dictionaryStatuses[stock.status] });
        setShowStockUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'details') {
        setInputDataStockDetailsModal({ ...stock, status: dictionaryStatuses[stock.status] });
        setShowStockDetailsModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataStockDeleteModal({ ...stock, status: dictionaryStatuses[stock.status] });
        setShowStockDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  useEffect(() => {
    if (errorSupplier && errorSupplier !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: errorSupplier, delay: 4000 }));
      dispatch(resetErrorSupplier());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSupplier]);
  useEffect(() => {
    if (errorWarehouse && errorWarehouse !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: errorWarehouse, delay: 4000 }));
      dispatch(resetErrorWarehouse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorWarehouse]);
  useEffect(() => {
    if (errorShelf && errorShelf !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: errorShelf, delay: 4000 }));
      dispatch(resetErrorShelf());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorShelf]);
  useEffect(() => {
    if (errorBrand && errorBrand !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: errorBrand, delay: 4000 }));
      dispatch(resetErrorBrand());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorBrand]);
  useEffect(() => {
    if (errorDevice && errorDevice !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: errorDevice, delay: 4000 }));
      dispatch(resetErrorDevice());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDevice]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'name', name: 'ÜRÜN ADI' }, { value: 'price', name: 'FİYAT' }, { value: 'quantity', name: 'ADET' }, { value: 'shelf', name: 'RAF ADI' }, { value: 'device', name: 'CİHAZ' }];
  const filters = ['id', 'date', 'name', 'price', 'quantity', 'shelf', 'device'];
  const clickOn = (id) => { handleShowStockUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <LuPackageCheck className='button-icon' />,
      onclick: ((rowData) => { handleShowStockUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Detaylar',
      icon: <LuPackageOpen className='button-icon' />,
      onclick: ((rowData) => { handleShowStockDetailsModal(rowData.id); }),
      variant: 'info'
    },
    {
      text: 'Sil',
      icon: <LuPackageMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowStockDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Depo';
  const operations = [
    {
      text: 'Stok Ekle',
      icon: <LuPackagePlus className='button-icon' />,
      onclick: (() => { handleShowStockAddModal(); })
    },
    {
      text: 'Tedarikçi Ekle',
      icon: <LuUserRoundPlus className='button-icon' />,
      onclick: (() => { handleShowSupplierAddModal(); })
    },
    {
      text: 'Depo Ekle',
      icon: <TbHomePlus className='button-icon' />,
      onclick: (() => { handleShowWarehouseAddModal(); })
    },
    {
      text: 'Raf Ekle',
      icon: <LuFolderPlus className='button-icon' />,
      onclick: (() => { handleShowShelfAddModal(); })
    },
    {
      text: 'Marka Ekle',
      icon: <LuBadgePlus className='button-icon' />,
      onclick: (() => { handleShowBrandAddModal(); })
    },
    {
      text: 'Cihaz Ekle',
      icon: <FaPlugCirclePlus className='button-icon' />,
      onclick: (() => { handleShowDeviceAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showStockAddModal, setShowStockAddModal] = useState(false);
  const handleCloseStockAddModal = () => {
    setShowStockAddModal(false);
  }
  const handleShowStockAddModal = () => {
    setShowStockAddModal(true);
  }
  const handleAddStockAddModal = (values) => {
    dispatch(addNewStock({ newstock: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Add Modal (Supplier)
  const [showSupplierAddModal, setShowSupplierAddModal] = useState(false);
  const handleCloseSupplierAddModal = () => {
    setShowSupplierAddModal(false);
  }
  const handleShowSupplierAddModal = () => {
    setShowSupplierAddModal(true);
  }
  const handleAddSupplierAddModal = (values) => {
    dispatch(addNewSupplier({ newsupplier: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Add Modal (Warehouse)
  const [showWarehouseAddModal, setShowWarehouseAddModal] = useState(false);
  const handleCloseWarehouseAddModal = () => {
    setShowWarehouseAddModal(false);
  }
  const handleShowWarehouseAddModal = () => {
    setShowWarehouseAddModal(true);
  }
  const handleAddWarehouseAddModal = (values) => {
    dispatch(addNewWarehouse({ newwarehouse: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Add Modal (Shelf)
  const [showShelfAddModal, setShowShelfAddModal] = useState(false);
  const handleCloseShelfAddModal = () => {
    setShowShelfAddModal(false);
  }
  const handleShowShelfAddModal = () => {
    setShowShelfAddModal(true);
  }
  const handleAddShelfAddModal = (values) => {
    dispatch(addNewShelf({ newshelf: values }));
  }
  // Add Modal (Brand)
  const [showBrandAddModal, setShowBrandAddModal] = useState(false);
  const handleCloseBrandAddModal = () => {
    setShowBrandAddModal(false);
  }
  const handleShowBrandAddModal = () => {
    setShowBrandAddModal(true);
  }
  const handleAddBrandAddModal = (values) => {
    dispatch(addNewBrand({ newbrand: values }));
  }
  // Add Modal (Device)
  const [showDeviceAddModal, setShowDeviceAddModal] = useState(false);
  const handleCloseDeviceAddModal = () => {
    setShowDeviceAddModal(false);
  }
  const handleShowDeviceAddModal = () => {
    setShowDeviceAddModal(true);
  }
  const handleAddDeviceAddModal = (values) => {
    dispatch(addNewDevice({ newdevice: values }));
  }
  // Update Modal
  const [showStockUpdateModal, setShowStockUpdateModal] = useState(false);
  const [inputDataStockUpdateModal, setInputDataStockUpdateModal] = useState(null);
  const handleCloseStockUpdateModal = () => {
    setShowStockUpdateModal(false);
    setInputDataStockUpdateModal(null);
  }
  const handleShowStockUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getStockById({ id: id }));
  }
  const handleUpdateStockUpdateModal = (values) => {
    dispatch(updateStock({ stock: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Details Modal
  const [showStockDetailsModal, setShowStockDetailsModal] = useState(false);
  const [inputDataStockDetailsModal, setInputDataStockDetailsModal] = useState(null);
  const handleCloseStockDetailsModal = () => {
    setShowStockDetailsModal(false);
    setInputDataStockDetailsModal(null);
  }
  const handleShowStockDetailsModal = (id) => {
    setDataRequester('details');
    dispatch(getStockById({ id: id }));
  }
  // Delete Modal
  const [showStockDeleteModal, setShowStockDeleteModal] = useState(false);
  const [inputDataStockDeleteModal, setInputDataStockDeleteModal] = useState(null);
  const handleCloseStockDeleteModal = () => {
    setShowStockDeleteModal(false);
    setInputDataStockDeleteModal(null);
  }
  const handleShowStockDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getStockById({ id: id }));
  }
  const handleDeleteStockDeleteModal = () => {
    dispatch(deleteStock({ id: inputDataStockDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <StockAddModal
        show={showStockAddModal}
        handleClose={handleCloseStockAddModal}
        handleAdd={handleAddStockAddModal}
      />
      <SupplierAddModal
        show={showSupplierAddModal}
        handleClose={handleCloseSupplierAddModal}
        handleAdd={handleAddSupplierAddModal}
      />
      <WarehouseAddModal
        show={showWarehouseAddModal}
        handleClose={handleCloseWarehouseAddModal}
        handleAdd={handleAddWarehouseAddModal}
      />
      <ShelfAddModal
        show={showShelfAddModal}
        handleClose={handleCloseShelfAddModal}
        handleAdd={handleAddShelfAddModal}
      />
      <BrandAddModal
        show={showBrandAddModal}
        handleClose={handleCloseBrandAddModal}
        handleAdd={handleAddBrandAddModal}
      />
      <DeviceAddModal
        show={showDeviceAddModal}
        handleClose={handleCloseDeviceAddModal}
        handleAdd={handleAddDeviceAddModal}
      />
      <StockUpdateModal
        show={showStockUpdateModal}
        handleClose={handleCloseStockUpdateModal}
        handleUpdate={handleUpdateStockUpdateModal}
        inputData={inputDataStockUpdateModal}
      />
      <StockDetailsModal
        show={showStockDetailsModal}
        handleClose={handleCloseStockDetailsModal}
        inputData={inputDataStockDetailsModal}
      />
      <StockDeleteModal
        show={showStockDeleteModal}
        handleClose={handleCloseStockDeleteModal}
        handleDelete={handleDeleteStockDeleteModal}
        title="Stoğu Sil"
        message={`${inputDataStockDeleteModal?.id} id numaralı stoğu silmek istediğinize emin misiniz?`}
        deleteIcon={<LuPackageMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showStockAddModal, showSupplierAddModal, showWarehouseAddModal, showShelfAddModal, showBrandAddModal, showDeviceAddModal, showStockUpdateModal, inputDataStockUpdateModal, showStockDetailsModal, inputDataStockDetailsModal, showStockDeleteModal, inputDataStockDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Warehouse;