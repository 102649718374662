// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Surveys/';

// Async Thunks
export const getSurveyByToken = createAsyncThunk('backend_getallsuppliers', async ({ token }) => {
  try {
    const res = await axios.get(`${baseurl}GetSurveyByToken?token=${token}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const SendSurveyAnswers = createAsyncThunk('backend_getsupplierbyid', async ({ answers }) => {
  try {
    const res = await axios.post(`${baseurl}SendSurveyAnswers`, answers, { withCredentials: true });
    return res.data.data;
  } catch (error) { console.log(error); throw error.response.data || error; }
});

// Slice
const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    surveyquestions: [],
    getQuestionsStatus: null,
    sendQuestionStatus: null,
    error: null
  },
  reducers: {
    resetGetQuestionsStatus: (state) => { state.getQuestionsStatus = null; },
    resetSendQuestionsStatus: (state) => { state.sendQuestionStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSurveyByToken.pending, (state) => { state.getQuestionsStatus = "pending"; })
      .addCase(getSurveyByToken.fulfilled, (state, action) => { state.getQuestionsStatus = "fulfilled"; state.surveyquestions = action.payload || []; })
      .addCase(getSurveyByToken.rejected, (state, action) => { state.getQuestionsStatus = "rejected"; state.error = action.error.message; })
      .addCase(SendSurveyAnswers.pending, (state) => { state.sendQuestionStatus = "pending"; })
      .addCase(SendSurveyAnswers.fulfilled, (state) => { state.sendQuestionStatus = "fulfilled"; })
      .addCase(SendSurveyAnswers.rejected, (state, action) => { state.sendQuestionStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetGetQuestionsStatus, resetSendQuestionsStatus, resetError } = surveySlice.actions;
export default surveySlice.reducer;