// General
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
// Styles
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { Autocomplete, Button, Checkbox, FormControlLabel, styled, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../../storage/slices/general/ToastSlice';
import { getAllServiceActions, getAllServiceOperations, addNewServiceAction, resetAllActionsStatus, resetAllOperationsStatus, resetAddStatus as resetAddActionStatus } from '../../../../storage/slices/backend/ServiceactionSlice';
import { deleteService, resetDeleteStatus as resetDeleteServiceStatus } from '../../../../storage/slices/backend/ServiceSlice';
import { getCustomerByPhone, updateCustomer, resetGetDataStatus as resetGetCustomerDataStatus, resetUpdateStatus as resetCustomerUpdateStatus } from '../../../../storage/slices/backend/CustomerSlice';
import { addNewCaseaction, resetAddStatus as resetAddCaseactionStatus } from '../../../../storage/slices/backend/CaseactionSlice';
// Contexts
import { useData } from '../../../../context/DataContext';
import { useFunctions } from '../../../../context/FunctionContext';
import { useValidations } from '../../../../context/ValidationContext';
// Icons
import { TbDatabaseCog, TbDatabaseMinus } from "react-icons/tb";
import { TbUserEdit } from "react-icons/tb";
import { TbArrowBackUpDouble } from 'react-icons/tb';
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline } from "react-icons/io";
import { MdCloudUpload, MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
// Components
import ServiceOperations from '../../components/ServiceOperations';
// Modals
import CustomerUpdateModal from '../customers/CustomerUpdateModal';
import ServiceDeleteModal from '../DeleteModal';
import CaseactionAddModal from '../case/CaseactionAddModal';

const MAX_FILE_SIZE = 5 * 1024 * 1024;

function ServiceDetailsModal({ show, handleClose, handleUpdate, inputData, reload }) {
  // Storage Import
  const dispatch = useDispatch();
  const { deleteStatus: deleteServiceStatus } = useSelector((state) => state.service);
  const { customer, getDataStatus: customerGetDataStatus, updateStatus: customerUpdateStatus } = useSelector((state) => state.customer);
  const { addStatus: addCaseactionStatus } = useSelector((state) => state.caseaction);
  const { brands } = useSelector((state) => state.brand);
  const { devices } = useSelector((state) => state.device);
  const { sources } = useSelector((state) => state.source);
  const { employees } = useSelector((state) => state.employee);
  const { dealers } = useSelector((state) => state.dealer);
  const { vehicles } = useSelector((state) => state.vehicle);
  const { stocks } = useSelector((state) => state.stock);
  const { consigments } = useSelector((state) => state.consigmentdevice);
  const { serviceActions, serviceOperations, getAllActionsStatus, getAllOperationsStatus, addStatus: addActionStatus } = useSelector((state) => state.serviceaction);
  // Context Import
  const { myUser, warranties } = useData();
  const { stringDate, stringDateFromText, getStringDate, configureServiceOperation, configureCaseActionInformations } = useFunctions();
  const { vs_serviceupdate } = useValidations();
  // Variables
  const [selectedTab, setSelectedTab] = useState('1');
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  // Functions
  const handleChangeTab = (event, newValue) => { setSelectedTab(newValue); };
  // TABS
  // TAB - Service Informations
  const initalValues = {
    id: inputData?.id || -1,
    brandName: inputData?.brandName || '',
    deviceName: inputData?.deviceName || '',
    sourceName: inputData?.sourceName || '',
    model: inputData?.model || '',
    problem: inputData?.problem || '',
    note: inputData?.note || '',
    customerDate: inputData?.customerDate || getStringDate(0),
    customerTimeStart: inputData?.customerTimeStart || '09:00',
    customerTimeEnd: inputData?.customerTimeEnd || '20:00',
    status: inputData?.status || '',
    warranty: inputData?.warranty || 0,
    isEmergency: inputData?.isEmergency || false
  }
  const formikRef = useRef(null);
  const [getForFormik, setGetForFormik] = useState(false);
  const [getForModal, setGetForModal] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  useEffect(() => {
    if (inputData && customerData === null) {
      setCustomerData({ name: inputData?.name, customerType: inputData?.customerType, identityNumber: inputData?.identityNumber, taxNumber: inputData?.taxNumber, taxOffice: inputData?.taxOffice, phone1: inputData?.phone1, phone2: inputData?.phone2, city: inputData?.city, district: inputData?.district, address: inputData?.address });
    }
    if (inputData === null) {
      setCustomerData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);
  useEffect(() => {
    if (show && deleteServiceStatus === "fulfilled") {
      dispatch(resetDeleteServiceStatus());
      dispatch(addToast({ background: 'danger', icon: 'delete', message: 'Servis Bilgileri Silindi!', delay: 6000 }));
      handleCloseServiceDeleteModal();
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteServiceStatus]);
  useEffect(() => {
    if (addCaseactionStatus === 'fulfilled') {
      dispatch(addToast({ background: 'success', icon: 'caseactionAdd', message: 'Kasa İşlemi Eklendi!', delay: 6000 }));
      dispatch(resetAddCaseactionStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCaseactionStatus]);
  useEffect(() => {
    if (customerGetDataStatus === 'fulfilled') {
      if (getForModal) {
        setInputDataForCustomerUpdateModal(customer);
        setShowCustomerUpdateModal(true);
        setGetForModal(false);
      }
      if (getForFormik) {
        setCustomerData({ name: customer?.name, customerType: customer?.customerType, identityNumber: customer?.identityNumber, taxNumber: customer?.taxNumber, taxOffice: customer?.taxOffice, phone1: customer?.phone1, phone2: customer?.phone2, city: customer?.city, district: customer?.district, address: customer?.address });
        handleCloseCustomerUpdateModal();
        dispatch(resetGetCustomerDataStatus());
        setGetForFormik(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerGetDataStatus]);
  useEffect(() => {
    if (customerUpdateStatus === 'fulfilled' && getForFormik) {
      dispatch(resetCustomerUpdateStatus());
      dispatch(getCustomerByPhone({ phone: inputData?.phone1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerUpdateStatus]);
  // TAB - Service Operations
  const [searchedId, setSearchedId] = useState(-1);
  const [actionsData, setActionsData] = useState([]);
  const [operationsData, setOperationsData] = useState([]);
  const fetchAllDataSequentially = async (dispatch, signal, id) => {
    try {
      await dispatch(getAllServiceActions({ signal, id }));
      await dispatch(getAllServiceOperations({ signal, id }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    if (inputData) {
      if (inputData?.id !== searchedId) {
        const controller = new AbortController();
        const { signal } = controller;
        fetchAllDataSequentially(dispatch, signal, inputData?.id);
        return () => { controller.abort(); };
      }
    } else { setSearchedId(-1) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);
  useEffect(() => {
    if (getAllActionsStatus === 'fulfilled') {
      setActionsData(serviceActions.map(item => ({ ...item, description: stringDateFromText(item.description) })));
      dispatch(resetAllActionsStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllActionsStatus]);
  useEffect(() => {
    if (getAllOperationsStatus === 'fulfilled') {
      if (serviceOperations) {
        if (!(serviceOperations.length === 1 && serviceOperations[0].nextActionName === null) && serviceOperations.length > 0) {
          setOperationsData(serviceOperations);
        } else {
          setOperationsData([]);
        }
      } else {
        setOperationsData([]);
      }
      dispatch(resetAllOperationsStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllOperationsStatus]);
  const reloadData = async (signal, id) => {
    await dispatch(getAllServiceActions({ signal, id }));
    await dispatch(getAllServiceOperations({ signal, id }));
  }
  useEffect(() => {
    if (addActionStatus === 'fulfilled') {
      dispatch(resetAddActionStatus());
      setOperations('');
      dispatch(addToast({ background: 'success', icon: 'add', message: 'Servis Aksiyonu Eklendi!', delay: 6000 }));
      reloadData(null, inputData?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addActionStatus]);
  const [operation, setOperations] = useState('');
  const handleAddOperationsFunction = async (values) => {
    const transformedValues = configureServiceOperation(values, operation);
    const newServiceAction = { userId: myUser?.id, serviceId: inputData?.id, actionTypeName: operation, description: JSON.stringify(transformedValues).replace(/"/g, "'") }
    dispatch(addNewServiceAction({ newserviceaction: newServiceAction }));
  }
  const serviceOperationsButtons = () => {
    return (
      <div className={`d-flex justify-content-center align-items-${isMobile ? 'start' : 'center'}`} style={{ minWidth: `${isMobile ? '120px' : '240px'}` }}>
        <button className={`button-with-icon btn ${isMobile ? 'btn-sm' : ''} btn-danger ${isMobile ? 'me-1' : 'mx-1'}`} >
          <MdDeleteOutline className='button-icon' />
          Sil
        </button>
        <button className={`button-with-icon btn ${isMobile ? 'btn-sm' : ''} btn-primary ${isMobile ? 'me-1' : 'mx-1'}`} >
          <MdOutlineEdit className='button-icon' />
          Düzenle
        </button>
      </div>
    );
  };
  const [serviceoperationsexpanderopened, setServiceoperationsexpanderopened] = useState(null);
  const serviceoperationsexpander = (rowData) => {
    return (
      <div className="pb-0 ps-2 text-start">
        <ul className='list-unstyled mb-1'>
          <li><span className='text-capitalize fs-6 fw-bold'>İşlemi Yapan: </span><span className='fs-6 text-break '>{rowData.userName}</span></li>
          <li><span className='text-capitalize fs-6 fw-bold'>Açıklama: </span><span className='fs-6 text-break '>{rowData.description}</span></li>
          {serviceOperationsButtons(rowData)}
        </ul>
      </div>
    );
  };
  // TAB - Money Transactions
  const moneyTransactionsButtons = (rowData) => {
    return (
      <div className={`d-flex justify-content-center align-items-${isMobile ? 'start' : 'center'}`} style={{ minWidth: `${isMobile ? '120px' : '240px'}` }}>
        <button className={`button-with-icon btn ${isMobile ? 'btn-sm' : ''} btn-danger ${isMobile ? 'me-1' : 'mx-1'}`}>
          <MdDeleteOutline className='button-icon' />
          Sil
        </button>
        <button className={`button-with-icon btn ${isMobile ? 'btn-sm' : ''} btn-primary ${isMobile ? 'me-1' : 'mx-1'}`}>
          <MdOutlineEdit className='button-icon' />
          Güncelle
        </button>
      </div>
    )
  }
  const [moneytransactionsexpanderopened, setMoneytransactionsexpanderopened] = useState(null);
  const moneytransactionsexpander = (rowData) => {
    return (
      <div className="pb-0 ps-2 text-start">
        <ul className='list-unstyled mb-1'>
          <li><span className='text-capitalize fs-6 fw-bold'>İşlemi Yapan: </span><span className='fs-6 text-break '>{rowData.user}</span></li>
          <li><span className='text-capitalize fs-6 fw-bold'>Ödeme Şekli: </span><span className='fs-6 text-break '>{rowData.direction}</span></li>
          <li><span className='text-capitalize fs-6 fw-bold'>Durum: </span><span className='fs-6 text-break '>{rowData.status}</span></li>
          <li><span className='text-capitalize fs-6 fw-bold'>Açıklama: </span><span className='fs-6 text-break '>{rowData.description}</span></li>
          {moneyTransactionsButtons(rowData)}
        </ul>
      </div>
    );
  };
  // TAB - Photos
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  // TAB - Receipt Note
  const receiptNoteButtons = (rowData) => {
    return (
      <div className={`d-flex justify-content-center align-items-${isMobile ? 'start' : 'center'}`}>
        <button className={`button-with-icon btn ${isMobile ? 'btn-sm' : ''} btn-danger`}>
          <IoMdRemoveCircleOutline className='button-icon' />
          Sil
        </button>
      </div>
    );
  }
  const [receiptnoteexpanderopened, setReceiptnoteexpanderopened] = useState(null);
  const receiptnoteexpander = (rowData) => {
    return (
      <div className="pb-0 ps-2 text-start">
        <ul className='list-unstyled mb-1'>
          <li><span className='text-capitalize fs-6 fw-bold'>Açıklama: </span><span className='fs-6 text-break '>{rowData.description}</span></li>
          {receiptNoteButtons(rowData)}
        </ul>
      </div>
    );
  };
  // TAB - Operator Note
  const operatorNoteButtons = (rowData) => {
    return (
      <div className={`d-flex justify-content-center align-items-${isMobile ? 'start' : 'center'}`}>
        <button className={`button-with-icon btn ${isMobile ? 'btn-sm' : ''} btn-danger`}>
          <IoMdRemoveCircleOutline className='button-icon' />
          Sil
        </button>
      </div>
    );
  }
  const [operatornoteexpanderopened, setOperatornoteexpanderopened] = useState(null);
  const operatornoteexpander = (rowData) => {
    return (
      <div className="pb-0 ps-2 text-start">
        <ul className='list-unstyled mb-1'>
          <li><span className='text-capitalize fs-6 fw-bold'>Açıklama: </span><span className='fs-6 text-break '>{rowData.description}</span></li>
          {operatorNoteButtons(rowData)}
        </ul>
      </div>
    );
  };
  // TAB - Offers
  // TAB - Invoices
  // TAB - Reports
  const [reportsexpanderopened, setReportsexpanderopened] = useState(null);
  const reportsexpander = (rowData) => {
    return (
      <div className="pb-0 ps-2 text-start">
        <ul className='list-unstyled mb-1'>
          <li><span className='text-capitalize fs-6 fw-bold'>Açıklama: </span><span className='fs-6 text-break '>{rowData.description}</span></li>
        </ul>
      </div>
    );
  };
  // Modals
  // Customer Update Modal
  const [showCustomerUpdateModal, setShowCustomerUpdateModal] = useState(false);
  const [inputDataForCustomerUpdateModal, setInputDataForCustomerUpdateModal] = useState(null);
  const handleCloseCustomerUpdateModal = () => {
    setShowCustomerUpdateModal(false);
    setInputDataForCustomerUpdateModal(null);
  }
  const handleShowCustomerUpdateModal = () => {
    setGetForModal(true);
    dispatch(getCustomerByPhone({ phone: inputData?.phone1 }));
  }
  const handleUpdateCustomerUpdateModal = (values) => {
    setGetForFormik(true);
    dispatch(updateCustomer({ customer: values }));
  }
  // Service Delete Modal
  const [showServiceDeleteModal, setShowServiceDeleteModal] = useState(false);
  const [inputDataForServiceDeleteModal, setInputDataForServiceDeleteModal] = useState(-1);
  const handleCloseServiceDeleteModal = () => {
    setShowServiceDeleteModal(false)
    setInputDataForServiceDeleteModal(-1);
  };
  const handleShowServiceDeleteModal = (id) => {
    setInputDataForServiceDeleteModal(id);
    setShowServiceDeleteModal(true);
  };
  const handleDeleteServiceDeleteModal = () => {
    dispatch(deleteService({ id: inputDataForServiceDeleteModal }));
    handleCloseServiceDeleteModal();
  };
  // Service Add Modal
  const [showCaseactionAddModal, setShowCaseactionAddModal] = useState(false);
  const [inputDataCaseactionAddModal, setInputDataCaseactionAddModal] = useState(null);
  const handleCloseCaseactionAddModal = () => {
    setShowCaseactionAddModal(false);
    setInputDataCaseactionAddModal(null);
  }
  const handleShowCaseactionAddModal = (type) => {
    setInputDataCaseactionAddModal({ isService: true, paymentType: type });
    setShowCaseactionAddModal(true);
  }
  const handleAddCaseactionAddModal = (values) => {
    handleCloseCaseactionAddModal();
    dispatch(addNewCaseaction({ newcaseaction: configureCaseActionInformations(values) }));
  }
  // Return Modals
  const modals = (
    <>
      <CustomerUpdateModal
        show={showCustomerUpdateModal}
        handleClose={handleCloseCustomerUpdateModal}
        handleUpdate={handleUpdateCustomerUpdateModal}
        inputData={inputDataForCustomerUpdateModal}
      />
      <ServiceDeleteModal
        show={showServiceDeleteModal}
        handleClose={handleCloseServiceDeleteModal}
        handleDelete={handleDeleteServiceDeleteModal}
        title="Servisi Silmek İstiyor Musunuz?"
        message={`${inputDataForServiceDeleteModal} id numaralı servisi silmek istiyor musunuz?`}
        deleteIcon={<TbDatabaseMinus className='button-icon' />}
      />
      <CaseactionAddModal
        show={showCaseactionAddModal}
        handleClose={handleCloseCaseactionAddModal}
        handleAdd={handleAddCaseactionAddModal}
        inputData={inputDataCaseactionAddModal}
      />
    </>
  );
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered className='modal-xl' style={{ maxHeight: '90vh', overflowY: 'auto' }} backdrop="static">
      <Modal.Header className='py-2' closeButton>
        <Modal.Title>Servis Detayları ({inputData?.id} - {inputData?.name} ({inputData?.brandName}/{inputData?.deviceName}))</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label='BİLGİLER' value='1' />
              <Tab label='İŞLEMLER' value='2' />
              <Tab label='PARA HAREKETLİ' value='3' />
              <Tab label='FOTOĞRAFLAR' value='4' />
              <Tab label='FİŞ NOTU' value='5' />
              <Tab label='OPERATÖR NOTU' value='6' />
              <Tab label='TEKLİFLER' value='7' />
              <Tab label='FATURALAR' value='8' />
              <Tab label='RAPORLAR' value='9' />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Formik key={0} initialValues={initalValues} validationSchema={vs_serviceupdate} onSubmit={(values) => handleUpdate(values)} innerRef={formikRef}>
              {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                <Form>
                  <div className='row g-2 my-2'>
                    <div className='col-12 col-md-12'>
                      <div className='card bg-light m-0 p-1'>
                        <div className='row'>
                          <div className='col-12 col-md-6'>
                            <div className='row'>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='ServiceUpdateDate_date'
                                  name='date'
                                  label="Tarih"
                                  value={stringDate(inputData?.date) || ''}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='row g-2'>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={Autocomplete}
                                  id="ServiceUpdateDate_sourceName"
                                  name="sourceName"
                                  value={values.sourceName}
                                  onChange={(event, newValue) => { setFieldValue('sourceName', newValue); }}
                                  onBlur={() => { setFieldTouched('sourceName', true); }}
                                  variant='outlined'
                                  size='small'
                                  options={sources.map(item => item.name)}
                                  renderInput={(params) => <TextField {...params} label="Kaynak" error={touched.sourceName && !!errors.sourceName} helperText={touched.sourceName && errors.sourceName} />}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='ServiceUpdateDate_username'
                                  label="Operatör"
                                  value={inputData?.userName}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 d-flex align-items-stretch'>
                      <div className='card bg-light m-0 flex-fill'>
                        <div className='card-header d-flex justify-content-between py-1'>
                          <div className='card-title my-1'>Müşteri Bilgileri</div>
                          <button type='button' className='btn btn-outline-warning mx-1 p-1' onClick={() => handleShowCustomerUpdateModal()}>
                            <TbUserEdit style={{ fontSize: '1.5em' }} />
                          </button>
                        </div>
                        <div className='card-body'>
                          <div className='row g-2'>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_name'
                                name='name'
                                label="Müşteri Adı"
                                value={customerData?.name || ''}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_customerType'
                                name='customerType'
                                label="Müşteri Türü"
                                value={customerData?.customerType || ''}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_phone1'
                                name='phone1'
                                label="Birinci Telefon"
                                value={customerData?.phone1 || ''}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_phone2'
                                name='phone2'
                                label="İkinci Telefon"
                                value={customerData?.phone2 || ''}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_city'
                                name='city'
                                label="İl"
                                value={customerData?.city || ''}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_district'
                                name='district'
                                label="İlçe"
                                value={customerData?.district || ''}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_address'
                                name='address'
                                label="Adres"
                                value={customerData?.address || ''}
                                slotProps={{ input: { resize: "none", readOnly: true } }}
                                multiline
                                minRows={2}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_customerDate'
                                name='customerDate'
                                label='Müsait Zaman'
                                type="date"
                                variant='outlined'
                                size='small'
                                value={values.customerDate}
                                onChange={(e) => setFieldValue('customerDate', e.target.value)}
                                slotProps={{ input: { shrink: "true" } }}
                                fullWidth
                                error={touched.customerDate && !!errors.customerDate}
                                helperText={touched.customerDate && errors.customerDate}
                              />
                            </div>
                            <div className='col-6 col-md-3'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_customerTimeStart'
                                name='customerTimeStart'
                                label='Başlangıç'
                                type="time"
                                variant='outlined'
                                size='small'
                                value={values.customerTimeStart}
                                onChange={(e) => setFieldValue('customerTimeStart', e.target.value)}
                                slotProps={{ input: { shrink: "true" } }}
                                fullWidth
                                error={touched.customerTimeStart && !!errors.customerTimeStart}
                                helperText={touched.customerTimeStart && errors.customerTimeStart}
                              />
                            </div>
                            <div className='col-6 col-md-3'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_customerTimeEnd'
                                name='customerTimeEnd'
                                label='Bitiş'
                                type="time"
                                variant='outlined'
                                size='small'
                                value={values.customerTimeEnd}
                                onChange={(e) => setFieldValue('customerTimeEnd', e.target.value)}
                                slotProps={{ input: { shrink: "true" } }}
                                fullWidth
                                error={touched.customerTimeEnd && !!errors.customerTimeEnd}
                                helperText={touched.customerTimeEnd && errors.customerTimeEnd}
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={Autocomplete}
                                id="ServiceUpdateDate_consignmentDevice"
                                name="consignmentDevice"
                                value={values.consignmentDevice}
                                onChange={(event, newValue) => {
                                  setFieldValue('consignmentDevice', newValue);
                                  console.log(newValue);
                                }}
                                onBlur={() => { setFieldTouched('consignmentDevice', true); }}
                                variant='outlined'
                                size='small'
                                options={consigments}
                                getOptionLabel={(option) => `${option.id} - ${option.brandName}/${option.deviceName} ${option.model}`}
                                renderOption={(props, option) => {
                                  const { key, ...otherProps } = props;
                                  return (
                                    <li key={option.id} {...otherProps}>
                                      {option ? `${option.id} - ${option.brandName}/${option.deviceName} ${option.model}` : ''}
                                    </li>
                                  )
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params} label="Konsinye Cihaz Seçiniz" />}
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_invoice'
                                name='invoice'
                                label="Fatura"
                                value={''}
                                onChange={(e) => setFieldValue('invoice', e.target.value)}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 d-flex align-items-stretch'>
                      <div className='card bg-light m-0 flex-fill'>
                        <div className='card-header d-flex justify-content-between py-1'>
                          <div className='card-title my-1'>Cihaz Bilgileri</div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                checked={values.isEmergency}
                                onChange={(e) => setFieldValue('isEmergency', e.target.checked)}
                              />
                            }
                            label="ACİL!"
                          />
                        </div>
                        <div className='card-body'>
                          <div className='row g-2'>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={Autocomplete}
                                id="ServiceUpdateDate_brandName"
                                name="brandName"
                                value={values.brandName}
                                onChange={(event, newValue) => { setFieldValue('brandName', newValue); }}
                                onBlur={() => { setFieldTouched('brandName', true); }}
                                variant='outlined'
                                size='small'
                                options={brands.map(item => item.name)}
                                renderInput={(params) => <TextField {...params} label="Cihaz Markası" error={touched.brandName && !!errors.brandName} helperText={touched.brandName && errors.brandName} />}
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={Autocomplete}
                                id="ServiceUpdateDate_deviceName"
                                name="deviceName"
                                value={values.deviceName}
                                onChange={(event, newValue) => { setFieldValue('deviceName', newValue); }}
                                onBlur={() => { setFieldTouched('deviceName', true); }}
                                variant='outlined'
                                size='small'
                                options={devices.map(item => item.name)}
                                renderInput={(params) => <TextField {...params} label="Cihaz Markası" error={touched.deviceName && !!errors.deviceName} helperText={touched.deviceName && errors.deviceName} />}
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_model'
                                name='model'
                                label="Cihaz Modeli"
                                value={values.model}
                                onChange={(e) => setFieldValue('model', e.target.value)}
                                variant="outlined"
                                size="small"
                                fullWidth
                                error={touched.model && !!errors.model}
                                helperText={touched.model && errors.model}
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_stockNo'
                                name='stockNo'
                                label="Stok Seri Numarası"
                                value={''}
                                onChange={(e) => setFieldValue('stockNo', e.target.value)}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_problem'
                                name='problem'
                                label="Cihaz Arızası"
                                value={values.problem}
                                onChange={(e) => setFieldValue('problem', e.target.value)}
                                slotProps={{ input: { resize: "none" } }}
                                multiline
                                minRows={2}
                                variant="outlined"
                                size="small"
                                fullWidth
                                error={touched.problem && !!errors.problem}
                                helperText={touched.problem && errors.problem}
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_note'
                                name='note'
                                label="Operator Notu"
                                value={values.note}
                                onChange={(e) => setFieldValue('note', e.target.value)}
                                slotProps={{ input: { resize: "none" } }}
                                multiline
                                minRows={2}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={Autocomplete}
                                id="ServiceUpdateDate_warranty"
                                name="warranty"
                                value={values.warranty}
                                onChange={(event, newValue) => { setFieldValue('warranty', newValue); }}
                                onBlur={() => { setFieldTouched('warranty', true); }}
                                variant='outlined'
                                size='small'
                                options={warranties}
                                getOptionLabel={(option) => option === 0 ? 'Yok' : `${option.toString()} Ay`}
                                renderOption={(props, option) => {
                                  const { key, ...otherProps } = props;
                                  return (
                                    <li key={option} {...otherProps}>
                                      {option === 0 ? 'Yok' : `${option.toString()} Ay`}
                                    </li>
                                  );
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params} label="Cihaz Garantisi" error={touched.warranty && !!errors.warranty} helperText={touched.warranty && errors.warranty} />}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-12'>
                      <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                        <div className='col-12 col-md-6'>
                          <div className='row'>
                            <div className='col-12 col-md-6'>
                              <button type='button' className='button-with-icon btn btn-danger w-100' onClick={() => handleShowServiceDeleteModal(inputData?.id)}>
                                <TbDatabaseMinus className='button-icon' />
                                Sil
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-md-3'>
                          <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                            <TbArrowBackUpDouble className='button-icon' />
                            Geri
                          </button>
                        </div>
                        <div className='col-12 col-md-3'>
                          <button type='submit' className='button-with-icon btn btn-primary w-100'>
                            <TbDatabaseCog className='button-icon' />
                            Güncelle
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </TabPanel>
          <TabPanel value="2">
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-1 m-1'>
                <div className='row g-2 d-flex justify-content-between'>
                  <div className='col-12 col-md-4'>
                    <TextField
                      label="Bugünün Tarihi"
                      value={stringDate(inputData?.date) || ''}
                      slotProps={{ input: { readOnly: true } }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='row g-2'>
                      <div className='col-12 col-md-6'>
                        <TextField
                          label="Kaynak"
                          className='me-2'
                          value={inputData?.sourceName}
                          slotProps={{ input: { readOnly: true } }}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </div>
                      <div className='col-12 col-md-6'>
                        <TextField
                          label="Operatör"
                          value={inputData?.userName}
                          slotProps={{ input: { readOnly: true } }}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-1 m-1'>
                <div className='row g-2 d-flex justify-content-between'>
                  <div className='col-12 col-md-4'>
                    <TextField
                      label="Servis Durumu"
                      value={(inputData?.status)}
                      slotProps={{ input: { readOnly: true } }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <Autocomplete
                      value={operation}
                      onChange={(event, newValue) => setOperations(newValue ? newValue : '')}
                      variant='outlined'
                      size='small'
                      options={operationsData.map(item => item.nextActionName)}
                      renderInput={(params) => <TextField {...params} label="Yapılacak İşlem" />}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
            </div>
            {operation !== '' && (
              <div className='card bg-light mt-1 mb-0'>
                <div className='card-body p-1 m-1'>
                  <div className='row g-2'>
                    <ServiceOperations
                      type={operation}
                      handleAdd={handleAddOperationsFunction}
                      users={employees.map(item => item.name)}
                      dealers={dealers.map(item => item.name)}
                      products={stocks.map(item => item.name)}
                      vehicles={vehicles.map(item => item.plate)}
                      serviceId={inputData?.id}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                {isMobile ? (
                  <DataTable
                    value={actionsData}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    expandedRows={serviceoperationsexpanderopened}
                    onRowToggle={(e) => setServiceoperationsexpanderopened(e.data)}
                    rowExpansionTemplate={serviceoperationsexpander}
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' expander style={{ width: "0.5em" }} />
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'actionName'} header={'İŞLEM ADI'} bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                ) : (
                  <DataTable
                    value={actionsData}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'userName'} header={'İŞLEMİ YAPAN'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'actionName'} header={'İŞLEM ADI'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'description'} header={'AÇIKLAMA'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' body={serviceOperationsButtons} bodyClassName="align-middle" />
                  </DataTable >
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel value="3">
            <div className='card bg-light mt-2 mb-0'>
              <div className='card-body row g-2 d-flex justify-content-end p-0 m-1'>
                <div className='col-12 col-md-3 m-0'>
                  <button className='button-with-icon btn btn-success w-100' onClick={() => handleShowCaseactionAddModal('Gelen Ödeme')}>
                    <IoMdAddCircleOutline className='button-icon' />
                    Gelir Ekle
                  </button>
                </div>
                <div className='col-12 col-md-3 m-0'>
                  <button className='button-with-icon btn btn-danger w-100' onClick={() => handleShowCaseactionAddModal('Giden Ödeme')}>
                    <IoMdRemoveCircleOutline className='button-icon' />
                    Gider Ekle
                  </button>
                </div>
              </div>
            </div>
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                {isMobile ? (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    expandedRows={moneytransactionsexpanderopened}
                    onRowToggle={(e) => setMoneytransactionsexpanderopened(e.data)}
                    rowExpansionTemplate={moneytransactionsexpander}
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' expander style={{ width: "0.5em" }} />
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'finalamount'} header={'FİYAT'} bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                ) : (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'user'} header={'İŞLEMİ YAPAN'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'direction'} header={'ÖDEME ŞEKLİ'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'description'} header={'AÇIKLAMA'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'status'} header={'DURUM'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'finalamount'} header={'FİYAT'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' body={moneyTransactionsButtons} bodyClassName="align-middle" />
                  </DataTable >
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel value="4">
            <div className='row justify-content-center px-3  pt-4 '>
              <Button
                className='fs-7'
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<MdCloudUpload />}
              >
                Resim dosyası ekleyin (en fazla 5 MB jpg,png dosyaları yüklenebilir )
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileChange}
                  multiple
                />
              </Button>
              {/* {photos.map((photo, index) => (
                <div key={index} className='col-md-3 col-sm-6 col-6 mb-4  '>
                  <div className="img-wrapper">
                    <LazyLoadImage
                      alt={`Photo ${index + 1}`}
                      effect='opacity'
                      wrapperProps={{
                        style: { transitionDelay: "1s" },
                      }}
                      src={photo} // Fotoğrafın URL'si
                      className='img-fluid rounded img-custom'
                    />
                  </div>
                </div>
              ))} */}
            </div>
          </TabPanel>
          <TabPanel value="5">
            <div className='card bg-light mt-2 mb-0'>
              <div className='card-body row g-2 d-flex justify-content-end p-0 m-1'>
                <div className='col-12 col-md-3 m-0'>
                  <button className='button-with-icon btn btn-success w-100'>
                    <IoMdAddCircleOutline className='button-icon' />
                    Fatura Notu Ekle
                  </button>
                </div>
              </div>
            </div>
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                {isMobile ? (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    expandedRows={receiptnoteexpanderopened}
                    onRowToggle={(e) => setReceiptnoteexpanderopened(e.data)}
                    rowExpansionTemplate={receiptnoteexpander}
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' expander style={{ width: "0.5em" }} />
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'employee'} header={'PERSONEL'} bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                ) : (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'employee'} header={'PERSONEL'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'description'} header={'AÇIKLAMA'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' body={receiptNoteButtons} bodyClassName="align-middle" />
                  </DataTable >
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel value="6">
            <div className='card bg-light mt-2 mb-0'>
              <div className='card-body row g-2 d-flex justify-content-end p-0 m-1'>
                <div className='col-12 col-md-3 m-0'>
                  <button className='button-with-icon btn btn-success w-100'>
                    <IoMdAddCircleOutline className='button-icon' />
                    Operatör Notu Ekle
                  </button>
                </div>
              </div>
            </div>
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                {isMobile ? (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    expandedRows={operatornoteexpanderopened}
                    onRowToggle={(e) => setOperatornoteexpanderopened(e.data)}
                    rowExpansionTemplate={operatornoteexpander}
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' expander style={{ width: "0.5em" }} />
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'employee'} header={'PERSONEL'} bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                ) : (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'employee'} header={'PERSONEL'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'description'} header={'AÇIKLAMA'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' body={operatorNoteButtons} bodyClassName="align-middle" />
                  </DataTable >
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel value="7">
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                <DataTable
                  value={[]}
                  size='small'
                  stripedRows
                  emptyMessage="Bu tabloda henüz veri yok!"
                  showGridlines
                  paginatorLeft
                  paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                  paginator
                  paginatorClassName='bg-light'
                  rows={5}>
                  <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                  <Column align='center' sortable field={'status'} header={'DURUM'} bodyClassName='align-middle fs-6 text-center' />
                  <Column align='center' sortable field={'total'} header={'GENEL TOPLAM'} bodyClassName='align-middle fs-6 text-center' />
                </DataTable >
              </div>
            </div>
          </TabPanel>
          <TabPanel value="8">
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                <DataTable
                  value={[]}
                  size='small'
                  stripedRows
                  emptyMessage="Bu tabloda henüz veri yok!"
                  showGridlines
                  paginatorLeft
                  paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                  paginator
                  paginatorClassName='bg-light'
                  rows={5}>
                  <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                  <Column align='center' sortable field={'status'} header={'DURUM'} bodyClassName='align-middle fs-6 text-center' />
                  <Column align='center' sortable field={'total'} header={'GENEL TOPLAM'} bodyClassName='align-middle fs-6 text-center' />
                </DataTable >
              </div>
            </div>
          </TabPanel>
          <TabPanel value="9">
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                {isMobile ? (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    expandedRows={reportsexpanderopened}
                    onRowToggle={(e) => setReportsexpanderopened(e.data)}
                    rowExpansionTemplate={reportsexpander}
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' expander style={{ width: "0.5em" }} />
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'total'} header={'TOPLAM'} bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                ) : (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'description'} header={'AÇIKLAMA'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'total'} header={'TOPLAM'} bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                )}
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </Modal.Body>
      {modals}
    </Modal>
  );
}

function handleFileChange(event) {
  const files = event.target.files;
  if (files.length > 0) {
    [...files].map((file) => {
      const selectfile = file;
      const fileName = selectfile.name.toLowerCase();
      const fileSize = selectfile.size;
      const validFileExtensions = ['.jpg', '.jpeg', '.png'];
      const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
      if (!validFileExtensions.includes(fileExtension)) {
        alert('Sadece JPG ve PNG dosyalarına izin verilmektedir.');
        event.target.value = null;
        return null;
      }
      if (fileSize > MAX_FILE_SIZE) {
        alert('Dosya boyutu 5 MB\'ı geçemez.');
        event.target.value = null;
        return null;
      }
      console.log('Geçerli dosya:', file);
      return null;
    })

  }
}

export default ServiceDetailsModal;