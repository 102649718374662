// General
import React, { useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
// Icons
import { TbArrowBackUpDouble, TbLayoutGrid } from "react-icons/tb";
import { TiDelete } from 'react-icons/ti';

function RolePermissionModal({ show, handleClose, handleSet, inputData }) {
  // Variables
  const [allPermissions, setAllPermissions] = useState(["Ürün Ekleme", "Ürün Güncelleme", "Ürün Silme", "Müşteri Silme", "Müşteri Ekleme", "Müşteri Güncelleme"]);
  //Formik Values
  const initialValues = {
    id: inputData?.id || -1,
    permissions: inputData?.permissions || []
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header className='py-2' closeButton>
          <Modal.Title>İzinler ({inputData?.name})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={initialValues} onSubmit={(values) => handleSet(values)}>
            {({ values, setFieldValue }) => (
              <Form>
                <div className='row g-1  rounded-3'>
                  <div className='col-12 col-md-12'>
                    <Autocomplete
                      id='RolePermissionModal_permission'
                      name='permissions'
                      options={allPermissions.map(item => item)}
                      value={values.permissions || []}
                      onChange={(event, newValue) => { setFieldValue('permissions', newValue) }}
                      size='small'
                      fullWidth
                      multiple
                      limitTags={5}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={option}
                              label={option}
                              size='small'
                              {...tagProps}
                              deleteIcon={
                                <TiDelete
                                  type='button'
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const newValues = values.permissions.filter((i) => i !== option);
                                    setFieldValue("permissions", newValues);
                                  }}
                                />
                              }
                            />
                          );
                        })
                      }
                      renderInput={(params) => <TextField {...params} label="İzinler" />}
                    />
                  </div>
                  <div className="row g-2  px-0 mx-0 mt-1 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                        <TbArrowBackUpDouble className='button-icon' />
                        Geri
                      </button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-info w-100'>
                        <TbLayoutGrid className='button-icon' />
                        Güncelle
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  );
}

export default RolePermissionModal;