// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllSuppliers, getSupplierById, addNewSupplier, updateSupplier, deleteSupplier, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/SupplierSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import SupplierAddModal from '../modals/suppliers/SupplierAddModal';
import SupplierUpdateModal from '../modals/suppliers/SupplierUpdateModal';
import SupplierDetailsModal from '../modals/suppliers/SupplierDetailsModal';
import SupplierDeleteModal from '../modals/DeleteModal';
// Icons
import { LuUserRoundPlus, LuUserRoundMinus, LuUserRoundCog, LuUserRoundPen } from "react-icons/lu";
import { TbPrinter } from 'react-icons/tb';

function Suppliers() {
  // Storage Import
  const dispatch = useDispatch();
  const { suppliers, supplier, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.supplier);
  // Context Import
  const { myUser } = useData();
  const { dictionaryStatuses } = useFunctions();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllSuppliers({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      setDataToTable(suppliers.map(item => ({ ...item, status: dictionaryStatuses[item.status] })));
      setDataToPDF(suppliers.map(item => ({ ...item, status: dictionaryStatuses[item.status] })));
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseSupplierAddModal();
      dispatch(addToast({ background: 'success', icon: 'supplierAdd', message: 'Tedarikçi Eklendi!', delay: 6000 }));
      dispatch(getAllSuppliers({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseSupplierUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'supplierUpdate', message: 'Tedarikçi Güncellendi!', delay: 6000 }));
      dispatch(getAllSuppliers({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseSupplierDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'supplierDelete', message: 'Tedarikçi Silindi!', delay: 6000 }));
      dispatch(getAllSuppliers({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataSupplierUpdateModal({ ...supplier, status: dictionaryStatuses[supplier.status] });
        setShowSupplierUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'details') {
        setInputDataSupplierDetailsModal({ ...supplier, status: dictionaryStatuses[supplier.status] });
        setShowSupplierDetailsModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataSupplierDeleteModal({ ...supplier, status: dictionaryStatuses[supplier.status] });
        setShowSupplierDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'TEDARİKÇİ' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'name', 'status'];
  const clickOn = (id) => { handleShowSupplierUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <LuUserRoundCog className='button-icon' />,
      onclick: ((rowData) => { handleShowSupplierUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Detaylar',
      icon: <LuUserRoundPen className='button-icon' />,
      onclick: ((rowData) => { handleShowSupplierDetailsModal(rowData.id); }),
      variant: 'info'
    },
    {
      text: 'Sil',
      icon: <LuUserRoundMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowSupplierDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Tedarikçiler';
  const operations = [
    {
      text: 'Tedarikçi Ekle',
      icon: <LuUserRoundPlus className='button-icon' />,
      onclick: (() => { handleShowSupplierAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showSupplierAddModal, setShowSupplierAddModal] = useState(false);
  const handleCloseSupplierAddModal = () => {
    setShowSupplierAddModal(false);
  }
  const handleShowSupplierAddModal = () => {
    setShowSupplierAddModal(true);
  }
  const handleAddSupplierAddModal = (values) => {
    dispatch(addNewSupplier({ newsupplier: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Update Modal
  const [showSupplierUpdateModal, setShowSupplierUpdateModal] = useState(false);
  const [inputDataSupplierUpdateModal, setInputDataSupplierUpdateModal] = useState(null);
  const handleCloseSupplierUpdateModal = () => {
    setShowSupplierUpdateModal(false);
    setInputDataSupplierUpdateModal(null);
  }
  const handleShowSupplierUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getSupplierById({ id: id }));
  }
  const handleUpdateSupplierUpdateModal = (values) => {
    dispatch(updateSupplier({ supplier: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Details Modal
  const [showSupplierDetailsModal, setShowSupplierDetailsModal] = useState(false);
  const [inputDataSupplierDetailsModal, setInputDataSupplierDetailsModal] = useState(null);
  const handleCloseSupplierDetailsModal = () => {
    setShowSupplierDetailsModal(false);
    setInputDataSupplierDetailsModal(null);
  }
  const handleShowSupplierDetailsModal = (id) => {
    setDataRequester('details');
    dispatch(getSupplierById({ id: id }));
  }
  // Delete Modal
  const [showSupplierDeleteModal, setShowSupplierDeleteModal] = useState(false);
  const [inputDataSupplierDeleteModal, setInputDataSupplierDeleteModal] = useState(null);
  const handleCloseSupplierDeleteModal = () => {
    setShowSupplierDeleteModal(false);
    setInputDataSupplierDeleteModal(null);
  }
  const handleShowSupplierDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getSupplierById({ id: id }));
  }
  const handleDeleteSupplierDeleteModal = () => {
    dispatch(deleteSupplier({ id: inputDataSupplierDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <SupplierAddModal
        show={showSupplierAddModal}
        handleClose={handleCloseSupplierAddModal}
        handleAdd={handleAddSupplierAddModal}
      />
      <SupplierUpdateModal
        show={showSupplierUpdateModal}
        handleClose={handleCloseSupplierUpdateModal}
        handleUpdate={handleUpdateSupplierUpdateModal}
        inputData={inputDataSupplierUpdateModal}
      />
      <SupplierDetailsModal
        show={showSupplierDetailsModal}
        handleClose={handleCloseSupplierDetailsModal}
        inputData={inputDataSupplierDetailsModal}
      />
      <SupplierDeleteModal
        show={showSupplierDeleteModal}
        handleClose={handleCloseSupplierDeleteModal}
        handleDelete={handleDeleteSupplierDeleteModal}
        title="Tedarikçiyi Sil"
        message={`${inputDataSupplierDeleteModal?.name} adlı tedarikçiyi silmek istediğinize emin misiniz?`}
        deleteIcon={<LuUserRoundMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showSupplierAddModal, showSupplierUpdateModal, inputDataSupplierUpdateModal, showSupplierDetailsModal, inputDataSupplierDetailsModal, showSupplierDeleteModal, inputDataSupplierDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Suppliers;