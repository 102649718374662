// General
import React from 'react';
// Styles
import { Autocomplete, TextField } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
// Storages
import { useSelector } from 'react-redux';
// Contexts
import { useFunctions } from '../../../../context/FunctionContext';
import { useValidations } from '../../../../context/ValidationContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { GrPlan } from "react-icons/gr";

function ServiceAssignmentModal({ show, handleClose, handleAssign, inputStatus }) {
  // Storage Import
  const { vehicles } = useSelector((state) => state.vehicle);
  const { employees } = useSelector((state) => state.employee);
  // Context Import
  const { vs_serviceassignment, vs_serviceassignmenttoend, vs_serviceassignmentofix } = useValidations();
  const { getStringDate } = useFunctions();
  // Formik Variables
  const standartInitialValues = {
    date: getStringDate(0),
    starttime: '09:00',
    endtime: '17:00',
    employee: '',
    assistantEmployee: '',
    vehicle: ''
  };
  const readyToFixInitialValues = {
    employee: '',
    description: ''
  }
  const readyToEndInitialValues = {
    employee: '',
    description: '',
    date: getStringDate(0)
  }
  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header className='py-2' closeButton>
        <Modal.Title>Servis Planlama</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {inputStatus === 'Cihaz Atölyeye Alındı' ? (
          <Formik initialValues={readyToFixInitialValues} validationSchema={vs_serviceassignmentofix} onSubmit={(values) => handleAssign(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
              <Form>
                <div className='row g-2'>
                  <div className='col-12'>
                    <Field
                      as={Autocomplete}
                      id="ServiceAssignModal_employee"
                      name="employee"
                      value={values.employee}
                      onChange={(event, newValue) => { setFieldValue('employee', newValue || ''); }}
                      onBlur={() => { setFieldTouched('employee', true); }}
                      variant='outlined'
                      size='small'
                      options={employees.map(item => item.name)}
                      renderInput={(params) => (
                        <TextField {...params} label="Tamir Eden Usta" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />
                      )}
                    />
                  </div>
                  <div className='col-12'>
                    <Field
                      as={TextField}
                      id='ServiceAssignModal_description'
                      name='description'
                      label='Açıklama'
                      value={values.description}
                      onChange={(e) => setFieldValue('description', e.target.value)}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                      <TbArrowBackUpDouble className='button-icon' />
                      Geri
                    </button>
                  </div>
                  <div className='col-12 col-md-6'>
                    <button type='submit' className='button-with-icon btn btn-success w-100'>
                      <GrPlan className='button-icon' />
                      Kaydet
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (inputStatus === 'Cihaz Tamir Edilemiyor' || inputStatus === 'Teslimata Hazır (Tamamlandı)') ? (
          <Formik initialValues={readyToEndInitialValues} validationSchema={vs_serviceassignmenttoend} onSubmit={(values) => handleAssign(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
              <Form>
                <div className='row g-2'>
                  <div className='col-12 col-md-6'>
                    <Field
                      as={Autocomplete}
                      id="ServiceAssignModal_employee"
                      name="employee"
                      value={values.employee}
                      onChange={(event, newValue) => { setFieldValue('employee', newValue || ''); }}
                      onBlur={() => { setFieldTouched('employee', true); }}
                      variant='outlined'
                      size='small'
                      options={employees.map(item => item.name)}
                      renderInput={(params) => (
                        <TextField {...params} label="Nakil Eden Personel" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id='ServiceAssignModal_date'
                      name='date'
                      label='Nakil Tarihi'
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.date && !!errors.date}
                      helperText={touched.date && errors.date}
                    />
                  </div>
                  <div className='col-12'>
                    <Field
                      as={TextField}
                      id='ServiceAssignModal_description'
                      name='description'
                      label='Açıklama'
                      value={values.description}
                      onChange={(e) => setFieldValue('description', e.target.value)}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                      <TbArrowBackUpDouble className='button-icon' />
                      Geri
                    </button>
                  </div>
                  <div className='col-12 col-md-6'>
                    <button type='submit' className='button-with-icon btn btn-success w-100'>
                      <GrPlan className='button-icon' />
                      Kaydet
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik initialValues={standartInitialValues} validationSchema={vs_serviceassignment} onSubmit={(values) => handleAssign(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id='ServiceAssignModal_date'
                      name='date'
                      label='Gidiş Tarihi'
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.date && !!errors.date}
                      helperText={touched.date && errors.date}
                    />
                  </div>
                  <div className='col-6 col-md-3'>
                    <Field
                      as={TextField}
                      id='ServiceAssignModal_starttime'
                      name='starttime'
                      label='Başlangıç'
                      type="time"
                      value={values.starttime}
                      onChange={(e) => setFieldValue('starttime', e.target.value)}
                      variant='outlined'
                      size='small'
                      slotProps={{ input: { shrink: "true" } }}
                      fullWidth
                      error={touched.starttime && !!errors.starttime}
                      helperText={touched.starttime && errors.starttime}
                    />
                  </div>
                  <div className='col-6 col-md-3'>
                    <Field
                      as={TextField}
                      id='ServiceAssignModal_endtime'
                      name='endtime'
                      label='Bitiş'
                      type="time"
                      value={values.endtime}
                      onChange={(e) => setFieldValue('endtime', e.target.value)}
                      variant='outlined'
                      size='small'
                      slotProps={{ input: { shrink: "true" } }}
                      fullWidth
                      error={touched.endtime && !!errors.endtime}
                      helperText={touched.endtime && errors.endtime}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="ServiceAssignModal_employee"
                      name="employee"
                      value={values.employee}
                      onChange={(event, newValue) => { setFieldValue('employee', newValue || ''); }}
                      onBlur={() => { setFieldTouched('employee', true); }}
                      variant='outlined'
                      size='small'
                      options={employees.map(item => item.name)}
                      renderInput={(params) => (
                        <TextField {...params} label="Teknisyen" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="ServiceAssignModal_assistantEmployee"
                      name="assistantEmployee"
                      value={values.assistantEmployee}
                      onChange={(event, newValue) => { setFieldValue('assistantEmployee', newValue || ''); }}
                      onBlur={() => { setFieldTouched('assistantEmployee', true); }}
                      variant='outlined'
                      size='small'
                      options={employees.map(item => item.name)}
                      renderInput={(params) => (
                        <TextField {...params} label="Yardımcı Teknisyen" error={touched.assistantEmployee && !!errors.assistantEmployee} helperText={touched.assistantEmployee && errors.assistantEmployee} />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={Autocomplete}
                      id="ServiceAssignModal_vehicle"
                      name="vehicle"
                      value={values.vehicle}
                      onChange={(event, newValue) => { setFieldValue('vehicle', newValue || ''); }}
                      onBlur={() => { setFieldTouched('vehicle', true); }}
                      variant='outlined'
                      size='small'
                      options={vehicles.map(vehicle => vehicle.plate)}
                      renderInput={(params) => (
                        <TextField {...params} label="Araç" error={touched.vehicle && !!errors.vehicle} helperText={touched.vehicle && errors.vehicle} />
                      )}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                      <TbArrowBackUpDouble className='button-icon' />
                      Geri
                    </button>
                  </div>
                  <div className='col-12 col-md-6'>
                    <button type='submit' className='button-with-icon btn btn-success w-100'>
                      <GrPlan className='button-icon' />
                      Kaydet
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ServiceAssignmentModal;