// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllConsigmentdevices, getConsigmentdeviceById, addNewConsigmentdevice, updateConsigmentdevice, deleteConsigmentdevice, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/ConsigmentdeviceSlice';
import { getAllBrands, addNewBrand, resetAddStatus as resetAddBrandStatus, resetError as resetErrorBrand } from '../../../storage/slices/backend/BrandSlice';
import { getAllDevices, addNewDevice, resetAddStatus as resetAddDeviceStatus, resetError as resetErrorDevice } from '../../../storage/slices/backend/DeviceSlice';
// Contexts
import { useData } from '../../../context/DataContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import ConsigmentdeviceAddModal from '../modals/consigmentdevices/ConsigmentdeviceAddModal';
import BrandAddModal from '../modals/brands/BrandAddModal';
import DeviceAddModal from '../modals/devices/DeviceAddModal';
import ConsigmentdeviceUpdateModal from '../modals/consigmentdevices/ConsigmentdeviceUpdateModal';
import ConsigmentdeviceDeleteModal from '../modals/DeleteModal';
// Icons
import { TbDeviceIpadHorizontalPlus, TbDeviceIpadHorizontalMinus, TbDeviceIpadHorizontalCog, TbPrinter } from "react-icons/tb";
import { LuBadgePlus } from 'react-icons/lu';
import { FaPlugCirclePlus } from 'react-icons/fa6';

function Consigmentdevices() {
  // Storage Import
  const dispatch = useDispatch();
  const { consigmentdevices, consigmentdevice, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.consigmentdevice);
  const { addStatus: addBrandStatus, error: errorBrand } = useSelector((state) => state.brand);
  const { addStatus: addDeviceStatus, error: errorDevice } = useSelector((state) => state.device);
  // Context Import
  const { myUser } = useData();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllConsigmentdevices({ signal }));
      await dispatch(getAllBrands({ signal }));
      await dispatch(getAllDevices({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      setDataToTable(consigmentdevices);
      setDataToPDF(consigmentdevices);
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseConsigmentdeviceAddModal();
      dispatch(addToast({ background: 'success', icon: 'consigmentdeviceAdd', message: 'Konsinye Cihaz Eklendi!', delay: 6000 }));
      dispatch(getAllConsigmentdevices({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (addBrandStatus === "fulfilled") {
      handleCloseBrandAddModal();
      dispatch(addToast({ background: 'success', icon: 'brandAdd', message: 'Marka Eklendi!', delay: 6000 }));
      dispatch(getAllBrands({}));
      dispatch(resetAddBrandStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addBrandStatus]);
  useEffect(() => {
    if (addDeviceStatus === "fulfilled") {
      handleCloseDeviceAddModal();
      dispatch(addToast({ background: 'success', icon: 'deviceAdd', message: 'Cihaz Eklendi!', delay: 6000 }));
      dispatch(getAllDevices({}));
      dispatch(resetAddDeviceStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDeviceStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseConsigmentdeviceUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'consigmentdeviceUpdate', message: 'Konsinye Cihaz Güncellendi!', delay: 6000 }));
      dispatch(getAllConsigmentdevices({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseConsigmentdeviceDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'consigmentdeviceDelete', message: 'Konsinye Cihaz Silindi!', delay: 6000 }));
      dispatch(getAllConsigmentdevices({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataConsigmentdeviceUpdateModal(consigmentdevice);
        setShowConsigmentdeviceUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataConsigmentdeviceDeleteModal(consigmentdevice);
        setShowConsigmentdeviceDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  useEffect(() => {
    if (errorBrand && errorBrand !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: errorBrand, delay: 4000 }));
      dispatch(resetErrorBrand());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorBrand]);
  useEffect(() => {
    if (errorDevice && errorDevice !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: errorDevice, delay: 4000 }));
      dispatch(resetErrorDevice());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDevice]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'model', name: 'MODEL' }, { value: 'deviceName', name: 'CİHAZ ADI' }, { value: 'brandName', name: 'MARKA ADI' }, { value: 'status', name: 'DURUM' }, { value: 'quantity', name: 'MİKTAR' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'model', 'deviceName', 'brandName', 'status', 'quantity', 'description'];
  const clickOn = (id) => { handleShowConsigmentdeviceUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <TbDeviceIpadHorizontalCog className='button-icon' />,
      onclick: ((rowData) => { handleShowConsigmentdeviceUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <TbDeviceIpadHorizontalMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowConsigmentdeviceDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Konsinye Cihazlar';
  const operations = [
    {
      text: 'Konsinye Cihaz Ekle',
      icon: <TbDeviceIpadHorizontalPlus className='button-icon' />,
      onclick: (() => { handleShowConsigmentdeviceAddModal(); })
    },
    {
      text: 'Marka Ekle',
      icon: <LuBadgePlus className='button-icon' />,
      onclick: (() => { handleShowBrandAddModal(); })
    },
    {
      text: 'Cihaz Ekle',
      icon: <FaPlugCirclePlus className='button-icon' />,
      onclick: (() => { handleShowDeviceAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showConsigmentdeviceAddModal, setShowConsigmentdeviceAddModal] = useState(false);
  const handleCloseConsigmentdeviceAddModal = () => {
    setShowConsigmentdeviceAddModal(false);
  }
  const handleShowConsigmentdeviceAddModal = () => {
    setShowConsigmentdeviceAddModal(true);
  }
  const handleAddConsigmentdeviceAddModal = (values) => {
    dispatch(addNewConsigmentdevice({ newconsigmentdevice: values }));
  }
  // Add Modal (Brand)
  const [showBrandAddModal, setShowBrandAddModal] = useState(false);
  const handleCloseBrandAddModal = () => {
    setShowBrandAddModal(false);
  }
  const handleShowBrandAddModal = () => {
    setShowBrandAddModal(true);
  }
  const handleAddBrandAddModal = (values) => {
    dispatch(addNewBrand({ newbrand: values }));
  }
  // Add Modal (Device)
  const [showDeviceAddModal, setShowDeviceAddModal] = useState(false);
  const handleCloseDeviceAddModal = () => {
    setShowDeviceAddModal(false);
  }
  const handleShowDeviceAddModal = () => {
    setShowDeviceAddModal(true);
  }
  const handleAddDeviceAddModal = (values) => {
    dispatch(addNewDevice({ newdevice: values }));
  }
  // Update Modal
  const [showConsigmentdeviceUpdateModal, setShowConsigmentdeviceUpdateModal] = useState(false);
  const [inputDataConsigmentdeviceUpdateModal, setInputDataConsigmentdeviceUpdateModal] = useState(null);
  const handleCloseConsigmentdeviceUpdateModal = () => {
    setShowConsigmentdeviceUpdateModal(false);
    setInputDataConsigmentdeviceUpdateModal(null);
  }
  const handleShowConsigmentdeviceUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getConsigmentdeviceById({ id: id }));
  }
  const handleUpdateConsigmentdeviceUpdateModal = (values) => {
    dispatch(updateConsigmentdevice({ consigmentdevice: values }));
  }
  // Delete Modal
  const [showConsigmentdeviceDeleteModal, setShowConsigmentdeviceDeleteModal] = useState(false);
  const [inputDataConsigmentdeviceDeleteModal, setInputDataConsigmentdeviceDeleteModal] = useState(null);
  const handleCloseConsigmentdeviceDeleteModal = () => {
    setShowConsigmentdeviceDeleteModal(false);
    setInputDataConsigmentdeviceDeleteModal(null);
  }
  const handleShowConsigmentdeviceDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getConsigmentdeviceById({ id: id }));
  }
  const handleDeleteConsigmentdeviceDeleteModal = () => {
    dispatch(deleteConsigmentdevice({ id: inputDataConsigmentdeviceDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <ConsigmentdeviceAddModal
        show={showConsigmentdeviceAddModal}
        handleClose={handleCloseConsigmentdeviceAddModal}
        handleAdd={handleAddConsigmentdeviceAddModal}
      />
      <BrandAddModal
        show={showBrandAddModal}
        handleClose={handleCloseBrandAddModal}
        handleAdd={handleAddBrandAddModal}
      />
      <DeviceAddModal
        show={showDeviceAddModal}
        handleClose={handleCloseDeviceAddModal}
        handleAdd={handleAddDeviceAddModal}
      />
      <ConsigmentdeviceUpdateModal
        show={showConsigmentdeviceUpdateModal}
        handleClose={handleCloseConsigmentdeviceUpdateModal}
        handleUpdate={handleUpdateConsigmentdeviceUpdateModal}
        inputData={inputDataConsigmentdeviceUpdateModal}
      />
      <ConsigmentdeviceDeleteModal
        show={showConsigmentdeviceDeleteModal}
        handleClose={handleCloseConsigmentdeviceDeleteModal}
        handleDelete={handleDeleteConsigmentdeviceDeleteModal}
        title="Konsinye Cihazı Sil"
        message={`${inputDataConsigmentdeviceDeleteModal?.name} adlı konsinye cihazı silmek istediğinize emin misiniz?`}
        deleteIcon={<TbDeviceIpadHorizontalMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showConsigmentdeviceAddModal, showBrandAddModal, showDeviceAddModal, showConsigmentdeviceUpdateModal, inputDataConsigmentdeviceUpdateModal, showConsigmentdeviceDeleteModal, inputDataConsigmentdeviceDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Consigmentdevices;