// General
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Styles
import { NavLink } from 'react-bootstrap';
import Badge from '@mui/material/Badge';
// Storages
import { useDispatch } from 'react-redux';
import { addToast } from '../../storage/slices/general/ToastSlice';
import { logout, resetLogoutStatus } from '../../storage/slices/backend/LoginSlice';
// Contexts
import { useData } from '../../context/DataContext';
import { useFunctions } from '../../context/FunctionContext';
// Modals
import MessageModal from '../pages/modals/MessageModal';
// Icons
import { LuMenu } from "react-icons/lu";
import { FaBell, FaAngleLeft, FaAngleRight, FaAngleDown, FaHouseUser, FaUser, FaDoorClosed } from "react-icons/fa";
import { TbMessageFilled } from "react-icons/tb";
import { BsHouseFill } from "react-icons/bs";

function Header() {
  /// Storage Import
  const dispatch = useDispatch();
  // Context Import
  const { sidebar, setSidebar, myUser } = useData();
  const { openNewPage } = useFunctions();
  // Variables
  const navigate = useNavigate();
  const dropdownpagesize = 4;
  // Functions
  const handleLogout = async () => {
    await dispatch(logout());
    await dispatch(resetLogoutStatus());
    navigate("/giris");
    dispatch(addToast({ background: 'danger', icon: 'logout', message: 'Çıkış Yapıldı!', delay: 2000 }));
  }
  // Messages Dropdown
  const [messagesData, setMessagesData] = useState([]);
  const [messagesStartIndex, setMessagesStartIndex] = useState(0);
  const [messagesDropdown, setMesseagesDropdown] = useState(false);
  const toggleMesseagesDropdown = () => { setMesseagesDropdown(!messagesDropdown); };
  const handleClickOutsideMesseages = useCallback((event) => { if (!event.target.closest('.messagesdropdown')) { setMesseagesDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMesseages);
    return () => { document.removeEventListener('click', handleClickOutsideMesseages); };
  }, [handleClickOutsideMesseages]);
  // Alerts Dropdown
  const [alertsData, setAlertsData] = useState([]);
  const [alertsStartIndex, setAlertsStartIndex] = useState(0);
  const [alertsDropdown, setAlertsDropdown] = useState(false);
  const toggleAlertsDropdown = () => { setAlertsDropdown(!alertsDropdown); };
  const handleClickOutsideAlerts = useCallback((event) => { if (!event.target.closest('.alertsdropdown')) { setAlertsDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideAlerts);
    return () => { document.removeEventListener('click', handleClickOutsideAlerts); };
  }, [handleClickOutsideAlerts]);
  // Dealers Dropdown
  const [dealersData, setDealersData] = useState(["Örnek Bayi", "Bayi Deneme"]);
  const [dealersDropdown, setDealersDropdown] = useState(false);
  const toggleDealersDropdown = () => { setDealersDropdown(!dealersDropdown); };
  const handleClickOutsideDealers = useCallback((event) => { if (!event.target.closest('.dealersdropdown')) { setDealersDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDealers);
    return () => { document.removeEventListener('click', handleClickOutsideDealers); };
  }, [handleClickOutsideDealers]);
  // Profile Dropdown
  const [profileDropdown, setProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => { setProfileDropdown(!profileDropdown); };
  const handleClickOutsideProfile = useCallback((event) => { if (!event.target.closest('.profiledropdown')) { setProfileDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideProfile);
    return () => { document.removeEventListener('click', handleClickOutsideProfile); };
  }, [handleClickOutsideProfile]);
  // Modals
  // Message Modal
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [inputDataForMessageModal, setInputDataForMessageModal] = useState(null);
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    setInputDataForMessageModal(null);
  }
  const handleShowMessageModal = (data) => {
    setInputDataForMessageModal(data);
    setShowMessageModal(true);
  }
  // HTML
  return (
    <div className="topbar-custom px-3" style={{ left: `${sidebar ? '260px' : '0px'}` }}>
      <div className="d-flex justify-content-between h-100">
        <ul className="list-unstyled topnav-menu mb-0 d-flex align-items-center">
          <li>
            <button className="button-toggle-menu nav-link" onClick={() => setSidebar(!sidebar)}>
              <LuMenu className="align-middle fullscreen noti-icon" style={{ fontSize: '1.8em' }} />
            </button>
          </li>
        </ul>
        <ul className="list-unstyled topnav-menu mb-0 d-flex align-items-center">
          <li>
            <div className='position-relative'>
              <NavLink className='messagesdropdown' onClick={toggleMesseagesDropdown}>
                <Badge badgeContent={messagesData.filter(item => item.seen === false).length} color="info">
                  <TbMessageFilled style={{ fontSize: '1.6em' }} />
                </Badge>
              </NavLink>
              {messagesDropdown && (
                <div className='card messagesdropdown position-absolute m-0 mt-4 p-2' style={{ top: '100%', left: '0px', minWidth: '200px' }}>
                  <div className='row g-2'>
                    {messagesData.slice(messagesStartIndex, messagesStartIndex + dropdownpagesize).map((message, index) => {
                      return (
                        <div key={index} className='col-xs-12 col-md-12' style={{ cursor: 'pointer' }} onClick={() => { handleShowMessageModal(message); setMessagesData(messagesData.map(item => item.id === message.id ? { ...item, seen: true } : item)) }}>
                          <div className={`card bg-light m-0 p-0 ${message.seen ? 'text-muted' : 'text-black'}`}>
                            <div className='card-header m-0 p-1'>
                              <div className=' card-title m-0 p-0'>{message.title}</div>
                            </div>
                            <div className='card-body m-0 p-2'>
                              <p className='m-0 p-0'>{message.seen ? '' : 'Okunmamış '}Mesaj:</p>
                              <p className='m-0 p-0'>{message.text.slice(0, 20)}...</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className='col-12 d-flex justify-content-between align-items-center w-100'>
                      <button className='btn btn-info m-0 p-1' onClick={() => setMessagesStartIndex(messagesStartIndex - dropdownpagesize)} disabled={messagesStartIndex - dropdownpagesize < 0}>
                        <FaAngleLeft style={{ fontSize: '1.2em' }} />
                      </button>
                      {`${Math.ceil(messagesStartIndex / dropdownpagesize) + 1}/${Math.ceil(messagesData.length / dropdownpagesize)}`}
                      <button className='btn btn-info m-0 p-1' onClick={() => setMessagesStartIndex(messagesStartIndex + dropdownpagesize)} disabled={messagesStartIndex + dropdownpagesize >= messagesData.length}>
                        <FaAngleRight style={{ fontSize: '1.2em' }} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </li>
          <li>
            <div className='position-relative'>
              <NavLink className='alertsdropdown' onClick={toggleAlertsDropdown}>
                <Badge badgeContent={alertsData.filter(item => item.seen === false).length} color="warning">
                  <FaBell style={{ fontSize: '1.4em' }} />
                </Badge>
              </NavLink>
              {alertsDropdown && (
                <div className='card alertsdropdown position-absolute m-0 mt-4 p-2' style={{ top: '100%', left: '0px', minWidth: '200px' }}>
                  <div className='row g-2'>
                    {alertsData.slice(alertsStartIndex, alertsStartIndex + dropdownpagesize).map((alert, index) => {
                      return (
                        <div key={index} className='col-xs-12 col-md-12' style={{ cursor: 'pointer' }} onClick={() => { handleShowMessageModal(alert); setAlertsData(alertsData.map(item => item.id === alert.id ? { ...item, seen: true } : item)) }}>
                          <div className={`card bg-light m-0 p-0 ${alert.seen ? 'text-muted' : 'text-black'}`}>
                            <div className='card-header m-0 p-1'>
                              <div className=' card-title m-0 p-0'>{alert.title}</div>
                            </div>
                            <div className='card-body m-0 p-2'>
                              <p className='m-0 p-0'>{alert.seen ? '' : 'Okunmamış '}Mesaj:</p>
                              <p className='m-0 p-0'>{alert.text.slice(0, 20)}...</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className='col-12 d-flex justify-content-between align-items-center w-100'>
                      <button className='btn btn-info m-0 p-1' onClick={() => setAlertsStartIndex(alertsStartIndex - dropdownpagesize)} disabled={alertsStartIndex - dropdownpagesize < 0}>
                        <FaAngleLeft style={{ fontSize: '1.2em' }} />
                      </button>
                      {`${Math.ceil(alertsStartIndex / dropdownpagesize) + 1}/${Math.ceil(alertsData.length / dropdownpagesize)}`}
                      <button className='btn btn-info m-0 p-1' onClick={() => setAlertsStartIndex(alertsStartIndex + dropdownpagesize)} disabled={alertsStartIndex + dropdownpagesize >= alertsData.length}>
                        <FaAngleRight style={{ fontSize: '1.2em' }} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </li>
          <li className="dropdown notification-list topbar-dropdown">
            <NavLink className="nav-link dealersdropdown dropdown-toggle nav-user me-0" onClick={toggleDealersDropdown}>
              <FaHouseUser style={{ fontSize: '1.4em' }} />
              <span className="pro-user-name ms-1">Bayiler</span>
              {dealersDropdown ? <FaAngleDown className='ms-1' /> : <FaAngleLeft className='ms-1' />}
            </NavLink>
            {dealersDropdown && (
              <div className="dropdown-menu dropdown-menu-end profile-dropdown show dealersdropdown" style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(60px, 72px)" }}>
                {dealersData.map((dealer, index) => {
                  return (
                    <button key={index} className="dropdown-item notify-item" onClick={() => openNewPage('anasayfa')}>
                      <BsHouseFill className="fs-16 align-middle me-2" />
                      <span>{dealer}</span>
                    </button>
                  );
                })}
              </div>
            )}
          </li>
          <li className="dropdown notification-list topbar-dropdown">
            <NavLink className="nav-link profiledropdown dropdown-toggle nav-user me-0" onClick={toggleProfileDropdown}>
              <FaUser style={{ fontSize: '1.4em' }} />
              <span className="pro-user-name ms-1">{myUser?.username}</span>
              {profileDropdown ? <FaAngleDown className='ms-1' /> : <FaAngleLeft className='ms-1' />}
            </NavLink>
            {profileDropdown && (
              <div className="dropdown-menu dropdown-menu-end profile-dropdown show profiledropdown" style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(8px, 72px)" }}>
                <button className="dropdown-item notify-item" onClick={handleLogout}>
                  <FaDoorClosed className="fs-16 align-middle me-2" />
                  <span>Çıkış</span>
                </button>
              </div>
            )}
          </li>
        </ul>
      </div>
      <MessageModal
        show={showMessageModal}
        handleClose={handleCloseMessageModal}
        inputData={inputDataForMessageModal}
      />
    </div >
  );
}

export default Header;