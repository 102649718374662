// General
import { createContext, useContext } from 'react';

const FunctionContext = createContext();

const FunctionProvider = ({ children }) => {
  // String Functions
  const stringUpperCase = (string) => {
    return string.toUpperCase();
  }
  const stringMaxLenght = (text, limit) => {
    return text.length > limit ? text.slice(0, limit) : text;
  }
  const stringOnlyNumbers = (text) => {
    return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(text[text.length - 1]) ? text : text.slice(0, text.length - 1);
  }
  const stringMoney = (price, currency) => {
    const fixedPrice = parseFloat(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const lastDotIndex = fixedPrice.lastIndexOf('.');
    if (lastDotIndex !== -1) {
      return fixedPrice.substring(0, lastDotIndex) + ',' + fixedPrice.substring(lastDotIndex + 1) + ' ' + currency;
    }
    return fixedPrice + ' ' + currency;
  }
  const stringDate = (date) => {
    const months = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
    if (date) {
      const [year, month, day] = date.split("-").map(Number);
      return `${day} ${months[month - 1]} ${year}`;
    } else { return '' }
  }
  const stringDateFromText = (text) => {
    const dateRegex = /\b\d{4}-\d{2}-\d{2}\b/g;
    return text.replace(dateRegex, (match) => stringDate(match));
  }
  const stringPlate = (string) => {
    const formattedRegex = /^(0[1-9]|[1-7][0-9]|8[01]) [A-Z]{1,3} \d{1,4}$/;
    const unformattedRegex = /^(0[1-9]|[1-7][0-9]|8[01])[A-Z]{1,3}\d{1,4}$/;
    if (formattedRegex.test(string)) { return string; }
    else if (unformattedRegex.test(string)) { return string.replace(/^(0[1-9]|[1-7][0-9]|8[01])([A-Z]{1,3})(\d{1,4})$/, '$1 $2 $3'); }
    else { return 'Hatalı plaka formatı!'; }
  }
  const getStringDate = (offset) => {
    const date = new Date();
    date.setDate(date.getDate() - offset);
    return date.toISOString().split('T')[0];
  };
  // List Functions
  const listSortAlphabetically = (list) => {
    return list.slice().sort((a, b) => a.localeCompare(b));
  }
  const listAddItem = (list, newItem) => {
    return [...list, newItem];
  }
  const listDeleteItem = (list, itemId) => {
    return list.filter(item => item.id !== itemId);
  }
  const calculateMultiplebyCount = (list, columnName) => {
    return list.map(item => ({ ...item, [`total_${columnName}`]: (item['count'] || 0) * (item[columnName] || 0) }));
  }
  const calculateTotalOfColumn = (list, columnName) => {
    return list.reduce((total, item) => { return total + (item[columnName] || 0); }, 0);
  }
  // Calculate Functions
  const calculateAmountWithoutKdv = (amount, kdvRate) => {
    return amount / (1 + kdvRate);
  }
  const calculateTotal = (amount, kdvRate, withholdingRate = 0) => {
    const kdvAmount = amount * kdvRate;
    const withholdingAmount = kdvAmount * withholdingRate;
    return amount + kdvAmount - withholdingAmount;
  }
  // Configurative Functions
  const configureServiceOperation = (data, type) => {
    if (type === 'Atölyeye Aldır (Nakliye Gönder)') {
      return { "Alınacak Tarih": data.date, "Başlangıç Saati": data.starttime, "Bitiş Saati": data.endtime, "Açıklama": data.desciption, "Teklif": data.offer }
    } else if (type === 'Bayiye Gönder') {
      return { "Bayi": data.dealer, "Servis": data.serviceId }
    } else if (type === 'Müşteri Cihazı Atölyeye Getirdi') {
      return { "İşlemler": data.operations, "Teklif": data.offer }
    } else if (type === 'Müşteri İptal Etti' || type === 'Ürün Satışı Yapıldı' || type === 'Fiyatta Anlaşılamadı' || type === 'Müşteriye Ulaşılamadı' || type === 'Ürün Garantili Çıktı' || type === 'Cihaz Teslim Edilemedi (Arızalı)' || type === 'Cihaz Teslim Edildi' || type === 'Parça Hazır' || type === 'Parça Siparişte') {
      return { "Açıklama": data.desciption }
    } else if (type === 'Teknisyen Yönlendir' || type === 'Nakliye Gönder') {
      return { "Personel": data.employee, "Yardımcı Personel": data.helperemployee, "Araç": data.vehicle, "Gidiş Tarihi": data.date, "Başlangıç Saati": data.starttime, "Bitiş Saati": data.endtime }
    } else if (type === 'Cihaz Atölyeye Alındı' || type === 'Fiyat Yükseltildi' || type === 'Müşteri Para İade Edilecek' || type === 'Müşteri Para İade Edildi') {
      return { "Açıklama": data.desciption, "Teklif": data.offer }
    } else if (type === 'Cihaz Tamir Edilemiyor') {
      return { "Neden": data.cause }
    } else if (type === 'Haber Verecek') {
      return { "Açıklama": data.desciption, "Aranacak Tarih": data.date, "Başlangıç Saati": data.starttime, "Bitiş Saati": data.endtime }
    } else if (type === 'Parça Takmak İçin Teknisyene Yönlendir') {
      return { "Depocu": data.warehouseman, "Gidilecek Tarih": data.date, "Açıklama": data.desciption, "Teklif": data.offer }
    } else if (type === 'Parça Talep Et') {
      return { "Depocu": data.warehouseman, "Açıklama": data.desciption }
    } else if (type === 'Parçası Atölyeye Alındı') {
      return { "Depocu": data.warehouseman, "İşlemler": data.operations, "Açıklama": data.desciption }
    } else if (type === 'Yeniden Teknisyen Yönlendir' || type === 'Şikayetçi') {
      return { "Gidilecek Tarih": data.date, "Açıklama": data.desciption }
    } else if (type === 'Yerinde Bakım Yapıldı' || type === 'Cihaz Teslim Edildi (Parça Takıldı)') {
      return { "Açıklama": data.desciption, "Teklif": data.offer, "Ürün": data.product }
    } else if (type === 'Tahsilata Gönder') {
      return { "Gidilecek Tarih": data.date, "Personel": data.employee, "Teklif": data.offer, "Açıklama": data.desciption }
    } else if (type === 'Atölyede Tamir Ediliyor') {
      return { "Açıklama": data.desciption, "Personel": data.employee }
    } else if (type === 'Nakliyede (Teslim Edilecek)') {
      return { "Açıklama": data.desciption, "Personel": data.employee, "Nakil Tarihi": data.date }
    } else if (type === 'Parça Teslim Et') {
      return { "Parça": data.product }
    } else if (type === 'Teslimata Hazır (Tamamlandı)') {
      return { "Ürün": data.product, "Açıklama": data.desciption }
    } else if (type === 'Servisi Sonlandır') {
      return { "Sonlandır": data.ended }
    } else {
      return data;
    }
  }
  const configureCaseActionInformations = (values) => {
    const infos = values.actionTypeName === 'Depo Stok' ? { 'Açıklama': values.description, 'Tedarikçi': values.supplier } : (values.actionTypeName === 'Servis Para' || values.actionTypeName === 'Servis Parça' || values.actionTypeName === 'Müşteri İade') ? { 'Açıklama': values.description, 'Personel': values.employee, 'Servis': values.serviceId, 'Marka': values.brand, 'Cihaz': values.device } : (values.actionTypeName === 'Maaş' || values.actionTypeName === 'Prim') ? { 'Açıklama': values.description, 'Personel': values.employee } : { 'Açıklama': values.description }
    const newValues = {
      userId: values.userId,
      date: values.date,
      time: values.time,
      paymentType: values.paymentType,
      paymentMethod: values.paymentMethod,
      actionTypeName: values.actionTypeName,
      status: values.status,
      total: values.total,
      unit: values.unit,
      informations: JSON.stringify(infos).replace(/\\/g, '').replace(/"/g, "'")
    };
    return newValues;
  };
  const configureServiceAssignData = (values) => {
    return JSON.stringify(values).replace(/\\/g, '').replace(/"/g, "'")
  }
  // Dictionary Functions
  const dictionaryStatuses = {
    'AktifDegil': 'Aktif Değil',
    'Aktif Değil': 'AktifDegil',
    'Aktif': 'Aktif',
    'İzinli': 'İzinli'
  };
  const dictionaryBooleans = {
    true: 'Evet',
    false: 'Hayır',
    'Evet': true,
    'Hayır': false
  }
  const dictionaryRecurrenceIntervalTypes = {
    'Sadece Bir Kere': 'SadeceBirKere',
    'Her Gün': 'HerGun',
    'Haftada Bir Bu Gün': 'HaftadaBirBuGun',
    'Ayda Bir Bu Tarih': 'AydaBirBuTarih',
    'Yılda Bir Bu Tarih': 'YildaBirBuTarih',
    'SadeceBirKere': 'Sadece Bir Kere',
    'HerGun': 'Her Gün',
    'HaftadaBirBuGun': 'Haftada Bir Bu Gün',
    'AydaBirBuTarih': 'Ayda Bir Bu Tarih',
    'YildaBirBuTarih': 'Yılda Bir Bu Tarih'
  }
  const dictionaryReminderTimeOptions = {
    'Zamanında': 'Zamaninda',
    'Onbeş Dakika Önce': 'OnBesDakikaOnce',
    'Bir Saat Önce': 'BirSaatOnce',
    'Bir Gün Önce': 'BirGunOnce',
    'İki Gün Önce': 'IkiGunOnce',
    'Zamaninda': 'Zamanında',
    'OnBesDakikaOnce': 'Onbeş Dakika Önce',
    'BirSaatOnce': 'Bir Saat Önce',
    'BirGunOnce': 'Bir Gün Önce',
    'IkiGunOnce': 'İki Gün Önce'
  }
  const dictionaryCaseactionTypes = {
    'Gelen Ödeme': 'Gelen',
    'Giden Ödeme': 'Giden',
    'Gelen': 'Gelen Ödeme',
    'Giden': 'Giden Ödeme'
  }
  const dictionaryCaseactionMethods = {
    'Nakit': 'Nakit',
    'EFT / Havale': 'Eft',
    'Kredi Kartı': 'Kredi',
    'Eft': 'EFT / Havale',
    'Kredi': 'Kredi Kartı'
  }
  // Page Functions
  const openNewPage = (page, filters = {}) => {
    const queryParams = new URLSearchParams(filters).toString();
    const url = `/${page}?${queryParams}`;
    window.open(url, "_blank", "noopener,noreferrer");
  }
  // Export
  const values = { stringUpperCase, stringMaxLenght, stringOnlyNumbers, stringMoney, stringDate, stringDateFromText, stringPlate, getStringDate, listSortAlphabetically, listAddItem, listDeleteItem, calculateMultiplebyCount, calculateTotalOfColumn, calculateAmountWithoutKdv, calculateTotal, configureServiceOperation, configureCaseActionInformations, configureServiceAssignData, dictionaryStatuses, dictionaryBooleans, dictionaryRecurrenceIntervalTypes, dictionaryReminderTimeOptions, dictionaryCaseactionTypes, dictionaryCaseactionMethods, openNewPage }
  return <FunctionContext.Provider value={values}>{children}</FunctionContext.Provider>
}

const useFunctions = () => useContext(FunctionContext);

export { FunctionProvider, useFunctions };