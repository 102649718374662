// General
import React from 'react';

function Invoices() {
  // HTML
  return (
    <div className='d-flex justify-content-center align-items-center w-100' style={{ height: 'calc(100% - 130px)' }}>
      <div className='card my-5'>
        <div className='card-body'>
          <h5>Bu sayfaya henüz apileri eklenmediği için final versiyonunda bu sayfa gösteilemiyor!</h5>
        </div>
      </div>
    </div>
  );
}

export default Invoices;