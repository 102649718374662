// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Premiums/';

// Async Thunks
export const getAllUserPremiums = createAsyncThunk('backend_getalluserpremiums', async ({ signal = null, start, end, type, name, status }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllUserPremiums?startDate=${start}&endDate=${end}&employeeType=${type}&employeeName=${name}&status=${status}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const payUserPremium = createAsyncThunk('backend_payuserpremium', async ({ start, end, type, name, status }) => {
  try {
    const res = await axios.get(`${baseurl}PayUserPremium?startDate=${start}&endDate=${end}&employeeType=${type}&employeeName=${name}&status=${status}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const premiumSlice = createSlice({
  name: 'premium',
  initialState: {
    premiums: [],
    getAllStatus: null,
    payStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetPayStatus: (state) => { state.payStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserPremiums.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllUserPremiums.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.premiums = action.payload || []; })
      .addCase(getAllUserPremiums.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(payUserPremium.pending, (state) => { state.payStatus = "pending"; })
      .addCase(payUserPremium.fulfilled, (state, action) => { state.payStatus = "fulfilled"; })
      .addCase(payUserPremium.rejected, (state, action) => { state.payStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = premiumSlice.actions;
export default premiumSlice.reducer;