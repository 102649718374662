// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getRemindersByDate, getReminderById, addNewReminder, updateReminder, deleteReminder, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/ReminderSlice';
import { getAllEmployees } from '../../../storage/slices/backend/EmployeeSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import ReminderAddModal from '../modals/reminders/ReminderAddModal';
import ReminderUpdateModal from '../modals/reminders/ReminderUpdateModal';
import ReminderDeleteModal from '../modals/DeleteModal';
// Icons
import { LuCalendarPlus, LuCalendarMinus, LuCalendarCog } from "react-icons/lu";
import { TbPrinter } from 'react-icons/tb';

function Reminders() {
  // Storage Import
  const dispatch = useDispatch();
  const { reminders, reminder, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.reminder);
  // Context Import
  const { myUser } = useData();
  const { stringDate, getStringDate, dictionaryStatuses, dictionaryRecurrenceIntervalTypes, dictionaryReminderTimeOptions } = useFunctions();
  // Variables
  const [dates, setDates] = useState({ start: getStringDate(7), end: getStringDate(0) });
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal, start, end) => {
    try {
      await dispatch(getRemindersByDate({ signal, start, end }));
      await dispatch(getAllEmployees({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal, dates.start, dates.end); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch, dates]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      const data = reminders.map((data) => {
        const id = data.id;
        const message = data.message;
        const date = `${stringDate(data.date)} ${data.reminderTime}`;
        const recurrenceInterval = dictionaryRecurrenceIntervalTypes[data.recurrenceInterval];
        const reminderOption = dictionaryReminderTimeOptions[data.reminderOption];
        const users = data.name;
        const status = dictionaryStatuses[data.status];
        return { id, message, date, recurrenceInterval, reminderOption, users, status };
      });
      setDataToTable(data);
      setDataToPDF(data);
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseReminderAddModal();
      dispatch(addToast({ background: 'success', icon: 'reminderAdd', message: 'Hatırlatıcı Eklendi!', delay: 6000 }));
      dispatch(getRemindersByDate({ signal: null, start: dates.start, end: dates.end }));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseReminderUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'reminderUpdate', message: 'Hatırlatıcı Güncellendi!', delay: 6000 }));
      dispatch(getRemindersByDate({ signal: null, start: dates.start, end: dates.end }));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseReminderDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'reminderDelete', message: 'Hatırlatıcı Silindi!', delay: 6000 }));
      dispatch(getRemindersByDate({ signal: null, start: dates.start, end: dates.end }));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataReminderUpdateModal({ ...reminder, recurrenceInterval: dictionaryRecurrenceIntervalTypes[reminder.recurrenceInterval], reminderOption: dictionaryReminderTimeOptions[reminder.reminderOption], status: dictionaryStatuses[reminder.status] });
        setShowReminderUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataReminderDeleteModal({ ...reminder, recurrenceInterval: dictionaryRecurrenceIntervalTypes[reminder.recurrenceInterval], reminderOption: dictionaryReminderTimeOptions[reminder.reminderOption], status: dictionaryStatuses[reminder.status] });
        setShowReminderDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'message', name: 'MESAJ' }, { value: 'recurrenceInterval', name: 'HATIRLATMA ZAMANI' }, { value: 'reminderOption', name: 'TEKRAR SÜRESİ' }, { value: 'users', name: 'HATIRLATILACAK KİŞİLER' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'date', 'message', 'recurrenceInterval', 'reminderOption', 'users', 'status'];
  const changeDates = (startDate, endDate) => { setDates({ start: startDate, end: endDate }) }
  const clickOn = (id) => { handleShowReminderUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <LuCalendarCog className='button-icon' />,
      onclick: ((rowData) => { handleShowReminderUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <LuCalendarMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowReminderDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Hatırlatıcılar';
  const operations = [
    {
      text: 'Hatırlatıcı Ekle',
      icon: <LuCalendarPlus className='button-icon' />,
      onclick: (() => { handleShowReminderAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        changeDates={changeDates}
        dates={dates}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showReminderAddModal, setShowReminderAddModal] = useState(false);
  const handleCloseReminderAddModal = () => {
    setShowReminderAddModal(false);
  }
  const handleShowReminderAddModal = () => {
    setShowReminderAddModal(true);
  }
  const handleAddReminderAddModal = (values) => {
    dispatch(addNewReminder({ newreminder: values }));
  }
  // Update Modal
  const [showReminderUpdateModal, setShowReminderUpdateModal] = useState(false);
  const [inputDataReminderUpdateModal, setInputDataReminderUpdateModal] = useState(null);
  const handleCloseReminderUpdateModal = () => {
    setShowReminderUpdateModal(false);
    setInputDataReminderUpdateModal(null);
  }
  const handleShowReminderUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getReminderById({ id: id }));
  }
  const handleUpdateReminderUpdateModal = (values) => {
    dispatch(updateReminder({ reminder: values }));
  }
  // Delete Modal
  const [showReminderDeleteModal, setShowReminderDeleteModal] = useState(false);
  const [inputDataReminderDeleteModal, setInputDataReminderDeleteModal] = useState(null);
  const handleCloseReminderDeleteModal = () => {
    setShowReminderDeleteModal(false);
    setInputDataReminderDeleteModal(null);
  }
  const handleShowReminderDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getReminderById({ id: id }));
  }
  const handleDeleteReminderDeleteModal = () => {
    dispatch(deleteReminder({ id: inputDataReminderDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <ReminderAddModal
        show={showReminderAddModal}
        handleClose={handleCloseReminderAddModal}
        handleAdd={handleAddReminderAddModal}
      />
      <ReminderUpdateModal
        show={showReminderUpdateModal}
        handleClose={handleCloseReminderUpdateModal}
        handleUpdate={handleUpdateReminderUpdateModal}
        inputData={inputDataReminderUpdateModal}
      />
      <ReminderDeleteModal
        show={showReminderDeleteModal}
        handleClose={handleCloseReminderDeleteModal}
        handleDelete={handleDeleteReminderDeleteModal}
        title="Hatırlatıcıyı Sil"
        message={`${inputDataReminderDeleteModal?.id} id numaralı hatırlatıcıyı silmek istediğinize emin misiniz?`}
        deleteIcon={<LuCalendarMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showReminderAddModal, showReminderUpdateModal, inputDataReminderUpdateModal, showReminderDeleteModal, inputDataReminderDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Reminders;