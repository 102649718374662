// General
import React, { useCallback, useEffect, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllCaseactionsByDate, getCaseactionById, addNewCaseaction, updateCaseaction, deleteCaseaction, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/CaseactionSlice';
import { getAllEmployees } from '../../../storage/slices/backend/EmployeeSlice';
import { getAllSuppliers } from '../../../storage/slices/backend/SupplierSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
// Modals
import CaseactionAddModal from '../modals/case/CaseactionAddModal';
import CaseactionUpdateModal from '../modals/case/CaseactionUpdateModal';
import CaseactionDeleteModal from '../modals/DeleteModal';
// Icons
import { HiMiniChevronDown } from 'react-icons/hi2';
import { TbBook2, TbCashBanknote, TbCashBanknoteOff, TbCashBanknoteFilled, TbPrinter } from 'react-icons/tb';

function Case() {
  // Storage Import
  const dispatch = useDispatch();
  const { caseactions, caseaction, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.caseaction);
  // Context Import
  const { myUser } = useData();
  const { stringDate, stringMoney, getStringDate, configureCaseActionInformations, dictionaryCaseactionTypes, dictionaryCaseactionMethods } = useFunctions();
  // Variables
  const [dates, setDates] = useState({ start: getStringDate(7), end: getStringDate(0) })
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal, start, end) => {
    try {
      await dispatch(getAllCaseactionsByDate({ signal, start, end }));
      await dispatch(getAllEmployees({ signal }));
      await dispatch(getAllSuppliers({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      const data = caseactions.map((data) => {
        const id = data.id;
        const date = stringDate(data.date);
        const user = data.user;
        const info = data.informations;
        const type = `${dictionaryCaseactionTypes[data.paymentType]} ${dictionaryCaseactionMethods[data.paymentMethod]}`;
        const status = data.status;
        const total = stringMoney(data.total, 'TL');
        return { id, date, user, info, type, status, total };
      });
      setDataToTable(data);
      setDataToPDF(data);
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseCaseactionAddModal();
      dispatch(addToast({ background: 'success', icon: 'caseactionAdd', message: 'Kasa İşlemi Eklendi!', delay: 6000 }));
      dispatch(getAllCaseactionsByDate({ signal: null, start: dates.start, end: dates.end }));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseCaseactionUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'caseactionUpdate', message: 'Kasa İşlemi Güncellendi!', delay: 6000 }));
      dispatch(getAllCaseactionsByDate({ signal: null, start: dates.start, end: dates.end }));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseCaseactionDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'caseactionDelete', message: 'Kasa İşlemi Silindi!', delay: 6000 }));
      dispatch(getAllCaseactionsByDate({ signal: null, start: dates.start, end: dates.end }));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataCaseactionUpdateModal({ ...caseaction, paymentType: dictionaryCaseactionTypes[caseaction.paymentType], paymentMethod: dictionaryCaseactionMethods[caseaction.paymentMethod] });
        setShowCaseactionUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataCaseactionDeleteModal({ ...caseaction, paymentType: dictionaryCaseactionTypes[caseaction.paymentType], paymentMethod: dictionaryCaseactionMethods[caseaction.paymentMethod] });
        setShowCaseactionDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'user', name: 'PERSONEL' }, { value: 'info', name: 'AÇIKLAMA' }, { value: 'type', name: 'ŞEKLİ' }, { value: 'status', name: 'DURUM' }, { value: 'total', name: 'TUTAR' }];
  const filters = ['id', 'date', 'user', 'info', 'type', 'status', 'total'];
  const changeDates = (startDate, endDate) => { setDates({ start: startDate, end: endDate }); }
  const clickOn = (id) => { handleShowCaseactionUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <TbCashBanknoteFilled className='button-icon' />,
      onclick: ((rowData) => { handleShowCaseactionUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <TbCashBanknoteOff className='button-icon' />,
      onclick: ((rowData) => { handleShowCaseactionDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Kasa İşlemleri';
  const [operationsDropdown, setOperationsDropdown] = useState(false);
  const toggleOperationsDropdown = () => { setOperationsDropdown(!operationsDropdown); };
  const handleClickOutside = useCallback((event) => { if (!event.target.closest('.operationsdropdown')) { setOperationsDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => { document.removeEventListener('click', handleClickOutside); };
  }, [handleClickOutside]);
  const operations = [
    {
      text: 'Kasa İşlemi Ekle',
      icon: <TbCashBanknote className='button-icon' />,
      onclick: (() => { handleShowCaseactionAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const TableComponent = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        changeDates={changeDates}
        dates={dates}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showCaseactionAddModal, setShowCaseactionAddModal] = useState(false);
  const handleCloseCaseactionAddModal = () => {
    setShowCaseactionAddModal(false);
  }
  const handleShowCaseactionAddModal = () => {
    setShowCaseactionAddModal(true);
  }
  const handleAddCaseactionAddModal = (values) => {
    dispatch(addNewCaseaction({ newcaseaction: configureCaseActionInformations({ ...values, paymentType: dictionaryCaseactionTypes[values.paymentType], paymentMethod: dictionaryCaseactionMethods[values.paymentMethod] }) }));
  }
  // Update Modal
  const [showCaseactionUpdateModal, setShowCaseactionUpdateModal] = useState(false);
  const [inputDataCaseactionUpdateModal, setInputDataCaseactionUpdateModal] = useState(null);
  const handleCloseCaseactionUpdateModal = () => {
    setShowCaseactionUpdateModal(false);
    setInputDataCaseactionUpdateModal(null);
  }
  const handleShowCaseactionUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getCaseactionById({ id: id }));
  }
  const handleUpdateCaseactionUpdateModal = (values) => {
    dispatch(updateCaseaction({ caseaction: configureCaseActionInformations({ ...values, paymentType: dictionaryCaseactionTypes[values.paymentType], paymentMethod: dictionaryCaseactionMethods[values.paymentMethod] }) }));
  }
  // Delete Modal
  const [showCaseactionDeleteModal, setShowCaseactionDeleteModal] = useState(false);
  const [inputDataCaseactionDeleteModal, setInputDataCaseactionDeleteModal] = useState(null);
  const handleCloseCaseactionDeleteModal = () => {
    setShowCaseactionDeleteModal(false);
    setInputDataCaseactionDeleteModal(null);
  }
  const handleShowCaseactionDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getCaseactionById({ id: id }));
  }
  const handleDeleteCaseactionDeleteModal = () => {
    dispatch(deleteCaseaction({ id: inputDataCaseactionDeleteModal?.id }));
  }
  // HTML
  return (
    <div className='d-flex justify-content-center w-100 mt-5'>
      <div className='card w-100'>
        <div className='card-header m-0 p-1'>
          <div className='row d-flex align-items-center'>
            <div className='col-12 col-sm-8 col-lg-8 col-xl-7'>
              <div className='row h-100 m-0 p-2'>
                <div className='d-flex align-items-center'>
                  <div className='card-title m-0 p-0'>{title}</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-4 col-lg-4 col-xl-5 ps-3 ps-sm-0 ps-lg-0 ps-xl-3'>
              <div className='row d-flex justify-content-end m-1 ms-sm-0 ms-lg-0  ms-xl-2'>
                <div className='col-12 col-sm-12 col-lg-12 col-xl-5 ps-0'>
                  <div className="dropdown">
                    <button className="button-with-icon btn btn-success operationsdropdown dropdown-toggle w-100" onClick={toggleOperationsDropdown} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <TbBook2 className='button-icon' />
                      İşlemler
                      <HiMiniChevronDown style={{ fontSize: '1.25em' }} />
                    </button>
                    {operationsDropdown && (
                      <ul className="dropdown-menu operationsdropdown w-100 show">
                        {operations.map((operation, index) => {
                          return (
                            <button key={index} className='dropdown-item' onClick={(e) => { e.stopPropagation(); operation.onclick(); }}>
                              {operation.icon ? operation.icon : ''}
                              {operation.text}
                            </button>
                          )
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <TableComponent />
          <div className='row d-flex justify-content-end mb-2'>
            <div className='col-12 col-md-12'>
              <div className='row g-1'>
                <div className='col-6 col-md-3 d-flex align-items-stretch'>
                  <div className='card bg-success text-white m-0 p-1 flex-fill'>
                    <div className='card-title'><b>Gelen</b></div>
                    <div className='d-flex flex-column justify-content-center h-100'>
                      <p className='p-0 m-0'><b>Nakit:</b> 0 TL</p>
                      <p className='p-0 m-0'><b>EFT / Havale:</b> 0 TL</p>
                      <p className='p-0 m-0'><b>Kredi Kartı:</b> +5.000,00 TL</p>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-3 d-flex align-items-stretch'>
                  <div className='card bg-dark text-white m-0 p-1 flex-fill'>
                    <div className='card-title'><b>TV Gider</b></div>
                    <div className='d-flex flex-column justify-content-center h-100'>
                      <p className='p-0 m-0'><b>Nakit:</b> 0 TL</p>
                      <p className='p-0 m-0'><b>EFT / Havale:</b> 0 TL</p>
                      <p className='p-0 m-0'><b>Kredi Kartı:</b> 0 TL</p>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-3 d-flex align-items-stretch'>
                  <div className='card bg-primary text-white m-0 p-1 flex-fill'>
                    <div className='card-title'><b>Genel Toplam</b></div>
                    <div className='d-flex align-items-center h-100'>
                      <p className='p-0 m-0'><b>Toplam:</b> +2.000,00 TL</p>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-3 d-flex align-items-stretch'>
                  <div className='card bg-danger text-white m-0 p-1 flex-fill'>
                    <div className='card-title'><b>Giden Toplam</b></div>
                    <div className='d-flex align-items-center h-100'>
                      <p className='p-0 m-0'><b>Toplam:</b> -4.000,00 TL</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CaseactionAddModal
            show={showCaseactionAddModal}
            handleClose={handleCloseCaseactionAddModal}
            handleAdd={handleAddCaseactionAddModal}
            inputData={{ isService: false, paymentType: null }}
          />
          <CaseactionUpdateModal
            show={showCaseactionUpdateModal}
            handleClose={handleCloseCaseactionUpdateModal}
            handleUpdate={handleUpdateCaseactionUpdateModal}
            inputData={inputDataCaseactionUpdateModal}
          />
          <CaseactionDeleteModal
            show={showCaseactionDeleteModal}
            handleClose={handleCloseCaseactionDeleteModal}
            handleDelete={handleDeleteCaseactionDeleteModal}
            title="Kasa İşlemini Sil"
            message={`${inputDataCaseactionDeleteModal?.id} id numaralı kasa işlemini silmek istediğinize emin misiniz?`}
            deleteIcon={<TbCashBanknoteOff className='button-icon' />}
          />
        </div>
      </div>
    </div>
  );
}

export default Case;