// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllBrands, getBrandById, addNewBrand, updateBrand, deleteBrand, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/BrandSlice';
// Contexts
import { useData } from '../../../context/DataContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import BrandAddModal from '../modals/brands/BrandAddModal';
import BrandUpdateModal from '../modals/brands/BrandUpdateModal';
import BrandDeleteModal from '../modals/DeleteModal';
// Icons
import { LuBadgePlus, LuBadgeMinus, LuBadgeCheck } from "react-icons/lu";
import { TbPrinter } from 'react-icons/tb';

function Brands() {
  // Storage Import
  const dispatch = useDispatch();
  const { brands, brand, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.brand);
  // Context Import
  const { myUser } = useData();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllBrands({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      setDataToTable(brands);
      setDataToPDF(brands);
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseBrandAddModal();
      dispatch(addToast({ background: 'success', icon: 'brandAdd', message: 'Marka Eklendi!', delay: 6000 }));
      dispatch(getAllBrands({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseBrandUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'brandUpdate', message: 'Marka Güncellendi!', delay: 6000 }));
      dispatch(getAllBrands({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseBrandDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'brandDelete', message: 'Marka Silindi!', delay: 6000 }));
      dispatch(getAllBrands({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataBrandUpdateModal(brand);
        setShowBrandUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataBrandDeleteModal(brand);
        setShowBrandDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'MARKA' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'name', 'description'];
  const clickOn = (id) => { handleShowBrandUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <LuBadgeCheck className='button-icon' />,
      onclick: ((rowData) => { handleShowBrandUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <LuBadgeMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowBrandDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Markalar';
  const operations = [
    {
      text: 'Marka Ekle',
      icon: <LuBadgePlus className='button-icon' />,
      onclick: (() => { handleShowBrandAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showBrandAddModal, setShowBrandAddModal] = useState(false);
  const handleCloseBrandAddModal = () => {
    setShowBrandAddModal(false);
  }
  const handleShowBrandAddModal = () => {
    setShowBrandAddModal(true);
  }
  const handleAddBrandAddModal = (values) => {
    dispatch(addNewBrand({ newbrand: values }));
  }
  // Update Modal
  const [showBrandUpdateModal, setShowBrandUpdateModal] = useState(false);
  const [inputDataBrandUpdateModal, setInputDataBrandUpdateModal] = useState(null);
  const handleCloseBrandUpdateModal = () => {
    setShowBrandUpdateModal(false);
    setInputDataBrandUpdateModal(null);
  }
  const handleShowBrandUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getBrandById({ id: id }));
  }
  const handleUpdateBrandUpdateModal = (values) => {
    dispatch(updateBrand({ brand: values }));
  }
  // Delete Modal
  const [showBrandDeleteModal, setShowBrandDeleteModal] = useState(false);
  const [inputDataBrandDeleteModal, setInputDataBrandDeleteModal] = useState(null);
  const handleCloseBrandDeleteModal = () => {
    setShowBrandDeleteModal(false);
    setInputDataBrandDeleteModal(null);
  }
  const handleShowBrandDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getBrandById({ id: id }));
  }
  const handleDeleteBrandDeleteModal = () => {
    dispatch(deleteBrand({ id: inputDataBrandDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <BrandAddModal
        show={showBrandAddModal}
        handleClose={handleCloseBrandAddModal}
        handleAdd={handleAddBrandAddModal}
      />
      <BrandUpdateModal
        show={showBrandUpdateModal}
        handleClose={handleCloseBrandUpdateModal}
        handleUpdate={handleUpdateBrandUpdateModal}
        inputData={inputDataBrandUpdateModal}
      />
      <BrandDeleteModal
        show={showBrandDeleteModal}
        handleClose={handleCloseBrandDeleteModal}
        handleDelete={handleDeleteBrandDeleteModal}
        title="Markayı Sil"
        message={`${inputDataBrandDeleteModal?.name} adlı markayı silmek istediğinize emin misiniz?`}
        deleteIcon={<LuBadgeMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showBrandAddModal, showBrandUpdateModal, showBrandDeleteModal, inputDataBrandUpdateModal, inputDataBrandDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Brands;