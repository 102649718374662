// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/CaseActions/';

// Async Thunks
export const getAllCaseactions = createAsyncThunk('backend_getallcaseactions', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllCaseActions`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getCaseactionById = createAsyncThunk('backend_getcaseactionbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetCaseActionById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const getAllCaseactionsByDate = createAsyncThunk('backend_getallcaseactionsbydate', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllCaseActionsByDate?start=${start}&end=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewCaseaction = createAsyncThunk('backend_addnewcaseaction', async ({ newcaseaction }) => {
  try {
    const res = await axios.post(`${baseurl}AddCaseAction`, newcaseaction, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateCaseaction = createAsyncThunk('backend_updatecaseaction', async ({ caseaction }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateCaseAction`, caseaction, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteCaseaction = createAsyncThunk('backend_deletecaseaction', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteCaseAction?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const caseactionSlice = createSlice({
  name: 'caseaction',
  initialState: {
    caseactions: [],
    caseaction: {},
    getAllStatus: null,
    getDataStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetGetDataStatus: (state) => { state.getDataStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCaseactions.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllCaseactions.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.caseactions = action.payload || []; })
      .addCase(getAllCaseactions.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getCaseactionById.pending, (state) => { state.getDataStatus = "pending"; })
      .addCase(getCaseactionById.fulfilled, (state, action) => { state.getDataStatus = "fulfilled"; state.caseaction = action.payload || {}; })
      .addCase(getCaseactionById.rejected, (state, action) => { state.getDataStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllCaseactionsByDate.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllCaseactionsByDate.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.caseactions = action.payload || []; })
      .addCase(getAllCaseactionsByDate.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewCaseaction.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewCaseaction.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewCaseaction.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateCaseaction.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateCaseaction.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateCaseaction.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteCaseaction.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteCaseaction.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteCaseaction.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = caseactionSlice.actions;
export default caseactionSlice.reducer;