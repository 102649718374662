// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/WarehouseShelves/';

// Async Thunks
export const getAllShelves = createAsyncThunk('backend_getallshelfs', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllWarehouseShelves`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getShelfById = createAsyncThunk('backend_getshelfbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetWarehouseShelfById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewShelf = createAsyncThunk('backend_addnewshelf', async ({ newshelf }) => {
  try {
    const res = await axios.post(`${baseurl}AddWarehouseShelf`, newshelf, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateShelf = createAsyncThunk('backend_updateshelf', async ({ shelf }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateWarehouseShelf`, shelf, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteShelf = createAsyncThunk('backend_deleteshelf', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteWarehouseShelf?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const shelfSlice = createSlice({
  name: 'shelf',
  initialState: {
    shelves: [],
    shelf: {},
    getAllStatus: null,
    getDataStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetGetDataStatus: (state) => { state.getDataStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllShelves.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllShelves.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.shelves = action.payload || []; })
      .addCase(getAllShelves.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getShelfById.pending, (state) => { state.getDataStatus = "pending"; })
      .addCase(getShelfById.fulfilled, (state, action) => { state.getDataStatus = "fulfilled"; state.shelf = action.payload || {}; })
      .addCase(getShelfById.rejected, (state, action) => { state.getDataStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewShelf.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewShelf.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewShelf.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateShelf.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateShelf.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateShelf.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteShelf.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteShelf.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteShelf.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = shelfSlice.actions;
export default shelfSlice.reducer;