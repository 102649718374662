// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
// Icons
import { TbArrowBackUpDouble } from 'react-icons/tb';

function MessageModal({ show, handleClose, inputData }) {
  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header className='py-2' closeButton>
        <Modal.Title>{inputData?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex align-items-center'>
          <h6>{inputData?.text}</h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='row d-flex justify-content-end w-100'>
          <div className='col-xs-12 col-md-6'>
            <button className='button-with-icon btn btn-secondary w-100' onClick={handleClose}>
              <TbArrowBackUpDouble className='button-icon' />
              Geri Dön
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default MessageModal;