// General
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// Styles
import './assets/css/app.css';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/primereact.css';
// Storages
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './storage/Store';
// Contexts
import { DataProvider } from './context/DataContext';
import { FunctionProvider } from './context/FunctionContext';
import { ValidationProvider } from './context/ValidationContext';
import { IconProvider } from './context/IconContext';
import { PrimeReactProvider } from 'primereact/api';
// Components
import ToastMessages from './view/pages/components/ToastMessages';
// Pages
import Layout from './view/Layout';
import Login from './view/pages/contents/Login';
import Dashboard from './view/pages/contents/Dashboard';
import Services from './view/pages/contents/Services';
import Customers from './view/pages/contents/Customers';
import Employees from './view/pages/contents/Employees';
import Warehouse from './view/pages/contents/Warehouse';
import Offers from './view/pages/contents/Offers';
import Reminders from './view/pages/contents/Reminders';
import Surveys from './view/pages/contents/Surveys';
import Survey from './view/pages/contents/Survey';
import Statistics from './view/pages/contents/Statistics';
import Case from './view/pages/contents/Case';
import Invoices from './view/pages/contents/Invoices';
import Bulkmessages from './view/pages/contents/Bulkmessages';
import Transactionreports from './view/pages/contents/Transactionreports';
import Vehicles from './view/pages/contents/Vehicles';
import Suppliers from './view/pages/contents/Suppliers';
import Warehouses from './view/pages/contents/Warehouses';
import Shelves from './view/pages/contents/Shelves';
import Roles from './view/pages/contents/Roles';
import Brands from './view/pages/contents/Brands';
import Devices from './view/pages/contents/Devices';
import Dealers from './view/pages/contents/Dealers';
import Sources from './view/pages/contents/Sources';
import Consigmentdevices from './view/pages/contents/Consigmentdevices';
import Surveyquestions from './view/pages/contents/Surveyquestions';
import Messages from './view/pages/contents/Messages';
import Error from './view/pages/contents/Error';

function App() {
  // HTML
  return (
    <ReduxProvider store={store}>
      <DataProvider>
        <FunctionProvider>
          <ValidationProvider>
            <IconProvider>
              <PrimeReactProvider>
                <AppContent />
              </PrimeReactProvider>
            </IconProvider>
          </ValidationProvider>
        </FunctionProvider>
      </DataProvider>
    </ReduxProvider>
  );
}

function AppContent() {
  // HTML
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/giris" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="/anasayfa" />} />
            <Route path="anasayfa" element={<Dashboard />} />
            <Route path="servisler" element={<Services />} />
            <Route path="musteriler" element={<Customers />} />
            <Route path="personeller" element={<Employees />} />
            <Route path="depo" element={<Warehouse />} />
            <Route path="toplumesaj" element={<Bulkmessages />} />
            <Route path="kasa" element={<Case />} />
            <Route path="faturalar" element={<Invoices />} />
            <Route path="teklifler" element={<Offers />} />
            <Route path="islemraporlari" element={<Transactionreports />} />
            <Route path="hatirlaticilar" element={<Reminders />} />
            <Route path="istatistikler" element={<Statistics />} />
            <Route path="anketler" element={<Surveys />} />
            <Route path="araclar" element={<Vehicles />} />
            <Route path="tedarikciler" element={<Suppliers />} />
            <Route path="depolar" element={<Warehouses />} />
            <Route path="raflar" element={<Shelves />} />
            <Route path="roller" element={<Roles />} />
            <Route path="markalar" element={<Brands />} />
            <Route path="cihazlar" element={<Devices />} />
            <Route path="bayiler" element={<Dealers />} />
            <Route path="kaynaklar" element={<Sources />} />
            <Route path="konsinyecihazlar" element={<Consigmentdevices />} />
            <Route path="anketsorulari" element={<Surveyquestions />} />
            <Route path='mesajlar' element={<Messages />} />
          </Route>
          <Route path="anket" element={<Survey />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </BrowserRouter>
      <ToastMessages />
    </>
  );
}

export default App;