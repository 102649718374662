// General
import { createContext, useContext, useState } from 'react';

const DataContext = createContext();

const DataProvider = ({ children }) => {
  // Variables
  const [myUser, setMyUser] = useState(null);
  const [sidebar, setSidebar] = useState(true);
  const [dollar, setDollar] = useState(null);
  const [euro, setEuro] = useState(null);
  // Lists
  const cities = ['İstanbul', 'Kocaeli', 'Sakarya'];
  const statuses = ['Aktif', 'Aktif Değil'];
  const booleans = ['Evet', 'Hayır'];
  const currencies = ['TL', 'USD', 'EURO'];
  const serviceOperations = ['Atölyeye Aldır', 'Bayiye Gönder', 'Müşteri Cihazı Atölyeye Getirdi', 'Müşteri İptal Etti', 'Teknisyen Yönlendir', 'Ürün Satışı Yapıldı', 'Cihaz Atölyeye Alındı', 'Cihaz Tamir Edilemiyor', 'Fiyatta Anlaşılamadı', 'Haber Verecek', 'Müşteriye Ulaşılamadı', 'Parça Takmak İçin Teknisyene Yönlendir', 'Parça Talep Et', 'Parçası Atölyeye Alındı', 'Ürün Garantili Çıktı', 'Yeniden Teknisyen Yönlendir', 'Yerinde Bakım Yapıldı', 'Fiyat Yükseltildi', 'Nakliye Gönder', 'Tahsilata Gönder', 'Atölyede Tamir Ediliyor', 'Nakliyede (Teslim Edilecek)', 'Cihaz Teslim Edilemedi (Arızalı)', 'Cihaz Teslim Edildi (Parça Takıldı)', 'Cihaz Teslim Edildi', 'Parça Teslim Et', 'Parça Hazır', 'Parça Siparişte', 'Teslimata Hazır (Tamamlandı)', 'Şikayetçi', 'Müşteri Para İade Edilecek', 'Müşteri Para İade Edildi', 'Servisi Sonlandır'];
  const warranties = [0, 6, 12];
  const customerTypes = ['Bireysel', 'Kurumsal'];
  const customerStatuses = ['Aktif', 'Aktif Değil', 'İzinli'];
  const userStatuses = ['Aktif', 'Aktif Değil', 'İzinli'];
  const offerStatuses = ['Beklemede', 'Onay', 'Red'];
  const recurrenceIntervalTypes = ['Sadece Bir Kere', 'Her Gün', 'Haftada Bir Bu Gün', 'Ayda Bir Bu Tarih', 'Yılda Bir Bu Tarih'];
  const reminderTimeOptions = ['Zamanında', 'Onbeş Dakika Önce', 'Bir Saat Önce', 'Bir Gün Önce', 'İki Gün Önce'];
  const surveyStatuses = ['Açık', 'İşlemde', 'Tamamlandı', 'Kapalı'];
  const answerOptions = ['Evet', 'Hayır', 'Belirsiz'];
  const caseactionTypes = ['Gelen Ödeme', 'Giden Ödeme'];
  const caseactionMethods = ['Nakit', 'EFT / Havale', 'Kredi Kartı'];
  const caseactionOperations = ['Depo Stok', 'Servis Para', 'Servis Parça', 'Maaş', 'Ofis', 'Kira', 'Prim', 'Google Reklam', 'Araç Bakım', 'Araç Yakıt', 'Mutfak / Yemek', 'Müşteri İade', 'Diğer / Muhtelif'];
  const caseactionOperationsForServices = ['Servis Para', 'Servis Parça', 'Müşteri İade'];
  const caseactionStatuses = ['Tamamlandı', 'Beklemede'];
  const witholdingRates = [
    { id: 601, name: 'Yapım işleri ile bu işlerle birlikte ifa edilen mühendislik-mimarlık ve etüt-proje hizmetleri', rate: 0.4 },
    { id: 602, name: 'Etüt, plan-proje, danışmanlık, denetim ve benzeri hizmetler', rate: 0.9 },
    { id: 603, name: 'Makine, teçhizat, demirbaş ve taşıtlara ait tadil, bakım ve onarım hizmetleri', rate: 0.7 },
    { id: 604, name: 'Yemek servis hizmeti', rate: 0.5 },
    { id: 605, name: 'Organizasyon hizmeti', rate: 0.5 },
    { id: 606, name: 'İşgücü temin hizmetleri', rate: 0.9 },
    { id: 607, name: 'Özel güvenlik hizmeti', rate: 0.9 },
    { id: 608, name: 'Yapı denetim hizmetleri', rate: 0.9 },
    { id: 609, name: 'Fason olarak yaptırılan tekstil ve konfeksiyon işleri, çanta ve ayakkabı dikim işleri ve bu işlere aracılık hizmetleri', rate: 0.7 },
    { id: 610, name: 'Turistik mağazalara verilen müşteri bulma / götürme hizmetleri', rate: 0.9 },
    { id: 611, name: 'Spor kulüplerinin yayın, reklâm ve isim hakkı gelirlerine konu işlemleri', rate: 0.9 },
    { id: 612, name: 'Temizlik hizmeti', rate: 0.9 },
    { id: 613, name: 'Çevre ve bahçe bakım hizmetleri', rate: 0.9 },
    { id: 614, name: 'Servis taşımacılığı hizmeti', rate: 0.5 },
    { id: 615, name: 'Her türlü baskı ve basım hizmetleri', rate: 0.7 },
    { id: 616, name: '5018 sayılı kanuna ekli cetvellerdeki idare, kurum ve kuruşlara yapılan diğer hizmetler', rate: 0.5 },
    { id: 617, name: 'Hurda metalden elde edilen külçe teslimleri', rate: 0.7 },
    { id: 618, name: 'Hurda metalden elde edilenler dışındaki bakır, çinko ve alüminyum külçe teslimleri', rate: 0.7 },
    { id: 619, name: 'Bakır, çinko ve alüminyum ürünlerinin teslimi', rate: 0.7 },
    { id: 620, name: 'İstisnadan vazgeçenlerin hurda ve atık teslimi', rate: 0.7 },
    { id: 621, name: 'Metal, plastik, lastik, kauçuk, kâğıt ve cam hurda ve atıklardan elde edilen hammadde teslimi', rate: 0.9 },
    { id: 622, name: 'Pamuk, tiftik, yün ve yapağı ile ham post ve deri teslimleri', rate: 0.9 },
    { id: 623, name: 'Ağaç ve orman ürünleri teslimi', rate: 0.5 },
    { id: 624, name: 'Yük taşımacılığı hizmeti', rate: 0.2 },
    { id: 625, name: 'Ticari reklam hizmetleri', rate: 0.3 },
    { id: 626, name: 'Diğer teslimler', rate: 0.2 },
    { id: 627, name: 'Demir-Çelik ürünlerinin teslimi', rate: 0.5 },
    { id: 801, name: 'Yapım İşleri ile Bu İşlerle Birlikte İfa Edilen Mühendislik-Mimarlık ve Etüt-Proje Hizmetleri', rate: 1 },
    { id: 802, name: 'Etüt, Plan-Proje, Danışmanlık, Denetim ve Benzeri Hizmetler', rate: 1 },
    { id: 803, name: 'Makine, Teçhizat, Demirbaş ve Taşıtlara Ait Tadil, Bakım ve Onarım Hizmetleri', rate: 1 },
    { id: 804, name: 'Yemek Servis Hizmeti', rate: 1 },
    { id: 805, name: 'Organizasyon Hizmeti', rate: 1 },
    { id: 806, name: 'İşgücü Temin Hizmetleri', rate: 1 },
    { id: 807, name: 'Özel Güvenlik Hizmeti', rate: 1 },
    { id: 808, name: 'Yapı Denetim Hizmetleri', rate: 1 },
    { id: 809, name: 'Fason Olarak Yaptırılan Tekstil ve Konfeksiyon İşleri, Çanta ve Ayakkabı Dikim İşleri ve Bu İşlere Aracılık Hizmetleri', rate: 1 },
    { id: 810, name: 'Turistik Mağazalara Verilen Müşteri Bulma/ Götürme Hizmetleri', rate: 1 },
    { id: 811, name: 'Spor Kulüplerinin Yayın, Reklâm ve İsim Hakkı Gelirlerine Konu İşlemleri', rate: 1 },
    { id: 812, name: 'Temizlik Hizmeti', rate: 1 },
    { id: 813, name: 'Çevre ve Bahçe Bakım Hizmetleri', rate: 1 },
    { id: 814, name: 'Servis Taşımacılığı Hizmeti', rate: 1 },
    { id: 815, name: 'Her Türlü Baskı ve Basım Hizmetleri	', rate: 1 },
    { id: 816, name: 'Hurda Metalden Elde Edilen Külçe Teslimleri', rate: 1 },
    { id: 817, name: 'Hurda Metalden Elde Edilenler Dışındaki Bakır, Çinko, Demir Çelik, Alüminyum ve Kurşun Külçe Teslimi', rate: 1 },
    { id: 818, name: 'Bakır, Çinko, Alüminyum ve Kurşun Ürünlerinin Teslimi', rate: 1 },
    { id: 819, name: 'İstisnadan Vazgeçenlerin Hurda ve Atık Teslimi', rate: 1 },
    { id: 820, name: 'Metal, Plastik, Lastik, Kauçuk, Kâğıt ve Cam Hurda ve Atıklardan Elde Edilen Hammadde Teslimi', rate: 1 },
    { id: 821, name: 'Pamuk, Tiftik, Yün ve Yapağı İle Ham Post ve Deri Teslimleri', rate: 1 },
    { id: 822, name: 'Ağaç ve Orman Ürünleri Teslimi', rate: 1 },
    { id: 823, name: 'Yük Taşımacılığı Hizmeti', rate: 1 },
    { id: 824, name: 'Ticari Reklam Hizmetleri', rate: 1 },
    { id: 825, name: 'Demir-Çelik Ürünlerinin Teslimi', rate: 1 },
  ];
  const kdvRates = [
    { id: 301, name: 'Mal ihracatı', rate: 0 },
    { id: 302, name: 'Hizmet ihracatı', rate: 0 },
    { id: 303, name: 'Roaming hizmetleri', rate: 0 },
    { id: 304, name: 'Deniz, hava ve demiryolu taşıma araçlarının teslimi ve inşa, tadil, bakım ve onarımları', rate: 0 },
    { id: 305, name: 'Deniz ve hava taşıma araçları için liman ve hava meydanlarında yapılan hizmetler', rate: 0 },
    { id: 306, name: 'Petrol aramaları ve petrol boru hatlarının inşa ve modernizasyonuna ilişkin teslim ve hizmetler', rate: 0 },
    { id: 307, name: 'Maden aramaları, altın, gümüş veya platin madenlerine ilişkin işlemler', rate: 0 },
    { id: 308, name: 'Teşvikli yatırım mallarının teslimi', rate: 0.18 },
    { id: 309, name: 'Liman ve hava meydanlarının inşası, yenilenmesi ve genişletilmesi', rate: 0 },
    { id: 310, name: 'Ulusal güvenlik amaçlı teslim ve hizmetler', rate: 0 },
    { id: 311, name: 'Uluslararası taşımacılık', rate: 0 },
    { id: 312, name: 'Diplomatik organ ve misyonlara yapılan teslim ve hizmetler', rate: 0 },
    { id: 313, name: 'Uluslararası kuruluşlara yapılan teslim ve hizmetler', rate: 0 },
    { id: 314, name: 'Uluslararası anlaşmalar kapsamındaki istisnalar', rate: 0 },
    { id: 315, name: 'İhraç konusu eşyayı taşıyan araçlara motorin teslimi', rate: 0 },
    { id: 316, name: 'Serbest bölgelerdeki müşteriler için yapılan fason hizmetler', rate: 0.18 },
    { id: 317, name: 'Engellilere yönelik eğitim ve meslek araç gereçleri teslimi', rate: 0 },
    { id: 318, name: 'Yap-işlet-devret projeleri kapsamında teslim ve hizmetler', rate: 0 },
    { id: 319, name: 'Başbakanlık Merkez Teşkilatına yapılan araç teslimleri', rate: 0.18 },
    { id: 320, name: 'İSMEP kapsamında yapılan teslim ve hizmetler', rate: 0.18 },
    { id: 321, name: 'BM ve NATO’ya yapılan teslim ve hizmetler', rate: 0 },
    { id: 322, name: 'Bavul ticareti', rate: 0 },
    { id: 323, name: 'Ürün senetlerinin ticaret borsaları aracılığıyla ilk teslimi', rate: 0.18 },
    { id: 324, name: 'Kızılay’a yapılan teslim ve hizmetler', rate: 0 },
    { id: 325, name: 'Yem teslimleri', rate: 0.01 },
    { id: 326, name: 'Gübrelerin teslimi', rate: 0.01 },
    { id: 327, name: 'Gübre hammaddelerinin gübre üreticilerine teslimi', rate: 0.01 },
    { id: 328, name: 'Konut veya işyeri teslimleri', rate: 0.08 },
    { id: 330, name: 'OSB ve küçük sanayi siteleri inşası', rate: 0.08 },
    { id: 331, name: 'Ar-Ge faaliyetleri için makina-teçhizat teslimi', rate: 0.18 },
    { id: 332, name: 'İmalat sanayiine yönelik teşvikli teslimler', rate: 0.18 },
    { id: 333, name: 'Kamuya yapılan bağışların inşaat işlemleri', rate: 0.18 },
    { id: 334, name: 'Yabancılara verilen sağlık hizmetleri', rate: 0.08 },
    { id: 335, name: 'Basılı kitap ve süreli yayınların teslimi', rate: 0.01 },
    { id: 336, name: 'UEFA müsabakaları kapsamındaki teslimler', rate: 0 },
    { id: 350, name: 'Diğerleri', rate: 0.18 },
    { id: 351, name: 'İstisna olmayan diğer işlemler', rate: 0.18 },
  ];
  const consigmentdeviceStatuses = ['Müsait', 'Kullanımda', 'Silindi'];
  const premiumStatuses = ['Ödendi', 'Ödenmedi'];
  // Export
  const values = { myUser, setMyUser, sidebar, setSidebar, dollar, setDollar, euro, setEuro, cities, statuses, booleans, currencies, serviceOperations, warranties, customerTypes, customerStatuses, userStatuses, offerStatuses, recurrenceIntervalTypes, reminderTimeOptions, surveyStatuses, answerOptions, caseactionTypes, caseactionMethods, caseactionOperations, caseactionOperationsForServices, caseactionStatuses, witholdingRates, kdvRates, consigmentdeviceStatuses, premiumStatuses }
  return <DataContext.Provider value={values}>{children}</DataContext.Provider>
}

const useData = () => useContext(DataContext);

export { DataProvider, useData };