// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllCustomers, getCustomerById, addNewCustomer, updateCustomer, deleteCustomer, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/CustomerSlice';
// Contexts
import { useData } from '../../../context/DataContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import CustomerAddModal from '../modals/customers/CustomerAddModal';
import CustomerUpdateModal from '../modals/customers/CustomerUpdateModal';
import CustomerDetailsModal from '../modals/customers/CustomerDetailsModal';
import CustomerDeleteModal from '../modals/DeleteModal';
// Icons
import { LuUserRoundPlus, LuUserRoundMinus, LuUserRoundCog, LuUserRoundPen } from "react-icons/lu";
import { TbPrinter } from 'react-icons/tb';

function Customers() {
  // Storage Import
  const dispatch = useDispatch();
  const { customers, customer, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.customer);
  // Context Import
  const { myUser } = useData();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllCustomers({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      const data = customers.map((data) => {
        const id = data.id;
        const name = data.name;
        const phone = data.phone1;
        const address = data.address;
        const district = data.district;
        const city = data.city;
        const addressString = `${address}\n${district}/${city}`;
        const addressDiv = (
          <div className='d-flex flex-column justify-content-start align-items-start'>
            <p className='m-0 p-0'>{address}</p>
            <p className='m-0 p-0'>{district}/{city}</p>
          </div>
        );
        return { id, name, phone, address, district, city, addressString, addressDiv };
      });
      setDataToTable(data.map(item => ({ id: item.id, name: item.name, phone: item.phone, address: item.addressDiv, searchAddress: item.addressString })));
      setDataToPDF(data.map(item => ({ id: item.id, name: item.name, phone: item.phone, address: item.addressString })));
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseCustomerAddModal();
      dispatch(addToast({ background: 'success', icon: 'customerAdd', message: 'Müşteri Eklendi!', delay: 6000 }));
      dispatch(getAllCustomers({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseCustomerUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'customerAdd', message: 'Müşteri Güncellendi!', delay: 6000 }));
      dispatch(getAllCustomers({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseCustomerDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'customerAdd', message: 'Müşteri Silindi!', delay: 6000 }));
      dispatch(getAllCustomers({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataCustomerUpdateModal(customer);
        setShowCustomerUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'details') {
        setInputDataCustomerDetailsModal(customer);
        setShowCustomerDetailsModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataCustomerDeleteModal(customer);
        setShowCustomerDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'MÜŞTERİ' }, { value: 'phone', name: 'TELEFON' }, { value: 'address', name: 'ADRES' }];
  const filters = ['id', 'name', 'phone', 'searchAddress'];
  const clickOn = (id) => { handleShowCustomerUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <LuUserRoundCog className='button-icon' />,
      onclick: ((rowData) => { handleShowCustomerUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Detaylar',
      icon: <LuUserRoundPen className='button-icon' />,
      onclick: ((rowData) => { handleShowCustomerDetailsModal(rowData.id); }),
      variant: 'info'
    },
    {
      text: 'Sil',
      icon: <LuUserRoundMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowCustomerDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Müşteriler';
  const operations = [
    {
      text: 'Müşteri Ekle',
      icon: <LuUserRoundPlus className='button-icon' />,
      onclick: (() => { handleShowCustomerAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showCustomerAddModal, setShowCustomerAddModal] = useState(false);
  const handleCloseCustomerAddModal = () => {
    setShowCustomerAddModal(false);
  }
  const handleShowCustomerAddModal = () => {
    setShowCustomerAddModal(true);
  }
  const handleAddCustomerAddModal = (values) => {
    dispatch(addNewCustomer({ newcustomer: values }));
  }
  // Update Modal
  const [showCustomerUpdateModal, setShowCustomerUpdateModal] = useState(false);
  const [inputDataCustomerUpdateModal, setInputDataCustomerUpdateModal] = useState(null);
  const handleCloseCustomerUpdateModal = () => {
    setShowCustomerUpdateModal(false);
    setInputDataCustomerUpdateModal(null);
  }
  const handleShowCustomerUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getCustomerById({ id: id }));
  }
  const handleUpdateCustomerUpdateModal = (values) => {
    dispatch(updateCustomer({ customer: values }));
  }
  // Details Modal
  const [showCustomerDetailsModal, setShowCustomerDetailsModal] = useState(false);
  const [inputDataCustomerDetailsModal, setInputDataCustomerDetailsModal] = useState(null);
  const handleCloseCustomerDetailsModal = () => {
    setShowCustomerDetailsModal(false);
    setInputDataCustomerDetailsModal(null);
  }
  const handleShowCustomerDetailsModal = (id) => {
    setDataRequester('details');
    dispatch(getCustomerById({ id: id }));
  }
  // Delete Modal
  const [showCustomerDeleteModal, setShowCustomerDeleteModal] = useState(false);
  const [inputDataCustomerDeleteModal, setInputDataCustomerDeleteModal] = useState(null);
  const handleCloseCustomerDeleteModal = () => {
    setShowCustomerDeleteModal(false);
    setInputDataCustomerDeleteModal(null);
  }
  const handleShowCustomerDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getCustomerById({ id: id }));
  }
  const handleDeleteCustomerDeleteModal = () => {
    dispatch(deleteCustomer({ id: inputDataCustomerDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <CustomerAddModal
        show={showCustomerAddModal}
        handleClose={handleCloseCustomerAddModal}
        handleAdd={handleAddCustomerAddModal}
      />
      <CustomerUpdateModal
        show={showCustomerUpdateModal}
        handleClose={handleCloseCustomerUpdateModal}
        handleUpdate={handleUpdateCustomerUpdateModal}
        inputData={inputDataCustomerUpdateModal}
      />
      <CustomerDetailsModal
        show={showCustomerDetailsModal}
        handleClose={handleCloseCustomerDetailsModal}
        inputData={inputDataCustomerDetailsModal}
      />
      <CustomerDeleteModal
        show={showCustomerDeleteModal}
        handleClose={handleCloseCustomerDeleteModal}
        handleDelete={handleDeleteCustomerDeleteModal}
        title="Müşteriyi Sil"
        message={`${inputDataCustomerDeleteModal?.name} adlı müşteriyi silmek istediğinize emin misiniz?`}
        deleteIcon={<LuUserRoundMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showCustomerAddModal, showCustomerUpdateModal, inputDataCustomerUpdateModal, showCustomerDetailsModal, inputDataCustomerDetailsModal, showCustomerDeleteModal, inputDataCustomerDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Customers;