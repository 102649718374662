// General
import { createSlice } from '@reduxjs/toolkit'

// Slice
const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    toasts: [],
  },
  reducers: {
    addToast: (state, action) => {
      const { background = '', icon = null, message = '', delay = 3000 } = action.payload;
      const id = Date.now();
      const newToast = { id, background, icon, message, delay, show: true };
      state.toasts.push(newToast);
    },
    removeToast: (state, action) => {
      const id = action.payload;
      const toast = state.toasts.find((toast) => toast.id === id);
      if (toast) { toast.show = false; }
    }
  },
});

export const { addToast, removeToast } = toastSlice.actions;
export default toastSlice.reducer;