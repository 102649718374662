// General
import React, { useEffect, useState } from 'react';
// Styles
import { Autocomplete, Chip, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { getServicesStatistics, getOperatorStatistics, getCaseActionIncomesStatistics, getCaseActionOutcomesStatistics, getServiceStatusStatistics, getServiceDistrictStatistics, resetServiceStatisticsStatus, resetOperatorStatisticsStatus, resetCaseActionIncomesStatisticsStatus, resetCaseActionOutcomesStatisticsStatus, resetServiceStatusStatisticsStatus, resetServiceDistrictStatisticsStatus, resetError } from '../../../storage/slices/backend/StatisticSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import SwitchButton from '../components/SwitchButton';
import Table from '../components/Table';
import PieChart from '../components/PieChart';
// Icons
import { TbPrinter, TbDatabaseShare } from "react-icons/tb";
import { TiDelete } from "react-icons/ti";

function Statistics() {
  // Storage Import
  const dispatch = useDispatch();
  const { serviceStatistics, serviceStatisticsStatus, operatorStatistics, operatorStatisticsStatus, caseActionIncomesStatistics, caseActionIncomesStatisticsStatus, caseActionOutcomesStatistics, caseActionOutcomesStatisticsStatus, serviceStatusStatistics, serviceStatusStatisticsStatus, serviceDistrictStatistics, serviceDistrictStatisticsStatus, error } = useSelector((state) => state.statistic);
  // Context Import
  const { myUser } = useData();
  const { getStringDate } = useFunctions();
  // Variables
  const [selectedTab, setSelectedTab] = useState('1');
  const [pageStyle, setPageStyle] = useState('tablo');
  const [startdate, setStartdate] = useState(getStringDate(7));
  const [enddate, setEnddate] = useState(getStringDate(0));
  const [dates, setDates] = useState({ start: getStringDate(7), end: getStringDate(0) });
  // Functions
  const handleChangeTab = (event, newValue) => { setSelectedTab(newValue); };
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal, start, end) => {
    try {
      await dispatch(getServicesStatistics({ signal, start, end }));
      await dispatch(getOperatorStatistics({ signal, start, end }));
      await dispatch(getCaseActionIncomesStatistics({ signal, start, end }));
      await dispatch(getCaseActionOutcomesStatistics({ signal, start, end }));
      await dispatch(getServiceStatusStatistics({ signal, start, end }));
      await dispatch(getServiceDistrictStatistics({ signal, start, end }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal, dates.start, dates.end); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch, dates]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (serviceStatisticsStatus) {
      const stats = {
        brands: serviceStatistics.filter(item => item.category === 'Brand').map(item => ({ brand: item.name, total: item.serviceCountValue })),
        devices: serviceStatistics.filter(item => item.category === 'Device').map(item => ({ device: item.name, total: item.serviceCountValue })),
        sources: serviceStatistics.filter(item => item.category === 'Source').map(item => ({ source: item.name, total: item.serviceCountValue })),
        users: serviceStatistics.filter(item => item.category === 'User').map(item => ({ user: item.name, total: item.serviceCountValue }))
      }
      setServiceStats(stats);
      dispatch(resetServiceStatisticsStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceStatisticsStatus]);
  useEffect(() => {
    if (serviceStatusStatisticsStatus === 'fulfilled') {
      setServiceStatusData(serviceStatusStatistics);
      dispatch(resetServiceStatusStatisticsStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceStatusStatisticsStatus]);
  useEffect(() => {
    if (serviceDistrictStatisticsStatus === 'fulfilled') {
      setServiceDistrictData(serviceDistrictStatistics);
      dispatch(resetServiceDistrictStatisticsStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceDistrictStatisticsStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Data Operations
  // Service Data
  const [serviceStats, setServiceStats] = useState({ brands: [], devices: [], sources: [], users: [] });
  const [selectedServiceDataForTableName, setSelectedServiceDataForTableName] = useState('Markalar');
  const [selectedServiceDataForTable, setSelectedServiceDataForTable] = useState(serviceStats.brands);
  const dictionaryOfServiceDataForTable = {
    brand: 'Marka',
    device: 'Cihaz',
    source: 'Kaynak',
    user: 'Operatör'
  }
  const dictionaryOfServiceDataForCharts = {
    'Markalar': 'brands',
    'Cihazlar': 'devices',
    'Kaynaklar': 'sources',
    'Operatörler': 'users'
  }
  useEffect(() => {
    if (selectedServiceDataForTableName === 'Markalar') {
      setSelectedServiceDataForTable(serviceStats.brands);
    } else if (selectedServiceDataForTableName === 'Cihazlar') {
      setSelectedServiceDataForTable(serviceStats.devices);
    } else if (selectedServiceDataForTableName === 'Kaynaklar') {
      setSelectedServiceDataForTable(serviceStats.sources);
    } else if (selectedServiceDataForTableName === 'Operatörler') {
      setSelectedServiceDataForTable(serviceStats.users);
    }
  }, [selectedServiceDataForTableName, serviceStats]);
  const [selectedServiceDataForCharts, setSelectedServiceDataForCharts] = useState([]);
  // Employee Data
  // Opertor Data
  // Checkout Data
  // Status Data
  const [serviceStatusData, setServiceStatusData] = useState([]);
  // Warehouse Data
  // District Data
  const [serviceDistrictData, setServiceDistrictData] = useState([]);
  // HTML
  return (
    <div className='d-flex justify-content-center w-100 mt-5'>
      <div className='card w-100'>
        <div className='card-header m-0 p-1'>
          <div className='row g-2 p-2'>
            <div className='col-12 col-md-9'>
              <div className='card-title m-0 ms-2 p-0'>İstatistikler</div>
            </div>
            <div className='col-12 col-md-3'>
              <button type="button" className="button-with-icon btn btn-success w-100">
                <TbPrinter className='button-icon' />
                Detaylı İstatistik
              </button>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='row g-2'>
            <div className='col-12 col-md-3 d-flex justify-content-center align-items-center'>
              <SwitchButton checked={pageStyle === 'grafik' ? true : false} change={() => setPageStyle(pageStyle === 'grafik' ? 'tablo' : 'grafik')} labelOn='Grafik' labelOff='Tablo' />
            </div>
            <div className='col-6 col-md-3'>
              <TextField
                label='Başlangıç Tarihi'
                type='date'
                value={startdate}
                onChange={(e) => { setStartdate(e.target.value); }}
                slotProps={{ input: { shrink: "true" } }}
                variant='outlined'
                size='small'
                fullWidth
              />
            </div>
            <div className='col-6 col-md-3'>
              <TextField
                label='Başlangıç Tarihi'
                type='date'
                value={enddate}
                onChange={(e) => { setEnddate(e.target.value); }}
                slotProps={{ input: { shrink: "true" } }}
                variant='outlined'
                size='small'
                fullWidth
              />
            </div>
            <div className='col-12 col-md-3'>
              <button type='button' className='button-with-icon btn btn-primary w-100' onClick={() => setDates({ start: startdate, end: enddate })}>
                <TbDatabaseShare className='button-icon' />
                Verileri Getir
              </button>
            </div>
          </div>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab}>
                <Tab label='SERVİS İST.' value='1' />
                <Tab label='TEKNİSYEN İST.' value='2' />
                <Tab label='OPERATÖR İST.' value='3' />
                <Tab label='KASA İST.' value='4' />
                <Tab label='DURUM İST.' value='5' />
                <Tab label='DEPO İST.' value='6' />
                <Tab label='İLÇE İST.' value='7' />
              </TabList>
            </Box>
            <TabPanel value="1">
              {pageStyle === 'tablo' ? (
                <div className='row g-2'>
                  <div className='col-6'>
                    <h5>Servis İstatistikleri</h5>
                  </div>
                  <div className='col-6'>
                    <Autocomplete
                      value={selectedServiceDataForTableName}
                      onChange={(event, newValue) => { newValue ? setSelectedServiceDataForTableName(newValue) : setSelectedServiceDataForTableName('Markalar') }}
                      variant='outlined'
                      size='small'
                      options={['Markalar', 'Cihazlar', 'Kaynaklar', 'Operatörler']}
                      renderInput={(params) => <TextField {...params} label="Gösterilecek Veri" />}
                    />
                  </div>
                  <div className='col-12'>
                    <Table
                      data={selectedServiceDataForTable}
                      rows={10}
                      columns={selectedServiceDataForTable.length > 0 ? [{ value: Object.keys(selectedServiceDataForTable[0])[0], name: dictionaryOfServiceDataForTable[Object.keys(selectedServiceDataForTable[0])[0]] }, { value: 'total', name: 'Toplam' }] : []}
                      filters={selectedServiceDataForTable.length > 0 ? [Object.keys(selectedServiceDataForTable[0])[0], 'total'] : []}
                      filterfullwidth={true}
                      paginatorColor='bg-light'
                      headerAlign='center'
                    />
                  </div>
                </div>
              ) : (
                <div className='row g-2'>
                  <div className='col-6'>
                    <h5>Servis İstatistikleri</h5>
                  </div>
                  <div className='col-6'>
                    <Autocomplete
                      id='selectedServiceDatas'
                      name='selectedServiceDatas'
                      multiple
                      limitTags={2}
                      options={['Markalar', 'Cihazlar', 'Kaynaklar', 'Operatörler']}
                      size='small'
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={selectedServiceDataForCharts}
                      onChange={(event, newValue) => { setSelectedServiceDataForCharts(newValue); }}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={option}
                              label={option}
                              size='small'
                              {...tagProps}
                              deleteIcon={
                                <TiDelete
                                  type='button'
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const newValues = selectedServiceDataForCharts.filter((i) => i !== option);
                                    setSelectedServiceDataForCharts(newValues);
                                  }}
                                />
                              }
                            />
                          );
                        })
                      }
                      renderInput={(params) => <TextField {...params} label="Gösterilecek Veriler" />}
                    />
                  </div>
                  <div className='col-12'>
                    <div className='row g-2'>
                      {selectedServiceDataForCharts.length === 0 ? (
                        <>
                          {['Markalar', 'Cihazlar', 'Kaynaklar', 'Operatörler'].map((chart, index) => {
                            return (
                              <div key={index} className='col-12 col-md-6'>
                                <h5>{chart}</h5>
                                <PieChart data={serviceStats[dictionaryOfServiceDataForCharts[chart]].map(item => ({ name: item[(Object.keys(item)[0])], value: item.total }))} unit='adet' />
                              </div>
                            )
                          })}
                        </>
                      ) : selectedServiceDataForCharts.length === 1 ? (
                        <div className='col-12'>
                          <h5>{selectedServiceDataForCharts[0]}</h5>
                          <PieChart data={serviceStats[dictionaryOfServiceDataForCharts[selectedServiceDataForCharts[0]]].map(item => ({ name: item[(Object.keys(item)[0])], value: item.total }))} unit='adet' />
                        </div>
                      ) : selectedServiceDataForCharts.length > 1 && (
                        <>
                          {selectedServiceDataForCharts.map((chart, index) => {
                            return (
                              <div key={index} className='col-12 col-md-6'>
                                <h5>{chart}</h5>
                                <PieChart data={serviceStats[dictionaryOfServiceDataForCharts[chart]].map(item => ({ name: item[(Object.keys(item)[0])], value: item.total }))} unit='adet' />
                              </div>
                            )
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </TabPanel>
            <TabPanel value="2">
              <div className='d-flex justify-content-center align-items-center'>
                <h5 className='p-0 m-0'>Bu veri henüz hazır değil!</h5>
              </div>
            </TabPanel>
            <TabPanel value="3">
              <div className='d-flex justify-content-center align-items-center'>
                <h5 className='p-0 m-0'>Bu veri şu anda gösterilmeye uygun değil!</h5>
              </div>
            </TabPanel>
            <TabPanel value="4">
              <div className='d-flex justify-content-center align-items-center'>
                <h5 className='p-0 m-0'>Bu veri şu anda gösterilmeye uygun değil!</h5>
              </div>
            </TabPanel>
            <TabPanel value="5">
              <div className='row g-2'>
                <div className='col-6'>
                  <div className='col-6'>
                    <h5>Durum İstatistikleri</h5>
                  </div>
                </div>
                <div className='col-6'></div>
                <div className='col-12'>
                  {pageStyle === 'tablo' ? (
                    <Table
                      data={serviceStatusData}
                      rows={10}
                      columns={[{ value: 'actionTypeName', name: 'Durum' }, { value: 'count', name: 'Toplam' }]}
                      filters={['actionTypeName', 'count']}
                      filterfullwidth={true}
                      paginatorColor='bg-light'
                      headerAlign='center'
                    />
                  ) : (
                    <PieChart
                      data={serviceStatusData.map(item => ({ name: item.actionTypeName, value: item.count }))}
                      unit='adet'
                    />
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel value="6">
              <div className='d-flex justify-content-center align-items-center'>
                <h5 className='p-0 m-0'>Bu veri henüz hazır değil!</h5>
              </div>
            </TabPanel>
            <TabPanel value="7">
              <div className='row g-2'>
                <div className='col-6'>
                  <div className='col-6'>
                    <h5>İlçe İstatistikleri</h5>
                  </div>
                </div>
                <div className='col-6'></div>
                <div className='col-12'>
                  {pageStyle === 'tablo' ? (
                    <Table
                      data={serviceDistrictData}
                      rows={10}
                      columns={[{ value: 'district', name: 'İlçe' }, { value: 'serviceCount', name: 'Toplam' }]}
                      filters={['district', 'serviceCount']}
                      filterfullwidth={true}
                      paginatorColor='bg-light'
                      headerAlign='center'
                    />
                  ) : (
                    <PieChart
                      data={serviceDistrictData.map(item => ({ name: item.district, value: item.serviceCount }))}
                      unit='adet'
                    />
                  )}
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
}

export default Statistics;