// General
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllServicesByDate, getAllServicesByOperator, getAllServicesByTecnician, getAllServicesByWorkshopTaken, getAllServicesByWorkshopMaster, getAllServicesByRepairedInWorkshop, getAllServicesBySendShipping, getAllServicesByInTransit, getAllServicesByDelivered, getAllServicesByWaitingParts, getAllServicesByProductSold, getAllServicesByConsigmentDevice, getAllServicesByDeletedService, getAllServicesByIncomingCalls, getAllServicesByStage, getAllServicesByDealer, getAllServicesByEmergency, getServiceById, addNewService, updateService, deleteService, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/ServiceSlice';
import { getAllServicesForPlan } from '../../../storage/slices/backend/ServiceplanSlice';
import { getAllCustomers } from '../../../storage/slices/backend/CustomerSlice';
import { getAllEmployees } from '../../../storage/slices/backend/EmployeeSlice';
import { getAllStocks } from '../../../storage/slices/backend/StockSlice';
import { getAllVehicles } from '../../../storage/slices/backend/VehicleSlice';
import { getAllBrands } from '../../../storage/slices/backend/BrandSlice';
import { getAllDevices } from '../../../storage/slices/backend/DeviceSlice';
import { getAllDealers } from '../../../storage/slices/backend/DealerSlice';
import { getAllSources } from '../../../storage/slices/backend/SourceSlice';
import { getAllConsigmentdevices } from '../../../storage/slices/backend/ConsigmentdeviceSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import ServiceAddModal from '../modals/services/ServiceAddModal';
import ServicePlannerModal from '../modals/services/ServicePlannerModal';
import ServiceIncomingCallsModal from '../modals/services/ServiceIncomingCallsModal';
import PremiumCalculatorModal from '../modals/services/PremiumCalculatorModal';
import ServiceReportsModal from '../modals/services/ServiceReportsModal';
import ServiceDetailsModal from '../modals/services/ServiceDetailsModal';
import ServiceDeleteModal from '../modals/DeleteModal';
// Icons
import { TbDatabasePlus, TbDatabaseMinus, TbDatabaseCog, TbPrinter, TbReportAnalytics } from 'react-icons/tb';
import { GrPlan } from "react-icons/gr";
import { LuPhoneIncoming } from "react-icons/lu";
import { HiOutlineBanknotes } from "react-icons/hi2";
// Images
import alarm from '../../../assets/images/alarm.gif';

function Services() {
  // Storage Import
  const dispatch = useDispatch();
  const { services, service, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.service);
  // Context Import
  const { myUser } = useData();
  const { stringDate, stringDateFromText, getStringDate } = useFunctions();
  // Variables
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramsReport = queryParams.get('report');
  const paramsSearch = queryParams.get('search');
  const paramsStartdate = queryParams.get('startdate');
  const paramsEnddate = queryParams.get('enddate');
  const paramsDate = queryParams.get('date');
  const paramsName = queryParams.get('employee')?.replace(' ', '+');
  const paramsBrand = queryParams.get('brand')?.replace(' ', '+');
  const paramsDevice = queryParams.get('device')?.replace(' ', '+');
  const paramsVehicle = queryParams.get('vehicle')?.replace(' ', '+');
  const paramsSource = queryParams.get('source')?.replace(' ', '+');
  const paramsStage = queryParams.get('stage')?.replace(' ', '+');
  const paramsDealer = queryParams.get('deaeler')?.replace(' ', '+');
  const paramsHelper = queryParams.get('helperemployee')?.replace(' ', '+');
  const hasNoParams = !Array.from(queryParams.keys()).length;
  const [isDate, setIsDate] = useState(true);
  const [dates, setDates] = useState({ start: paramsStartdate ? paramsStartdate : paramsDate ? paramsDate : getStringDate(7), end: paramsEnddate ? paramsEnddate : paramsDate ? paramsDate : getStringDate(0) })
  // Functions
  useEffect(() => {
    if (paramsReport) {
      if (queryParams.get('startdate') === null && queryParams.get('enddate') === null && queryParams.get('date') === null && !hasNoParams) {
        setIsDate(false);
      } else { setIsDate(true); }
    } else { setIsDate(true); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsReport]);
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const getAllServices = async (style, values) => {
    if (style === 'report') {
      try {
        if (paramsReport === '1') { await dispatch(getAllServicesByOperator({ signal: values.signal, start: values.start, end: values.end, name: paramsName })); }
        else if (paramsReport === '2') { await dispatch(getAllServicesByTecnician({ signal: values.signal, date: values.start, plate: paramsVehicle, id: paramsName, assistant: paramsHelper })); }
        else if (paramsReport === '3') { await dispatch(getAllServicesByWorkshopTaken({ signal: values.signal, start: values.start, end: values.end, id: paramsName, device: paramsDevice })); }
        else if (paramsReport === '4') { await dispatch(getAllServicesByWorkshopMaster({ signal: values.signal, date: values.start, id: paramsName })); }
        else if (paramsReport === '5') { await dispatch(getAllServicesByRepairedInWorkshop({ signal: values.signal, date: values.start, id: paramsName })); }
        else if (paramsReport === '6') { await dispatch(getAllServicesBySendShipping({ signal: values.signal, date: values.start, id: paramsName })); }
        else if (paramsReport === '7') { await dispatch(getAllServicesByInTransit({ signal: values.signal, date: values.start, id: paramsName })); }
        else if (paramsReport === '8') { await dispatch(getAllServicesByDelivered({ signal: values.signal, date: values.start, id: paramsName })); }
        else if (paramsReport === '9') { await dispatch(getAllServicesByWaitingParts({ signal: values.signal, date: values.start })); }
        else if (paramsReport === '10') { await dispatch(getAllServicesByProductSold({ signal: values.signal, start: values.start, end: values.end })); }
        else if (paramsReport === '11') { await dispatch(getAllServicesByConsigmentDevice({ signal: values.signal, brand: paramsBrand })); }
        else if (paramsReport === '12') { await dispatch(getAllServicesByDeletedService({ signal: values.signal, start: values.start, end: values.end })); }
        // Silinen Para Hareketler: 13 buraya gelecek
        else if (paramsReport === '14') { await dispatch(getAllServicesByIncomingCalls({ signal: values.signal, start: values.start, end: values.end, id: paramsName, source: paramsSource, brand: paramsBrand })); }
        else if (paramsReport === '15') { await dispatch(getAllServicesByStage({ signal: values.signal, start: values.start, end: values.end, stage: paramsStage })); }
        else if (paramsReport === '16') { await dispatch(getAllServicesByDealer({ signal: values.signal, start: values.start, end: values.end, dealer: paramsDealer })); }
        else if (paramsReport === '17') { await dispatch(getAllServicesByEmergency({ signal: values.signal, start: values.start, end: values.end })); }
        else { await dispatch(getAllServicesByDate({ signal: values.signal, start: values.start, end: values.end })); }
      } catch (error) {
        await dispatch(getAllServicesByDate({ signal: values.signal, start: values.start, end: values.end }));
      }
    }
    else { await dispatch(getAllServicesByDate({ signal: values.signal, start: values.start, end: values.end })); }
  }
  const fetchAllDataSequentially = async (dispatch, signal, start, end) => {
    try {
      if (paramsReport) { await getAllServices('report', { signal }); }
      else { await getAllServices('none', { signal, start, end }); }
      await dispatch(getAllCustomers({ signal }));
      await dispatch(getAllEmployees({ signal }));
      await dispatch(getAllStocks({ signal }));
      await dispatch(getAllVehicles({ signal }));
      await dispatch(getAllBrands({ signal }));
      await dispatch(getAllDevices({ signal }));
      await dispatch(getAllDealers({ signal }));
      await dispatch(getAllSources({ signal }));
      await dispatch(getAllConsigmentdevices({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal, dates.start, dates.end); }
    return () => { controller.abort(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canfetchData, dates]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      const data = services.map((data) => {
        const id = data.id;
        const date = `${stringDate(data.date.slice(0, 10))} ${data.date.slice(11, data.date.length)}`;
        const emergency = data.isEmergency;
        const name = data.name;
        const phone1 = data.phone1;
        const phone2 = data.phone2;
        const address = data.address;
        const district = data.district;
        const city = data.city;
        const customerString = `${name} - ${phone1}${phone2 ? ` - ${phone2}` : ''}\n${address}\n${district}/${city}`;
        const customerDiv = (
          <div className='d-flex flex-column text-start justify-content-start align-items-start'>
            <p className='m-0 p-0'>{emergency ? (<img src={alarm} alt='Acil' style={{ width: '1.5em', height: '1.5em', marginRight: '0.0em', paddingBottom: '0.2em', transform: 'rotate(180deg)' }} />) : ''}{name}</p>
            <p className='m-0 p-0'>{phone1}{phone2 ? ` - ${phone2}` : ''}</p>
            <p className='m-0 p-0'>{address}</p>
            <p className='m-0 p-0'>{district}/{city}</p>
          </div>
        );
        const brand = data.brandName;
        const device = data.deviceName;
        const deviceString = `${brand}/${device}`
        const statusString = data.serviceAction;
        const [statusName, statusDesc] = data.serviceAction.split('\n');
        const statusDiv = (
          <div className='text-start'>
            <p className='p-0 m-0'>{statusName}</p>
            <p className='p-0 m-0'>{stringDateFromText(statusDesc)}</p>
          </div>
        )
        return { id, date, customerString, customerDiv, deviceString, statusDiv, statusString };
      });
      setDataToTable(data.map(item => ({ id: item.id, date: item.date, customer: item.customerDiv, customerSearch: item.customerString, device: item.deviceString, status: item.statusDiv, statusSearch: item.statusString })));
      setDataToPDF(data.map(item => ({ id: item.id, date: item.date, customer: item.customerString, device: item.deviceString, status: item.statusString })));
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseServiceAddModal();
      dispatch(addToast({ background: 'success', icon: 'serviceAdd', message: 'Servis Eklendi!', delay: 6000 }));
      if (paramsReport) { getAllServices('report', { signal: null }); }
      else { getAllServices('none', { signal: null, start: dates.start, end: dates.end }); }
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseServiceDetailsModal();
      dispatch(addToast({ background: 'primary', icon: 'serviceUpdate', message: 'Servis Güncellendi!', delay: 6000 }));
      if (paramsReport) { getAllServices('report', { signal: null }); }
      else { getAllServices('none', { signal: null, start: dates.start, end: dates.end }); }
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseServiceDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'serviceDelete', message: 'Servis Silindi!', delay: 6000 }));
      if (paramsReport) { getAllServices('report', { signal: null }); }
      else { getAllServices('none', { signal: null, start: dates.start, end: dates.end }); }
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'details') {
        setInputDataServiceDetailsModal(service);
        setShowServiceDetailsModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataServiceDeleteModal(service);
        setShowServiceDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'customer', name: 'MÜŞTERİ' }, { value: 'device', name: 'CİHAZ' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'date', 'customerSearch', 'device', 'statusSearch'];
  const changeDates = (startDate, endDate) => { setDates({ start: startDate, end: endDate }) }
  const clickOn = (id) => { handleShowServiceDetailsModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <TbDatabaseCog className='button-icon' />,
      onclick: ((rowData) => { handleShowServiceDetailsModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <TbDatabaseMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowServiceDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Servisler';
  const operations = [
    {
      text: 'Servis Ekle',
      icon: <TbDatabasePlus className='button-icon' />,
      onclick: (() => { handleShowServiceAddModal(); })
    },
    {
      text: 'Servis Planla',
      icon: <GrPlan className='button-icon' />,
      onclick: (() => { handleShowServicePlannerModal(); })
    },
    {
      text: 'Gelen Çağrı Ekle',
      icon: <LuPhoneIncoming className='button-icon' />,
      onclick: (() => { handleShowServiceIncomingCallsModal(); })
    },
    {
      text: 'Prim Hesapla',
      icon: <HiOutlineBanknotes className='button-icon' />,
      onclick: (() => { handleShowPremiumCalculatorModal(); })
    },
    {
      text: 'Servis Raporları',
      icon: <TbReportAnalytics className='button-icon' />,
      onclick: (() => { handleShowServiceReportsModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        filter={paramsSearch ? paramsSearch : null}
        changeDates={isDate ? changeDates : null}
        dates={isDate ? dates : null}
        singledate={paramsDate && isDate ? true : false}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showServiceAddModal, setShowServiceAddModal] = useState(false);
  const handleCloseServiceAddModal = () => {
    setShowServiceAddModal(false);
  }
  const handleShowServiceAddModal = () => {
    setShowServiceAddModal(true);
  }
  const handleAddServiceAddModal = (values) => {
    dispatch(addNewService({ newservice: values }));
  }
  // Planner Modal
  const [showServicePlannerModal, setShowServicePlannerModal] = useState(false);
  const handleCloseServicePlannerModal = () => { setShowServicePlannerModal(false); }
  const handleShowServicePlannerModal = async () => {
    await dispatch(getAllServicesForPlan({ signal: null }));
    setShowServicePlannerModal(true);
  }
  // Incoming Calls Modal
  const [showServiceIncomingCallsModal, setShowServiceIncomingCallsModal] = useState(false);
  const handleCloseServiceIncomingCallsModal = () => { setShowServiceIncomingCallsModal(false); };
  const handleShowServiceIncomingCallsModal = () => { setShowServiceIncomingCallsModal(true); };
  const handleSaveServiceIncomingCallsModal = (values) => {
    console.log(values);
  }
  // Calculator Modal
  const [showPremiumCalculatorModal, setShowPremiumCalculatorModal] = useState(false);
  const handleClosePremiumCalculatorModal = () => { setShowPremiumCalculatorModal(false); }
  const handleShowPremiumCalculatorModal = () => { setShowPremiumCalculatorModal(true); }
  // Reports Modal
  const [showServiceReportsModal, setShowServiceReportsModal] = useState(false);
  const handleCloseServiceReportsModal = () => { setShowServiceReportsModal(false); }
  const handleShowServiceReportsModal = () => { setShowServiceReportsModal(true); }
  // Details Modal
  const [showServiceDetailsModal, setShowServiceDetailsModal] = useState(false);
  const [inputDataServiceDetailsModal, setInputDataServiceDetailsModal] = useState(null);
  const handleCloseServiceDetailsModal = () => {
    setShowServiceDetailsModal(false);
    setInputDataServiceDetailsModal(null);
  }
  const handleShowServiceDetailsModal = (id) => {
    setDataRequester('details');
    dispatch(getServiceById({ id: id }));
  }
  const handleUpdateServiceDetailsModal = (values) => {
    dispatch(updateService({ service: values }));
  }
  const reloadData = () => {
    handleCloseServiceDetailsModal();
    if (paramsReport) { getAllServices('report', { signal: null }); }
    else { getAllServices('none', { signal: null, start: dates.start, end: dates.end }); }
  }
  // Delete Modal
  const [showServiceDeleteModal, setShowServiceDeleteModal] = useState(false);
  const [inputDataServiceDeleteModal, setInputDataServiceDeleteModal] = useState(null);
  const handleCloseServiceDeleteModal = () => {
    setShowServiceDeleteModal(false);
    setInputDataServiceDeleteModal(null);
  }
  const handleShowServiceDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getServiceById({ id: id }));
  }
  const handleDeleteServiceDeleteModal = () => {
    dispatch(deleteService({ id: inputDataServiceDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <ServiceAddModal
        show={showServiceAddModal}
        handleClose={handleCloseServiceAddModal}
        handleAdd={handleAddServiceAddModal}
      />
      <ServiceIncomingCallsModal
        show={showServiceIncomingCallsModal}
        handleClose={handleCloseServiceIncomingCallsModal}
        handleSave={handleSaveServiceIncomingCallsModal}
      />
      <PremiumCalculatorModal
        show={showPremiumCalculatorModal}
        handleClose={handleClosePremiumCalculatorModal}
      />
      <ServiceReportsModal
        show={showServiceReportsModal}
        handleClose={handleCloseServiceReportsModal}
      />
      <ServicePlannerModal
        show={showServicePlannerModal}
        handleClose={handleCloseServicePlannerModal}
      />
      <ServiceDetailsModal
        show={showServiceDetailsModal}
        handleClose={handleCloseServiceDetailsModal}
        handleUpdate={handleUpdateServiceDetailsModal}
        inputData={inputDataServiceDetailsModal}
        reload={reloadData}
      />
      <ServiceDeleteModal
        show={showServiceDeleteModal}
        handleClose={handleCloseServiceDeleteModal}
        handleDelete={handleDeleteServiceDeleteModal}
        title="Servisi Sil"
        message={`${inputDataServiceDeleteModal?.id} id numaralı servisi silmek istediğinize emin misiniz?`}
        deleteIcon={<TbDatabaseMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showServiceAddModal, showServiceIncomingCallsModal, showPremiumCalculatorModal, showServiceReportsModal, showServicePlannerModal, showServiceDetailsModal, inputDataServiceDetailsModal, showServiceDeleteModal, inputDataServiceDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Services;