// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllQuestions, getQuestionById, addNewQuestion, updateQuestion, deleteQuestion, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/SurveyquestionSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import SurveyquestionAddModal from '../modals/surveyquestions/SurveyquestionAddModal';
import SurveyquestionUpdateModal from '../modals/surveyquestions/SurveyquestionUpdateModal';
import SurveyquestionDeleteModal from '../modals/DeleteModal';
// Icons
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline, IoMdInformationCircleOutline } from 'react-icons/io';
import { TbPrinter } from "react-icons/tb";

function Surveyquestions() {
  // Storage Import
  const dispatch = useDispatch();
  const { questions, question, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.surveyquestion);
  // Context Import
  const { myUser } = useData();
  const { dictionaryStatuses } = useFunctions();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllQuestions({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      setDataToTable(questions.map(item => ({ ...item, status: dictionaryStatuses[item.status] })));
      setDataToPDF(questions.map(item => ({ ...item, status: dictionaryStatuses[item.status] })));
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseSurveyquestionAddModal();
      dispatch(addToast({ background: 'success', icon: 'surveyquestionAdd', message: 'Soru Eklendi!', delay: 6000 }));
      dispatch(getAllQuestions({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseSurveyquestionUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'surveyquestionUpdate', message: 'Soru Güncellendi!', delay: 6000 }));
      dispatch(getAllQuestions({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseSurveyquestionDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'surveyquestionDelete', message: 'Soru Silindi!', delay: 6000 }));
      dispatch(getAllQuestions({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataSurveyquestionUpdateModal({ ...question, status: dictionaryStatuses[question.status] });
        setShowSurveyquestionUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataSurveyquestionDeleteModal({ ...question, status: dictionaryStatuses[question.status] });
        setShowSurveyquestionDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'text', name: 'SORU' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'text', 'status'];
  const clickOn = (id) => { handleShowSurveyquestionUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <IoMdInformationCircleOutline className='button-icon' />,
      onclick: ((rowData) => { handleShowSurveyquestionUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <IoMdRemoveCircleOutline className='button-icon' />,
      onclick: ((rowData) => { handleShowSurveyquestionDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Anket Soruları';
  const operations = [
    {
      text: 'Anket Sorusu Ekle',
      icon: <IoMdAddCircleOutline className='button-icon' />,
      onclick: (() => { handleShowSurveyquestionAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showSurveyquestionAddModal, setShowSurveyquestionAddModal] = useState(false);
  const handleCloseSurveyquestionAddModal = () => {
    setShowSurveyquestionAddModal(false);
  }
  const handleShowSurveyquestionAddModal = () => {
    setShowSurveyquestionAddModal(true);
  }
  const handleAddSurveyquestionAddModal = (values) => {
    dispatch(addNewQuestion({ newquestion: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Update Modal
  const [showSurveyquestionUpdateModal, setShowSurveyquestionUpdateModal] = useState(false);
  const [inputDataSurveyquestionUpdateModal, setInputDataSurveyquestionUpdateModal] = useState(null);
  const handleCloseSurveyquestionUpdateModal = () => {
    setShowSurveyquestionUpdateModal(false);
    setInputDataSurveyquestionUpdateModal(null);
  }
  const handleShowSurveyquestionUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getQuestionById({ id: id }));
  }
  const handleUpdateSurveyquestionUpdateModal = (values) => {
    dispatch(updateQuestion({ question: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Delete Modal
  const [showSurveyquestionDeleteModal, setShowSurveyquestionDeleteModal] = useState(false);
  const [inputDataSurveyquestionDeleteModal, setInputDataSurveyquestionDeleteModal] = useState(null);
  const handleCloseSurveyquestionDeleteModal = () => {
    setShowSurveyquestionDeleteModal(false);
    setInputDataSurveyquestionDeleteModal(null);
  }
  const handleShowSurveyquestionDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getQuestionById({ id: id }));
  }
  const handleDeleteSurveyquestionDeleteModal = () => {
    dispatch(deleteQuestion({ id: inputDataSurveyquestionDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <SurveyquestionAddModal
        show={showSurveyquestionAddModal}
        handleClose={handleCloseSurveyquestionAddModal}
        handleAdd={handleAddSurveyquestionAddModal}
      />
      <SurveyquestionUpdateModal
        show={showSurveyquestionUpdateModal}
        handleClose={handleCloseSurveyquestionUpdateModal}
        handleUpdate={handleUpdateSurveyquestionUpdateModal}
        inputData={inputDataSurveyquestionUpdateModal}
      />
      <SurveyquestionDeleteModal
        show={showSurveyquestionDeleteModal}
        handleClose={handleCloseSurveyquestionDeleteModal}
        handleDelete={handleDeleteSurveyquestionDeleteModal}
        title="Anket Sorusunu Sil"
        message={`${inputDataSurveyquestionDeleteModal?.text} anket sorusunu silmek istediğinize emin misiniz?`}
        deleteIcon={<IoMdRemoveCircleOutline className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showSurveyquestionAddModal, showSurveyquestionUpdateModal, inputDataSurveyquestionUpdateModal, showSurveyquestionDeleteModal, inputDataSurveyquestionDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Surveyquestions;