// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Suppliers/';

// Async Thunks
export const getAllSuppliers = createAsyncThunk('backend_getallsuppliers', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllSuppliers`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getSupplierById = createAsyncThunk('backend_getsupplierbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetSupplierById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewSupplier = createAsyncThunk('backend_addnewsupplier', async ({ newsupplier }) => {
  try {
    const res = await axios.post(`${baseurl}AddSupplier`, newsupplier, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateSupplier = createAsyncThunk('backend_updatesupplier', async ({ supplier }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateSupplier`, supplier, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteSupplier = createAsyncThunk('backend_deletesupplier', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteSupplier?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const supplierSlice = createSlice({
  name: 'supplier',
  initialState: {
    suppliers: [],
    supplier: {},
    getAllStatus: null,
    getDataStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetGetDataStatus: (state) => { state.getDataStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSuppliers.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllSuppliers.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.suppliers = action.payload || []; })
      .addCase(getAllSuppliers.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getSupplierById.pending, (state) => { state.getDataStatus = "pending"; })
      .addCase(getSupplierById.fulfilled, (state, action) => { state.getDataStatus = "fulfilled"; state.supplier = action.payload || {}; })
      .addCase(getSupplierById.rejected, (state, action) => { state.getDataStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewSupplier.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewSupplier.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewSupplier.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateSupplier.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateSupplier.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateSupplier.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteSupplier.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteSupplier.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteSupplier.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = supplierSlice.actions;
export default supplierSlice.reducer;