// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllOffersByDate, getOfferById, addNewOffer, updateOffer, deleteOffer, getOfferPDFById, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetPdfStatus, resetError } from '../../../storage/slices/backend/OfferSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import OfferAddModal from '../modals/offers/OfferAddModal';
import OfferUpdateModal from '../modals/offers/OfferUpdateModal';
import OfferDeleteModal from '../modals/DeleteModal';
import LoadingModal from '../modals/LoadingModal';
// Icons
import { TbTagPlus, TbTagMinus, TbTag, TbPrinter } from 'react-icons/tb';
import { BsFileEarmarkPdf } from "react-icons/bs";

function Offers() {
  // Storage Import
  const dispatch = useDispatch();
  const { offers, offer, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, pdfStatus, error } = useSelector((state) => state.offer);
  // Context Import
  const { myUser } = useData();
  const { stringDate, stringMoney, getStringDate } = useFunctions();
  // Variables
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [dates, setDates] = useState({ start: getStringDate(7), end: getStringDate(0) });
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal, start, end) => {
    try {
      await dispatch(getAllOffersByDate({ signal, start, end }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal, dates.start, dates.end); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch, dates]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      const data = offers.map((data) => {
        const id = data.id;
        const date = stringDate(data.date);
        const name = data.customerName;
        const phone = data.customerPhone1;
        const address = data.customerAddress;
        const district = data.district;
        const city = data.city;
        const total = stringMoney(data.total, 'TL');
        const status = data.status;
        const customerString = `${name} - ${phone}\n${address}\n${district} / ${city}`;
        const customerDiv = (
          <div className='d-flex flex-column text-start justify-content-start align-items-start'>
            <p className='m-0 p-0'>{name} - {phone} </p>
            <p className='m-0 p-0'>{address}</p>
            <p className='m-0 p-0'>{district}/{city}</p>
          </div>
        );
        const statusDiv = (
          <p className='m-0 p-0 fw-bold'>{status}</p>
        );
        return { id, date, name, phone, address, district, city, customerString, customerDiv, total, status, statusDiv };
      });
      setDataToTable(data.map(item => ({ id: item.id, date: item.date, customer: item.customerDiv, customerSearch: item.customerString, total: item.total, status: item.statusDiv, statusSearch: item.status })));
      setDataToPDF(data.map(item => ({ id: item.id, date: item.date, customer: item.customerString, total: item.total, status: item.status })));
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseOfferAddModal();
      dispatch(addToast({ background: 'success', icon: 'offerAdd', message: 'Teklif Eklendi!', delay: 6000 }));
      dispatch(getAllOffersByDate({ signal: null, start: dates.start, end: dates.end }));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseOfferUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'offerUpdate', message: 'Teklif Güncellendi!', delay: 6000 }));
      dispatch(getAllOffersByDate({ signal: null, start: dates.start, end: dates.end }));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseOfferDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'offerDelete', message: 'Teklif Silindi!', delay: 6000 }));
      dispatch(getAllOffersByDate({ signal: null, start: dates.start, end: dates.end }));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  useEffect(() => {
    if (pdfStatus === 'pending') {
      setLoadingPDF(true);
      dispatch(resetPdfStatus());
    } else { setLoadingPDF(false); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfStatus])
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataOfferUpdateModal(offer);
        setShowOfferUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataOfferDeleteModal(offer);
        setShowOfferDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'customer', name: 'MÜŞTERİ' }, { value: 'total', name: 'TOPLAM' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'date', 'customerSearch', 'total', 'statusSearch'];
  const changeDates = (startDate, endDate) => { setDates({ start: startDate, end: endDate }); }
  const clickOn = (id) => { handleShowOfferUpdateModal(id); }
  const buttons = [
    {
      text: 'PDF Ekle',
      icon: <BsFileEarmarkPdf className='button-icon' />,
      onclick: ((rowData) => { dispatch(getOfferPDFById({ id: rowData.id })); }),
      variant: 'success'
    },
    {
      text: 'Güncelle',
      icon: <TbTag className='button-icon' />,
      onclick: ((rowData) => { handleShowOfferUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <TbTagMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowOfferDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Teklifler';
  const operations = [
    {
      text: 'Teklif Ekle',
      icon: <TbTagPlus className='button-icon' />,
      onclick: (() => { handleShowOfferAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        changeDates={changeDates}
        dates={dates}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showOfferAddModal, setShowOfferAddModal] = useState(false);
  const handleCloseOfferAddModal = () => {
    setShowOfferAddModal(false);
  }
  const handleShowOfferAddModal = () => {
    setShowOfferAddModal(true);
  }
  const handleAddOfferAddModal = (values) => {
    dispatch(addNewOffer({ newoffer: values }));
  }
  // Update Modal
  const [showOfferUpdateModal, setShowOfferUpdateModal] = useState(false);
  const [inputDataOfferUpdateModal, setInputDataOfferUpdateModal] = useState(null);
  const handleCloseOfferUpdateModal = () => {
    setShowOfferUpdateModal(false);
    setInputDataOfferUpdateModal(null);
  }
  const handleShowOfferUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getOfferById({ id: id }));
  }
  const handleUpdateOfferUpdateModal = (values) => {
    dispatch(updateOffer({ offer: values }));
  }
  // Delete Modal
  const [showOfferDeleteModal, setShowOfferDeleteModal] = useState(false);
  const [inputDataOfferDeleteModal, setInputDataOfferDeleteModal] = useState(null);
  const handleCloseOfferDeleteModal = () => {
    setShowOfferDeleteModal(false);
    setInputDataOfferDeleteModal(null);
  }
  const handleShowOfferDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getOfferById({ id: id }));
  }
  const handleDeleteOfferDeleteModal = () => {
    dispatch(deleteOffer({ id: inputDataOfferDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <OfferAddModal
        show={showOfferAddModal}
        handleClose={handleCloseOfferAddModal}
        handleAdd={handleAddOfferAddModal}
      />
      <OfferUpdateModal
        show={showOfferUpdateModal}
        handleClose={handleCloseOfferUpdateModal}
        handleUpdate={handleUpdateOfferUpdateModal}
        inputData={inputDataOfferUpdateModal}
      />
      <OfferDeleteModal
        show={showOfferDeleteModal}
        handleClose={handleCloseOfferDeleteModal}
        handleDelete={handleDeleteOfferDeleteModal}
        title="Teklifi Sil"
        message={`${inputDataOfferDeleteModal?.id} id numaralı teklifi silmek istediğinize emin misiniz?`}
        deleteIcon={<TbTagMinus className='button-icon' />}
      />
      <LoadingModal show={loadingPDF} />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showOfferAddModal, showOfferUpdateModal, inputDataOfferUpdateModal, showOfferDeleteModal, inputDataOfferDeleteModal, loadingPDF]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Offers;