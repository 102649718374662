// General
import React, { useState } from 'react';
// Styles
import { Autocomplete, TextField } from '@mui/material';
import { Field } from 'formik';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getServiceById, resetGetDataStatus } from '../../../storage/slices/backend/ServiceSlice';

function CaseactionType({ errors, touched, values, setFieldValue, setFieldTouched, type }) {
  // Storage Import
  const dispatch = useDispatch();
  const { employees } = useSelector((state) => state.employee);
  const { suppliers } = useSelector((state) => state.supplier);
  // Variables
  const [filteredOptions, setFilteredOptions] = useState([]);
  // Functions
  const handleInputChange = async (event, newInputValue) => {
    if (!newInputValue) { setFilteredOptions([]); return; }
    const id = /^\d+$/.test(newInputValue);
    if (id) {
      try {
        const service = await dispatch(getServiceById({ id: parseInt(newInputValue) }));
        if (service.payload) {
          setFilteredOptions([service.payload]);
        } else { setFilteredOptions([]); }
      } catch (error) { throw error; } finally { dispatch(resetGetDataStatus()); }
    }
    else { setFilteredOptions([]); }
  };
  // HTMLW
  return (
    <>
      {type === 'Depo Stok' ? (
        <>
          <div className='col-12 col-md-12'>
            <Field
              as={TextField}
              id='description'
              name='description'
              label="Açıklama"
              value={values.description}
              onChange={(e) => setFieldValue('description', e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
            />
          </div>
          <div className='col-12 col-md-12'>
            <Field
              as={Autocomplete}
              id="supplier"
              name="supplier"
              value={values.supplier}
              onChange={(event, newValue) => { setFieldValue('supplier', newValue); }}
              onBlur={() => { setFieldTouched('supplier', true); }}
              variant='outlined'
              size='small'
              options={suppliers.map(item => item.name)}
              renderInput={(params) => <TextField {...params} label="Tedarikçi" error={touched.supplier && !!errors.supplier} helperText={touched.supplier && errors.supplier} />}
            />
          </div>
        </>
      ) : type === 'Servis Para' || type === 'Servis Parça' || type === 'Müşteri İade' ? (
        <>
          <div className='col-12 col-md-12'>
            <Field
              as={TextField}
              id='description'
              name='description'
              label="Açıklama"
              value={values.description}
              onChange={(e) => setFieldValue('description', e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
            />
          </div>
          <div className='col-12 col-md-12'>
            <Field
              as={Autocomplete}
              id="employee"
              name="employee"
              value={values.employee}
              onChange={(event, newValue) => { setFieldValue('employee', newValue); }}
              onBlur={() => { setFieldTouched('employee', true); }}
              variant='outlined'
              size='small'
              options={employees.map(item => item.name)}
              renderInput={(params) => <TextField {...params} label="Personel" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
            />
          </div>
          <div className='col-12 col-md-12'>
            <Field
              as={Autocomplete}
              id="transactionreportsaddmodal_serviceId"
              name="serviceId"
              options={filteredOptions || []}
              value={values.serviceId}
              onChange={(event, newValue) => { setFieldValue('serviceId', newValue || ''); }}
              onInputChange={handleInputChange}
              onBlur={() => { setFieldTouched('serviceId', true); }}
              variant='outlined'
              size='small'
              getOptionLabel={(option) =>
                option && option.id
                  ? `${option.id} - ${option.name} (${option.phone1}) - ${option.brandName}/${option.deviceName}`
                  : ''
              }
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {`${option.id} - ${option.name} (${option.phone1}) - ${option.brandName}/${option.deviceName}`}
                </li>
              )}
              isOptionEqualToValue={(option, value) => option.id === value}
              renderInput={(params) => (
                <TextField {...params} label="Servis" error={touched.serviceId && !!errors.serviceId} helperText={touched.serviceId && errors.serviceId} />
              )}
            />
          </div>
          <div className='col-12 col-md-12'>
            <Field
              as={TextField}
              id="brand"
              name="brand"
              label="Marka"
              value={values.brand}
              slotProps={{ input: { readOnly: true } }}
              variant='outlined'
              size='small'
              fullWidth
              error={touched.brand && !!errors.brand}
              helperText={touched.brand && errors.brand}
            />
          </div>
          <div className='col-12 col-md-12'>
            <Field
              as={TextField}
              id="device"
              name="device"
              label="Cihaz"
              value={values.device}
              slotProps={{ input: { readOnly: true } }}
              variant='outlined'
              size='small'
              fullWidth
              error={touched.device && !!errors.device}
              helperText={touched.device && errors.device}
            />
          </div>
        </>
      ) : type === 'Maaş' || type === 'Prim' ? (
        <>
          <div className='col-12 col-md-12'>
            <Field
              as={TextField}
              id='description'
              name='description'
              label="Açıklama"
              value={values.description}
              onChange={(e) => setFieldValue('description', e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
            />
          </div>
          <div className='col-12 col-md-12'>
            <Field
              as={Autocomplete}
              id="employee"
              name="employee"
              value={values.employee}
              onChange={(event, newValue) => { setFieldValue('employee', newValue); }}
              onBlur={() => { setFieldTouched('employee', true); }}
              variant='outlined'
              size='small'
              options={employees.map(item => item.name)}
              renderInput={(params) => <TextField {...params} label="Personel" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
            />
          </div>
        </>
      ) : (type === 'Ofis' || type === 'Kira' || type === 'Google Reklam' || type === 'Araç Bakım' || type === 'Araç Yakıt' || type === 'Mutfak / Yemek' || type === 'Diğer / Muhtelif') && (
        <>
          <div className='col-12 col-md-12'>
            <Field
              as={TextField}
              id='description'
              name='description'
              label="Açıklama"
              value={values.description}
              onChange={(e) => setFieldValue('description', e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
            />
          </div>
        </>
      )}
    </>
  );
}

export default CaseactionType;