// General
import React, { useCallback, useEffect, useState } from 'react';
// Icons
import { HiMiniChevronDown } from 'react-icons/hi2';
import { TbBook2 } from 'react-icons/tb';

function TablePage({ Title, Operations, Table, Modals }) {
  // Dropdown
  const [operationsDropdown, setOperationsDropdown] = useState(false);
  const toggleOperationsDropdown = () => { setOperationsDropdown(!operationsDropdown); };
  const handleClickOutside = useCallback((event) => { if (!event.target.closest('.operationsdropdown')) { setOperationsDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => { document.removeEventListener('click', handleClickOutside); };
  }, [handleClickOutside]);
  // HTML
  return (
    <div className='d-flex justify-content-center w-100 mt-5'>
      <div className='card w-100'>
        <div className='card-header m-0 p-1'>
          <div className='row d-flex align-items-center'>
            <div className='col-12 col-sm-8 col-lg-8 col-xl-7'>
              <div className='row h-100 m-0 p-2'>
                <div className='d-flex align-items-center'>
                  <div className='card-title m-0 p-0'>{Title}</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-4 col-lg-4 col-xl-5 ps-3 ps-sm-0 ps-lg-0 ps-xl-3'>
              <div className='row d-flex justify-content-end m-1 ms-sm-0 ms-lg-0  ms-xl-2'>
                <div className='col-12 col-sm-12 col-lg-12 col-xl-5 ps-0'>
                  <div className="dropdown">
                    <button className="button-with-icon btn btn-success operationsdropdown dropdown-toggle w-100" onClick={toggleOperationsDropdown} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <TbBook2 className='button-icon' />
                      İşlemler
                      <HiMiniChevronDown style={{ fontSize: '1.25em' }} />
                    </button>
                    {operationsDropdown && (
                      <ul className="dropdown-menu operationsdropdown w-100 show">
                        {Operations.map((operation, index) => {
                          return (
                            <button key={index} className='dropdown-item' onClick={(e) => { e.stopPropagation(); operation.onclick(); }}>
                              {operation.icon ? operation.icon : ''}
                              {operation.text}
                            </button>
                          )
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <Table />
          {Modals}
        </div>
      </div>
    </div>
  );
}

export default TablePage;