// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

// Async Thunks
export const getAllServiceActions = createAsyncThunk('backend_getallserviceactions', async ({ signal = null, id }) => {
  try {
    const res = await axios.get(`https://tservis-api.tuyak.com.tr/api/Services/GetServiceActions?serviceId=${id}`, { signal, withCredentials: true });
    return res.data.data.map(item => ({ ...item, date: item.date.slice(0, 10) }));
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServiceOperations = createAsyncThunk('backend_getallserviceoperations', async ({ signal = null, id }) => {
  try {
    const res = await axios.get(`https://tservis-api.tuyak.com.tr/api/Services/GetServiceActionFlows?serviceId=${id}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const addNewServiceAction = createAsyncThunk('backend_addnewserviceaction', async ({ newserviceaction }) => {
  try {
    const res = await axios.post('https://tservis-api.tuyak.com.tr/api/ServiceActions/AddServiceAction', newserviceaction, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const serviceactionSlice = createSlice({
  name: 'serviceaction',
  initialState: {
    serviceActions: [],
    serviceOperations: [],
    getAllActionsStatus: null,
    getAllOperationsStatus: null,
    addStatus: null,
    error: null
  },
  reducers: {
    resetAllActionsStatus: (state) => { state.getAllActionsStatus = null; },
    resetAllOperationsStatus: (state) => { state.getAllOperationsStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllServiceActions.pending, (state) => { state.getAllActionsStatus = "pending"; })
      .addCase(getAllServiceActions.fulfilled, (state, action) => { state.getAllActionsStatus = "fulfilled"; state.serviceActions = action.payload || []; })
      .addCase(getAllServiceActions.rejected, (state, action) => { state.getAllActionsStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServiceOperations.pending, (state) => { state.getAllOperationsStatus = "pending"; })
      .addCase(getAllServiceOperations.fulfilled, (state, action) => { state.getAllOperationsStatus = "fulfilled"; state.serviceOperations = action.payload || []; })
      .addCase(getAllServiceOperations.rejected, (state, action) => { state.getAllOperationsStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewServiceAction.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewServiceAction.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewServiceAction.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetAllActionsStatus, resetAllOperationsStatus, resetAddStatus, resetError } = serviceactionSlice.actions;
export default serviceactionSlice.reducer;