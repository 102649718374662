// General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useDispatch } from 'react-redux';
import { getServiceById, resetGetDataStatus } from '../../../../storage/slices/backend/ServiceSlice';
// Contexts
import { useFunctions } from '../../../../context/FunctionContext';
import { useValidations } from '../../../../context/ValidationContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { HiOutlineDocumentCheck } from "react-icons/hi2";

function TransactionreportsUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Storage Import
  const dispatch = useDispatch();
  // Context Import
  const { stringOnlyNumbers } = useFunctions();
  const { vs_transactionreports } = useValidations();
  // Variables
  const [serviceData, setServiceData] = useState(null);
  const [serviceDataId, setServiceDataId] = useState(null);
  // Functions
  const getServiceData = async (id) => {
    const service = await dispatch(getServiceById({ id: id }));
    if (service.payload) {
      setServiceData(service.payload);
      setServiceDataId(service.payload.id);
    } else {
      setServiceData(null);
      setServiceDataId(null);
    }
    await dispatch(resetGetDataStatus());
  }
  useEffect(() => {
    if (!(inputData?.serviceId === serviceDataId) && show) {
      getServiceData(inputData?.serviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);
  // Formik Variables
  const initialValues = {
    id: inputData?.id || -1,
    serviceId: { id: inputData?.serviceId } || -1,
    date: inputData?.date || '',
    fault: inputData?.fault || '',
    transactions: inputData?.transactions || '',
    informations: inputData?.informations || '',
    total: inputData?.total || 0
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header className='py-2' closeButton>
          <Modal.Title>İşlem Raporunu Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={initialValues} validationSchema={vs_transactionreports} onSubmit={(values) => handleUpdate(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='transactionreportsupdatemodal_serviceId'
                      label='Servis'
                      value={serviceData ? `${serviceData?.id} - ${serviceData?.name} - ${serviceData?.brandName}/${serviceData?.deviceName}` : ''}
                      slotProps={{ input: { readOnly: true } }}
                      variant='outlined'
                      size='small'
                      fullWidth
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='transactionreportsupdatemodal_fault'
                      name='fault'
                      label="Arıza"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={3}
                      error={touched.fault && !!errors.fault}
                      helperText={touched.fault && errors.fault}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='transactionreportsupdatemodal_transactions'
                      name='transactions'
                      label="Yapılan İşlemler"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={3}
                      error={touched.transactions && !!errors.transactions}
                      helperText={touched.transactions && errors.transactions}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='transactionreportsupdatemodal_informations'
                      name='informations'
                      label="İşlem Bilgileri"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={3}
                      error={touched.informations && !!errors.informations}
                      helperText={touched.informations && errors.informations}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='transactionreportsupdatemodal_total'
                      name='total'
                      type='number'
                      label='Maliyet'
                      value={values.total}
                      onChange={(e) => { setFieldValue('total', parseFloat(stringOnlyNumbers(e.target.value))); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.total && !!errors.total}
                      helperText={touched.total && errors.total}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                        <TbArrowBackUpDouble className='button-icon' />
                        Geri
                      </button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-primary w-100'>
                        <HiOutlineDocumentCheck className='button-icon' />
                        Güncelle
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  );
}

export default TransactionreportsUpdateModal;