// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

// Async Thunk
export const getDistricts = createAsyncThunk('getgeneralapi_district', async ({ signal = null, city }) => {
  try {
    const res = await axios.get(`https://turkiyeapi.dev/api/v1/provinces?name=${city}`, { signal });
    return res.data.data[0].districts.map(item => item.name);
  } catch (error) { throw error; }
});

// Slice
const districtSlice = createSlice({
  name: 'district',
  initialState: { districts: [], status: '', error: '' },
  reducers: {
    resetStatus: (state) => { state.status = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDistricts.pending, (state) => { state.status = 'loading'; })
      .addCase(getDistricts.fulfilled, (state, action) => { state.status = 'fulfilled'; state.districts = action.payload || []; })
      .addCase(getDistricts.rejected, (state, action) => { state.status = 'rejected'; state.error = action.error.message; });
  }
});

export const { resetStatus, resetError } = districtSlice.actions;
export default districtSlice.reducer;