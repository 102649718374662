// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";

function DeleteModal({ show, handleClose, handleDelete, title, message, deleteIcon }) {
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header className='py-2' closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-center align-items-center'>
          <h6>{message}</h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100'>
          <div className='col-12 col-md-6'>
            <button className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
              <TbArrowBackUpDouble className='button-icon' />
              Geri
            </button>
          </div>
          <div className='col-12 col-md-6'>
            <button className='button-with-icon btn btn-danger w-100' onClick={handleDelete}>
              {deleteIcon}
              Sil
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;