// General
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
// Styles
import { Table } from 'react-bootstrap';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardData, resetStatus } from '../../../storage/slices/backend/DashboardSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Charts
import BarChart from '../components/BarChart';

function Dashboard() {
  // Storage Import
  const dispatch = useDispatch();
  const { dashboardDatas, status } = useSelector((state) => state.dashboard);
  // Context Import
  const { myUser } = useData();
  const { stringDate, stringMoney, calculateTotalOfColumn, openNewPage } = useFunctions();
  const [selected, setSelected] = useState(-1);
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  const colors = ["success", "secondary", "info", "primary", "warning", "danger"];
  // Api Operations
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getDashboardData({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (status === 'fulfilled') {
      setTotalServices(dashboardDatas.totalService);
      setTotalCustomers(dashboardDatas.totalCustomer);
      setTotalEmployees(dashboardDatas.totalEmployee);
      setTotalCash(dashboardDatas.totalCash);
      setLastDaysData(dashboardDatas.lastThreeDaysDtos);
      setMonthlyServices({ x: dashboardDatas.monthlyServicesDtos.map(item => item.total), y: dashboardDatas.monthlyServicesDtos.map(item => item.monthName) });
      setMostlyUsedStocks(dashboardDatas.mostUsedStocksDtos);
      setMostlyVisitedDistricts(dashboardDatas.mostVisitedDistrictsDtos);
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  // Data Operations
  const [totalServices, setTotalServices] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [lastDaysData, setLastDaysData] = useState([]);
  const [montlyServices, setMonthlyServices] = useState({ x: [10, 15, 10, 10, 15, 10], y: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran"] });
  const [mostlyUsedStocks, setMostlyUsedStocks] = useState([]);
  const [mostlyVisitedDistricts, setMostlyVisitedDistricts] = useState([]);
  // HTML
  return (
    <div>
      <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
        <div className="flex-grow-1">
          <h4 className="fs-18 fw-semibold m-0">Anasayfa</h4>
        </div>
      </div>
      <div className='row g-3'>
        <div className="col-12 col-md-3 d-flex align-items-stretch">
          <NavLink to='/servisler' className="card m-0 flex-fill">
            <div className="card-body">
              <div className="text-center">
                <div className="fs-14 mb-1 ">Toplam Servis</div>
              </div>
              <div className="text-center">
                <div className="fs-22 mb-0 me-2 fw-semibold text-black">{totalServices} Adet</div>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="col-12 col-md-3 d-flex align-items-stretch">
          <NavLink to='/musteriler' className="card m-0 flex-fill">
            <div className="card-body">
              <div className="text-center">
                <div className="fs-14 mb-1 ">Toplam Müşteri</div>
              </div>
              <div className="text-center">
                <div className="fs-22 mb-0 me-2 fw-semibold text-black">{totalCustomers} Kişi</div>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="col-12 col-md-3 d-flex align-items-stretch">
          <NavLink to='/personeller' className="card m-0 flex-fill">
            <div className="card-body">
              <div className="text-center">
                <div className="fs-14 mb-1 ">Toplam Çalışan</div>
              </div>
              <div className="text-center">
                <div className="fs-22 mb-0 me-2 fw-semibold text-black">{totalEmployees} Kişi</div>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="col-12 col-md-3 d-flex align-items-stretch">
          <NavLink to='/kasa' className="card m-0 flex-fill">
            <div className="card-body">
              <div className="text-center">
                <div className="fs-14 mb-1 ">Kasa</div>
              </div>
              <div className="text-center">
                <div className="fs-22 mb-0 me-2 fw-semibold text-black">{stringMoney(totalCash, '₺')}</div>
              </div>
            </div>
          </NavLink>
        </div>
        <div className='col-12 col-md-12'>
          <div className='card m-0'>
            <div className='card-body'>
              <h6><b>Son 3 gün gidilen servis sayısı</b></h6>
              <Table responsive striped className="w-100 text-center mb-0">
                <thead>
                  <tr>
                    <th>GİDİLEN TARİH</th>
                    <th>GİDİLEN SERVİS SAYISI</th>
                  </tr>
                </thead>
                <tbody>
                  {lastDaysData.map((item, index) => {
                    return (
                      <>
                        <tr key={`lastdaydata_${index}`}>
                          <td>
                            <NavLink className="text-dark" onClick={() => setSelected(selected === index ? -1 : index)}>
                              {stringDate(item.date)}
                            </NavLink>
                          </td>
                          <td>{item.total}</td>
                        </tr>
                        {selected === index && (
                          <>
                            {item.lastThreeDaysDealers.map((dealer, i) => {
                              return (
                                <tr key={`lastThreeDaysDealers|${index}_${i}`}>
                                  <td><NavLink onClick={() => openNewPage('/dashboard')}>{dealer.name}</NavLink></td>
                                  <td>{dealer.value}</td>
                                </tr>
                              );
                            })}
                          </>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-12'>
          <div className="card m-0">
            <div className="card-body">
              <h6><b>Aylık Servis Sayısı ({new Date().getFullYear()})</b></h6>
              <BarChart data={montlyServices} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex align-items-stretch">
          <div className="card m-0 flex-fill">
            <div className="card-body">
              <h6><b>En Çok Kullanılan Parçalar</b></h6>
              <Table className="table table-traffic mb-0">
                <thead>
                  <tr>
                    <th>Parça Adı</th>
                    <th colSpan="2">Adet</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {mostlyUsedStocks.map((item, index) => {
                    return (
                      <tr key={`parca_${index}`}>
                        <td>{item.stock}</td>
                        <td>{item.total}</td>
                        <td className="w-50">
                          <div className="progress progress-md mt-0">
                            <div className={`progress-bar bg-${colors[index > 5 ? index - 6 : index]}`} style={{ width: `${((item.total / calculateTotalOfColumn(mostlyUsedStocks, 'total')) * 100).toFixed(2)}%` }}></div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex align-items-stretch">
          <div className="card m-0 flex-fill ">
            <div className="card-body">
              <h6><b>En Çok Ziyaret Edilen İlçeler</b></h6>
              <Table className="table table-traffic mb-0">
                <thead>
                  <tr>
                    <th>İlçe Adı</th>
                    <th colSpan="2">Ziyaret Miktarı</th>
                  </tr>
                </thead>
                <tbody>
                  {mostlyVisitedDistricts.map((item, index) => {
                    return (
                      <tr key={`ilce_${index}`}>
                        <td>{item.district}</td>
                        <td>{item.total}</td>
                        <td className="w-50">
                          <div className="progress progress-md mt-0">
                            <div className={`progress-bar bg-${colors[index > 5 ? index - 6 : index]}`} style={{ width: `${((item.total / calculateTotalOfColumn(mostlyVisitedDistricts, 'total')) * 100).toFixed(2)}%` }}></div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;