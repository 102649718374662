// General
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyByToken, SendSurveyAnswers, resetGetQuestionsStatus, resetSendQuestionsStatus } from '../../../storage/slices/backend/SurveySlice';
// Icons
import { LuSend } from "react-icons/lu";

function Survey() {
  // Storage Import
  const dispatch = useDispatch();
  const { surveyquestions, getQuestionsStatus, sendQuestionStatus, error } = useSelector((state) => state.survey);
  // Variables
  const [status, setStatus] = useState('loading');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  // Api Functions
  const fetchAllDataSequentially = async (dispatch, token) => {
    try {
      await dispatch(getSurveyByToken({ token }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    fetchAllDataSequentially(dispatch, token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (getQuestionsStatus === 'fulfilled') {
      setQuestions(surveyquestions.questions);
      const initialAnswers = surveyquestions.questions.map(question => ({ questionId: question.questionId, answerValue: "" }));
      setAnswers(initialAnswers);
      setStatus('showsurvey');
      dispatch(resetGetQuestionsStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuestionsStatus]);
  useEffect(() => {
    if (sendQuestionStatus === 'fulfilled') {
      setStatus('answered');
      dispatch(resetSendQuestionsStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendQuestionStatus]);
  useEffect(() => {
    if (error) {
      setStatus('error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Funcions
  const handleAnswerChange = (questionId, answerValue) => {
    setAnswers(prevState => {
      const updatedAnswers = prevState.map(answer =>
        answer.questionId === questionId
          ? { ...answer, answerValue }
          : answer
      );
      return updatedAnswers;
    });
  };
  const handleSubmitSurvey = () => {
    const answersData = { token, answerDtos: answers }
    dispatch(SendSurveyAnswers({ answers: answersData }));
  }
  // Api Operations
  // HTML
  return (
    <section className="vh-100 gradient-survey">
      <div className="container h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card bg-light shadow" style={{ borderRadius: '1rem', maxHeight: '90vh' }}>
              <div className='card-body py-0 px-3 my-3 mx-0 overflow-auto'>
                {status === 'showsurvey' ? (
                  <>
                    <div className='mb-5'>
                      <h4 className='text-center m-0 p-0'><b>Servis Değerlendirme Anketi</b></h4>
                    </div>
                    {questions.map((item, index) => (
                      <div className="row my-3" key={index}>
                        <div className='d-flex align-items-center col-12 mb-3'><span>{`${index + 1}. ${item.question}`}</span></div>
                        <div className='col-12'>
                          <div className="form-group w-100 d-flex justify-content-between">
                            <div className='form-check form-check-inline'>
                              <input
                                className="form-check-input"
                                type="radio"
                                id={`yes-${index}`}
                                name={`question-${index}`}
                                value="Evet"
                                onChange={() => handleAnswerChange(item.questionId, 'Evet')}
                              />
                              <label className="form-check-label" htmlFor={`yes-${index}`}>Evet</label>
                            </div>
                            <div className='form-check form-check-inline'>
                              <input
                                className="form-check-input"
                                type="radio"
                                id={`no-${index}`}
                                name={`question-${index}`}
                                value="Hayır"
                                onChange={() => handleAnswerChange(item.questionId, 'Hayır')}
                              />
                              <label className="form-check-label" htmlFor={`no-${index}`}>Hayır</label>
                            </div>
                            <div className='form-check form-check-inline'>
                              <input
                                className="form-check-input"
                                type="radio"
                                id={`undecided-${index}`}
                                name={`question-${index}`}
                                value="Belirsiz"
                                onChange={() => handleAnswerChange(item.questionId, 'Belirsiz')}
                              />
                              <label className="form-check-label" htmlFor={`undecided-${index}`}>Belirsiz</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className='row mt-5'>
                      <div className='col-12'>
                        <button className='button-with-icon btn btn-primary w-100' onClick={handleSubmitSurvey}>
                          <LuSend className='button-icon' />
                          Anketi Gönder
                        </button>
                      </div>
                    </div>
                  </>
                ) : status === 'error' ? (
                  <div className='d-flex flex-column justify-content-center align-items-center w-100 vh-50'>
                    <h5 className='p-0 m-0 mb-2 text-danger'><b>Hata!</b></h5>
                    <h5 className='p-0 m-0'>{error}</h5>
                  </div>
                ) : status === 'answered' ? (
                  <div className='d-flex flex-column justify-content-center align-items-center w-100 vh-50'>
                    <h5 className='p-0 m-0 mb-2 text-success'><b>Tebrikler</b></h5>
                    <h5 className='p-0 m-0'>Anketi başarıyla cevapladınız!</h5>
                  </div>
                ) : (
                  <div className='d-flex justify-content-center align-items-center w-100 vh-50'>
                    <h5 className='p-0 m-0'>Yükleniyor...</h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Survey;