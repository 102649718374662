// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllWarehouses, getWarehouseById, addNewWarehouse, updateWarehouse, deleteWarehouse, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/WarehouseSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import WarehouseAddModal from '../modals/warehouses/WarehouseAddModal';
import WarehouseUpdateModal from '../modals/warehouses/WarehouseUpdateModal';
import WarehouseDeleteModal from '../modals/DeleteModal';
// Icons
import { TbHomePlus, TbHomeMinus, TbHomeCog, TbPrinter } from "react-icons/tb";

function Warehouses() {
  // Storage Import
  const dispatch = useDispatch();
  const { warehouses, warehouse, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.warehouse);
  // Context Import
  const { myUser } = useData();
  const { dictionaryStatuses } = useFunctions();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllWarehouses({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      setDataToTable(warehouses.map(item => ({ ...item, status: dictionaryStatuses[item.status] })));
      setDataToPDF(warehouses.map(item => ({ ...item, status: dictionaryStatuses[item.status] })));
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseWarehouseAddModal();
      dispatch(addToast({ background: 'success', icon: 'warehouseAdd', message: 'Depo Eklendi!', delay: 6000 }));
      dispatch(getAllWarehouses({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseWarehouseUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'warehouseUpdate', message: 'Depo Güncellendi!', delay: 6000 }));
      dispatch(getAllWarehouses({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseWarehouseDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'warehouseDelete', message: 'Depo Silindi!', delay: 6000 }));
      dispatch(getAllWarehouses({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataWarehouseUpdateModal({ ...warehouse, status: dictionaryStatuses[warehouse.status] });
        setShowWarehouseUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataWarehouseDeleteModal({ ...warehouse, status: dictionaryStatuses[warehouse.status] });
        setShowWarehouseDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'DEPO' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'name', 'status'];
  const clickOn = (id) => { handleShowWarehouseUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <TbHomeCog className='button-icon' />,
      onclick: ((rowData) => { handleShowWarehouseUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <TbHomeMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowWarehouseDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Depolar';
  const operations = [
    {
      text: 'Depo Ekle',
      icon: <TbHomePlus className='button-icon' />,
      onclick: (() => { handleShowWarehouseAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showWarehouseAddModal, setShowWarehouseAddModal] = useState(false);
  const handleCloseWarehouseAddModal = () => {
    setShowWarehouseAddModal(false);
  }
  const handleShowWarehouseAddModal = () => {
    setShowWarehouseAddModal(true);
  }
  const handleAddWarehouseAddModal = (values) => {
    dispatch(addNewWarehouse({ newwarehouse: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Update Modal
  const [showWarehouseUpdateModal, setShowWarehouseUpdateModal] = useState(false);
  const [inputDataWarehouseUpdateModal, setInputDataWarehouseUpdateModal] = useState(null);
  const handleCloseWarehouseUpdateModal = () => {
    setShowWarehouseUpdateModal(false);
    setInputDataWarehouseUpdateModal(null);
  }
  const handleShowWarehouseUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getWarehouseById({ id: id }));
  }
  const handleUpdateWarehouseUpdateModal = (values) => {
    dispatch(updateWarehouse({ warehouse: { ...values, status: dictionaryStatuses[values.status] } }));
  }
  // Delete Modal
  const [showWarehouseDeleteModal, setShowWarehouseDeleteModal] = useState(false);
  const [inputDataWarehouseDeleteModal, setInputDataWarehouseDeleteModal] = useState(null);
  const handleCloseWarehouseDeleteModal = () => {
    setShowWarehouseDeleteModal(false);
    setInputDataWarehouseDeleteModal(null);
  }
  const handleShowWarehouseDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getWarehouseById({ id: id }));
  }
  const handleDeleteWarehouseDeleteModal = () => {
    dispatch(deleteWarehouse({ id: inputDataWarehouseDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <WarehouseAddModal
        show={showWarehouseAddModal}
        handleClose={handleCloseWarehouseAddModal}
        handleAdd={handleAddWarehouseAddModal}
      />
      <WarehouseUpdateModal
        show={showWarehouseUpdateModal}
        handleClose={handleCloseWarehouseUpdateModal}
        handleUpdate={handleUpdateWarehouseUpdateModal}
        inputData={inputDataWarehouseUpdateModal}
      />
      <WarehouseDeleteModal
        show={showWarehouseDeleteModal}
        handleClose={handleCloseWarehouseDeleteModal}
        handleDelete={handleDeleteWarehouseDeleteModal}
        title="Depoyu Sil"
        message={`${inputDataWarehouseDeleteModal?.name} adlı depoyu silmek istediğinize emin misiniz?`}
        deleteIcon={<TbHomeMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showWarehouseAddModal, showWarehouseUpdateModal, inputDataWarehouseUpdateModal, showWarehouseDeleteModal, inputDataWarehouseDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Warehouses;