// General
import { configureStore } from '@reduxjs/toolkit';
// Reducers
import ToastReducer from './slices/general/ToastSlice';
import PrintReducer from './slices/general/PrintSlice';
import DistrictReducer from './slices/general/DistrictSlice';
import ExchangeReducer from './slices/general/ExchangeSlice';
import LoginReducer from './slices/backend/LoginSlice';
import DashboardReducer from './slices/backend/DashboardSlice';
import ServiceReducer from './slices/backend/ServiceSlice';
import ServiceactionReducer from './slices/backend/ServiceactionSlice';
import ServiceplanReducer from './slices/backend/ServiceplanSlice';
import PremiumReducer from './slices/backend/PremiumSlice';
import CustomerReducer from './slices/backend/CustomerSlice';
import EmployeeReducer from './slices/backend/EmployeeSlice';
import StockReducer from './slices/backend/StockSlice';
import OfferReducer from './slices/backend/OfferSlice';
import ReminderReducer from './slices/backend/ReminderSlice';
import SurveyReducer from './slices/backend/SurveySlice';
import StatisticReducer from './slices/backend/StatisticSlice';
import CaseactionReducer from './slices/backend/CaseactionSlice';

import MessageReducer from './slices/backend/MessageSlice';
import TransactionreportReducer from './slices/backend/TransactionreportSlice';
import VehicleReducer from './slices/backend/VehicleSlice';
import SupplierReducer from './slices/backend/SupplierSlice';
import WarehouseReducer from './slices/backend/WarehouseSlice';
import ShelfReducer from './slices/backend/ShelfSlice';
import RoleReducer from './slices/backend/RoleSlice';
import BrandReducer from './slices/backend/BrandSlice';
import DeviceReducer from './slices/backend/DeviceSlice';
import DealerReducer from './slices/backend/DealerSlice';
import SourceReducer from './slices/backend/SourceSlice';
import ConsigmentdeviceReducer from './slices/backend/ConsigmentdeviceSlice';
import SurveyquestionReducer from './slices/backend/SurveyquestionSlice';

export const store = configureStore({
  reducer: {
    toast: ToastReducer,
    print: PrintReducer,
    district: DistrictReducer,
    exchange: ExchangeReducer,
    login: LoginReducer,
    dashboard: DashboardReducer,
    service: ServiceReducer,
    serviceaction: ServiceactionReducer,
    serviceplan: ServiceplanReducer,
    premium: PremiumReducer,
    customer: CustomerReducer,
    employee: EmployeeReducer,
    stock: StockReducer,
    offer: OfferReducer,
    reminder: ReminderReducer,
    survey: SurveyReducer,
    statistic: StatisticReducer,
    caseaction: CaseactionReducer,

    message: MessageReducer,
    transactionreport: TransactionreportReducer,
    vehicle: VehicleReducer,
    supplier: SupplierReducer,
    warehouse: WarehouseReducer,
    shelf: ShelfReducer,
    role: RoleReducer,
    brand: BrandReducer,
    device: DeviceReducer,
    dealer: DealerReducer,
    source: SourceReducer,
    consigmentdevice: ConsigmentdeviceReducer,
    surveyquestion: SurveyquestionReducer
  }
});