// General
import React from 'react'
// Styles
import { TextField } from '@mui/material';
import { Field } from 'formik';
// Contexts
import { useFunctions } from '../../../context/FunctionContext';

function CustomerType({ errors, touched, values, setFieldValue, readOnly }) {
  // Context Import
  const { stringMaxLenght, stringOnlyNumbers } = useFunctions();
  // HTML
  return (
    <>
      {values.customerType === 'Kurumsal' ? (
        <>
          <div className='col-12 col-md-6'>
            <Field
              as={TextField}
              id="taxNumber"
              name="taxNumber"
              label="Vergi Numarası"
              value={values.taxNumber}
              onChange={(e) => setFieldValue('taxNumber', stringOnlyNumbers(stringMaxLenght(e.target.value, 10)))}
              variant="outlined"
              size="small"
              slotProps={{ input: { readOnly: readOnly } }}
              fullWidth
              error={touched.taxNumber && !!errors.taxNumber}
              helperText={touched.taxNumber && errors.taxNumber}
            />
          </div>
          <div className='col-12 col-md-6'>
            <Field
              as={TextField}
              id="taxOffice"
              name="taxOffice"
              label="Vergi Dairesi"
              value={values.taxOffice}
              onChange={(e) => setFieldValue('taxOffice', e.target.value)}
              slotProps={{ input: { readOnly: readOnly } }}
              variant="outlined"
              size="small"
              fullWidth
              error={touched.taxOffice && !!errors.taxOffice}
              helperText={touched.taxOffice && errors.taxOffice}
            />
          </div>
        </>
      ) : (
        <div className='col-12 col-md-12'>
          <Field
            as={TextField}
            id="identityNumber"
            name="identityNumber"
            label="T.C. Kimlik Numarası"
            value={values.identityNumber}
            onChange={(e) => setFieldValue('identityNumber', stringOnlyNumbers(stringMaxLenght(e.target.value, 11)))}
            variant="outlined"
            size="small"
            slotProps={{ input: { readOnly: readOnly } }}
            fullWidth
            error={touched.identityNumber && !!errors.identityNumber}
            helperText={touched.identityNumber && errors.identityNumber}
          />
        </div>
      )}
    </>
  );
}

export default CustomerType;