// General
import React, { useEffect, useState } from 'react';
// Styles
import { Field, Form, Formik } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { getDistricts } from '../../../storage/slices/general/DistrictSlice';
import { sendBulkMessages, resetSendStatus, resetError } from '../../../storage/slices/backend/MessageSlice';
import { getAllBrands } from '../../../storage/slices/backend/BrandSlice';
import { getAllDevices } from '../../../storage/slices/backend/DeviceSlice';
import { getAllSources } from '../../../storage/slices/backend/SourceSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
import { useValidations } from '../../../context/ValidationContext';
// Components
import Table from '../components/Table';
// Icons
import { TbDatabaseShare } from 'react-icons/tb';
import { LuSend } from "react-icons/lu";

function BulkMessages() {
  // Storage Import
  const dispatch = useDispatch();
  const { districts } = useSelector((state) => state.district);
  const { sendStatus, error } = useSelector((state) => state.message);
  const { brands } = useSelector((state) => state.brand);
  const { devices } = useSelector((state) => state.device);
  const { sources } = useSelector((state) => state.source);
  // Context Import
  const { myUser, cities, serviceOperations } = useData();
  const { getStringDate } = useFunctions();
  const { vs_bulkmessage } = useValidations();
  // Variables
  const [city, setCity] = useState('İstanbul');
  const [searchedCity, setSearchedCity] = useState('');
  // Functions
  const handleSendMessage = async (values) => {
    dispatch(sendBulkMessages({ message: values }));
  }
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllBrands({ signal }));
      await dispatch(getAllDevices({ signal }));
      await dispatch(getAllSources({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  const fetchDistrictDataSequentially = async (dispatch, signal, search) => {
    try {
      await dispatch(getDistricts({ signal, city: search }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (city !== searchedCity) {
      fetchDistrictDataSequentially(dispatch, signal, city);
      setSearchedCity(city);
    }
    return () => { controller.abort(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);
  useEffect(() => {
    if (sendStatus === 'fulfilled') {
      dispatch(addToast({ background: 'success', icon: 'bulkmessageSent', message: 'Measj Göderildi!', delay: 6000 }));
      dispatch(resetSendStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Data Operaitons
  const [dataToTable, setDataToTable] = useState([]);
  // Formik Variables
  const initialValues = {
    startDate: getStringDate(0),
    endDate: getStringDate(0),
    city: 'İstanbul',
    district: '',
    brand: '',
    device: '',
    source: '',
    status: '',
    message: ''
  };
  // HTML
  return (
    <div className='d-flex justify-content-center w-100 mt-5'>
      <div className='card w-100'>
        <div className='card-header m-0 p-1'>
          <div className='row d-flex align-items-center'>
            <div className='col-12 col-sm-8 col-lg-8 col-xl-7'>
              <div className='row h-100 m-0 p-2'>
                <div className='d-flex align-items-center'>
                  <div className='card-title m-0 p-0'>Toplu Mesaj</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <Formik initialValues={initialValues} validationSchema={vs_bulkmessage} onSubmit={(values) => { handleSendMessage(values); }}>
            {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className='row g-2'>
                  <div className='col-12 mb-3'>
                    <h5 className='m-0 p-0'><b>Filtreler</b></h5>
                  </div>
                  <div className='col-6 col-md-3'>
                    <Field
                      as={TextField}
                      id='bulkmessages_startDate'
                      name='startDate'
                      label='Başlangıç Tarihi'
                      type="date"
                      value={values.startDate}
                      onChange={(e) => setFieldValue('startDate', e.target.value)}
                      slotProps={{ input: { shrink: "true" } }}
                      variant='outlined'
                      size='small'
                      fullWidth
                    />
                  </div>
                  <div className='col-6 col-md-3'>
                    <Field
                      as={TextField}
                      id='bulkmessages_endDate'
                      name='endDate'
                      label='Başlangıç Tarihi'
                      type="date"
                      value={values.endDate}
                      onChange={(e) => setFieldValue('endDate', e.target.value)}
                      slotProps={{ input: { shrink: "true" } }}
                      variant='outlined'
                      size='small'
                      fullWidth
                    />
                  </div>
                  <div className='col-6 col-md-3'>
                    <Field
                      as={Autocomplete}
                      id="bulkmessages_city"
                      name="city"
                      options={cities}
                      value={values.city}
                      onChange={(event, newValue) => {
                        setCity(newValue || '');
                        setFieldValue('city', newValue || '');
                        setFieldValue('district', '');
                      }}
                      onBlur={() => { setFieldTouched('city', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Şehir" />}
                    />
                  </div>
                  <div className='col-6 col-md-3'>
                    <Field
                      as={Autocomplete}
                      id="bulkmessages_district"
                      name="district"
                      options={districts}
                      value={values.district}
                      onChange={(event, newValue) => {
                        if (values.city !== '') {
                          setFieldValue("district", newValue);
                        }
                      }}
                      onBlur={() => { setFieldTouched('district', true); }}
                      disabled={values.city === ''}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="İlçe" />}
                    />
                  </div>
                  <div className='col-6 col-md-3'>
                    <Field
                      as={Autocomplete}
                      id="bulkmessages_brand"
                      name="brand"
                      options={brands.map((item) => item.name)}
                      value={values.brand}
                      onChange={(event, newValue) => { setFieldValue('brand', newValue); }}
                      onBlur={() => { setFieldTouched('brand', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Marka" />}
                    />
                  </div>
                  <div className='col-6 col-md-3'>
                    <Field
                      as={Autocomplete}
                      id="bulkmessages_device"
                      name="device"
                      options={devices.map((item) => item.name)}
                      value={values.device}
                      onChange={(event, newValue) => { setFieldValue('device', newValue); }}
                      onBlur={() => { setFieldTouched('device', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Cihaz" />}
                    />
                  </div>
                  <div className='col-6 col-md-3'>
                    <Field
                      as={Autocomplete}
                      id="bulkmessages_source"
                      name="source"
                      options={sources.map((item) => item.name)}
                      value={values.source}
                      onChange={(event, newValue) => { setFieldValue('source', newValue); }}
                      onBlur={() => { setFieldTouched('source', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Kaynak" />}
                    />
                  </div>
                  <div className='col-6 col-md-3'>
                    <Field
                      as={Autocomplete}
                      id="bulkmessages_status"
                      name="status"
                      options={serviceOperations}
                      value={values.status}
                      onChange={(event, newValue) => { setFieldValue('status', newValue); }}
                      onBlur={() => { setFieldTouched('status', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Durum" />}
                    />
                  </div>
                  <div className='col-6 col-m-9'></div>
                  <div className='col-6 col-m-3'>
                    <button type='button' className='button-with-icon btn btn-primary w-100'>
                      <TbDatabaseShare className='button-icon' />
                      Veri Getir
                    </button>
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      id='bulkmessages_message'
                      name='message'
                      multiline
                      minRows={4}
                      label='Mesaj'
                      slotProps={{ input: { resize: "none" } }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={touched.message && !!errors.message}
                      helperText={touched.message && errors.message}
                    />
                  </div>
                  <div className='col-6 col-m-9'></div>
                  <div className='col-6 col-m-3'>
                    <button type='submit' className='button-with-icon btn btn-success w-100'>
                      <LuSend className='button-icon' />
                      Toplu Mesaj Gönder
                    </button>
                  </div>
                  <div className='col-12'>
                    <h5 className='m-0 p-0 mt-3'><b>Gönderilecek Müşteriler</b></h5>
                  </div>
                  <div className='col-12'>
                    <Table
                      data={dataToTable}
                      rows={10}
                      columns={[{ value: 'name', name: 'MÜŞTERİ' }, { value: 'City', name: 'İL' }, { value: 'District', name: 'İLÇE' }, { value: 'Phone', name: 'TELEFON' }, { value: 'Device', name: 'CİHAZ' }]}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default BulkMessages;