// General
import { Autocomplete, Button, styled, TextField } from '@mui/material';
import React, { useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// Component
import Table from '../../components/Table';
// Icons
import { TbArrowBackUpDouble } from 'react-icons/tb';
import { MdCloudUpload } from "react-icons/md";

const MAX_FILE_SIZE = 5 * 1024 * 1024;

function StockDetailModal({ show, handleClose, inputData }) {
  // Variables
  const [selectedTab, setSelectedTab] = useState('1');
  // Functions
  const handleChangeTab = (event, newValue) => { setSelectedTab(newValue); };
  // TABS
  // TAB - Stock Operations
  const [stockoperationsData, setStockoperationsData] = useState([]);
  const stockoperationsColumns = [{ value: 'date', name: 'TARİH' }, { value: 'operation', name: 'İŞLEM' }, { value: 'no', name: 'NO' }, { value: 'piece', name: 'ADET' }, { value: 'price', name: 'FİYAT' }];
  const StockOperationsTableComponent = () => {
    return (
      <Table
        data={stockoperationsData}
        rows={5}
        columns={stockoperationsColumns}
      />
    );
  }
  const [stockoperations, setStockoperations] = useState(["Hepsi", "Alış", "Serviste Kullanım", "Personele Gönder"]);
  const [selectStockoperations, setSelectStockoperations] = useState('');
  // TAB - User Stocks
  const [userstocksData, setUserstocksData] = useState([]);
  const userstocksColumns = [{ value: 'name', name: 'PERSONEL' }, { value: 'piece', name: 'ADET' }];
  const UserStocksTableComponent = () => {
    return (
      <Table
        data={userstocksData}
        rows={5}
        columns={userstocksColumns}
      />
    );
  }
  // TAB - Photos
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header className='py-2' closeButton>
          <Modal.Title>{inputData?.id} numaralı id'ye sahip {inputData?.productName} ürününün detayları </Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-0 pt-0'>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab}>
                <Tab label='STOK HARAKETLERİ' value='1' />
                <Tab label='PERSONEL STOKLARI' value='2' />
                <Tab label='FOTOĞRAFLAR' value='3' />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className='row m-0 w-100 d-flex justify-content-end pt-4'>
                <div className='col-md-8'>
                  <Autocomplete
                    id="StockDetailModal_operation"
                    size='small'
                    options={stockoperations}
                    value={selectStockoperations}
                    onChange={(event, newValue) => { setSelectStockoperations(newValue) }}
                    variant='outlined'
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="İşlem" />}
                  />
                </div>
                <div className='col-md-4'>
                  <button className='btn btn-success w-100'>Hareket Ekle</button>
                </div>
                <div className=' col-md-12'>
                  <StockOperationsTableComponent />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="2">
              <div className="row m-0 w-100 justify-content-center pt-4">
                <div className="col-md-12  ">
                  <UserStocksTableComponent />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="3">
              <div className='row justify-content-center px-3  pt-4 '>
                <Button
                  className='fs-7'
                  component="label"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  startIcon={<MdCloudUpload />}
                >
                  Resim dosyası ekleyin (en fazla 5 MB jpg,png dosyaları yüklenebilir )
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileChange}
                    multiple
                  />
                </Button>
              </div>
            </TabPanel>
          </TabContext>
        </Modal.Body>
        <Modal.Footer className='px-0'>
          <div className='row m-0 d-flex justify-content-end align-items-center w-100'>
            <div className='col-12 col-md-5'>
              <button className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

// Local Functions
function handleFileChange(event) {
  const files = event.target.files;
  if (files.length > 0) {
    // eslint-disable-next-line array-callback-return
    [...files].map((file) => {
      const selectfile = file;
      const fileName = selectfile.name.toLowerCase();
      const fileSize = selectfile.size;
      const validFileExtensions = ['.jpg', '.jpeg', '.png'];
      const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
      if (!validFileExtensions.includes(fileExtension)) {
        alert('Sadece JPG ve PNG dosyalarına izin verilmektedir.');
        event.target.value = null;
        return null;
      }
      if (fileSize > MAX_FILE_SIZE) {
        alert('Dosya boyutu 5 MB\'ı geçemez.');
        event.target.value = null;
        return null;
      }
      console.log('Geçerli dosya:', file);
    })
  }
}

export default StockDetailModal;