// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllTransactionReports, getTransactionReportById, addTransactionReport, updateTransactionReport, deleteTransactionReport, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/TransactionreportSlice';
// Contexts
import { useData } from '../../../context/DataContext';
import { useFunctions } from '../../../context/FunctionContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import TransactionreportAddModal from '../modals/transactionreports/TransactionreportAddModal';
import TransactionreportUpdateModal from '../modals/transactionreports/TransactionreportUpdateModal';
import TransactionreportDeleteModal from '../modals/DeleteModal';
// Icons
import { HiOutlineDocumentPlus, HiOutlineDocumentMinus, HiOutlineDocumentCheck } from "react-icons/hi2";
import { TbPrinter } from 'react-icons/tb';

function Transactionreports() {
  // Storage Import
  const dispatch = useDispatch();
  const { transactionreports, transactionreport, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.transactionreport);
  // Context Import
  const { myUser } = useData();
  const { stringDate, stringMoney, getStringDate } = useFunctions();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllTransactionReports({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      const data = transactionreports.map((item) => {
        const id = item.id;
        const date = stringDate(item.date);
        const name = item.name;
        const phone1 = item.phone1;
        const address1 = item.address
        const address2 = item.district + '/' + item.city;
        const brand = item.brand;
        const device = item.device;
        const total = stringMoney(item.total, 'TL');
        const fault = item.fault;
        const deviceString = brand + '/' + device;
        const customerString = `${name} - ${phone1}\n${address1}\n${address2}`;
        const customerDiv = (
          <div className='d-flex flex-column justify-content-start align-items-start'>
            <p className='m-0 p-0'>{name} - {phone1} </p>
            <p className='m-0 p-0'>{address1}</p>
            <p className='m-0 p-0'>{address2}</p>
          </div>
        )
        return { id, date, customerString, customerDiv, deviceString, fault, total };
      });
      setDataToTable(data.map(item => ({ id: item.id, date: item.date, customer: item.customerDiv, customerSearch: item.customerString, device: item.deviceString, fault: item.fault, total: item.total })));
      setDataToPDF(data.map(item => ({ id: item.id, date: item.date, customer: item.customerString, device: item.deviceString, fault: item.fault, total: item.total })));
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseTransactionreportAddModal();
      dispatch(addToast({ background: 'success', icon: 'transactionreportAdd', message: 'İşlem Raporu Eklendi!', delay: 6000 }));
      dispatch(getAllTransactionReports({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseTransactionreportUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'transactionreportUpdate', message: 'İşlem Raporu Güncellendi!', delay: 6000 }));
      dispatch(getAllTransactionReports({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseTransactionreportDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'transactionreportDelete', message: 'İşlem Raporu Silindi!', delay: 6000 }));
      dispatch(getAllTransactionReports({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataTransactionreportUpdateModal(transactionreport);
        setShowTransactionreportUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataTransactionreportDeleteModal(transactionreport);
        setShowTransactionreportDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'customer', name: 'MÜŞTERİ' }, { value: 'device', name: 'CİHAZ' }, { value: 'fault', name: 'ARIZA' }, { value: 'total', name: 'TOPLAM' }];
  const filters = ['id', 'date', 'customerSearch', 'device', 'fault', 'total'];
  const changeDates = (startDate, endDate) => { setDates({ start: startDate, end: endDate }); }
  const [dates, setDates] = useState({ start: getStringDate(7), end: getStringDate(0) });
  const clickOn = (id) => { handleShowTransactionreportUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <HiOutlineDocumentCheck className='button-icon' />,
      onclick: ((rowData) => { handleShowTransactionreportUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <HiOutlineDocumentMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowTransactionreportDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'İşlem Raporları';
  const operations = [
    {
      text: 'İşlem Raporu Ekle',
      icon: <HiOutlineDocumentPlus className='button-icon' />,
      onclick: (() => { handleShowTransactionreportAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        changeDates={changeDates}
        dates={dates}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showTransactionreportAddModal, setShowTransactionreportAddModal] = useState(false);
  const handleCloseTransactionreportAddModal = () => {
    setShowTransactionreportAddModal(false);
  }
  const handleShowTransactionreportAddModal = () => {
    setShowTransactionreportAddModal(true);
  }
  const handleAddTransactionreportAddModal = (values) => {
    dispatch(addTransactionReport({ newtransactionreport: { ...values, serviceId: values?.serviceId?.id } }));
  }
  // Update Modal
  const [showTransactionreportUpdateModal, setShowTransactionreportUpdateModal] = useState(false);
  const [inputDataTransactionreportUpdateModal, setInputDataTransactionreportUpdateModal] = useState(null);
  const handleCloseTransactionreportUpdateModal = () => {
    setShowTransactionreportUpdateModal(false);
    setInputDataTransactionreportUpdateModal(null);
  }
  const handleShowTransactionreportUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getTransactionReportById({ id: id }));
  }
  const handleUpdateTransactionreportUpdateModal = (values) => {
    dispatch(updateTransactionReport({ transactionreport: { ...values, serviceId: values?.serviceId?.id } }));
  }
  // Delete Modal
  const [showTransactionreportDeleteModal, setShowTransactionreportDeleteModal] = useState(false);
  const [inputDataTransactionreportDeleteModal, setInputDataTransactionreportDeleteModal] = useState(null);
  const handleCloseTransactionreportDeleteModal = () => {
    setShowTransactionreportDeleteModal(false);
    setInputDataTransactionreportDeleteModal(null);
  }
  const handleShowTransactionreportDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getTransactionReportById({ id: id }));
  }
  const handleDeleteTransactionreportDeleteModal = () => {
    dispatch(deleteTransactionReport({ id: inputDataTransactionreportDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <TransactionreportAddModal
        show={showTransactionreportAddModal}
        handleClose={handleCloseTransactionreportAddModal}
        handleAdd={handleAddTransactionreportAddModal}
      />
      <TransactionreportUpdateModal
        show={showTransactionreportUpdateModal}
        handleClose={handleCloseTransactionreportUpdateModal}
        handleUpdate={handleUpdateTransactionreportUpdateModal}
        inputData={inputDataTransactionreportUpdateModal}
      />
      <TransactionreportDeleteModal
        show={showTransactionreportDeleteModal}
        handleClose={handleCloseTransactionreportDeleteModal}
        handleDelete={handleDeleteTransactionreportDeleteModal}
        title="İşlem Raporunu Sil"
        message={`${inputDataTransactionreportDeleteModal?.id} id numaralı işlemm raporunu silmek istediğinize emin misiniz?`}
        deleteIcon={<HiOutlineDocumentMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showTransactionreportAddModal, showTransactionreportUpdateModal, inputDataTransactionreportUpdateModal, showTransactionreportDeleteModal, inputDataTransactionreportDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Transactionreports;