// General
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../storage/slices/general/ToastSlice';
import { validate, getUserInfo, resetValidateStatus, resetUserInfoStatus } from '../storage/slices/backend/LoginSlice';
// Contexts
import { useData } from '../context/DataContext';
// Components
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
// Modals
import LoadingModal from './pages/modals/LoadingModal';

function Layout() {
  // Storage Import
  const dispatch = useDispatch();
  const { userinfo, validateStatus, userInfoStatus } = useSelector((state) => state.login);
  // Context Import
  const { setMyUser, sidebar } = useData();
  // Variables
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [authorized, setAuthorized] = useState(false);
  const navigate = useNavigate();
  // Api Operations
  useEffect(() => {
    dispatch(validate());
    const intervalId = setInterval(() => {
      dispatch(validate());
    }, 300000);
    return () => clearInterval(intervalId);
  }, [dispatch]);
  useEffect(() => {
    if (validateStatus === 'pending' && !authorized) { setLoading(true); }
    else if (validateStatus === 'fulfilled') {
      setAuthorized(true);
      dispatch(resetValidateStatus());
      setLoading(false);
    }
    else if (validateStatus === 'rejected') {
      if (authorized) { dispatch(addToast({ background: 'danger', icon: 'logout', message: 'Oturum Süreniz Doldu!', delay: 2000 })); }
      setAuthorized(false);
      dispatch(resetValidateStatus());
      setLoading(false);
      navigate('/giris');
    }
    else { setLoading(false); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateStatus]);
  useEffect(() => {
    if (authorized) { dispatch(getUserInfo()); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorized]);
  useEffect(() => {
    if (userInfoStatus === 'fulfilled') { setMyUser(userinfo) }
    dispatch(resetUserInfoStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoStatus]);
  // HTML
  return (
    <div className='main-wrapper'>
      {authorized && (
        <div className='app-layout'>
          <Header />
          <Sidebar />
          <div className='content-page m-0 p-0  px-2 px-xl-5'>
            <div className="content" style={{ paddingTop: '70px', paddingLeft: `${!sidebar || isMobile ? '0px' : '260px'}` }}>
              <Outlet />
              <Footer />
            </div>
          </div>
        </div>
      )}
      <LoadingModal show={loading} />
    </div>
  );
}

export default Layout;