// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/StandartQuestions/';

// Async Thunks
export const getQuestionStatistics = createAsyncThunk('backend_getquestionstatistics', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetStandartQuestionsWithAnswers`, { signal, withCredentials: true });
    console.log(res.data.data);
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const getAllQuestions = createAsyncThunk('backend_getallquestions', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllStandartQuestions`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getQuestionById = createAsyncThunk('backend_getquestionbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetStandartQuestionById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewQuestion = createAsyncThunk('backend_addnewquestion', async ({ newquestion }) => {
  try {
    const res = await axios.post(`${baseurl}AddStandartQuestion`, newquestion, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateQuestion = createAsyncThunk('backend_updatequestion', async ({ question }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateStandartQuestion`, question, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteQuestion = createAsyncThunk('backend_deletequestion', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteStandartQuestion?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const surveyquestionSlice = createSlice({
  name: 'surveyquestion',
  initialState: {
    statistics: [],
    questions: [],
    question: {},
    statisticsStatus: null,
    getAllStatus: null,
    getDataStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetGetDataStatus: (state) => { state.getDataStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetStatisticsStatus: (state) => { state.statisticsStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionStatistics.pending, (state) => { state.statisticsStatus = "pending"; })
      .addCase(getQuestionStatistics.fulfilled, (state, action) => { state.statisticsStatus = "fulfilled"; state.statistics = action.payload || []; })
      .addCase(getQuestionStatistics.rejected, (state, action) => { state.statisticsStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllQuestions.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllQuestions.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.questions = action.payload || []; })
      .addCase(getAllQuestions.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getQuestionById.pending, (state) => { state.getDataStatus = "pending"; })
      .addCase(getQuestionById.fulfilled, (state, action) => { state.getDataStatus = "fulfilled"; state.question = action.payload || {}; })
      .addCase(getQuestionById.rejected, (state, action) => { state.getDataStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewQuestion.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewQuestion.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewQuestion.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateQuestion.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateQuestion.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateQuestion.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteQuestion.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteQuestion.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteQuestion.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetStatisticsStatus, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = surveyquestionSlice.actions;
export default surveyquestionSlice.reducer;