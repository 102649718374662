// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Messages/';

// Async Thunks
export const sendBulkMessages = createAsyncThunk('backend_sendbulkmessages', async ({ message }) => {
  try {
    const res = await axios.post(`${baseurl}SendBulkMessages`, message, { withCredentials: true });
    return res.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllMessages = createAsyncThunk('backend_getallmessages', async () => {
  try {
    const res = await axios.get(`${baseurl}GetAllMessages`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteMessage = createAsyncThunk('backend_deletemessage', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteMessage?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const messageSlice = createSlice({
  name: 'message',
  initialState: {
    messages: [],
    getAllStatus: null,
    sendStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetSendStatus: (state) => { state.sendStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendBulkMessages.pending, (state) => { state.sendStatus = "pending"; })
      .addCase(sendBulkMessages.fulfilled, (state) => { state.sendStatus = "fulfilled"; })
      .addCase(sendBulkMessages.rejected, (state, action) => { state.sendStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllMessages.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllMessages.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.messages = action.payload || []; })
      .addCase(getAllMessages.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteMessage.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteMessage.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteMessage.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetGetAllStatus, resetSendStatus, resetDeleteStatus, resetError } = messageSlice.actions;
export default messageSlice.reducer;