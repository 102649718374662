// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Services/';

// Async Thunks
export const getAllServicesForPlan = createAsyncThunk('backend_getallservicesforplan', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllServicesForPlan`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getUserServices = createAsyncThunk('backend_getuserservices', async ({ signal = null, id, date }) => {
  try {
    const res = await axios.get(`${baseurl}GetUserServices?userId=${id}&date=${date}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getUserAssignedServices = createAsyncThunk('backend_getuserassignedservices', async ({ signal = null, id, date }) => {
  try {
    const res = await axios.get(`${baseurl}GetUserAssignedServices?date=${date}&userId=${id}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const assignAServiceToAnEmployee = createAsyncThunk('backend_assignaservicetoanemployee', async ({ signal = null, data }) => {
  try {
    const res = await axios.post(`${baseurl}AssignAServiceToAnEmployee`, data, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});

// Slice
const serviceplanSlice = createSlice({
  name: 'serviceplan',
  initialState: {
    planServices: [],
    userServices: [],
    userAssignedServices: [],
    planServicesStatus: null,
    userServicesStatus: null,
    userAssignedServicesStatus: null,
    assignStatus: null,
    error: null
  },
  reducers: {
    resetPlanServicesStatus: (state) => { state.planServicesStatus = null; },
    resetUserServicesStatus: (state) => { state.userServicesStatus = null; },
    resetUserAssignedServicesStatus: (state) => { state.userAssignedServicesStatus = null; },
    resetAssignStatus: (state) => { state.assignStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllServicesForPlan.pending, (state) => { state.planServicesStatus = "pending"; })
      .addCase(getAllServicesForPlan.fulfilled, (state, action) => { state.planServicesStatus = "fulfilled"; state.planServices = action.payload || []; })
      .addCase(getAllServicesForPlan.rejected, (state, action) => { state.planServicesStatus = "rejected"; state.error = action.error.message; })
      .addCase(getUserServices.pending, (state) => { state.userServicesStatus = "pending"; })
      .addCase(getUserServices.fulfilled, (state, action) => { state.userServicesStatus = "fulfilled"; state.userServices = action.payload || []; })
      .addCase(getUserServices.rejected, (state, action) => { state.userServicesStatus = "rejected"; state.error = action.error.message; })
      .addCase(getUserAssignedServices.pending, (state) => { state.userAssignedServicesStatus = "pending"; })
      .addCase(getUserAssignedServices.fulfilled, (state, action) => { state.userAssignedServicesStatus = "fulfilled"; state.userAssignedServices = action.payload || []; })
      .addCase(getUserAssignedServices.rejected, (state, action) => { state.userAssignedServicesStatus = "rejected"; state.error = action.error.message; })
      .addCase(assignAServiceToAnEmployee.pending, (state) => { state.assignStatus = "pending"; })
      .addCase(assignAServiceToAnEmployee.fulfilled, (state, action) => { state.assignStatus = "fulfilled"; state.userAssignedServices = action.payload || []; })
      .addCase(assignAServiceToAnEmployee.rejected, (state, action) => { state.assignStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetPlanServicesStatus, resetUserServicesStatus, resetUserAssignedServicesStatus, resetAssignStatus, resetError } = serviceplanSlice.actions;
export default serviceplanSlice.reducer;