// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { exportPdf } from '../../../storage/slices/general/PrintSlice';
import { getAllDevices, getDeviceById, addNewDevice, updateDevice, deleteDevice, resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storage/slices/backend/DeviceSlice';
// Contexts
import { useData } from '../../../context/DataContext';
// Components
import Table from '../components/Table';
import TablePage from '../components/TablePage';
// Modals
import DeviceAddModal from '../modals/devices/DeviceAddModal';
import DeviceUpdateModal from '../modals/devices/DeviceUpdateModal';
import DeviceDeleteModal from '../modals/DeleteModal';
// Icons
import { FaPlugCirclePlus, FaPlugCircleMinus, FaPlugCircleCheck } from "react-icons/fa6";
import { TbPrinter } from 'react-icons/tb';

function Devices() {
  // Storage Import
  const dispatch = useDispatch();
  const { devices, device, getAllStatus, getDataStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.device);
  // Context Import
  const { myUser } = useData();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllDevices({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      setDataToTable(devices);
      setDataToPDF(devices);
    }
    dispatch(resetGetAllStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseDeviceAddModal();
      dispatch(addToast({ background: 'success', icon: 'deviceAdd', message: 'Cihaz Eklendi!', delay: 6000 }));
      dispatch(getAllDevices({}));
      dispatch(resetAddStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseDeviceUpdateModal();
      dispatch(addToast({ background: 'primary', icon: 'deviceUpdate', message: 'Cihaz Güncellendi!', delay: 6000 }));
      dispatch(getAllDevices({}));
      dispatch(resetUpdateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseDeviceDeleteModal();
      dispatch(addToast({ background: 'danger', icon: 'deviceDelete', message: 'Cihaz Silindi!', delay: 6000 }));
      dispatch(getAllDevices({}));
      dispatch(resetDeleteStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getDataStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataDeviceUpdateModal(device);
        setShowDeviceUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataDeviceDeleteModal(device);
        setShowDeviceDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetDataStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Components
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPDF, setDataToPDF] = useState([]);
  const rows = 10;
  const columns = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'CİHAZ' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'name', 'description'];
  const clickOn = (id) => { handleShowDeviceUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <FaPlugCircleCheck className='button-icon' />,
      onclick: ((rowData) => { handleShowDeviceUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <FaPlugCircleMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowDeviceDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  // Table Page Components
  const title = 'Cihazlar';
  const operations = [
    {
      text: 'Cihaz Ekle',
      icon: <FaPlugCirclePlus className='button-icon' />,
      onclick: (() => { handleShowDeviceAddModal(); })
    },
    {
      text: 'Yazdır',
      icon: <TbPrinter className='button-icon' />,
      onclick: (() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPDF, cols: columns, pdftitle: title })); })
    }
  ];
  const table = () => {
    return (
      <Table
        data={dataToTable}
        buttons={buttons}
        rows={rows}
        columns={columns}
        filters={filters}
        clickOn={clickOn}
      />
    );
  }
  // Modals
  // Add Modal
  const [showDeviceAddModal, setShowDeviceAddModal] = useState(false);
  const handleCloseDeviceAddModal = () => {
    setShowDeviceAddModal(false);
  }
  const handleShowDeviceAddModal = () => {
    setShowDeviceAddModal(true);
  }
  const handleAddDeviceAddModal = (values) => {
    dispatch(addNewDevice({ newdevice: values }));
  }
  // Update Modal
  const [showDeviceUpdateModal, setShowDeviceUpdateModal] = useState(false);
  const [inputDataDeviceUpdateModal, setInputDataDeviceUpdateModal] = useState(null);
  const handleCloseDeviceUpdateModal = () => {
    setShowDeviceUpdateModal(false);
    setInputDataDeviceUpdateModal(null);
  }
  const handleShowDeviceUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getDeviceById({ id: id }));
  }
  const handleUpdateDeviceUpdateModal = (values) => {
    dispatch(updateDevice({ device: values }));
  }
  // Delete Modal
  const [showDeviceDeleteModal, setShowDeviceDeleteModal] = useState(false);
  const [inputDataDeviceDeleteModal, setInputDataDeviceDeleteModal] = useState(null);
  const handleCloseDeviceDeleteModal = () => {
    setShowDeviceDeleteModal(false);
    setInputDataDeviceDeleteModal(null);
  }
  const handleShowDeviceDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getDeviceById({ id: id }));
  }
  const handleDeleteDeviceDeleteModal = () => {
    dispatch(deleteDevice({ id: inputDataDeviceDeleteModal?.id }));
  }
  // Return Modals
  const modals = useMemo(() => (
    <>
      <DeviceAddModal
        show={showDeviceAddModal}
        handleClose={handleCloseDeviceAddModal}
        handleAdd={handleAddDeviceAddModal}
      />
      <DeviceUpdateModal
        show={showDeviceUpdateModal}
        handleClose={handleCloseDeviceUpdateModal}
        handleUpdate={handleUpdateDeviceUpdateModal}
        inputData={inputDataDeviceUpdateModal}
      />
      <DeviceDeleteModal
        show={showDeviceDeleteModal}
        handleClose={handleCloseDeviceDeleteModal}
        handleDelete={handleDeleteDeviceDeleteModal}
        title="Cihazı Sil"
        message={`${inputDataDeviceDeleteModal?.name} adlı cihazı silmek istediğinize emin misiniz?`}
        deleteIcon={<FaPlugCircleMinus className='button-icon' />}
      />
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [showDeviceAddModal, showDeviceUpdateModal, inputDataDeviceUpdateModal, showDeviceDeleteModal, inputDataDeviceDeleteModal]);
  // HTML
  return (
    <TablePage Title={title} Operations={operations} Table={table} Modals={modals} />
  );
}

export default Devices;