// General
import { createContext, useContext } from 'react';
import * as Yup from 'yup';

const ValidationContext = createContext();

const ValidationProvider = ({ children }) => {
  // Validation Schema Service
  const vs_serviceadd = Yup.object().shape({

  });
  const vs_serviceupdate = Yup.object().shape({

  });
  const vs_serviceassignment = Yup.object().shape({

  });
  const vs_serviceassignmentofix = Yup.object().shape({

  });
  const vs_serviceassignmenttoend = Yup.object().shape({

  });
  // Validation Schema Customer
  const vs_customer = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir müşteri adı giriniz!')
      .matches(/^[^\s]/, 'Müşteri adı boşluk ile başlayamaz!')
      .max(40, 'Müşteri adı en fazla 40 karakter uzunluğunda olabilir!'),
    phone1: Yup.string()
      .required('Lütfen bir telefon numarası giriniz!')
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!'),
    phone2: Yup.string()
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!')
      .nullable(),
    city: Yup.string()
      .required('Lütfen bir şehir seçiniz!'),
    district: Yup.string()
      .required('Lütfen bir ilçe seçiniz!'),
    address: Yup.string()
      .required('Lütfen bir adres giriniz!')
      .matches(/^[^\s]/, 'Adres boşluk ile başlayamaz!'),
    customerType: Yup.string()
      .required('Lütfen bir müşteri tipi seçiniz!')
      .oneOf(['Bireysel', 'Kurumsal'], 'Geçersiz bir müşteri tipi girdiniz!'),
    identityNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Bireysel'),
        then: (schema) => schema
          .matches(/^[1-9]/, 'T.C. kimlik numarası "0" ile başlayamaz!')
          .matches(/^\d+$/, 'T.C. kimlik numarası yalnızca sayılardan oluşmalıdır!')
          .length(11, 'T.C. kimlik numarası tam olarak 11 haneli olmalıdır!'),
      }
      ),
    taxNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema
          .matches(/^\d+$/, 'Vergi Numarası yalnızca sayılardan oluşmalıdır!')
          .length(10, 'Vergi Numarası tam olarak 10 haneli olmalıdır!'),
      }
      ),
    taxOffice: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema
          .matches(/^[^\s]/, 'Vergi dairesi boşluk ile başlayamaz!'),
      }
      ),
  });
  // Validation Schema Employees
  const vs_employeeadd = Yup.object().shape({
    date: Yup.date()
      .required('Lütfen bir işe giriş tarihi seçiniz!'),
    roleName: Yup.string()
      .required('Lütfen bir rol seçiniz!'),
    name: Yup.string()
      .required('Lütfen bir personel adı giriniz!')
      .matches(/^[^\s]/, 'Personel adı boşluk ile başlayamaz!')
      .max(40, 'Personel adı en fazla 40 karakter uzunluğunda olabilir!'),
    username: Yup.string()
      .required('Lütfen bir personel kulanıcıadı giriniz!')
      .matches(/^[^\s]/, 'Personel kulanıcıadı boşluk ile başlayamaz!')
      .max(40, 'Personel kulanıcıadı en fazla 40 karakter uzunluğunda olabilir!'),
    password: Yup.string()
      .required('Lütfen bir şifre giriniz!')
      .min(6, 'Şifre en az 6 karakterden oluşmalıdır!')
      .max(32, 'Şifre en fazla 32 karakterden oluşabilir!'),
    phone1: Yup.string()
      .required('Lütfen bir telefon numarası giriniz!')
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!'),
    phone2: Yup.string()
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!')
      .nullable(),
    prim: Yup.number()
      .required('Lütfen geçerli bir prim değeri girin!'),
    status: Yup.string()
      .required('Lütfen personele ait bir durum seçiniz!')
      .oneOf(['Aktif', 'Aktif Değil', 'İzinli'], 'Geçersiz bir durum girdiniz!'),
    city: Yup.string()
      .required('Lütfen bir şehir seçiniz!'),
    district: Yup.string()
      .required('Lütfen bir ilçe seçiniz!'),
    address: Yup.string()
      .required('Lütfen bir adres bilgisi giriniz!')
      .matches(/^[^\s]/, 'Adres boşluk ile başlayamaz!'),
  });
  const vs_employeeupdate = Yup.object().shape({
    date: Yup.date()
      .required('Lütfen bir işe giriş tarihi seçiniz!'),
    roleName: Yup.string()
      .required('Lütfen bir rol seçiniz!'),
    name: Yup.string()
      .required('Lütfen bir personel adı giriniz!')
      .matches(/^[^\s]/, 'Personel adı boşluk ile başlayamaz!')
      .max(40, 'Personel adı en fazla 40 karakter uzunluğunda olabilir!'),
    username: Yup.string()
      .required('Lütfen bir personel kulanıcıadı giriniz!')
      .matches(/^[^\s]/, 'Personel kulanıcıadı boşluk ile başlayamaz!')
      .max(40, 'Personel kulanıcıadı en fazla 40 karakter uzunluğunda olabilir!'),
    password: Yup.string()
      .min(6, 'Şifre en az 6 karakterden oluşmalıdır!')
      .max(32, 'Şifre en fazla 32 karakterden oluşabilir!')
      .nullable(),
    phone1: Yup.string()
      .required('Lütfen bir telefon numarası giriniz!')
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!'),
    phone2: Yup.string()
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!')
      .nullable(),
    prim: Yup.number()
      .required('Lütfen geçerli bir prim değeri girin!'),
    status: Yup.string()
      .required('Lütfen personele ait bir durum seçiniz!')
      .oneOf(['Aktif', 'Aktif Değil', 'İzinli'], 'Geçersiz bir durum girdiniz!'),
    city: Yup.string()
      .required('Lütfen bir şehir seçiniz!'),
    district: Yup.string()
      .required('Lütfen bir ilçe seçiniz!'),
    address: Yup.string()
      .required('Lütfen bir adres bilgisi giriniz!')
      .matches(/^[^\s]/, 'Adres boşluk ile başlayamaz!'),
  });
  // Validation Schema Stock
  const vs_stock = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir ürün adı giriniz!')
      .matches(/^[^\s]/, 'Ürün adı boşluk ile başlayamaz!')
      .min(2, 'Ürün adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Ürün adı en fazla 40 karakter uzunluğunda olabilir!'),
    supplierName: Yup.string()
      .required('Lütfen bir tedarikçi seçiniz!'),
    barcode: Yup.string()
      .required('Lütfen barkod numarasını giriniz!'),
    brandName: Yup.string()
      .required('Lütfen bir marka seçiniz!'),
    deviceName: Yup.string()
      .required('Lütfen bir cihaz seçiniz!'),
    warehouseName: Yup.string()
      .required('Lütfen bir depo seçiniz!'),
    warehouseShelfName: Yup.string()
      .required('Lütfen bir raf seçiniz!'),
    quantity: Yup.number()
      .required('Lütfen adet sayısını giriniz!'),
    purchasePrice: Yup.number()
      .required('Lütfen bir alış fiyatı giriniz!'),
    salePrice: Yup.number()
      .required('Lütfen bir satış fiyatı giriniz!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz!')
      .oneOf(['Aktif', 'Aktif Değil'], 'Geçersiz bir durum girdiniz!'),
  });
  // Validation Schema Offers
  const vs_offer = Yup.object().shape({
    customerName: Yup.string()
      .required('Lütfen bir müşteri adı giriniz!')
      .matches(/^[^\s]/, 'Müşteri adı boşluk ile başlayamaz!')
      .max(40, 'Müşteri adı en fazla 40 karakter uzunluğunda olabilir!'),
    customerPhone1: Yup.string()
      .required('Lütfen bir telefon numarası giriniz!')
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!'),
    customerPhone2: Yup.string()
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!')
      .nullable(),
    city: Yup.string()
      .required('Lütfen bir şehir seçiniz!'),
    district: Yup.string()
      .required('Lütfen bir ilçe seçiniz!'),
    customerAddress: Yup.string()
      .required('Lütfen bir adres bilgisi giriniz!')
      .matches(/^[^\s]/, 'Adres boşluk ile başlayamaz!'),
    customerType: Yup.string()
      .required('Lütfen bir müşteri tipi seçiniz!')
      .oneOf(['Bireysel', 'Kurumsal'], 'Geçersiz bir müşteri tipi girdiniz!'),
    identityNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Bireysel'),
        then: (schema) => schema
          .matches(/^[1-9]/, 'T.C. kimlik numarası "0" ile başlayamaz!')
          .matches(/^\d+$/, 'T.C. kimlik numarası yalnızca sayılardan oluşmalıdır!')
          .length(11, 'T.C. kimlik numarası tam olarak 11 haneli olmalıdır!'),
      }
      ),
    taxNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema
          .matches(/^\d+$/, 'Vergi Numarası yalnızca sayılardan oluşmalıdır!')
          .length(10, 'Vergi Numarası tam olarak 10 haneli olmalıdır!'),
      }
      ),
    taxOffice: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema
          .matches(/^[^\s]/, 'Vergi dairesi boşluk ile başlayamaz!'),
      }
      ),
    date: Yup.date()
      .required('Lütfen teklif tarihi seçiniz!'),
    title: Yup.string()
      .required('Lütfen teklif başlığı giriniz!')
      .matches(/^[^\s].*$/, 'Teklif başlığı boşluk ile başlayamaz!')
      .max(25, 'Teklif başlığı en fazla 25 karakter olmalıdır!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz!')
      .oneOf(['Onay', 'Red', 'Beklemede'], 'Geçersiz bir durum girdiniz!'),
    totalText: Yup.string()
      .required('Lütfen toplam tutarı yazıyla giriniz!')
      .matches(/^[^\s].*$/, 'Yazıyla toplam boşluk ile başlayamaz!')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ.,\s]*$/, 'Yazıyla toplam yalnızca harflerden oluşmalıdır!'),
    kdv: Yup.string()
      .required('Lütfen bir kdv giriniz!'),
    explanation: Yup.string()
      .matches(/^\S.*$/, 'Açıklama boşluk ile başlayamaz!')
      .max(250, 'Açıklama en fazla 250 karakter olmalıdır!'),
    offerDetails: Yup.array().of(
      Yup.object().shape({
        type: Yup.string()
          .required('Lütfen bir ürün adı giriniz!')
          .matches(/^[^\s].*$/, 'Ürün adı boşluk ile başlayamaz!')
          .max(40, 'Ürün adı en fazla 40 karakter olabilir!'),
        quantity: Yup.number()
          .required('Lütfen bir ürün miktarı giriniz!')
          .min(0, 'Ürün miktarı negatif bir sayı olamaz!'),
        price: Yup.number()
          .required('Lütfen bir ürün fiyatı giriniz!')
          .min(0, 'Ürün Fiyatı negatif bir sayı olamaz!'),
      })
    ),
  });
  // Validation Schema Reminder
  const vs_reminder = Yup.object().shape({
    message: Yup.string()
      .required('Lütfen bir mesaj giriniz!')
      .matches(/^[^\s]/, 'Mesaj boşluk ile başlayamaz!')
      .max(250, 'Mesaj en fazla 250 karakter uzunluğunda olabilir!'),
    date: Yup.string()
      .required('Lütfen geçerli bir tarih seçiniz!'),
    reminderTime: Yup.string()
      .required('Lütfen geçerli bir zaman seçiniz!'),
    recurrenceInterval: Yup.string()
      .required('Lütfen bir hatırlatma zamanı seçiniz!')
      .oneOf(['Sadece Bir Kere', 'Her Gün', 'Haftada Bir Bu Gün', 'Ayda Bir Bu Tarih', 'Yılda Bir Bu Tarih'], 'Geçersiz bir hatırlatma zamanı girdiniz!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz!')
      .oneOf(['Aktif', 'Aktif Değil'], 'Geçersiz bir durum girdiniz!'),
    reminderOption: Yup.string()
      .required('Lütfen bir tekrar süresi seçiniz!')
      .oneOf(['Zamanında', 'Onbeş Dakika Önce', 'Bir Saat Önce', 'Bir Gün Önce', 'İki Gün Önce'], 'Geçersiz bir tekrar süresi girdiniz!'),
    users: Yup.array()
      .min(1, 'Lütfen en az bir tane hatıraltılacak kişi seçiniz!')
      .required('Lütfen hatıraltılacak kişi seçiniz!'),
  });
  // Validation Schema Caseaction
  const vs_caseaction = Yup.object().shape({
    date: Yup.string()
      .required('Lütfen bir tarih seçiniz!'),
    time: Yup.string()
      .required('Lütfen bir saat seçiniz!'),
    paymentType: Yup.string()
      .required('Lütfen bir ödeme yönü seçiniz!'),
    paymentMethod: Yup.string()
      .required('Lütfen bir ödeme şekli seçiniz!'),
    actionTypeName: Yup.string()
      .required('Lütfen bir ödeme türü seçiniz!'),
    status: Yup.string()
      .required('Lütfen bir ödeme durumu seçiniz!'),
    total: Yup.string()
      .required('Lütfen bir tutar giriniz!'),
    unit: Yup.string()
      .required('Lütfen bir birim seçiniz!')
  });
  // Validation Schema Invoice

  // Validation Schema Bulk Message
  const vs_bulkmessage = Yup.object().shape({
    message: Yup.string()
      .required('Lütfen bir mesaj giriniz!')
      .matches(/^\S.*$/, 'Mesaj boşluk ile başlayamaz!')
      .max(250, 'Mesaj en fazla 250 karakterden oluşabilir!'),
  });
  // Validation Schema Transaction Reports
  const vs_transactionreports = Yup.object().shape({
    serviceId: Yup.object().required("Lütfen geçerli bir servis id giriniz!"),
    fault: Yup.string()
      .required('Lütfen bir problem giriniz!')
      .matches(/^\S.*$/, 'Problem boşluk ile başlayamaz!')
      .max(250, 'Problem en fazla 250 karakterden oluşabilir!'),
    transactions: Yup.string()
      .required('Lütfen yapılan işlemleri giriniz!')
      .matches(/^\S.*$/, 'Yapılan işemler boşluk ile başlayamaz!')
      .max(250, 'Yapılan işemler en fazla 250 karakterden oluşabilir!'),
    informations: Yup.string()
      .required('Lütfen işlem bilgilerini giriniz!')
      .matches(/^\S.*$/, 'İşlem bilgileri boşluk ile başlayamaz!')
      .max(250, 'İşlem bilgileri en fazla 250 karakterden oluşabilir!'),
    total: Yup.number()
      .required('Lütfen işlem maliyetini giriniz!'),
  });
  // Validation Schema Vehicle
  const vs_vehicle = Yup.object().shape({
    plate: Yup.string()
      .required('Lütfen bir araç plakası giriniz!')
      .matches(/^(0[1-9]|[1-7][0-9]|8[01])[A-Z]{1,3}\d{1,4}$|^(0[1-9]|[1-7][0-9]|8[01]) [A-Z]{1,3} \d{1,4}$/, 'Hatalı araç plakası girdiniz!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .max(200, 'Açıklama en fazla 200 karakterden oluşabilir!'),
  });
  // Validation Schema Supplier
  const vs_supplier = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir tedarikçi adı giriniz!')
      .matches(/^[^\s]/, 'Tedarikçi adı boşluk ile başlayamaz!')
      .max(40, 'Tedarikçi adı en fazla 40 karakter uzunluğunda olabilir!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz')
      .oneOf(['Aktif', 'Aktif Değil'], 'Geçersiz bir durum girdiniz!'),
  });
  // Validation Schema Warehouse
  const vs_warehouse = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir depo adı giriniz!')
      .matches(/^[^\s]/, 'Depo adı boşluk ile başlayamaz!')
      .max(40, 'Depo adı en fazla 40 karakter uzunluğunda olabilir!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz')
      .oneOf(['Aktif', 'Aktif Değil'], 'Geçersiz bir durum girdiniz!'),
  });
  // Validation Schema Shelf
  const vs_shelf = Yup.object().shape({
    warehousename: Yup.string()
      .required('Lütfen bir depo seçiniz!'),
    name: Yup.string()
      .required('Lütfen bir raf adı giriniz!')
      .matches(/^[^\s]/, 'Raf adı boşluk ile başlayamaz!')
      .max(40, 'Raf adı en fazla 40 karakter uzunluğunda olabilir!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .max(200, 'Açıklama en fazla 200 karakterden oluşabilir!'),
  });
  // Validation Schema Role
  const vs_role = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir rol adı giriniz!')
      .matches(/^[^\s]/, 'Rol adı boşluk ile başlayamaz!')
      .max(40, 'Rol adı en fazla 40 karakter uzunluğunda olabilir!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .max(200, 'Açıklama en fazla 200 karakterden oluşabilir!'),
    isPremium: Yup.string()
      .required('Bu rolün prim alabilir olup olmadığını seçiniz!')
      .oneOf(['Evet', 'Hayır'], 'Geçersiz bir prim durumu girdiniz!'),
  });
  // Validation Schema Brand
  const vs_brand = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir marka adı giriniz!')
      .matches(/^[^\s]/, 'Marka adı boşluk ile başlayamaz!')
      .max(40, 'Marka adı en fazla 40 karakter uzunluğunda olabilir!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .max(200, 'Açıklama en fazla 200 karakterden oluşabilir!'),
  });
  // Validation Schema Devices
  const vs_device = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir cihaz adı giriniz!')
      .matches(/^[^\s]/, 'Cihaz adı boşluk ile başlayamaz!')
      .max(40, 'Cihaz adı en fazla 40 karakter uzunluğunda olabilir!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .max(200, 'Açıklama en fazla 200 karakterden oluşabilir!'),
  });
  // Validation Schema Dealer
  const vs_dealer = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir bayi adı giriniz!')
      .matches(/^[^\s]/, 'Bayi adı boşluk ile başlayamaz!')
      .max(40, 'Bayi adı en fazla 40 karakter uzunluğunda olabilir!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz')
      .oneOf(['Aktif', 'Aktif Değil'], 'Geçersiz bir durum girdiniz!'),
  });
  // Validation Schema Source
  const vs_source = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir kaynak adı giriniz!')
      .matches(/^[^\s]/, 'Kaynak adı boşluk ile başlayamaz!')
      .max(40, 'Kaynak adı en fazla 40 karakter uzunluğunda olabilir!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz')
      .oneOf(['Aktif', 'Aktif Değil'], 'Geçersiz bir durum girdiniz!'),
  });
  // Validation Schema Consigment Device
  const vs_consigmentdevice = Yup.object().shape({
    brandName: Yup.string()
      .required('Lütfen bir marka seçiniz'),
    deviceName: Yup.string()
      .required('Lütfen bir cihaz seçiniz'),
    model: Yup.string()
      .required('Lütfen bir model giriniz!')
      .matches(/^[^\s]/, 'Model boşluk ile başlayamaz!')
      .max(60, 'Model en fazla 60 karakter uzunluğunda olabilir!'),
    quantity: Yup.number()
      .required('Lütfen bir miktar giriniz')
      .min(0, 'Miktar negatif bir değer olmamalıdır'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz')
      .oneOf(['Müsait', 'Kullanımda', 'Silindi'], 'Geçersiz bir durum girdiniz!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .max(200, 'Açıklama en fazla 200 karakterden oluşabilir!'),
  });
  // Validation Schema Survey Question
  const vs_surveyquestion = Yup.object().shape({
    text: Yup.string()
      .required('Lütfen bir soru giriniz!')
      .matches(/^[^\s]/, 'Soru boşluk ile başlayamaz!')
      .matches(/\?$/, 'Soru bir soru işareti ile bitmelidir!')
      .max(200, 'Soru en fazla 200 karakter uzunluğunda olabilir!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz')
      .oneOf(['Aktif', 'Aktif Değil'], 'Geçersiz bir durum girdiniz!'),
  });
  // Export
  const values = { vs_serviceadd, vs_serviceupdate, vs_serviceassignment, vs_serviceassignmenttoend, vs_serviceassignmentofix, vs_customer, vs_employeeadd, vs_employeeupdate, vs_stock, vs_offer, vs_reminder, vs_caseaction, vs_bulkmessage, vs_transactionreports, vs_vehicle, vs_supplier, vs_warehouse, vs_shelf, vs_role, vs_brand, vs_device, vs_dealer, vs_source, vs_consigmentdevice, vs_surveyquestion }
  return <ValidationContext.Provider value={values}>{children}</ValidationContext.Provider>
}

const useValidations = () => useContext(ValidationContext);

export { ValidationProvider, useValidations };