// General
import React, { useEffect, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storage/slices/general/ToastSlice';
import { getQuestionStatistics, resetStatisticsStatus, resetError } from '../../../storage/slices/backend/SurveyquestionSlice';
// Contexts
import { useData } from '../../../context/DataContext';
// Components
import Table from '../components/Table';
import PieChart from '../components/PieChart';

function Surveys() {
  // Storage Import
  const dispatch = useDispatch();
  const { statistics, statisticsStatus, error } = useSelector((state) => state.surveyquestion);
  // Context Import
  const { myUser } = useData();
  // Api Operations
  const [canfetchData, setCanFetchData] = useState(false);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getQuestionStatistics({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (statisticsStatus === 'fulfilled') {
      const data = statistics.map((data) => {
        const id = data.standartQuestionId;
        const question = data.standartQuestionText;
        const yes = data.answersSummaryDtos.find(item => item.answerType === 'Evet')?.count;
        const no = data.answersSummaryDtos.find(item => item.answerType === 'Hayır')?.count;
        const uncertain = data.answersSummaryDtos.find(item => item.answerType === 'Belirsiz')?.count;
        return { id, question, yes, no, uncertain };
      });
      setDataToTable(data);
      dispatch(resetStatisticsStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsStatus]);
  useEffect(() => {
    if (error && error !== 'canceled') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 4000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Data Operaitons
  const [dataToTable, setDataToTable] = useState([]);
  // HTML
  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div className='card mt-3'>
            <div className="card-header">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0">Anket Tablosu</h5>
              </div>
            </div>
            <div className='card-body'>
              <Table
                data={dataToTable}
                rows={10}
                columns={[{ value: 'id', name: 'ID' }, { value: 'question', name: 'SORU' }, { value: 'yes', name: 'EVET' }, { value: 'no', name: 'HAYIR' }, { value: 'uncertain', name: 'BELİRSİZ' }]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='row'>
          {dataToTable.map((data, index) => {
            return (
              <div key={index} className="col-12 col-md-6">
                <div className="card-body">
                  <h6><b>{data.id}. {data.question}</b></h6>
                  <PieChart
                    data={[{ name: 'Evet', value: data.yes }, { name: 'Hayır', value: data.no }, { name: 'Belirsiz', value: data.uncertain }]}
                    unit='Adet'
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Surveys;