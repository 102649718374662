// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Devices/';

// Async Thunks
export const getAllDevices = createAsyncThunk('backend_getalldevices', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllDevices`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getDeviceById = createAsyncThunk('backend_getdevicebyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetDeviceById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const getDeviceByName = createAsyncThunk('backend_getdevicebyname', async ({ name }) => {
  try {
    const res = await axios.get(`${baseurl}GetDeviceByName?name=${name}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewDevice = createAsyncThunk('backend_addnewdevice', async ({ newdevice }) => {
  try {
    const res = await axios.post(`${baseurl}AddDevice`, newdevice, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateDevice = createAsyncThunk('backend_updatedevice', async ({ device }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateDevice`, device, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteDevice = createAsyncThunk('backend_deletedevice', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteDevice?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    devices: [],
    device: {},
    getAllStatus: null,
    getDataStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetGetDataStatus: (state) => { state.getDataStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDevices.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllDevices.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.devices = action.payload || []; })
      .addCase(getAllDevices.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getDeviceById.pending, (state) => { state.getDataStatus = "pending"; })
      .addCase(getDeviceById.fulfilled, (state, action) => { state.getDataStatus = "fulfilled"; state.device = action.payload || {}; })
      .addCase(getDeviceById.rejected, (state, action) => { state.getDataStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewDevice.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewDevice.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewDevice.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateDevice.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateDevice.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateDevice.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteDevice.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteDevice.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteDevice.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = deviceSlice.actions;
export default deviceSlice.reducer;