// General
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// Context
import { useData } from '../../context/DataContext';
// Icons
import { TbHome, TbTags, TbMessage2, TbLayoutGrid, TbDeviceIpadHorizontal } from "react-icons/tb";
import { MdDevicesOther, MdShelves } from "react-icons/md";
import { LuChevronLeft, LuChevronDown, LuUser, LuUsers, LuPackage, LuCalendar, LuClipboard, LuChartPie, LuCar, LuSquareUser, LuWarehouse, LuBadge, LuPlug2, LuMapPinHouse } from "react-icons/lu";
import { TbDatabase, TbCashRegister, TbMessages } from "react-icons/tb";
import { HiOutlineDocumentDuplicate, HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { BsTelephone } from "react-icons/bs";
import { IoMdHelpCircleOutline } from "react-icons/io";
// Image
import logo from '../../assets/images/logo.png';

function Sidebar() {
  // Context Import
  const { sidebar, setSidebar } = useData();
  // Variables
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  //Functions
  const toggleMenu = () => { setIsOpen((prev) => !prev); }
  const closeMenu = () => { if (isMobile) { setSidebar(false); } }
  // HTML
  return (
    <>
      <div className="app-sidebar-menu" style={{ width: `${sidebar ? '260px' : '0px'}` }}>
        <div className="h-100 sidebarscrollbar">
          <div id="sidebar-menu">
            <div className="logo-box pt-3 pb-0">
              <NavLink to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="20" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="20" />
                </span>
              </NavLink>
              <NavLink to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="20" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="20" />
                </span>
              </NavLink>
            </div>
            <ul id="side-menu">
              <li className="menu-title pb-1">MENÜ</li>
              <li>
                <NavLink to="/anasayfa" className="tp-link pt-0 pb-1" onClick={closeMenu}>
                  <TbHome /><span>Anasayfa</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/servisler" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                  <TbDatabase /><span>Servisler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/musteriler" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                  <LuUser /><span>Müşteriler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/personeller" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                  <LuUsers /><span>Personeller</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/depo" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                  <LuPackage /><span>Depo</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/teklifler" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                  <TbTags /><span>Teklifler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/hatirlaticilar" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                  <LuCalendar /><span>Hatırlatıcılar</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/anketler" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                  <LuClipboard /><span>Anketler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/istatistikler" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                  <LuChartPie /><span>İstatistikler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/kasa" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                  <TbCashRegister /><span>Kasa</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/faturalar" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                  <HiOutlineDocumentDuplicate /><span>Faturalar</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/toplumesaj" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                  <TbMessage2 /><span>Toplu SMS</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/islemraporlari" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                  <HiOutlineClipboardDocumentList /><span>İşlem Raporları</span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={toggleMenu} className="d-flex align-items-center pt-1 pb-1">
                  <MdDevicesOther className="priorityicon me-1" />
                  <span>Diğer</span>
                  {isOpen ? <LuChevronLeft className='menu-arrow' /> : <LuChevronDown className='menu-arrow' />}
                </NavLink>
                {isOpen && (
                  <div className="collapse show">
                    <ul className="nav-second-level ps-2">
                      <li>
                        <NavLink to="/araclar" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                          <LuCar className='fs-4 me-2 ms-1' />
                          <span>Araçlar</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/tedarikciler" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                          <LuSquareUser className='fs-4 me-2 ms-1' />
                          <span>Tedarikçiler</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/depolar" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                          <LuWarehouse className='fs-4 me-2 ms-1' />
                          <span>Depolar</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/raflar" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                          <MdShelves className='fs-4 me-2 ms-1' />
                          <span>Raflar</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/roller" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                          <TbLayoutGrid className='fs-4 me-2 ms-1' />
                          <span>Roller</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/markalar" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                          <LuBadge className='fs-4 me-2 ms-1' />
                          <span>Markalar</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cihazlar" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                          <LuPlug2 className='fs-4 me-2 ms-1' />
                          <span>Cihazlar</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/bayiler" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                          <LuMapPinHouse className='fs-4 me-2 ms-1' />
                          <span>Bayiler</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/kaynaklar" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                          <BsTelephone className='fs-4 me-2 ms-1' />
                          <span>Kaynaklar</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/konsinyecihazlar" className="tp-link pt-1 pb-1" onClick={closeMenu}>
                          <TbDeviceIpadHorizontal className='fs-4 me-2 ms-1' />
                          <span>Konsinye Cihazlar</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/anketsorulari" className="tp-link pt-1 pb-0" onClick={closeMenu}>
                          <IoMdHelpCircleOutline className='fs-4 me-2 ms-1' />
                          <span>Anket Soruları</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/mesajlar" className="tp-link pt-1 pb-0" onClick={closeMenu}>
                          <TbMessages className='fs-4 me-2 ms-1' />
                          <span>Mesajlar</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
}
export default Sidebar;