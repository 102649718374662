// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Statics/';

// Async Thunks
export const getServicesStatistics = createAsyncThunk('backend_getservicesstatistics', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesStatics?startDate=${start}&endDate=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getOperatorStatistics = createAsyncThunk('backend_getoperatorstatistics', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetOperatorStatics?startDate=${start}&endDate=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getCaseActionIncomesStatistics = createAsyncThunk('backend_getcaseactionincomesstatistics', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetCaseActionIncomesStatics?startDate=${start}&endDate=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getCaseActionOutcomesStatistics = createAsyncThunk('backend_getcaseactionoutcomesstatistics', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetCaseActionOutcomesStatics?startDate=${start}&endDate=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getServiceStatusStatistics = createAsyncThunk('backend_getservicestatusstatistics', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetServiceStatusStatics?startDate=${start}&endDate=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getServiceDistrictStatistics = createAsyncThunk('backend_getservicedistrictstatistics', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetServiceDistrictStatics?startDate=${start}&endDate=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});

// Slice
const statisticSlice = createSlice({
  name: 'statistic',
  initialState: {
    serviceStatistics: [],
    serviceStatisticsStatus: null,
    operatorStatistics: [],
    operatorStatisticsStatus: null,
    caseActionIncomesStatistics: [],
    caseActionIncomesStatisticsStatus: null,
    caseActionOutcomesStatistics: [],
    caseActionOutcomesStatisticsStatus: null,
    serviceStatusStatistics: [],
    serviceStatusStatisticsStatus: null,
    serviceDistrictStatistics: [],
    serviceDistrictStatisticsStatus: null,
    error: null
  },
  reducers: {
    resetServiceStatisticsStatus: (state) => { state.serviceStatisticsStatus = null; },
    resetOperatorStatisticsStatus: (state) => { state.operatorStatisticsStatus = null; },
    resetCaseActionIncomesStatisticsStatus: (state) => { state.caseActionIncomesStatisticsStatus = null; },
    resetCaseActionOutcomesStatisticsStatus: (state) => { state.caseActionOutcomesStatisticsStatus = null; },
    resetServiceStatusStatisticsStatus: (state) => { state.serviceStatusStatisticsStatus = null; },
    resetServiceDistrictStatisticsStatus: (state) => { state.serviceDistrictStatisticsStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServicesStatistics.pending, (state) => { state.serviceStatisticsStatus = "pending"; })
      .addCase(getServicesStatistics.fulfilled, (state, action) => { state.serviceStatisticsStatus = "fulfilled"; state.serviceStatistics = action.payload || []; })
      .addCase(getServicesStatistics.rejected, (state, action) => { state.serviceStatisticsStatus = "rejected"; state.error = action.error.message; })
      .addCase(getOperatorStatistics.pending, (state) => { state.operatorStatisticsStatus = "pending"; })
      .addCase(getOperatorStatistics.fulfilled, (state, action) => { state.operatorStatisticsStatus = "fulfilled"; state.operatorStatistics = action.payload || []; })
      .addCase(getOperatorStatistics.rejected, (state, action) => { state.operatorStatisticsStatus = "rejected"; state.error = action.error.message; })
      .addCase(getCaseActionIncomesStatistics.pending, (state) => { state.caseActionIncomesStatisticsStatus = "pending"; })
      .addCase(getCaseActionIncomesStatistics.fulfilled, (state, action) => { state.caseActionIncomesStatisticsStatus = "fulfilled"; state.caseActionIncomesStatistics = action.payload || []; })
      .addCase(getCaseActionIncomesStatistics.rejected, (state, action) => { state.caseActionIncomesStatisticsStatus = "rejected"; state.error = action.error.message; })
      .addCase(getCaseActionOutcomesStatistics.pending, (state) => { state.caseActionOutcomesStatisticsStatus = "pending"; })
      .addCase(getCaseActionOutcomesStatistics.fulfilled, (state, action) => { state.caseActionOutcomesStatisticsStatus = "fulfilled"; state.caseActionOutcomesStatistics = action.payload || []; })
      .addCase(getCaseActionOutcomesStatistics.rejected, (state, action) => { state.caseActionOutcomesStatisticsStatus = "rejected"; state.error = action.error.message; })
      .addCase(getServiceStatusStatistics.pending, (state) => { state.serviceStatusStatisticsStatus = "pending"; })
      .addCase(getServiceStatusStatistics.fulfilled, (state, action) => { state.serviceStatusStatisticsStatus = "fulfilled"; state.serviceStatusStatistics = action.payload || []; })
      .addCase(getServiceStatusStatistics.rejected, (state, action) => { state.serviceStatusStatisticsStatus = "rejected"; state.error = action.error.message; })
      .addCase(getServiceDistrictStatistics.pending, (state) => { state.serviceDistrictStatisticsStatus = "pending"; })
      .addCase(getServiceDistrictStatistics.fulfilled, (state, action) => { state.serviceDistrictStatisticsStatus = "fulfilled"; state.serviceDistrictStatistics = action.payload || []; })
      .addCase(getServiceDistrictStatistics.rejected, (state, action) => { state.serviceDistrictStatisticsStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetServiceStatisticsStatus, resetOperatorStatisticsStatus, resetCaseActionIncomesStatisticsStatus, resetCaseActionOutcomesStatisticsStatus, resetServiceStatusStatisticsStatus, resetServiceDistrictStatisticsStatus, resetError } = statisticSlice.actions;
export default statisticSlice.reducer;