// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Reminders/';

// Async Thunks
export const getAllReminders = createAsyncThunk('backend_getallreminders', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllReminders`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getRemindersByDate = createAsyncThunk('backend_getremindersbydate', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetRemindersByDate?start=${start}&end=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getReminderById = createAsyncThunk('backend_getreminderbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetReminderById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewReminder = createAsyncThunk('backend_addnewreminder', async ({ newreminder }) => {
  try {
    const res = await axios.post(`${baseurl}AddReminder`, newreminder, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateReminder = createAsyncThunk('backend_updatereminder', async ({ reminder }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateReminder`, reminder, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteReminder = createAsyncThunk('backend_deletereminder', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteReminder?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const reminderSlice = createSlice({
  name: 'reminder',
  initialState: {
    reminders: [],
    reminder: {},
    getAllStatus: null,
    getDataStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetGetDataStatus: (state) => { state.getDataStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllReminders.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllReminders.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.reminders = action.payload || []; })
      .addCase(getAllReminders.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getRemindersByDate.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getRemindersByDate.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.reminders = action.payload || []; })
      .addCase(getRemindersByDate.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getReminderById.pending, (state) => { state.getDataStatus = "pending"; })
      .addCase(getReminderById.fulfilled, (state, action) => { state.getDataStatus = "fulfilled"; state.reminder = action.payload || {}; })
      .addCase(getReminderById.rejected, (state, action) => { state.getDataStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewReminder.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewReminder.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewReminder.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateReminder.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateReminder.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateReminder.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteReminder.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteReminder.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteReminder.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
});

export const { resetGetAllStatus, resetGetDataStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = reminderSlice.actions;
export default reminderSlice.reducer;