// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useSelector } from 'react-redux';
// Contexts
import { useData } from '../../../../context/DataContext';
import { useFunctions } from '../../../../context/FunctionContext';
import { useValidations } from '../../../../context/ValidationContext';
// Icons
import { LuCalendarCog } from "react-icons/lu";
import { TbArrowBackUpDouble } from "react-icons/tb";
import { TiDelete } from 'react-icons/ti';

function ReminderUpdateModal({ show, handleClose, handleAdd, inputData }) {
  // Storage Import
  const { employees } = useSelector((state) => state.employee);
  // Context Import
  const { statuses, recurrenceIntervalTypes, reminderTimeOptions } = useData();
  const { getStringDate } = useFunctions();
  const { vs_reminder } = useValidations();
  // Formik Variables
  const initialValues = {
    id: inputData?.id || -1,
    userId: inputData?.userId || -1,
    message: inputData?.message || '',
    date: inputData?.date || getStringDate(0),
    reminderTime: inputData?.reminderTime || '12:00',
    recurrenceInterval: inputData?.recurrenceInterval || '',
    status: inputData?.status || '',
    reminderOption: inputData?.reminderOption || '',
    users: inputData?.users || []
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header className='py-2' closeButton>
          <Modal.Title>Hatırlatıcıyı Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={initialValues} validationSchema={vs_reminder} onSubmit={(values) => handleAdd(values)}>
            {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id='ReminderUpdateModal_date'
                      name='date'
                      label='Hatırlatma tarihi'
                      type="date"
                      value={values.date}
                      onChange={(e) => { setFieldValue('date', e.target.value); }}
                      InputLabelProps={{ shrink: true, }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.date && !!errors.date}
                      helperText={touched.date && errors.date}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id='ReminderUpdateModal_reminderTime'
                      name='reminderTime'
                      label='Hatırlatma saati'
                      type="reminderTime"
                      value={values.reminderTime}
                      onChange={(e) => { setFieldValue('reminderTime', e.target.value); }}
                      InputLabelProps={{ shrink: true, }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.reminderTime && !!errors.reminderTime}
                      helperText={touched.reminderTime && errors.reminderTime}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <Field
                      as={Autocomplete}
                      id="ReminderUpdateModal_recurrenceInterval"
                      name="recurrenceInterval"
                      options={recurrenceIntervalTypes}
                      value={values.recurrenceInterval}
                      onChange={(event, newValue) => { setFieldValue('recurrenceInterval', newValue); }}
                      onBlur={() => { setFieldTouched('recurrenceInterval', true); }}
                      variant='outlined'
                      size='small'
                      renderInput={(params) => <TextField {...params} label="Hatırlatma Zamanı" error={touched.recurrenceInterval && !!errors.recurrenceInterval} helperText={touched.recurrenceInterval && errors.recurrenceInterval} />}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <Field
                      as={Autocomplete}
                      id="ReminderUpdateModal_reminderOption"
                      name="reminderOption"
                      options={reminderTimeOptions}
                      value={values.reminderOption}
                      onChange={(event, newValue) => { setFieldValue('reminderOption', newValue); }}
                      onBlur={() => { setFieldTouched('reminderOption', true); }}
                      variant='outlined'
                      size='small'
                      renderInput={(params) => <TextField {...params} label="Tekrar Süresi" error={touched.reminderOption && !!errors.reminderOption} helperText={touched.repeatTreminderOptionime && errors.reminderOption} />}
                    />
                  </div>
                  <div className="col-12 col-md-12 ">
                    <Field
                      as={Autocomplete}
                      id="ReminderUpdateModal_status"
                      name="status"
                      options={statuses}
                      value={values.status}
                      onChange={(event, newValue) => { setFieldValue('status', newValue); }}
                      onBlur={() => { setFieldTouched('status', true); }}
                      variant='outlined'
                      size='small'
                      renderInput={(params) => <TextField {...params} label="Durum" error={touched.status && !!errors.status} helperText={touched.status && errors.status} />}
                    />
                  </div>
                  <div className="col-12 col-md-12 ">
                    <Autocomplete
                      id='ReminderUpdateModal_users'
                      name='users'
                      options={employees.map(item => item.name)}
                      value={values.users || []}
                      onChange={(event, newValue) => { setFieldValue('users', newValue) }}
                      size='small'
                      fullWidth
                      multiple
                      limitTags={2}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={option}
                              label={option}
                              size='small'
                              {...tagProps}
                              deleteIcon={
                                <TiDelete
                                  type='button'
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const newValues = values.users.filter((i) => i !== option);
                                    setFieldValue("users", newValues);
                                  }}
                                />
                              }
                            />
                          );
                        })
                      }
                      renderInput={(params) => <TextField {...params} label="Hatırlatılacak Kişiler" error={touched.users && !!errors.users} helperText={touched.users && errors.users} />}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='ReminderUpdateModal_message'
                      name='message'
                      label="Mesaj"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none' } } }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.message && !!errors.message}
                      helperText={touched.message && errors.message}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                        <TbArrowBackUpDouble className='button-icon' />
                        Geri
                      </button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-primary w-100' >
                        <LuCalendarCog className='button-icon' />
                        Güncelle
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  )
}

export default ReminderUpdateModal;