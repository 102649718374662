// General
import React from 'react';
// styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Field, Form, Formik } from 'formik';
// Contexts
import { useData } from '../../../../context/DataContext';
import { useFunctions } from '../../../../context/FunctionContext';
import { useValidations } from '../../../../context/ValidationContext';
// Components
import CaseactionType from '../../components/CaseactionType';
// Icons
import { TbArrowBackUpDouble, TbCashBanknoteFilled } from 'react-icons/tb';

function CaseactionUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Context Import
  const { myUser, currencies, caseactionTypes, caseactionMethods, caseactionOperations, caseactionStatuses } = useData();
  const { getStringDate } = useFunctions();
  const { vs_caseaction } = useValidations();
  // Formik Variables
  const initialValues = {
    id: inputData?.id || -1,
    userId: myUser?.id || -1,
    date: inputData?.date || getStringDate(0),
    time: inputData?.time || '12:00',
    paymentType: inputData?.paymentType || '',
    paymentMethod: inputData?.paymentMethod || '',
    actionTypeName: inputData?.actionTypeName || '',
    status: inputData?.status || '',
    total: inputData?.total || null,
    unit: inputData?.unit || '',
    informations: inputData?.informations || {},
    description: '',
    supplier: '',
    employee: '',
    serviceId: -1,
    brand: '',
    device: ''
  }
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header className='py-2' closeButton>
        <Modal.Title>Kasa İşlemini Güncelle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} validationSchema={vs_caseaction} onSubmit={(values) => handleUpdate(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form>
              <div className='row g-2'>
                <div className='col-12 col-md-8'>
                  <Field
                    as={TextField}
                    id='CaseactionUpdateModal_date'
                    name='date'
                    label='Ödeme Tarih'
                    type="date"
                    variant='outlined'
                    size='small'
                    value={values.date}
                    onChange={(e) => setFieldValue('date', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <Field
                    as={TextField}
                    id='CaseactionUpdateModal_time'
                    name='time'
                    label='Saat'
                    type="time"
                    variant='outlined'
                    size='small'
                    value={values.time}
                    onChange={(e) => setFieldValue('time', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.time && !!errors.time}
                    helperText={touched.time && errors.time}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id="CaseactionUpdateModal_paymentType"
                    name="paymentType"
                    value={values.paymentType}
                    onChange={(event, newValue) => { setFieldValue('paymentType', newValue); }}
                    onBlur={() => { setFieldTouched('paymentType', true); }}
                    variant='outlined'
                    size='small'
                    options={caseactionTypes}
                    renderInput={(params) => <TextField {...params} label="Ödeme Yönü" error={touched.paymentType && !!errors.paymentType} helperText={touched.paymentType && errors.paymentType} />}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id="CaseactionUpdateModal_paymentMethod"
                    name="paymentMethod"
                    value={values.paymentMethod}
                    onChange={(event, newValue) => { setFieldValue('paymentMethod', newValue); }}
                    onBlur={() => { setFieldTouched('paymentMethod', true); }}
                    variant='outlined'
                    size='small'
                    options={caseactionMethods}
                    renderInput={(params) => <TextField {...params} label="Ödeme Şekli" error={touched.paymentMethod && !!errors.paymentMethod} helperText={touched.paymentMethod && errors.paymentMethod} />}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id="CaseactionUpdateModal_actionTypeName"
                    name="actionTypeName"
                    value={values.actionTypeName}
                    onChange={(event, newValue) => { setFieldValue('actionTypeName', newValue); }}
                    onBlur={() => { setFieldTouched('actionTypeName', true); }}
                    variant='outlined'
                    size='small'
                    options={caseactionOperations}
                    renderInput={(params) => <TextField {...params} label="Ödeme Türü" error={touched.actionTypeName && !!errors.actionTypeName} helperText={touched.actionTypeName && errors.actionTypeName} />}
                  />
                </div>
                <CaseactionType errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} type={values.actionTypeName} />
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id="CaseactionUpdateModal_status"
                    name="status"
                    value={values.status}
                    onChange={(event, newValue) => { setFieldValue('status', newValue); }}
                    onBlur={() => { setFieldTouched('status', true); }}
                    variant='outlined'
                    size='small'
                    options={caseactionStatuses}
                    renderInput={(params) => <TextField {...params} label="Ödeme Durumu" error={touched.status && !!errors.status} helperText={touched.status && errors.status} />}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='CaseactionUpdateModal_total'
                    name='total'
                    label="Tutar"
                    type="number"
                    value={values.total}
                    onChange={(e) => setFieldValue('total', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.total && !!errors.total}
                    helperText={touched.total && errors.total}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id="CaseactionUpdateModal_unit"
                    name="unit"
                    value={values.unit}
                    onChange={(event, newValue) => { setFieldValue('unit', newValue); }}
                    onBlur={() => { setFieldTouched('unit', true); }}
                    variant='outlined'
                    size='small'
                    options={currencies}
                    renderInput={(params) => <TextField {...params} label="Birim" error={touched.unit && !!errors.unit} helperText={touched.unit && errors.unit} />}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                    <TbArrowBackUpDouble className='button-icon' />
                    Geri
                  </button>
                </div>
                <div className='col-12 col-md-6'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'>
                    <TbCashBanknoteFilled className='button-icon' />
                    Güncelle
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default CaseactionUpdateModal;