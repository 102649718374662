// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Auth/';

// Async Thunks
export const login = createAsyncThunk('backend_login', async ({ username, password }) => {
  try {
    const res = await axios.post(`${baseurl}login`, { username, password }, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const logout = createAsyncThunk('backend_logout', async () => {
  try {
    const res = await axios.get(`${baseurl}Logout`, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const validate = createAsyncThunk('backend_validate', async () => {
  try {
    const res = await axios.get(`${baseurl}Validate`, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const getUserInfo = createAsyncThunk('backend_getuserinfo', async () => {
  try {
    const res = await axios.get(`${baseurl}UserInfo`, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const loginSlice = createSlice({
  name: 'login',
  initialState: {
    userinfo: null,
    loginStatus: null,
    logoutStatus: null,
    validateStatus: null,
    userInfoStatus: null,
    error: null
  },
  reducers: {
    resetLoginStatus: (state) => { state.loginStatus = null; },
    resetLogoutStatus: (state) => { state.logoutStatus = null; },
    resetValidateStatus: (state) => { state.validateStatus = null; },
    resetUserInfoStatus: (state) => { state.userInfoStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => { state.loginStatus = 'pending'; })
      .addCase(login.fulfilled, (state) => { state.loginStatus = 'fulfilled'; })
      .addCase(login.rejected, (state, action) => { state.loginStatus = 'rejected'; state.error = action.error.message; })
      .addCase(logout.pending, (state) => { state.logoutStatus = 'pending'; })
      .addCase(logout.fulfilled, (state) => { state.logoutStatus = 'fulfilled'; })
      .addCase(logout.rejected, (state) => { state.logoutStatus = 'rejected'; })
      .addCase(validate.pending, (state) => { state.validateStatus = 'pending'; })
      .addCase(validate.fulfilled, (state) => { state.validateStatus = 'fulfilled'; })
      .addCase(validate.rejected, (state) => { state.validateStatus = 'rejected'; state.userinfo = null; })
      .addCase(getUserInfo.pending, (state) => { state.userInfoStatus = 'pending'; })
      .addCase(getUserInfo.fulfilled, (state, action) => { state.userInfoStatus = 'fulfilled'; state.userinfo = action.payload; })
      .addCase(getUserInfo.rejected, (state) => { state.userInfoStatus = 'rejected'; state.userinfo = null; });
  }
});

export const { resetLoginStatus, resetLogoutStatus, resetValidateStatus, resetUserInfoStatus, resetError } = loginSlice.actions;
export default loginSlice.reducer;