// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Dashboards/';

// Async Thunks
export const getDashboardData = createAsyncThunk('backend_getdashboarddata', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetDashboardDetails`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});

// Slice
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    dashboardDatas: [],
    status: null,
    error: null
  },
  reducers: {
    resetStatus: (state) => { state.status = null },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardData.pending, (state) => { state.status = "pending"; })
      .addCase(getDashboardData.fulfilled, (state, action) => { state.status = "fulfilled"; state.dashboardDatas = action.payload || []; })
      .addCase(getDashboardData.rejected, (state, action) => { state.status = "rejected"; state.error = action.error.message; });
  }
});

export const { resetStatus, resetError } = dashboardSlice.actions;
export default dashboardSlice.reducer;