// General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
// Storages
import { useSelector } from 'react-redux';
// Components
import Table from '../../components/Table';

function UserServicesModal({ show, handleClose, username }) {
  // Storage Import
  const { userAssignedServices } = useSelector((state) => state.serviceplan);
  // Data Operations
  const [data, setData] = useState([]);
  useEffect(() => { setData(userAssignedServices); }, [userAssignedServices]);
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered className='modal-xl' backdrop="static">
      <Modal.Header className='py-2' closeButton>
        <Modal.Title><b>{username || ''}</b> Adlı Personele Atanmış Aktif Servisler</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table
          data={data}
          rows={5}
          columns={[{ value: 'id', name: 'ID' }, { value: 'serviceDate', name: 'TARİH' }, { value: 'status', name: 'DURUM' }]}
          headerAlign='center'
        />
      </Modal.Body>
    </Modal>
  );
}

export default UserServicesModal;