// General
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Styles
import { Formik, Form, Field } from 'formik';
import { TextField, InputAdornment, IconButton } from '@mui/material';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { login, resetLoginStatus } from '../../../storage/slices/backend/LoginSlice';
import { addToast } from '../../../storage/slices/general/ToastSlice';
// Icons
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { FaDoorOpen } from "react-icons/fa";
// Images
import logo from '../../../assets/images/logo.png';

function Login() {
  // Storage Import
  const dispatch = useDispatch();
  const { loginStatus } = useSelector((state) => state.login);
  // Variables
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => { setShowPassword(!showPassword) }
  // Functions
  const handleLogin = (values) => {
    dispatch(login({ username: values.username, password: values.password }));
  }
  const navigate = useNavigate();
  useEffect(() => {
    if (loginStatus === 'fulfilled') {
      navigate("/anasayfa");
      dispatch(addToast({ background: 'success', icon: 'login', message: 'Başarı ile giriş Yaptınız!', delay: 2000 }));
      dispatch(resetLoginStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);
  // HTML
  return (
    <section className="login-container">
      <div className='card card-login bg-light shadow'>
        <div className='card-body d-flex flex-column justify-content-center align-items-center'>
          <div className='d-flex flex-column justify-content-center align-items-center mb-5'>
            <img src={logo} alt='logo' className='mb-2' style={{ width: '150px' }} />
            <p className="text-black-50">Tekrar hoş geldiniz! Lütfen giriş yapın.</p>
          </div>
          <Formik initialValues={{ username: '', password: '' }} onSubmit={(values) => handleLogin(values)}>
            {(
              <Form className='w-100'>
                <div className="mb-3">
                  <Field
                    as={TextField}
                    id='Login_username'
                    name='username'
                    className='form-control'
                    label="Kullanıcı Adı"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </div>
                <div className="mb-3">
                  <Field
                    as={TextField}
                    id='Login_password'
                    name='password'
                    className="form-control"
                    label="Şifre"
                    type={showPassword ? 'text' : 'password'}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                              {showPassword ? <IoEye /> : <IoEyeOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                  <button className="button-with-icon btn btn-outline-login btn-lg w-100" type='submit'>
                    <FaDoorOpen className='button-icon' />
                    Giriş Yap
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
}

export default Login;